import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { WebsiteConstants } from '.././global/website-constants';

@Injectable({
  providedIn: 'root'
})
export class MyDiscoverMatchesService {

  private masterUrl = WebsiteConstants.MasterUrl;

  MyDiscoverMatchesUrl = this.masterUrl+"DashboardNearYou/DashboardNearYou"
  
  MyDiscoverByProfessionUrl = this.masterUrl+"MyDiscoverByProfession/MyDiscoverByProfession"
  
  MyDiscoverByQualificationUrl = this.masterUrl+"MyDiscoverByQualification/MyDiscoverByQualification"
  constructor(private http : HttpClient) { }
  
  MyDiscoverMatchesPost(body,headers)
  {
    return this.http.post(this.MyDiscoverMatchesUrl,headers,body)
  }

  MyDiscoverByProfessionPost(body,headers)
  {
    return this.http.post(this.MyDiscoverByProfessionUrl,headers,body)
  }

  MyDiscoverByQualificationPost(body,headers)
  {
    return this.http.post(this.MyDiscoverByQualificationUrl,headers,body)
  }
}

