import { Component, OnInit, ViewEncapsulation} from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder }  from '@angular/forms';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {Router} from '@angular/router';
import { ImageService } from '../services/image.service';
import {MultiplePhotosService} from '../services/multiple-photos.service'
import {DeleteMultiplePhotoService} from '../services/delete-multiple-photo.service'
import * as $ from 'jquery';
import { WebsiteConstants } from '.././global/website-constants';

@Component({
  selector: 'app-photo-gallery',
  templateUrl: './photo-gallery.component.html',
  styleUrls: ['./photo-gallery.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PhotoGalleryComponent implements OnInit {

  images = []; myMessage:any; logshortlistmespinner:boolean;
  slideIndex = 0;  photopath:any
  SiteUrl = WebsiteConstants.SiteUrl; 
  sitetitle = WebsiteConstants.SiteTitle; 
  masterUrl = WebsiteConstants.MasterUrl;

  constructor(private imageService: ImageService, private multiplephotoservice :MultiplePhotosService,private deletePhoto : DeleteMultiplePhotoService) { }

  ngOnInit(): void {
    this.photopath = this.masterUrl;
    this.gallaryphotospost();
    }
    

    gallaryphotospost = function()
{
   this.logshortlistmespinner = true;
   let headers = new Headers({'Content-type' : 'application/json'});
   let body = new FormData();
   body.append('MemberID', localStorage.getItem('UserMemberID'));
   
   this.multiplephotoservice.galleryPhotopost(body,headers).subscribe(Response =>{
     if(Response.data !== undefined)
     {
       console.log("dadu"+Response.data)
       this.images = Response.data
       this.myMessage = "";
     }
     else
     {
      this.images = Response.status
      this.myMessage = "Photos are not available";
    }
   
   this.logshortlistmespinner = false;
    
   })
}

deletePhotoFromGallaryHash(photopath)
{
  let headers = new Headers({'Content-type' : 'application/json'});
   let body = new FormData();

   body.append('MemberID',localStorage.getItem('userid'))
   body.append('MemberMultiplePhotoPath',photopath);

   Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete this photo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {


  return this.deletePhoto.deleteMultiplePhotoPost(body,headers).subscribe(updateReq =>{
    
      this.gallaryphotospost();
    })

    } else if (result.dismiss === Swal.DismissReason.cancel) {
      
      }
    })
}

    
    openModal() {
      document.getElementById('imgModal').style.display = "block";
    }
  
    closeModal() {
      document.getElementById('imgModal').style.display = "none";
    }
  
  
     plusSlides(n) {
      this.showSlides(this.slideIndex += n);
    }
  
     currentSlide(n) {
      this.showSlides(this.slideIndex = n);
    }

    showSlides(slideIndex);
     showSlides(n) {
      let i;
      const slides = document.getElementsByClassName("img-slides") as HTMLCollectionOf<HTMLElement>;
      const dots = document.getElementsByClassName("images")as HTMLCollectionOf<HTMLElement>;
      if (n > slides.length) {this.slideIndex = 1}
      if (n < 1) {this.slideIndex = slides.length}
      for (i = 0; i < slides.length; i++) {
          slides[i].style.display = "none";
      }
      for (i = 0; i < dots.length; i++) {
          dots[i].className = dots[i].className.replace(" active", "");
      }
      slides[this.slideIndex-1].style.display = "block";
      if (dots && dots.length > 0) {
        dots[this.slideIndex-1].className += " active";
      }
    }

}
