
<div class="dashboardMain" id="activeButtDIV">
    <app-left-menu></app-left-menu>    
         <div class="admin-panel clearfix">
             <div class="main">
                 <app-top-menu></app-top-menu>
                 
             <div class="samplecover-spin" id="samplecover-spin" *ngIf="logspinner"></div> 
            <div class="dashMainInner" *ngIf="!logspinner">
                <div class="matchesInner">
                        
                    <div class="successStoMain">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="storyLeft">
                                    <img [src]='sPhoto' alt="">
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="storyRight">
                                    <div class="commo-subHead"><b>{{sName}}</b></div>
                                    

                                    <div class="commo-subHead" *ngIf="sMarrigeDate != '' "><b>{{sMarrigeDate}}</b></div>

                                    <p>
                                        {{sDiscription}}
                                    </p>
                                    
                                </div>
                            </div>
                        </div>
                      </div>
                      
                </div>
            </div>
 
 
         </div>
     </div>
 </div>
