import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler, HttpHeaders} from '@angular/common/http';
import { Observable,of } from 'rxjs';
import { catchError} from 'rxjs/Operators';
import{ Religion } from './religion';
import { headersToString } from 'selenium-webdriver/http';
import { WebsiteConstants } from '.././global/website-constants';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  private masterUrl = WebsiteConstants.MasterUrl;

  private religionurl = this.masterUrl+'GetReligions/GetReligions';
  private userexisturl = this.masterUrl+'CheckexitRegistration/CheckexitRegistration';
  private registration = this.masterUrl+'MemberRegistrationWeb/MemberRegistrationWeb';
  private casteurl = this.masterUrl+'GetCastedet/GetCastedet';
  private countryurl = this.masterUrl+'GetCountries/GetCountries';
  private stateurl = this.masterUrl+'GetStatedet/GetStatedet';
  private cityurl = this.masterUrl+'GetCitydet/GetCitydet';
  private gothraurl  = this.masterUrl+'GetGothras/GetGothras';
  private subcasturl = this.masterUrl+'GetSubCastedet/GetSubCastedet';
  private heighturl = this.masterUrl+'GetHeightsDet/GetHeightsDet';
  private bloodgrpurl = this.masterUrl+'GetBloodgroup/GetBloodgroup';
  private educationaurl  = this.masterUrl+'GetEducations/GetEducations';
  private occupationurl  = this.masterUrl+'MulOccup/DashOccup';

  constructor(private http:HttpClient) 
  { 

  }

  registrationUrl(body, headers)
  {
    return this.http.post(this.registration, body, headers);
  }

  getReligionData(headers)
  {
    return this.http.get(this.religionurl, headers);
  }

  getCountryData(headers)
  {
    return this.http.get(this.countryurl,headers);
  }

  getStateData(body, headers)
  {
    return this.http.post(this.stateurl, body, headers)
  }

  getCityData(body,headers)
  {
    return this.http.post(this.cityurl,body,headers)
  }


  userExistUrl(body, headers)
  {
    return this.http.post(this.userexisturl, body, headers);
  }

  getCasteData(body, headers)
  {
    return this.http.post(this.casteurl, body, headers);
  }

  getSubCastData(body,headers)
  {
    return this.http.post(this.subcasturl,body,headers);
  }

  getGothraData(headers)
  {
    return this.http.get(this.gothraurl,headers);
  }

  getEducationData(headers)
  {
    return this.http.get(this.educationaurl,headers);
  }

  getOccupationData(body,headers)
  {
    return this.http.post(this.occupationurl,body,headers);
  }

  getHeightData(headers)
  {
    return this.http.get(this.heighturl,headers);
  }
  getBloodGrpData(headers)
  {
    return this.http.get(this.bloodgrpurl,headers);
  }

}
