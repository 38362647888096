
<div class="dashboardMain" id="activeButtDIV">
    <app-left-menu></app-left-menu>     
<div class="admin-panel clearfix">
    <div class="main">
      <app-top-menu></app-top-menu>
          <div class="mainContent clearfix">
        
            <div id="editPreference">

              <div class="samplecover-spin" id="samplecover-spin" *ngIf="logspinner"></div>
               
                <div class="dashMainCont" *ngIf="!logspinner">
                  <div class="dashMainInner">
                    <div class="proDetailsDiv">

       
                 <h4 class="proHead"><i class="fa fa-cog"></i> Basic Preferences <i class="fa fa-pencil proEditIco" (click)="openMOdelbasicpreference()" ></i></h4>
                     <div class="proInnerCont">
                       <ul>
                          <li><b>Age</b><span>: {{userProfileData.MemberFromAge}} to {{userProfileData.MemberToAge}} Yrs</span></li>
                          <li><b>Height</b><span>: {{userProfileData.MemberDropdownFromH}} - {{userProfileData.MemberDropdownToH}}</span></li>
                          <li><b>Maritial Status</b><span>: {{marritalStatus}}</span></li>
                          <li><b>Disability</b><span>: {{disability}}</span></li>
                       </ul>
                     </div>
       
                 <h4 class="proHead"><i class="fa fa-book"></i> Religious Preferences <i class="fa fa-pencil proEditIco" (click)="openMOdelreligionpreference()" ></i></h4>
                 <div class="proInnerCont">
                   <ul>
                    <li><b>Mother Tongue</b><span>: {{mothertongue}}</span></li>
                    <li><b>Religion</b><span>: {{Religion}}</span></li>
                    <li><b>Caste</b><span>: {{userCaste}}</span></li>
                    <li><b>Sub Caste</b><span>: {{subCasteVal}}</span></li>
                    <li><b>Nakshtra</b><span>: {{nakshatraVal}}</span></li>
                    <li><b>Mangal Dosh</b><span>: {{userProfileData.MemberDosh}}</span></li>
                  </ul>
                 </div>
       
                 <h4 class="proHead"><i class="fa fa-map-marker"></i> Location Preferences <i class="fa fa-pencil proEditIco" (click)="openMOdellocationpreference()"></i></h4>
                 <div class="proInnerCont">
                   <ul>
                    <li><b>Country</b><span>: {{userProfileData.MemberCountryName}}</span></li>
                    <li><b>State</b><span>: {{userProfileData.MemberStateName}}</span></li>
                    <li><b>City</b><span>: {{userProfileData.MemberCityName}}</span></li>
                    <li><b>Job Country</b><span>: {{jobcountryVal}}</span></li>
                  </ul>
                 </div>
       
                 <h4 class="proHead"><i class="fa fa-graduation-cap"></i> Professional Preferences <i class="fa fa-pencil proEditIco" (click)="openMOdelprofessionalpreference()"></i></h4>
                 <div class="proInnerCont">
                   <ul>
                    <li><b>Education</b><span>: {{educationVal}}</span></li>
                    <li><b>Occupation</b><span>: {{occupationVal}}</span></li>
                    <li><b>Employee In</b><span>: {{employeeInVal}}</span></li>
                  </ul>
                 </div>
       
                 <h4 class="proHead"><i class="fa fa-user"></i> About Partner <i class="fa fa-pencil proEditIco"  (click)="openMOdel()"></i></h4>
                 <div class="proInnerCont aboutPartner">
                   {{userProfileData.MemberAboutPartner}}
                 </div>
       
       
             </div>

          </div>


    </div>
    
</div>



<!-- basic preference -->
<form [formGroup]="basicpreferencesForm">
  <div class="modal fade" id="basicpreferences" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle"> Basic Preferences</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModelbasicpreference()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">  

          <div class="row">
            <div class="col-md-6">
            <label class="basicPreHEadingPoint">Age</label>
            <div class="clear"></div>

            <!-- <div class="col-md-6"> -->
              <div class="row">
                <div class="col-md-6">
                <label>From </label>
              <select class="inputFil inputFilSelect" placeholder="Height" formControlName="agefrom"  [(ngModel)]="memfrmage">
                <option [ngValue]="null" selected>Age</option> 
                <option *ngFor="let a of agelist" value="{{a}}">{{a}}</option>
              </select>
              <div class="animTest w3-animate-zoom" *ngIf="issubmitbasicpreference && agefrom.invalid">
                <div *ngIf="agefrom.errors.required">Please Select Age</div>
            </div>
              </div>
              <div class="col-md-6">
                <label>To</label>
              <select class="inputFil inputFilSelect" placeholder="Height" formControlName="ageto"  [(ngModel)]="memtoage">
                <option [ngValue]="null" selected>Age</option> 
                <option *ngFor="let a of agelist" value="{{a}}">{{a}}</option>

              </select>
              <div class="animTest w3-animate-zoom" *ngIf="issubmitbasicpreference && ageto.invalid">
                <div *ngIf="ageto.errors.required">Please Select Age</div>
            </div>
              </div>
              </div>
            </div>
           
            <div class="col-md-6">
                <label class="basicPreHEadingPoint">Height</label>
                <div class="row">
                  <div class="col-md-6">
                  <label>From </label>
                <select class="inputFil inputFilSelect" placeholder="Height" formControlName="heightfrom"  [(ngModel)]="userProfileData.MemberFromHeight">
                  <option [ngValue]="null" selected>Height</option> 
                  <!-- HeightDisplayUser -->
                  <option *ngFor="let h of heightlist" value="{{h.HeightDisplayUser}}">{{h.HeightDropdownDisplay}}</option>
                </select>
                <div class="animTest w3-animate-zoom" *ngIf="issubmitbasicpreference && heightfrom.invalid">
                  <div *ngIf="heightfrom.errors.required">Please Select Height</div>
              </div>
                </div>
                <!-- <div class="clear"></div> -->

                <div class="col-md-6">

                  <label>To</label>
                <select class="inputFil inputFilSelect" placeholder="Height" formControlName="heightto"   [(ngModel)]="userProfileData.MemberToHeight">
                  <option [ngValue]="null" selected>Height</option> 
                  <!-- HeightDisplayUser -->
                  <option *ngFor="let h of heightlist" value="{{h.HeightDisplayUser}}">{{h.HeightDropdownDisplay}}</option>
                </select>
                <div class="animTest w3-animate-zoom" *ngIf="issubmitbasicpreference && heightto.invalid">
                  <div *ngIf="heightto.errors.required">Please Select Height</div>
              </div>
                </div>
                </div>
              </div>
           <div class="clear"></div>

            <div class="col-md-6" *ngIf="widow=='Female'">
              <label>Maritial Status</label>
              <div class="inputFil2">
              <ng-multiselect-dropdown
                [placeholder]="'Maritial Status'"
                [settings]="dropdownSettings"
                [data]="dropdownList_female"
                [(ngModel)]="mstarr"
                (onSelect)="onItemSelect_height($event)"
                (onDeSelect)="onItemDeSelect_height($event)"
                formControlName="mstatus"
              >
              </ng-multiselect-dropdown>
              </div>
              <div class="animTest w3-animate-zoom" *ngIf="issubmitbasicpreference && mstatus.invalid">
                <div *ngIf="mstatus.errors.required">Please select Maritial Status</div>
            </div>
            </div>

            <div class="col-md-6" *ngIf="widow=='Male'">
              <label>Maritial Status</label>
              <div class="inputFil2">
              <ng-multiselect-dropdown
                [placeholder]="'Maritial Status'"
                [settings]="dropdownSettings"
                [data]="dropdownList_male"
                [(ngModel)]="mstarr"
                (onSelect)="onItemSelect_height($event)"
                (onDeSelect)="onItemDeSelect_height($event)"
                formControlName="mstatus"
              >
              </ng-multiselect-dropdown>
              </div>
              <div class="animTest w3-animate-zoom" *ngIf="issubmitbasicpreference && mstatus.invalid">
                <div *ngIf="mstatus.errors.required">Please select Maritial Status</div>
            </div>
            </div>
           
            <div class="col-md-6">
              <label>Disability </label>
              <select class="inputFil inputFilSelect inputFilSelect2" formControlName="disabilit" [(ngModel)]="disability">
                <option [ngValue]="null" selected>Select</option>
                <option value="Any" >Any </option>
                <option value="Physical Disability" >Physical Disability </option>
                <option value="Normal" >Normal </option>
                </select>
                <div class="animTest w3-animate-zoom" *ngIf="issubmitbasicpreference && disabilit.invalid">
                    <div *ngIf="disabilit.errors.required">Please select Disability</div>
                </div>
            </div>
         
            <div class="clear"></div>
            
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModelbasicpreference()">Close</button>
          <button type="button" class="btn btn-primary" (click)="Editbasicpreference()">Save Changes</button>
        </div>
    </div>
  </div>
  </div>
  </form>




<!-- religious preference -->

<form [formGroup]="religiouspreferenceform">
  <div class="modal fade" id="religiousPreferencesEdit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle"> Religious Preferences</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModelreligiouspreference()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">  

          <div class="row">


            <div class="col-md-6">
              <label>Mother Tongue</label>
              <div class="inputFil2">
              <ng-multiselect-dropdown
                [placeholder]="'Mother Tongue'"
                [settings]="dropdownSettings"
                [data]="mother_toungue"
                [(ngModel)]="motarr"
                (onSelect)="onItemSelect_mothert($event)"
                (onDeSelect)="onItemDeSelect_mothert($event)"
                formControlName="mtongue"
              >
              </ng-multiselect-dropdown>
              </div>
              <div class="animTest w3-animate-zoom" *ngIf="isreligiouspreference && mtongue.invalid">
                <div *ngIf="mtongue.errors.required">Please select Mother Tongue</div>
            </div>
            </div>
            <div class="col-md-6">
              <label>Religion</label>
              <div class="inputFil2">
              <ng-multiselect-dropdown
                [placeholder]="'Religion'"
                [settings]="religiondropdownSettings"
                [data]="religionlist"
                [(ngModel)]="religarr"
                (onSelect)="getCasteDetails()"
                (onDeSelect)="getCasteDetails()"
                formControlName="relig"
              >
              </ng-multiselect-dropdown>
              </div>
              <div class="animTest w3-animate-zoom" *ngIf="isreligiouspreference && relig.invalid">
                <div *ngIf="relig.errors.required">Please select Religion</div>
            </div>
            </div>
            <div class="clear"></div>
            <div class="col-md-6">
              <label>Caste</label>
              <div class="inputFil2">
              <ng-multiselect-dropdown
                [placeholder]="'Caste'"
                [settings]="castdropdownSettings"
                [data]="castelist"
                [(ngModel)]="castarr"
                (onSelect)="getSubCastDetails()"
                (onDeSelect)="getSubCastDetails()"
                formControlName="caste"
              >
              </ng-multiselect-dropdown>    
              </div>
              <div class="animTest w3-animate-zoom" *ngIf="isreligiouspreference && caste.invalid">
                <div *ngIf="caste.errors.required">Please select Caste</div>
            </div>
            </div>        
            <div class="col-md-6">
              <label>Sub Caste</label>
              <div class="inputFil2">
              <ng-multiselect-dropdown
                [placeholder]="'Sub Caste'"
                [settings]="subcastdropdownSettings"
                [data]="subcastlist"
                [(ngModel)]="subcastarr"
                (onSelect)="onItemSelect_subcast($event)"
                (onDeSelect)="onItemDeSelect_subcast($event)"
                formControlName="subcaste"
              >
              </ng-multiselect-dropdown>
              </div>
              <div class="animTest w3-animate-zoom" *ngIf="isreligiouspreference && subcaste.invalid">
                <div *ngIf="subcaste.errors.required">Please select Sub Caste</div>
            </div>
            </div>
            <div class="clear"></div>
            <div class="col-md-6">
              <label>Nakshtra</label>
              <div class="inputFil2">
              <ng-multiselect-dropdown
                [placeholder]="'Nakshtra'"
                [settings]="dropdownSettings"
                [data]="nakshatralist"
                [(ngModel)]="naksharr"
                (onSelect)="onItemSelect_naksh($event)"
                (onDeSelect)="onItemDeSelect_naksh($event)"
                formControlName="nakshatra"
              >
              </ng-multiselect-dropdown>
              </div>
              <div class="animTest w3-animate-zoom" *ngIf="isreligiouspreference && nakshatra.invalid">
                <div *ngIf="nakshatra.errors.required">Please select Nakshtra</div>
            </div>
            </div>     
            <div class="col-md-6">
              <label>Mangal Dosh</label>
              <!-- [(ngModel)]="doshval"  -->
              <div class="radioSt radioSt2">
                <!-- <p class="extLab">Mangal Dosh</p> -->
                <input type="radio" id="doshno" class="maleInp" formControlName="mangalDosh" value="Any" [(ngModel)]="userProfileData.MemberDosh">
                <label for="doshno" class="maleStyle">Any</label>

              <input type="radio" id="doshno" class="maleInp" formControlName="mangalDosh" value="No" [(ngModel)]="userProfileData.MemberDosh">
              <label for="doshno" class="maleStyle">No</label>
              
              <input type="radio" id="doshyes" class="maleInp" formControlName="mangalDosh" value="Yes" [(ngModel)]="userProfileData.MemberDosh">
              <label for="doshyes" class="maleStyle">Yes</label>
            </div>
              <div class="animTest w3-animate-zoom" *ngIf="isreligiouspreference && mangalDosh.invalid">
                <div *ngIf="mangalDosh.errors.required">Please Select MangalDosh</div>
            </div>
            </div>

            <div class="clear"></div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModelreligiouspreference()">Close</button>
          <button type="button" class="btn btn-primary" (click)="Editreligiouspreference()">Save Changes</button>
        </div>
    </div>
  </div>
  </div>
  </form>


<!-- Professional Preferences -->
  <form [formGroup]="professionalpreferenceform">
    <div class="modal fade" id="professionalPreferencesEdit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle"> Professional Preferences</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModelprofessionalpreference()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">  
  
            <div class="row">
  
  
              <div class="col-md-6">
                <label>Education</label>
                <div class="inputFil2">
                <ng-multiselect-dropdown
                  [placeholder]="'Education'"
                  [settings]="educationdropdownSettings"
                  [data]="Educationlist"
                  [(ngModel)]="educarr"
                  (onSelect)="onItemSelect_educat($event)"
                  (onDeSelect)="onItemDeSelect_educat($event)"
                  formControlName="educat"
                >
                </ng-multiselect-dropdown>

                <!-- <angular2-multiselect 
                  [settings]="educationdropdownSettings"
                  [data]="Educationlist"
                  [(ngModel)]="educarr"
                  (onSelect)="onItemSelect_educat($event)"
                  (onDeSelect)="onItemDeSelect_educat($event)"
                  formControlName="educat"
                ></angular2-multiselect> -->


                </div>
                <div class="animTest w3-animate-zoom" *ngIf="isprofessionalpreference && educat.invalid">
                  <div *ngIf="educat.errors.required">Please select Education</div>
              </div>
              </div>  
              <div class="col-md-6">
                <label>Occupation</label>
                <div class="inputFil2">
                <ng-multiselect-dropdown
                  [placeholder]="'Occupation'"
                  [settings]="occupationdropdownSettings"
                  [data]="Occupationlist"
                  [(ngModel)]="occarr"
                 (onSelect)="onItemSelect_occ($event)"
                  (onDeSelect)="onItemDeSelect_occ($event)"
                  formControlName="occupation"
                >
                </ng-multiselect-dropdown>
                </div>
                <div class="animTest w3-animate-zoom" *ngIf="isprofessionalpreference && occupation.invalid">
                  <div *ngIf="occupation.errors.required">Please select Occupation</div>
              </div>
              </div>

              <div class="clear"></div>
              <div class="col-md-6">
                <label>Employee In</label>
                <div class="inputFil2">
                <ng-multiselect-dropdown
                [placeholder]="'Employee In'"
                [settings]="dropdownSettings"
                [data]="empin_list"
                [(ngModel)]="mpinarr"
               (onSelect)="onItemSelect_emp($event)"
                (onDeSelect)="onItemDeSelect_emp($event)"
                formControlName="empin"
              >
                </ng-multiselect-dropdown>
               </div>
              <div class="animTest w3-animate-zoom" *ngIf="isprofessionalpreference && empin.invalid">
                <div *ngIf="empin.errors.required">Please select employee In</div>
            </div>
              </div>
           

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModelprofessionalpreference()">Close</button>
            <button type="button" class="btn btn-primary" (click)="Editprofessionalpreference()">Save Changes</button>
          </div>
      </div>
    </div>
    </div>
    </form>


<!-- location Preferences -->
    <form [formGroup]="locationpreferenceform">
      <div class="modal fade" id="locationPreferencesEdit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle"> Location Preferences</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModellocationpreference()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">  
    
              <div class="row">
    
    
                <div class="col-md-6">
                  <label>Country</label>
                  <div class="inputFil2">
                  <ng-multiselect-dropdown
                    [placeholder]="'Country'"
                    [settings]="cntrdropdownSettings"
                    [data]="countrylist"
                    [(ngModel)]="cntarr"
                     (onSelect)="onChangeCountry()"
                    (onDeSelect)="onChangeCountry()"
                    formControlName="country"
                  >
                  </ng-multiselect-dropdown>
                  </div>
                  <div class="animTest w3-animate-zoom" *ngIf="islocationpreference && country.invalid">
                    <div *ngIf="country.errors.required">Please select Country</div>
                </div>
                </div>
    
    
                <div class="col-md-6">
                  <label>State</label>
                  <div class="inputFil2">
                  <ng-multiselect-dropdown
                    [placeholder]="'State'"
                    [settings]="statedropdownSettings"
                    [data]="statelist"
                    [(ngModel)]="statarr"
                   (onSelect)="onChangeState()"
                    (onDeSelect)="onChangeState()"
                    formControlName="state"
                  >
                  </ng-multiselect-dropdown>
                  </div>
                  <div class="animTest w3-animate-zoom" *ngIf="islocationpreference && state.invalid">
                    <div *ngIf="state.errors.required">Please select State</div>
                </div>
                </div>
             
                <div class="clear"></div>


                <div class="col-md-6">
                  <label>City</label>
                  <div class="inputFil2">
                  <ng-multiselect-dropdown
                  [placeholder]="'City'"
                  [settings]="citydropdownSettings"
                  [data]="citylist"
                  [(ngModel)]="cityarr"
                 (onSelect)="onItemSelect_city($event)"
                  (onDeSelect)="onItemDeSelect_city($event)"
                  formControlName="city"
                >
                </ng-multiselect-dropdown>
                 </div>
                <div class="animTest w3-animate-zoom" *ngIf="islocationpreference && city.invalid">
                  <div *ngIf="city.errors.required">Please select City</div>
              </div>
                </div>


                <div class="col-md-6">
                  <label>Job Country</label>
                  <div class="inputFil2">
                  <ng-multiselect-dropdown
                  [placeholder]="'Job Country'"
                  [settings]="cntrdropdownSettings"
                  [data]="countrylist_job"
                  [(ngModel)]="jbcntarr"
                  (onSelect)="onItemSelect_job($event)"
                  (onDeSelect)="onItemDeSelect_job($event)"
                  formControlName="jobcountry"
                
                >
                </ng-multiselect-dropdown>
                 </div>
                <div class="animTest w3-animate-zoom" *ngIf="islocationpreference && jobcountry.invalid">
                  <div *ngIf="jobcountry.errors.required">Please select Job Country</div>
              </div>
                </div>
             
                <div class="clear"></div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModellocationpreference()">Close</button>
              <button type="button" class="btn btn-primary" (click)="Editlocationlpreference()">Save Changes</button>
            </div>
        </div>
      </div>
      </div>
      </form>





      <form [formGroup]="aboutForForm">
        <div class="modal fade" id="proEditAbout" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">About Partner</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModel()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">  
              <textarea class="textAria" formControlName="aboutForm" name="aboutFormval"  [(ngModel)]="userProfileData.MemberAboutPartner"></textarea>
              <div class="animTest w3-animate-zoom" *ngIf="isSubmitAbout && aboutForm.touched && aboutForm.invalid">Please enter About Partner</div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModel()">Close</button>
                <button type="button" class="btn btn-primary" (click)="EditAboutUsHash()">Save Changes</button>
              </div>
          </div>
        </div>
        </div>
        </form>
</div>    

