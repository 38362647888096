import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { HttpClientModule} from '@angular/common/http'; //, HttpHandler, HttpHeaders
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { LoginService } from './services/login.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RegistrationService} from './services/registration.service';
import { ConfirmotpComponent } from './confirmotp/confirmotp.component';
import { StringreplacepipePipe } from './stringreplacepipe.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ScrollComponent } from './scroll/scroll.component';
import { MyMatchesComponent } from './my-matches/my-matches.component';
import { MyDashboardComponent } from './my-dashboard/my-dashboard.component';
import { NewMatchesComponent } from './new-matches/new-matches.component';
import { NearMeComponent } from './near-me/near-me.component';
import { ShortlistedComponent } from './shortlisted/shortlisted.component';
import { ViewedMyProfileComponent } from './viewed-my-profile/viewed-my-profile.component';
import { ShortlistedMeComponent } from './shortlisted-me/shortlisted-me.component';
import {ViewmemberdetailsService} from './services/viewmemberdetails.service';
import {MemberDetailsComponent} from './member-details/member-details.component'
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { QuickSearchComponent } from './quick-search-by-id/quick-search.component';
import { QuickSearchByIdService } from './services/quick-search-by-id.service';
import { NgImageSliderModule } from 'ng-image-slider';
import { MyDiscoverMatchesComponent } from './my-discover-matches/my-discover-matches.component';
import { UpdateProfileComponent } from './update-profile/update-profile.component';
import { MyDiscoverMatchesService} from './services/my-discover-matches.service';
import { UpdateProfileService } from './services/update-profile.service';
import { ProfilePreferredLocationComponent } from './profile-preferred-location/profile-preferred-location.component';
import { ProfilePreferredProfesssionComponent } from './profile-preferred-professsion/profile-preferred-professsion.component';
import { ProfilePreferredEducationComponent } from './profile-preferred-education/profile-preferred-education.component';
import { LeftMenuComponent } from './left-menu/left-menu.component';
import { TopMenuComponent } from './top-menu/top-menu.component';
import { EditPreferenceComponent } from './edit-preference/edit-preference.component';
import { SendInterestRequestComponent } from './send-interest-request/send-interest-request.component';
import { InboxRequestService } from './services/inbox-request.service';
import { UpgradeAccountComponent } from './upgrade-account/upgrade-account.component';
import { ShowNotificationComponent } from './show-notification/show-notification.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { PhotoGalleryComponent } from './photo-gallery/photo-gallery.component';
import {ImageService} from './services/image.service';
import { HelpScreenComponent } from './help-screen/help-screen.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { AdvanceSearchComponent } from './advance-search/advance-search.component';
import { SuccessStoriesComponent } from './success-stories/success-stories.component';
import { AdvanceDetailComponent } from './advance-detail/advance-detail.component';
import { SucessPaymentComponent } from './sucess-payment/sucess-payment.component';
import { FailPaymentComponent } from './fail-payment/fail-payment.component';
import { CancelPaymentComponent } from './cancel-payment/cancel-payment.component';
import {ViewSuccessStoryComponent} from './view-success-story/view-success-story.component'
import { PaymentNewComponent } from './payment-new/payment-new.component';
import { SuccessRedirectComponent } from './success-redirect/success-redirect.component'
// import {BottomComponent} from './bottom/bottom.component';
// import {HomeTopComponent} from './home-top/home-top.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { HomeTopComponent } from './home-top/home-top.component';
import { BottomComponent } from './bottom/bottom.component';
import { TermsUseComponent } from './terms-use/terms-use.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    RegistrationComponent,
    PagenotfoundComponent,
    LoadingSpinnerComponent,
    DashboardComponent,
    ConfirmotpComponent,
    StringreplacepipePipe,
    ScrollComponent,
    MyMatchesComponent,
    MyDashboardComponent,
    NewMatchesComponent,
    NearMeComponent,
    ShortlistedComponent,
    ViewedMyProfileComponent,
    ShortlistedMeComponent,
    MemberDetailsComponent,
    QuickSearchComponent,
    MyDiscoverMatchesComponent,
    UpdateProfileComponent,
    ProfilePreferredLocationComponent,
    ProfilePreferredProfesssionComponent,
    ProfilePreferredEducationComponent,
    LeftMenuComponent,
    TopMenuComponent,
    EditPreferenceComponent,
    SendInterestRequestComponent,
    UpgradeAccountComponent,
    ShowNotificationComponent,
    ChangePasswordComponent,
    PhotoGalleryComponent,
    HelpScreenComponent,
    AdvanceSearchComponent,
    SuccessStoriesComponent,
    AdvanceDetailComponent,
    SucessPaymentComponent,
    FailPaymentComponent,
    CancelPaymentComponent,
    ViewSuccessStoryComponent,
    PaymentNewComponent,
    SuccessRedirectComponent,
    // BottomComponent,
    // HomeTopComponent,
    AboutUsComponent,
    ContactUsComponent,
    HomeTopComponent,
    BottomComponent,
    TermsUseComponent,
    PrivacyPolicyComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    TimepickerModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    NgImageSliderModule,
    AngularMultiSelectModule
  ],
  providers: [LoginService,RegistrationService,ViewmemberdetailsService,QuickSearchByIdService,MyDiscoverMatchesService,UpdateProfileService,InboxRequestService,ImageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
