import { Component, HostListener, ViewChild } from '@angular/core';
import {Router, Event as RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  
  @ViewChild(BsDatepickerDirective, { static: false }) datepicker: BsDatepickerDirective;
  title = 'Matrimony';
  GlobelMessagebox = "Marathi ReshimGathi";

  loading:boolean;
  interval: any;

  constructor(private router: Router) {
    
        router.events.subscribe((event: RouterEvent) => {
          this.navigationInterceptor(event)
        })
      }
      
      // Shows and hides the loading spinner during RouterEvent changes
      navigationInterceptor(event: RouterEvent): void {
        
        if (event instanceof NavigationStart) {
          
          //this.loading = true;
        }
        if (event instanceof NavigationEnd) {
          if (this.interval) {
            clearInterval(this.interval);
          }
          //   this.interval = setInterval(() => {
          //   this.loading = false;
          // }, 500);
        }
    
        // Set loading state to false in both of the below events to hide the spinner in case a request fails
        if (event instanceof NavigationCancel) {
          this.loading = false;
        }
        if (event instanceof NavigationError) {
          this.loading = false;
        }
      }
    

      ngOnDestroy() {
        if (this.interval) {
          clearInterval(this.interval);
        }
      }
}
