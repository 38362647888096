import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { WebsiteConstants } from '.././global/website-constants';

@Injectable({
  providedIn: 'root'
})
export class AllnotificationService {

  private masterUrl = WebsiteConstants.MasterUrl;

  allnotificationUrl = this.masterUrl+"ViewNotifications/ViewNotifications";
  constructor(private http : HttpClient) { }

  notificationlist(body,headers)
  {
      return this.http.post(this.allnotificationUrl,body,headers)
  }

}
