<div class="innerPagesMain">
  <div class="homeMain">
      <app-home-top></app-home-top>
  

  <div class="innerPageBannerMain">
      <h3>About Brahmin Reshimgathi </h3>
  </div>
  <div class="innerPageBodyMain">
      
      <div class="container">
          <div class="col-md-12">
            <div class="contentDivMain abutmain">
              <span class="aboutPara">
                Brahmin Reshimgathi established by <b style="float: none;">Mrs. Pooja Lawate</b> in 2020 and has, since its inception, worked with passion, in the field of match-making with a gentle touch. Brahminreshimgathi.com is one of the pioneer online platforms to provide a superior matchmaking experience to all Brahmin community and its sub-caste all over India and worldwide. Brahmin Reshimgathi strongly belief that Marriage is not only a ceremony but its Sanskar and Birth of new beautiful relation between two families. Brahmin Reshimgathi provides your number of services through its online mobile mantrimony app which helps to find your perfect match.   Our purpose is to provide best quality services for brides and grooms seeking the perfect life partner.
              </span>
              <span class="aboutPara">
                You can register for Free and search according to your specific criteria on age, height, profession, income, location and much more – on your computer tablet or mobile.
              </span>
            </div>
            <div class="contentDivMain abutmain">
              <h3>Mrs. Pooja Lawate </h3>
              <b>Director of Brahmin Reshimgathi</b>
              <div class="aboutProDiv">
                <img src="../../assets/images/pooja.jpg"/>
              </div>
              <span class="aboutPara">
                Pooja Lawate is the Director of Brahmin Reshimgathi online platform service. She completed her science graduation from well-known college at Nasik.  She likes to interact with people, youngsters, like to hear from parents of bride and grooms. From interaction with parents, she realised how important is to start a platform which will provide number of matches making at one click from where one can choose perfect life partner for self and Dear ones. and with this beautiful thought Brahmin Reshimgathi was established in 2020. Her goal is to provide best quality service to Brahmin community. Pooja has networked with prospective brides and grooms, young married couples, and parents of prospective brides and grooms and has made it her mission to simplify the process of finding the right match.
              </span>
            </div>
          </div>


      </div>

      <!-- <section class="image-section m-0 p-0 image-bg-2 section-main">
          <div class="container-fluid p-0 m-0">
              <div class="bg-overlay-dark"></div>
              <div class="content">
                  <h1>beautiful template <span class="colorTxt">in-two</span></h1>
                  <p>you will get a short idea about our daily working process</p>
              </div>
          </div>

      </section> -->
  </div>

  <app-bottom></app-bottom>
</div>
</div>
