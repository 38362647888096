import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { MymatchesService } from '../services/mymatches.service';
import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router'
import {InboxRequestService} from '../services/inbox-request.service'
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as $ from 'jquery';
import { WebsiteConstants } from '.././global/website-constants';
@Component({
  selector: 'app-send-interest-request',
  templateUrl: './send-interest-request.component.html',
  styleUrls: ['./send-interest-request.component.css']
})
export class SendInterestRequestComponent implements OnInit {
  lognearmespinner:any;pendingData:any;message;any;photopath:any;profilePic:any;acceptReq:any;acceptMsg:any;declineReq:any;declineMsg:any;slectval:any;logspinner=false;sentPendingData:any;sentMessage:any; sentAcceptedData:any; sentDeclinedData:any;

  SiteUrl = WebsiteConstants.SiteUrl; 
  sitetitle = WebsiteConstants.SiteTitle; 
  masterUrl = WebsiteConstants.MasterUrl;

  constructor(private inboxRequest : InboxRequestService,private route : Router, private service:MymatchesService) {
    this.slectval  = 'Inbox'
   }
  

  ngOnInit(): void {
    this.photopath = this.masterUrl;
    this.profilePic = this.masterUrl+localStorage.getItem("UserPhotoPath");
    this.sentRemoveInterst();
    this.pendingRequestHash();
  }

  memberdetailspost(id)
  {
    localStorage.setItem('viewUser',id)
    let link = ['/member-details'];
    this.route.navigate(link);
  }

  pendingRequestHash()
  {
    if(this.slectval == "Sent")
    {
       this.selectOption(this.slectval)
    }
    else
    {

    this.logspinner = true;
    $('#tab1defaultt').addClass('in active');
    $('#tab2defaultt').removeClass('in active');
    $('#tab3defaultt').removeClass('in active');
    $('#tab4defaultt').removeClass('in active');
    $('#tab5defaultt').removeClass('in active');
    $('#tab6defaultt').removeClass('in active');


    $('#pendingTab1').addClass('active');
    $('#pendingTab2').removeClass('active');
    $('#pendingTab3').removeClass('active');

    let headers = new Headers ({'content-type' : 'application/json'})
    let body = new FormData

    body.append('MemberID',localStorage.getItem('UserMemberID'))
    return this.inboxRequest.pendingRequestPost(body,headers).subscribe(pendingResult =>{
      
      if(pendingResult['data'] !== undefined)
      {
        this.pendingData = pendingResult['data']
        this.message = ""
        this.logspinner = false;
      }
      else
      {
        this.pendingData = pendingResult['status']
        this.message = pendingResult['message']
        this.logspinner = false;
      }
    })
  }
  }

  sentRemoveInterst()
  {
      let headers = new Headers({ 'Content-Type': 'application/json' });
      let body = new FormData();
      body.append('MemberID',localStorage.getItem('UserMemberID'));
      this.service.messageclearcount(body,headers).subscribe(Response =>{
        let link = ['/send-interest-request']
        this.route.navigate(link);

      })

    
  }
  acceptRequest()
  {
    if(this.slectval == "Sent")
    {
       this.sentaccepted(this.slectval)
    }
    else
    {
    this.logspinner = true;
    $('#tab1defaultt').removeClass('in active');
    $('#tab2defaultt').addClass('in active');
    $('#tab3defaultt').removeClass('in active');
    $('#tab4defaultt').removeClass('in active');
    $('#tab5defaultt').removeClass('in active');
    $('#tab6defaultt').removeClass('in active');


    $('#pendingTab1').removeClass('active');
    $('#pendingTab2').addClass('active');
    $('#pendingTab3').removeClass('active');


    let headers = new Headers({'content-type' : 'application/json'})
    let body = new FormData

    body.append('MemberID',localStorage.getItem('UserMemberID'))
    body.append('MembStatus','Accepted')
    return this.inboxRequest.ShowAcceptDeclinedRequestPost(body,headers).subscribe(showAccept =>{
      console.log("aish"+JSON.stringify(showAccept['data']))
      if(showAccept['data'] !== undefined)
      {
        
        this.acceptReq = showAccept['data']
        this.acceptMsg = ""
        this.logspinner = false;
      }
      else
      {
        this.acceptReq = showAccept['status']
        this.acceptMsg = showAccept['message']
        this.logspinner = false;
      }
    })
   }
  }
  DeclineRequest()
  {
    if(this.slectval == "Sent")
    {
       this.sentdeclined(this.slectval)
    }
    else
    {
    this.logspinner = true;
    $('#tab1defaultt').removeClass('in active');
    $('#tab2defaultt').removeClass('in active');
    $('#tab3defaultt').addClass('in active');
    $('#tab4defaultt').removeClass('in active');
    $('#tab5defaultt').removeClass('in active');
    $('#tab6defaultt').removeClass('in active');

    $('#pendingTab1').removeClass('active');
    $('#pendingTab2').removeClass('active');
    $('#pendingTab3').addClass('active');

  
    let headers = new Headers({'content-type' : 'application/json'})
    let body = new FormData

    body.append('MemberID',localStorage.getItem('UserMemberID'))
    body.append('MembStatus','Declined')
    return this.inboxRequest.ShowAcceptDeclinedRequestPost(body,headers).subscribe(showDecline =>{
      console.log("aish"+JSON.stringify(showDecline['data']))
      
      if(showDecline['data']  !== undefined)
      {
        this.declineReq = showDecline['data']
        this.declineMsg = ""
        this.logspinner = false;
      }
      else
      {
        this.declineReq = showDecline['status']
        this.declineMsg = showDecline['message'] 
        this.logspinner = false;
      }
    })
  }
  }

  acceptRequestInfo(row)
  {
     Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to Accept an interest?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {

        let acceptReqt = 'You have Accepted request '+ row.MemberFullName +' '+ row.MemberProfileID
          Swal.fire({
            title : 'Accepted', 
            text: acceptReqt, 
            icon : 'success',
            showConfirmButton : false,
            timer : 1500});

          $('#pendingReq_'+row.MemberID).css('display','none');
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let body = new FormData();
    body.append('MemberID',localStorage.getItem('UserMemberID'))
    body.append('MemberName',localStorage.getItem('UserFullName') )
    body.append('MembStatus','Accepted')
    body.append('MembrequstorID',row.MemberID)
    body.append('MembSessName',row.MemberFullName)
    return this.inboxRequest.updateRequestPost(body,headers).subscribe(updateReq =>{
      console.log("hello"+updateReq)
    })
      
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      
      }
    })
  }

  declineRequestInfo(row)
  {

    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to Decline an interest?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        
        let declineReqt = 'You have Declined  request '+ row.MemberFullName +' '+ row.MemberProfileID
        Swal.fire({
          title : 'Declined', 
          text: declineReqt, 
          icon : 'success',
          showConfirmButton : false,
          timer : 1500});

          $('#pendingReq_'+row.MemberID).css('display','none');
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let body = new FormData();
    body.append('MemberID',localStorage.getItem('UserMemberID'))
    body.append('MemberName',localStorage.getItem('UserFullName') )
    body.append('MembStatus','Declined')
    body.append('MembrequstorID',row.MemberID)
    body.append('MembSessName',row.MemberFullName)
    return this.inboxRequest.updateRequestPost(body,headers).subscribe(updateReq =>{
      console.log("hello"+updateReq)
    })
      
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      
      }
    })
  }

  selectOption(value : string)
  {
    if(value == 'Inbox')
    {
      this.pendingRequestHash()
    }
    else
    {
      this.logspinner = true;
      $('#tab1defaultt').removeClass('in active');
      $('#tab2defaultt').removeClass('in active');
      $('#tab3defaultt').removeClass('in active');
      $('#tab4defaultt').addClass('in active');
      $('#tab5defaultt').removeClass('in active');
      $('#tab6defaultt').removeClass('in active');


      $('#pendingTab1').addClass('active');
      $('#pendingTab2').removeClass('active');
      $('#pendingTab3').removeClass('active');

    let headers = new Headers ({'content-type' : 'application/json'})
    let body = new FormData

    body.append('MemberID',localStorage.getItem('UserMemberID'))
    return this.inboxRequest.ShowSentRequestdetailsPost(body,headers).subscribe(sentPendingResult =>{
      if(sentPendingResult['data'] !== undefined)
      {
        this.sentPendingData = [];
        for(let cnt=0; cnt<sentPendingResult['data'].length; cnt++)
            {
                if(sentPendingResult['data'][cnt]['MemberShortMessage'] == "ADD PHOTO REQUESTED" || sentPendingResult['data'][cnt]['MemberShortMessage'] == "INTEREST SENT")
                {
                  this.sentPendingData.push(sentPendingResult['data'][cnt]);
                }

          }


        this.sentMessage = ""
        this.logspinner = false;

      }
      else
      {
        this.sentPendingData = sentPendingResult['status']
        this.sentMessage = sentPendingResult['message']
        this.logspinner = false;
      }
    })

  }
}



sentaccepted(value : string)
  {
  
    if(value == 'Inbox')
    {
      this.acceptRequest()
    }
    else
    {
    
    this.logspinner = true;
    $('#tab1defaultt').removeClass('in active');
    $('#tab2defaultt').removeClass('in active');
    $('#tab3defaultt').removeClass('in active');
    $('#tab4defaultt').removeClass('in active');
    $('#tab5defaultt').addClass('in active');
    $('#tab6defaultt').removeClass('in active');


    $('#pendingTab1').removeClass('active');
    $('#pendingTab2').addClass('active');
    $('#pendingTab3').removeClass('active');

    let headers = new Headers ({'content-type' : 'application/json'})
    let body = new FormData

    body.append('MemberID',localStorage.getItem('UserMemberID'))
    return this.inboxRequest.ShowSentRequestdetailsPost(body,headers).subscribe(sentAcceptedResult =>{
      if(sentAcceptedResult['data'] !== undefined)
      {
        this.sentAcceptedData = [];
        for(let cnt=0; cnt<sentAcceptedResult['data'].length; cnt++)
            {
                if(sentAcceptedResult['data'][cnt]['MemberShortMessage'] == "INTEREST ACCEPTED")
                {
                  this.sentAcceptedData.push(sentAcceptedResult['data'][cnt]);
                }

          }
        this.sentMessage = ""
        this.logspinner = false;

      }
      else
      {
        this.sentAcceptedData = sentAcceptedResult['status']
        this.sentMessage = "Record not available.";
        this.logspinner = false;
      }
    })

  }
}


sentdeclined(value : string)
  {
    if(value == 'Inbox')
    {
      this.DeclineRequest()
    }
    else
    {
     this.logspinner = true;
    $('#tab1defaultt').removeClass('in active');
    $('#tab2defaultt').removeClass('in active');
    $('#tab3defaultt').removeClass('in active');
    $('#tab4defaultt').removeClass('in active');
    $('#tab5defaultt').removeClass('in active');
    $('#tab6defaultt').addClass('in active');

    $('#pendingTab1').removeClass('active');
    $('#pendingTab2').removeClass('active');
    $('#pendingTab3').addClass('active');

    let headers = new Headers ({'content-type' : 'application/json'})
    let body = new FormData

    body.append('MemberID',localStorage.getItem('UserMemberID'))
    return this.inboxRequest.ShowSentRequestdetailsPost(body,headers).subscribe(sentDeclinedResult =>{
      
      if(sentDeclinedResult['data'] !== undefined)
      {
        //this.sentPendingData = sentPendingResult['data'];

        this.sentDeclinedData = [];
        for(let cnt=0; cnt<sentDeclinedResult['data'].length; cnt++)
            {
                if(sentDeclinedResult['data'][cnt]['MemberShortMessage'] == "INTEREST DECLINED")
                {
                  this.sentDeclinedData.push(sentDeclinedResult['data'][cnt]);
                }

          }
        
        this.sentMessage = ""
        this.logspinner = false;

      }
      else
      {
        this.sentDeclinedData = sentDeclinedResult['status']
        this.sentMessage = sentDeclinedResult['message']
        this.logspinner = false;
      }
    })

  }
}
  

redirectuser()
{
  let link = ['/update-profile'];
  this.route.navigate(link);
}


}
