import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { WebsiteConstants } from '.././global/website-constants';

@Injectable({
  providedIn: 'root'
})
export class QuickSearchByIdService {

  private masterUrl = WebsiteConstants.MasterUrl;
  private siteurl=this.masterUrl;
  private quichSearchByIdUrl = this.siteurl+"QuickSearchByIDName/QuickSearchByIDName";
  constructor(private http : HttpClient) { }
  quichSearchByIdPost(body, headres)
  {
     return this.http.post(this.quichSearchByIdUrl,body, headres)
  }

}
