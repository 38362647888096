
<div class="dashboardMain" id="activeButtDIV">
  
  
<app-left-menu></app-left-menu>

<div class="admin-panel clearfix">
  <div class="main">
           

            <app-top-menu></app-top-menu>
           


            <div id="myMatche" class="myMatchesStyle" >
              <div class="search-reault" 
      infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
      (scrolled)="onScroll()">

      <div class="mainContent clearfix">
              <div class="samplecover-spin" id="samplecover-spin" *ngIf="logspinner"></div>
               <div class="dashMainCont" *ngIf="!logspinner">
                 <div class="dashMainInner">
                    <div class="matchesInner">
                      
                      
                      <div class="offerDivMain" *ngIf="userIsPaid == 0  && AppDiscountBannerFlag==1">
                        <!-- <img src="../../assets/images/addNew.jpg"/> -->
                        <img [src]="FlashWebBannerPath"/>
                      </div>



                      <div class="optMainOuter">
                        <select class="optMain"[(ngModel)]="slectval" (change)="selectOption($event.target.value)">
                          <option value="Desc">Newest Profile First</option>
                          <option value="Asc">Oldest Profile First</option>
                          <option value="RAND()">Random Profiles</option>
                        </select>

                      </div>

                      <div class="row">    
                    <div *ngIf="mymatchesresult?.length > 0">  
                    <div *ngFor="let responceData of mymatchesresult"> 
                        <div class="col-md-4">
                          <div class="matchesDiv">
                              <!-- <div class="ignorBt">Ignore <i class="fa fa-close"></i></div> -->
                              <div class="matchesPic">
                                <div class="coun" *ngIf="responceData.MemberCNtMultiplePhotos > 0">{{responceData.MemberCNtMultiplePhotos}}</div>
                                <div class="leftA"> <i class="fa fa-angle-left"></i> </div>
                                <div class="rightA"> <i class="fa fa-angle-right"></i> </div>
                                
                                  <img [src]='photopath+responceData.MemberPhotoPath'>
                                  
                                  <div *ngIf="responceData.MemberIsPhotoUpload == '0' && responceData.MembPhotoAddFlag== '0'">
                                  <button class="requestBtUp" id="requestphoto{{responceData.MemberID}}"(click)="photoRequestDash($event.target,responceData)">Request Photo </button>
                                </div>

                                <div *ngIf="responceData.MemberIsPhotoUpload == '0' && responceData.MembPhotoAddFlag== '1'">
                                  <button class="requestBtUp" id="requestphoto{{responceData.MemberID}}" disabled>Photo Request Sent </button>
                                </div>

                                  <i class="fa fa-camera phoIco" *ngIf="responceData.MemberCNtMultiplePhotos > 0"></i>
                              </div>
                              <div class="callDetais">
                                  <div class="floatNeed">
                                  <span data-tooltip="Call Now"><i class="fa fa-phone phoneIc" (click)="openMOdel(responceData,masterUrl)"></i></span>


      <span data-tooltip="Sent Interest" *ngIf="responceData.MemberSentInterestFlag !== '0'">
        <i class="fa fa-heart heartIc likeIntro" id="sentInterst{{responceData.MemberID}}" *ngIf="responceData.MemberSentInterestFlag !== '0'"></i>
        <i class="fa fa-heart heartIc" id="sentrunInterst{{responceData.MemberID}}" *ngIf="responceData.MemberSentInterestFlag == '0'" (click)="memberInterestlist(responceData)"></i>
      </span>

      <span data-tooltip="Send Interest" *ngIf="responceData.MemberSentInterestFlag == '0'">
        <i class="fa fa-heart heartIc likeIntro" id="sentInterst{{responceData.MemberID}}" *ngIf="responceData.MemberSentInterestFlag !== '0'"></i>
        <i class="fa fa-heart heartIc" id="sentrunInterst{{responceData.MemberID}}" *ngIf="responceData.MemberSentInterestFlag == '0'" (click)="memberInterestlist(responceData)"></i>
      </span>

      <span data-tooltip="Sent Interest" >
        <i class="fa fa-heart heartIc hideHeart" id="sentshowInterst{{responceData.MemberID}}"></i>
      </span>
      
      <input type="hidden" name="hiddeninterest{{responceData.MemberID}}" id="hiddeninterest{{responceData.MemberID}}" value="{{responceData.MemberSentInterestFlag}}" [(ngModel)]="responceData.MemberSentInterestFlag">

                                  <span data-tooltip="Shortlist Profile">
                                    <i class="fa fa-star starIc shortLis"  id="shortListID{{responceData.MemberID}}" *ngIf="responceData.MemberShortlistFlag !== '0'" (click)="membershortlist(responceData)"></i>
                                    <i class="fa fa-star starIc" id="shortListID{{responceData.MemberID}}" *ngIf="responceData.MemberShortlistFlag == '0'"  (click)="membershortlist(responceData)"></i>
                                    <input type="hidden" name="hiddenshort{{responceData.MemberID}}" id="hiddenshort{{responceData.MemberID}}" value="{{responceData.MemberShortlistFlag}}" [(ngModel)]="responceData.MemberShortlistFlag">
                                  </span>



                                  </div>
      
                                  <div class="detailsOuter">
                                  <h4 class="callDetaillsName">{{responceData.MemberName}} ({{responceData.MemberProfileID}})</h4>
                                  <p>{{responceData.MemberEducation}}, Job-City - {{responceData.MemberJobCity}}</p>
                                  <p>{{responceData.MemberAge}} yrs, {{responceData.MemberHeight}} | {{responceData.MemberMartialStatus}}</p>
                                  <p>{{responceData.MemberCaste}}, {{responceData.MemberNativePlace}} </p>
                                </div>
                                  
                                  <button type="button" class="proDetailsBt" (click)="memberdetailspost(responceData)">
                                    View Profile
                                  </button>
                              </div>
                          </div>
                      </div>
                    </div>
                    </div>
                    <div *ngIf="mymatchesresult==0" class="noDataMessage">
                      <img src="../../assets/images/no-data-pana.png"/>
                      {{mymessage}}
                    </div>
        </div>
      
                      
      
      
      
                      </div>
                    </div>
                 </div>
               </div>
            </div>

          </div>







    </div>
    
</div>
</div>    
<div class="samplecover-spin" id="samplecover-spin" *ngIf="spinner"></div>



<div class="modal fade" id="upPhoto" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Upload Your Photo</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModelPersonal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" style="text-align: center;">
          <div class="upPicOuter">
            <label class="newbtn" (click)="redirectuser()">
                <img id="blah2" src="assets/images/default-avatar.jpg" >
                <i class="fa fa-pencil pencilUpPic" (click)="redirectuser()"></i>
            </label>
          </div>
          <p>Profile with photo give 10 times better response. Add quality profile photo.</p>
          <p class="upPhoConText">You can make your photo visible ONLY to people you send interests to and people you accept. Your photo will not be visible to the rest of the members on the site. Do you want to upload your photo now ?</p>
          <input id="pic" class='pis' onchange="readURL(this);" type="file" >
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModelPersonal()">Close</button>
          <button type="button" class="btn btn-primary" (click)="redirectuser()">Upload photo</button>
        </div>
    </div>
  </div>
</div>



<div class="modal fade success-popup in" id="callModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" style=" padding-left: 17px;">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content">
      
      <div class="modal-body text-center">
        <i class="fa fa-close callPopDele" (click)="closePopup()" data-dismiss="modal"></i>
         <div class="calImgDiv" id="callImgDivs">
          <img src="" >
         </div>
         <h4 id="popupName">Contact Akshda Meshra on</h4>
          <p id="popupContact"></p>
      </div>
      <div class="modal-footer" id="popupupgrads">
        
      </div>
    </div>
  </div>
</div>









