<div class="headerMain">
    <div class="regiMain forInnerTop">
    <div class="container">
        <div class="logoMainLogo">
            <nav class="navbar navbar-default">
  <div class="container-fluid">
  <!-- Brand and toggle get grouped for better mobile display -->
  <div class="navbar-header">
  <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
    <span class="sr-only">Toggle navigation</span>
    <span class="icon-bar"></span>
    <span class="icon-bar"></span>
    <span class="icon-bar"></span>
  </button>
  <a class="navbar-brand" href="/"><img src="assets/images/br-logo1.png" alt="logo"></a>
  </div>
  
  <!-- Collect the nav links, forms, and other content for toggling *ngIf="!usersession"-->
  <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
  <ul class="nav navbar-nav navbar-right" *ngIf="isusertopauth">
    <li><a [routerLink]="['/login']">Login</a></li>
    <li>|</li>
    <li><a [routerLink]="['/registration']">Register Free</a></li>
  </ul>

  <!-- <ul class="nav navbar-nav navbar-right" *ngIf="usersession">
    <li><a [routerLink]="['/my-matches']">Welcome {{candidatename}}</a></li>
    <li>|</li>
    <li><a [routerLink]="['/my-matches']">Dashboard</a></li>
    <li>|</li>
    <li><a href="Javascript:void(0)" (click)="logout()">Logout</a></li>
  </ul> -->

  </div><!-- /.navbar-collapse -->
  </div><!-- /.container-fluid -->
  </nav>
        </div>
    </div>
  </div>
</div>



