
<div class="dashboardMain" id="activeButtDIV">
    
  <app-left-menu></app-left-menu>   
  <div class="admin-panel clearfix">
    <div class="main">
            
      <app-top-menu></app-top-menu>
             
  
  
              <div id="proDetails">
                <!-- <h2 class="header"><a href="#" class="backArrow"><i class="fa fa-arrow-circle-o-left"></i></a>Profile Details</h2> -->
                <div class="samplecover-spin" id="samplecover-spin" *ngIf="prodetailspinner"></div>
                <div class="dashMainCont proDetailMain" *ngIf="!prodetailspinner">
                   <div class="dashMainInner">
                         <div class="row">
                           

                          <div class="col-md-12">
                            <div class="slider-box-block" >
                              <div class="firstSlide">
                                <ng-image-slider [images]="imageObject" #nav></ng-image-slider>
                              </div>
                           </div>  
                           </div>



                           
       
                           <div class="col-md-12">
                             <div class="proDetailsDiv">
                 <h4 class="proHead proHead2"><i class="fa fa-user"></i> About My {{memberdetailsresult.MemberProfileFor}}</h4>
                 <div class="proInnerCont">
                  <p>{{memberdetailsresult.MemberAboutMe}}</p>
                 </div>
       
                 <h4 class="proHead"><i class="fa fa-list"></i> Personal Details</h4>
                 <div class="proInnerCont">
                  <ul>
                    <li><b>Profile ID </b><span>: {{memberdetailsresult.MemberProfileID}}</span></li>
                    <li><b>Full Name</b><span>: {{memberdetailsresult.MemberFullName}}</span></li>
                    <li><b>Gender</b><span>: {{memberdetailsresult.MemberGender}}</span></li>
                    <li><b>Date of Birth </b><span>: {{memberdetailsresult.MemberDOB}}</span></li>
                    
                    <li><b>Age</b><span>: {{memberdetailsresult.MemberAge}}</span></li>
                    <li><b>height</b><span>: {{memberdetailsresult.MemberHeight}} (173 cm)</span></li>
                    <li><b>Birth Time</b><span>: {{memberdetailsresult.MemberBirthTime}}</span></li>
                    <li><b>Birth Place</b><span>: {{memberdetailsresult.MemberBirthPlace}}</span></li>
                    
                    <li><b>Marital Status</b><span>: {{memberdetailsresult.MemberMartialStatus}}</span></li>

                    <li *ngIf="hidemaritalstatus"><b>No. of Children</b><span>: {{memberdetailsresult.MemberNoOfChild}}</span></li>

                    <li *ngIf="hidemaritalstatus"><b> </b><span>: {{memberdetailsresult.MemberChildStay}}</span></li>



                    <li><b>Mother Tounge</b><span>: {{memberdetailsresult.MemberMotherTongue}}</span></li>
                    <li><b>Complexion</b><span>: {{memberdetailsresult.MemberComplexion}}</span></li>
                    <li><b>Blood Group</b><span>: {{memberdetailsresult.MemberBloodGroup}}</span></li>
                    <li><b>Disability</b><span>: {{memberdetailsresult.MemberDisability}}</span></li>
                  </ul>
                 </div>
       
                 <h4 class="proHead"><i class="fa fa-globe"></i> Religion Details</h4>
                 <div class="proInnerCont">
                  <ul>
                    <li><b>Religion</b><span>: {{memberdetailsresult.MemberRelgName}}</span></li>
                    <li><b>Caste</b><span>: {{memberdetailsresult.MemberCasteName}}</span></li>
                    <li><b>Sub Caste </b><span>: {{memberdetailsresult.MemberSubCastName}}</span></li>
                    <li><b>Gotra</b><span>: {{memberdetailsresult.MemberGothra}}</span></li>
                    <li><b>Raas</b><span>: {{memberdetailsresult.MemberRaas}}</span></li>
                    <li><b>Charan</b><span>: {{memberdetailsresult.MemberCharan}}</span></li>
                    <li><b>Gana</b><span>: {{memberdetailsresult.MemberGana}}</span></li>
                    <li><b>Nakshtra</b><span>: {{memberdetailsresult.MemberNakshtra}}</span></li>
                    <li><b>Nadi</b><span>: {{memberdetailsresult.MemberNadi}}</span></li>
                    <li><b>Mangal Dosh</b><span *ngIf="memberdetailsresult.MemberDosh !== '0'">: 
                    Yes</span><span *ngIf="memberdetailsresult.MemberDosh === '0'">: No</span></li>
                  </ul>
                 </div>
       
                 <h4 class="proHead"><i class="fa fa-drivers-license"></i> Contact Details</h4>
                 <div class="proInnerCont">
                  <ul>
                    <li><b>Contact Number</b><span *ngIf="memberdetailsresult.MemberContactNo !== '0'">:
                      {{memberdetailsresult.MemberContactNo}}</span><span *ngIf="memberdetailsresult.MemberContactNo === '0'">: XXXXXXXXXX</span></li>
                    <li><b>Email ID</b><span *ngIf="memberdetailsresult.MemberEmailID !== '' ">: {{memberdetailsresult.MemberEmailID}}</span><span *ngIf="memberdetailsresult.MemberEmailID ==='' ">: XXX@XXXX.com</span></li>

                    <li class="upgradeBtListing" *ngIf="userIsPaid == '0'"><b><i class="fa fa-lock"></i> &nbsp; To unlock the contact detail</b><span>: <button><a href="Javascript:void(0)" (click)="UpgradeMembership()">Upgrade Now </a></button></span></li>
                  </ul>
                 </div>
       
                 <h4 class="proHead"><i class="fa fa-map-marker"></i> Address Details</h4>
                 <div class="proInnerCont">
                  <ul>
                    <li><b>Address</b><span>: {{memberdetailsresult.MemberAddress}}</span></li>
                    <li><b>Resident State</b><span>: {{memberdetailsresult.MemberStateName}}</span></li>
                    <li><b>Resident City / District</b><span>: {{memberdetailsresult.MemberCityName}}</span></li>
                    <li><b>Resident Taluka</b><span>: {{memberdetailsresult.MemberTaluka}}</span></li>
                  </ul>
                 </div>
       
                 <h4 class="proHead"><i class="fa fa-drivers-license"></i> Professional Details</h4>
                 <div class="proInnerCont">
                  <ul>
                    <!-- <li><b>Education</b><span>: {{memberdetailsresult.MemberEductionGroupName}}</span></li> -->
                    <li *ngIf="memberdetailsresult.MemberOtherEducation !='' && memberdetailsresult.MemberOtherEducation != null"><b>Education</b><span>: {{memberdetailsresult.MemberHeightsEducation}},{{memberdetailsresult.MemberOtherEducation}}</span></li>

                    <li *ngIf="memberdetailsresult.MemberOtherEducation =='' || memberdetailsresult.MemberOtherEducation == null"><b>Education</b><span>: {{memberdetailsresult.MemberHeightsEducation}}</span></li>

                    <li><b>Employee in</b><span>: {{memberdetailsresult.MemberEmployedIn}}</span></li>
                    <li><b>Occupation</b><span>: {{memberdetailsresult.MemberOccupation}}</span></li>
                    <li><b>Job Country</b><span>: {{memberdetailsresult.MemberJobCountryName}}</span></li>
                    <li><b>Job City</b><span>: {{memberdetailsresult.MemberJobCity}}</span></li>
                    <li><b>Annual Income</b><span>: {{memberdetailsresult.MemberAnnualIncome}}</span></li>
                  </ul>
                 </div>
       
                 <h4 class="proHead"><i class="fa fa-users"></i> Family Details</h4>
                 <div class="proInnerCont">
                  <ul>
                    <li><b>Father Name</b><span>: {{memberdetailsresult.MemberFatherName}}</span></li>
                    <li><b>Father's Occupation</b><span>: {{memberdetailsresult.MemberFatherOccupation}}</span></li>
                    <li><b>Father's/Mother's/Guardian's Contact No</b><span>: {{memberdetailsresult.MemberFatherContactNo}}</span></li>
                    <li><b>Mother's Name</b><span>: {{memberdetailsresult.MemberMotherName}}</span></li>
                    <li><b>Mother's Occupation</b><span>: {{memberdetailsresult.MemberMotherOccupation}}</span></li>
                    <li><b>Brother(S)</b><span>: {{memberdetailsresult.MemberBrotherNos}}</span></li>
                    <li><b>Brother(S) Married</b><span>: {{memberdetailsresult.MemberBMarried}}</span></li>
                    <li><b>Sister(S)</b><span>: {{memberdetailsresult.MemberSisterNos}}</span></li>
                    <li><b>Sister(S) Married</b><span>: {{memberdetailsresult.MemberSMarried}}</span></li>
                    <li><b>Family Status</b><span>: {{memberdetailsresult.MemberFamilyStatus}}</span></li>
                    <li><b>Family Type</b><span>: {{memberdetailsresult.MemberFamilyType}}</span></li>
                    <li><b>Family Value</b><span>: {{memberdetailsresult.MemberFamilyValue}}</span></li>
                    <li><b>Relatives</b><span>: {{memberdetailsresult.MemberRelative}}</span></li>
                  </ul>
                 </div>
       
                 <h4 class="proHead"><i class="fa fa-chain"></i> Profile Matching Your Location</h4>
                 <div class="proInnerCont bottomSliderOuter">
                   <div class="proCarouselMain">
                     <div class="span12">          
                         <!-- <div class="carousel slide myCarouselMain" id="myCarousel"> -->

                          <div class="slider-box-block" >


                          <div class="bottomSlide">
                            <ng-image-slider [images]="imageObjectval" [imagePopup]="false" (imageClick)="imageClickHandler(imageObjectval,$event)" [autoSlide]="4" [slideImage]="4" #nav></ng-image-slider>
                          </div>


                             <!-- <div class="carousel-inner">
       
       
                                
                             </div> -->
                             


                            
                         </div>          
                     </div>                
                   </div>        
                 </div>
       
                 <h4 class="proHead"><i class="fa fa-handshake-o"></i> Partner Preference Match</h4>
                 <div class="proInnerCont">
                   <div class="bothMatcMain">
                     <div class="bothMatches">
                       <img [src]='profilePic'>
                     </div>
                     <div class="bothMatches2">
                       <img [src]='photopath+memberdetailsresult.MemberPhotoPath'>
                     </div>
       
                     <div class="bothMatCont">
                       <p style="margin-bottom: 0; font-weight: 600;">Matched Preference</p>
                       <p style="color: green;">{{rightWrongCount}}/17</p>
                     </div>
                   </div>
       
                   <ul>
                      
       
                    <li *ngFor="let preferenceval of partnerpreference"><b>{{preferenceval.Title}}</b><span>: 
                      {{preferenceval.DisplayValue|stringreplacepipe}}<i *ngIf="preferenceval.Result === 'Match'" class="fa fa-check bothMatcMark"></i> <i *ngIf="preferenceval.Result !== 'Match' && preferenceval.Title!='About Partner'" class="fa fa-close bothNotMatcMark"></i>
                     </span></li>
                    <div *ngIf="userIsPaid == '0'">
                    <li class="upgradeBtListing"><b><i class="fa fa-lock"></i> &nbsp; Upgrade Membership</b><span>: <button><a href="Javascript:void(0)" (click)="UpgradeMembership()">Upgrade Now </a></button></span></li>
                      </div>
                   </ul>
                 </div>
       
       
             </div>
                           </div>
                         </div>
                   </div>
                 </div>
               </div>
  
  
  
  
  
  
      </div>
      
  </div>
  </div>    
  <!-- <div class="samplecover-spin" id="samplecover-spin" *ngIf="spinner"></div> -->
  
  