import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { WebsiteConstants } from '.././global/website-constants';

@Injectable({
  providedIn: 'root'
})
export class UpdateProfileService {

private masterUrl = WebsiteConstants.MasterUrl;  
EditAboutUsUrl = this.masterUrl+"EditAboutUs/EditAboutUs"
EditPersonalDetailsUrl = this.masterUrl+"EditPersonalDetails/EditPersonalDetails"
EditReligionDetailsUrl = this.masterUrl+"EditReligionDetails/EditReligionDetails"
EditProfessionalDetailsUrl = this.masterUrl+"EditProfessionalDetails/EditProfessionalDetails"
EditAdressDetailsUrl = this.masterUrl+"EditAddressDetails/EditAddressDetails"
EditFamilyDetailsUrl = this.masterUrl+"EditFamilyDetails/EditFamilyDetails"


  constructor(private http : HttpClient) { }

  EditAboutUsPost(body,headers)
  {
    return this.http.post(this.EditAboutUsUrl,body,headers)
  }
  EditPersonalDetailsPost(body,headers)
  {
    return this.http.post(this.EditPersonalDetailsUrl,body,headers)
  }
  EditReligionDetailsPost(body,headers)
  {
    return this.http.post(this.EditReligionDetailsUrl,body,headers)
  }
  EditProfessionalDetailsPost(body,headers)
  {
    return this.http.post(this.EditProfessionalDetailsUrl,body,headers)
  }
  EditAdressDetailsPost(body,headers)
  {
    return this.http.post(this.EditAdressDetailsUrl,body,headers)
  }
  EditFamilyDetailsPost(body,headers)
  {
    return this.http.post(this.EditFamilyDetailsUrl,body,headers)
  }

}
