import { Component, OnInit } from '@angular/core';
import {SuccessStoryServiceService} from '../services/success-story-service.service'
import { WebsiteConstants } from '.././global/website-constants';
@Component({
  selector: 'app-view-success-story',
  templateUrl: './view-success-story.component.html',
  styleUrls: ['./view-success-story.component.css']
})
export class ViewSuccessStoryComponent implements OnInit {
  helpcontact:any; helpemail:any; fraudcontact:any; fraudemail:any; 
  logspinner:boolean; succStory:any; photopath:any;sName:any;sMarrigeDate:any;sPhoto:any;sDiscription:any
  masterUrl = WebsiteConstants.MasterUrl;
  constructor(private story : SuccessStoryServiceService) { }

  ngOnInit(): void {
    this.photopath = this.masterUrl;
    this.sName = localStorage.getItem('successName')
    this.sMarrigeDate = localStorage.getItem('successMarriageDate')
    //this.sMarrigeDate = "21 October 2022"
    this.sPhoto = localStorage.getItem('successPhoto')
    this.sDiscription = localStorage.getItem('successDiscription')
  }

  
}
