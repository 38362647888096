import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { WebsiteConstants } from '.././global/website-constants';

@Injectable({
  providedIn: 'root'
})
export class ViewProfileByMemberService {
  private masterUrl = WebsiteConstants.MasterUrl;
  ViewProfileByMemberUrl= this.masterUrl+"ViewProfileByMember/ViewProfileByMember"
  constructor(private http : HttpClient) { }
  ViewProfileByMemberPost(body,headers)
  {
    return this.http.post(this.ViewProfileByMemberUrl,body,headers)
  }
}
