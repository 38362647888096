import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { WebsiteConstants } from '.././global/website-constants';

@Injectable({
  providedIn: 'root'
})
export class ShortlistedService {
  private masterUrl = WebsiteConstants.MasterUrl;
  private siteurl=this.masterUrl;
  private shortlistedurl = this.siteurl+"ViewShortlisted/ViewShortlisted";
  constructor(private http : HttpClient) { }
  shortlistedfunction(body,headers)
  {
    return this.http.post(this.shortlistedurl,body,headers)
  }
}
