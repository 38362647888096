import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-sucess-payment',
  templateUrl: './sucess-payment.component.html',
  styleUrls: ['./sucess-payment.component.css']
})
export class SucessPaymentComponent implements OnInit {

  logspinner:any=true; profileid : any; profilename:any;
  Mperiod:any; paidAmt:any;

  constructor(private route : Router) {  }

  ngOnInit(): void {
    this.logspinner=false;
    this.profileid = localStorage.getItem("userprofileid");
    this.profilename = localStorage.getItem("UserFullName");
    this.paidAmt = localStorage.getItem("hiddenSamt");
    this.Mperiod = localStorage.getItem("hiddenSperiod");
    localStorage.setItem('UserIsPaid','1');
    let link = ['/successful-payment'];
    this.route.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.route.navigate(link);
     
  });
  }



}
