import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { WebsiteConstants } from '.././global/website-constants';

@Injectable({
  providedIn: 'root'
})
export class MultiplePhotosService {

  constructor(private http : HttpClient) { }
  private masterUrl = WebsiteConstants.MasterUrl;
  private siteurl=this.masterUrl;
  multiplePhotoUrl = this.siteurl+"GetMultiplePhotos/GetMultiplePhotos";
  userphotogallary = this.siteurl+"GetMultiplePhotosforGallery/GetMultiplePhotosforGallery";

  multiplePhotopost(body,headres)
  {
    return this.http.post(this.multiplePhotoUrl,body,headres)
  }

  galleryPhotopost(body,headres)
  {
    return this.http.post(this.userphotogallary,body,headres)
  }

}
