import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { count } from 'rxjs/Operators';
import { EditPreferenceService } from '../services/edit-preference.service';
import { RegistrationService } from '../services/registration.service';


@Component({
  selector: 'app-advance-search',
  templateUrl: './advance-search.component.html',
  styleUrls: ['./advance-search.component.css']
})
export class AdvanceSearchComponent implements OnInit {
  agelist:any=[]
  heightlist:any=[]
  Educationlist:any=[]
  Occupationlist:any=[]
  mstarr:any=[{"item_id":"Any","item_text":"Any"}];
  religionlist: any=[];
  castelist :any=[]
  castarr: any=[{"CastID":"-1","CastName":"Any"}];
  subcastlist: any=[];
  subcastarr:any=[{"SubCastID":"-1","SubCastName":"Any"}];
  countrylist: any=[];
  cntarr: any=[{'CntID':"-1","CntName":"Any"}];
  statelist: any=[];
  naksharr: any=[];
  statarr: any=[{'StateID':"-1","StateName":"Any"}];
  citylist: any=[];
  cityarr: any=[{"CityID":"Any","CityName":"Any"}];
  
  jbcntarr:any=[{"CntID":"Any","CntName":"Any"}];

  // result1:any=[];
  // result2:any=[];

  occarr:any=[{"OccupationID":"-1","OccupationName":"Any"}];
  countrylist_job:any=[]
  educarr:any=[{"EducationID":"-1","EducationName":"Any"}];
  mpinarr: any=[{"item_id":"Any","item_text":"Any"}];
  incomelist: any=[];
  motarr: any=[{"item_id":"Any","item_text":"Any"}];
  mangaldd:any='No';
  ageto:any="";
  agefrom:any="";
  heightto:any="";
  heightfrom:any="";
  advanceheight=[];
  heightfromval:any;
  heighttoval:any;
  constructor(private regservice : RegistrationService,private memberservice : EditPreferenceService,private route : Router) { }

  ngOnInit(): void {
    

    this.getCountryDetails();
    this.getCountryDetails_job();
    this.getage()
    this.getincome()
    this.getHeightDetails()
    // this.getReligionDetails()
    this.getCasteDetails()
    this.getEducationDetails();
    this.getOccupationDetails();
    $(document).ready(function()
  {
  $(".inputFilSlide").click(function(){
        $(".inpuData").slideUp();
        $(this).next().slideDown();
});

$(".doneBt").click(function(){
        $(".inpuData").slideUp();
});

})

  }
  empin_list=[
    { item_id: 'Any', item_text: 'Any' },
    { item_id: 'Government', item_text: 'Government' },
    { item_id: 'Defense', item_text: 'Defense' },
    { item_id: 'Self Employed', item_text: 'Self Employed' },
    { item_id: 'Business', item_text: 'Business' },
    { item_id: 'Doctor', item_text: 'Doctor' },
    { item_id: 'Pharmacy', item_text: 'Pharmacy' },
    { item_id: 'Not Working', item_text: 'Not Working' },
    { item_id: 'Private', item_text: 'Private' }
  ]
  dropdownList_female = [
    {  item_id: 'Any',item_text: 'Any' },
    { item_id: 'Never Married', item_text: 'Never Married' },
    { item_id: 'Divorced', item_text: 'Divorced' },
    { item_id: 'Awiating Divorced', item_text: 'Awiating Divorced' },
    { item_id: 'widower', item_text: 'widower' },
  ];
  mother_toungue = [
    { item_id: 'Any', item_text: 'Any' },
    { item_id: 'Marathi', item_text: 'Marathi' },
    { item_id: 'Hindi', item_text: 'Hindi' },
    { item_id: 'English', item_text: 'English' },
    { item_id: 'Gujrathi', item_text: 'Gujrathi' },
    { item_id: 'Kannada', item_text: 'Kannada' },
    { item_id: 'Telgu', item_text: 'Telgu' },
    { item_id: 'Tamil', item_text: 'Tamil' },
    { item_id: 'Bengali', item_text: 'Bengali' },
    { item_id: 'Kokani', item_text: 'Kokani' },
    { item_id: 'Rajsthani', item_text: 'Rajsthani' }
    
  ];
  dropdownList_male = [
    {  item_id: 'Any',item_text: 'Any' },
    { item_id: 'Never Married', item_text: 'Never Married' },
    { item_id: 'Divorced', item_text: 'Divorced' },
    { item_id: 'Awiating Divorced', item_text: 'Awiating Divorced' },
    { item_id: 'widow', item_text: 'widow' },
  ];



 


  dropdownSettings:IDropdownSettings = {
    singleSelection: false,
    textField: 'item_text',
    enableCheckAll: false,
    idField: 'item_id',

    allowSearchFilter: true
  };
  religiondropdownSettings:IDropdownSettings = {
    singleSelection: false,
    textField: 'ReligionName',
    enableCheckAll: false,
    idField: 'ReligionID',

    allowSearchFilter: true
  };
  castdropdownSettings:IDropdownSettings = {
    singleSelection: false,
    textField: 'CastName',
    enableCheckAll: false,
    idField: 'CastID',
    allowSearchFilter: true
  };

  subcastdropdownSettings:IDropdownSettings = {
    singleSelection: false,
    textField: 'SubCastName',
    enableCheckAll: false,
    idField: 'SubCastID',
    allowSearchFilter: true
  };

  occupationdropdownSettings:IDropdownSettings = {
    singleSelection: false,
    textField: 'OccupationName',
    enableCheckAll: false,
    idField: 'OccupationID',
    allowSearchFilter: true
  };

  // occupationdropdownSettings = {
  //   singleSelection: false,
  //     text: "Select Fields",
  //     searchPlaceholderText: 'Search Fields',
  //     enableSearchFilter: true,
  //     primaryKey:'OccupationID',
  //     labelKey:'OccupationName',
  //     groupBy: "OccupationGroupName"
  // };

  // educationdropdownSettings = {
  //   singleSelection: false,
  //     text: "Select Fields",
  //     searchPlaceholderText: 'Search Fields',
  //     enableSearchFilter: true,
  //     primaryKey:'EducationID',
  //     labelKey:'EducationName',
  //     groupBy: "EducationGroupName"
  // };

  educationdropdownSettings:IDropdownSettings = {
    singleSelection: false,
    textField: 'EducationName',
    enableCheckAll: false,
    idField: 'EducationID',
    allowSearchFilter: true
  };
  
  cntrdropdownSettings:IDropdownSettings = {
    singleSelection: false,
    textField: 'CntName',
    enableCheckAll: false,
    idField: 'CntID',
    allowSearchFilter: true
  };

  statedropdownSettings:IDropdownSettings = {
    singleSelection: false,
    textField: 'StateName',
    enableCheckAll: false,
    idField: 'StateID',
    allowSearchFilter: true
  };

  citydropdownSettings:IDropdownSettings = {
    singleSelection: false,
    textField: 'CityName',
    enableCheckAll: false,
    idField: 'CityID',
    allowSearchFilter: true
  };


  countrydropdownSettings:IDropdownSettings = {
    singleSelection: false,
    textField: 'CntName',
    enableCheckAll: false,
    idField: 'CntID',
    allowSearchFilter: true
  };





  onItemSelect_mothert(item: any){
    if(item.item_id=="Any")
{
  this.motarr=[]
  this.motarr.push({"item_id":"Any","item_text":"Any"});
}else{
  for(let i=0;i<this.motarr.length;i++)
  {
    if(this.motarr[i]["item_id"]=='Any')
    {
        this.motarr.splice(i,1)
    }
  }
 
  this.motarr = [...this.motarr];



}
  

  }
  onItemSelect_emp(item: any){
    if(item.item_id=="Any")
  {
  this.mpinarr=[]
  this.mpinarr.push({"item_id":"Any","item_text":"Any"})
  }else{
  
  for(let i=0;i<this.mpinarr.length;i++)
  {
    if(this.mpinarr[i]["item_id"]=='Any')
    {
        this.mpinarr.splice(i,1)
    }
  }
  this.mpinarr = [...this.mpinarr];
  }
    
  }
  //education
onItemSelect_educat(item: any){
  if(item.EducationID=="-1")
{
this.educarr=[]
this.educarr.push({"EducationID":"-1","EducationName":"Any"})

}else{

for(let i=0;i<this.educarr.length;i++)
{
  if(this.educarr[i]["EducationID"]=='-1')
  {
      this.educarr.splice(i,1)
  }
}
this.educarr = [...this.educarr];
}

}
onItemDeSelect_educat(item: any) {
  
}
//occupation
onItemSelect_occ(item: any){
  if(item.OccupationID=="-1")
{
this.occarr=[]
this.occarr.push({"OccupationID":"-1","OccupationName":"Any"})

}else{

for(let i=0;i<this.occarr.length;i++)
{
  if(this.occarr[i]["OccupationID"]=='-1')
  {
      this.occarr.splice(i,1)
  }
}
this.occarr = [...this.occarr];
}
  
}
//city
onItemSelect_city(item: any)
{
   if(item.CityID=="Any")
  {
    this.cityarr=[]
    this.cityarr.push({"CityID":"Any","CityName":"Any"})
    
  }
  else
  {
    for(let i=0;i<this.cityarr.length;i++)
    {
      if(this.cityarr[i]["CityID"]=='Any')
      {
        this.cityarr.splice(i,1)
      }
    }
      this.cityarr = [...this.cityarr];
  }
      
}

  onItemSelect_job(item: any) {
    
    if(item.CntID=="Any")
    {
      this.jbcntarr=[]
      this.jbcntarr.push({"CntID":"Any","CntName":"Any"});
    
    }else{
      
      for(let i=0;i<this.jbcntarr.length;i++)
      {
        if(this.jbcntarr[i]["CntID"]=='Any')
        {
            this.jbcntarr.splice(i,1)
        }
      }
     
      this.jbcntarr = [...this.jbcntarr];
    
    
    //console.log("jobcountryies====>"+JSON.stringify(this.jbcntarr))
    
  }
      
    
      }
  onItemSelect_mstatus(item: any)
  {
    if(item.item_id=="Any")
    {
      this.mstarr=[]
      this.mstarr.push({"item_id":"Any","item_text":"Any"}); 
    }
    else
    {
      for(let i=0;i<this.mstarr.length;i++)
      {
        if(this.mstarr[i]["item_id"]=='Any')
        {
          this.mstarr.splice(i,1)
        }
      }

      this.mstarr = [...this.mstarr];
    }
      
  }


  onChangeCountry()
{
  let newarr_sts=[]
 
    if(this.cntarr[0].CntID=='-1')
    {
      for(let j=0;j<this.countrylist.length;j++){
      newarr_sts.push(this.countrylist[j].CntID)
      }
    }else{
      for(let i=0;i<this.cntarr.length;i++)
      {
  newarr_sts.push(this.cntarr[i].CntID)
  }}
  this.statelist=[]
      let headers = new Headers({'Content-Type':'multipart/form-data'});
      let body = new FormData();
      body.append('VarCountryID', JSON.stringify(newarr_sts).replace(/"/g, "'").slice(1, -1));
      this.memberservice.getmulstateDataUrl(body,headers).subscribe(Response =>
        {
          if(Response['status']==1){
            this.statelist =  Response['data'];
            this.statelist.unshift({"StateID":"-1","StateName":"Any"})

           this. onChangeState()

          }
          else
          {
            this.statelist = [];
          }

        })
   

}



onChangeState()
{
  let newarr_sts=[]
  if(this.statarr.length>0)
  {
  if(this.statarr[0].StateID=='-1')
  {
    for(let j=0;j<this.statelist.length;j++){
    newarr_sts.push(this.statelist[j].StateID)
    }
  }
  else
  {
    for(let i=0;i<this.statarr.length;i++)
    {
      newarr_sts.push(this.statarr[i].StateID)
    }
  }
  
 
  this.citylist=[]
      let headers = new Headers({'Content-Type':'multipart/form-data'});
      let body = new FormData();
      body.append('VarStateID', JSON.stringify(newarr_sts).replace(/"/g, "'").slice(1, -1));
      this.memberservice.getmulcityDataUrl(body,headers).subscribe(Response =>
        {

          if(Response['status']=='1'){
            this.citylist =  Response['data'];
            this.citylist.unshift({'CityID':"Any","CityName":"Any"});

          }else{
            this.citylist=[];
            this.cityarr=[];
            this.cityarr.push({"CityID":"Any","CityName":"Any"});
            this.citylist.unshift({'CityID':"Any","CityName":"Any"});
          }
        })
  
      }
      else
      {
        this.citylist=[];
        this.cityarr=[];
        this.cityarr.push({"CityID":"Any","CityName":"Any"});
        this.citylist.unshift({'CityID':"Any","CityName":"Any"})
      }
}


onDechangeState()
{
  let newarr_sts=[]
  if(this.statarr.length>0)
  {
  if(this.statarr[0].StateID=='-1')
  {
    for(let j=0;j<this.statelist.length;j++){
    newarr_sts.push(this.statelist[j].StateID)
    }
  }
  else
  {
    for(let i=0;i<this.statarr.length;i++)
    {
      newarr_sts.push(this.statarr[i].StateID)
    }
  }
  
 
  this.citylist=[]
      let headers = new Headers({'Content-Type':'multipart/form-data'});
      let body = new FormData();
      body.append('VarStateID', JSON.stringify(newarr_sts).replace(/"/g, "'").slice(1, -1));
      this.memberservice.getmulcityDataUrl(body,headers).subscribe(Response =>
        {

          if(Response['status']=='1'){
            this.citylist =  Response['data'];
              var result1=[];
              var result2=[];

            if(this.citylist.length > this.cityarr.length)
            {
              result1 = this.citylist;
              result2 = this.cityarr;
            }
            else
            {
              result1 = this.cityarr;
              result2 = this.citylist;
            }

            var props = ['CityID', 'CityName'];
            var result = result1.filter(function(o1){

              // filter out (!) items in result2
              return result2.some(function(o2){
                  return o1.CityID === o2.CityID;          // assumes unique id
              });
          }).map(function(o){
              // use reduce to make objects with only the required properties
              // and map to apply this to the filtered array as a whole
              return props.reduce(function(newo, name){
                  newo[name] = o[name];
                  return newo;
              }, {});
          });


               this.cityarr=[];
               if(result.length>0)
               {
                this.cityarr=result;
               }
               else
               {  
                this.cityarr.push({"CityID":"Any","CityName":"Any"});
               }
               
              this.citylist.unshift({'CityID':"Any","CityName":"Any"});

          }else{
            this.citylist=[];
            this.cityarr=[];
            this.cityarr.push({"CityID":"Any","CityName":"Any"});
            this.citylist.unshift({'CityID':"Any","CityName":"Any"});
          }
        })
  
      }
      else
      {
        this.citylist=[];
        this.cityarr=[];
        this.cityarr.push({"CityID":"Any","CityName":"Any"});
        this.citylist.unshift({'CityID':"Any","CityName":"Any"})
      }
}

  getCountryDetails()
  {
      let headers = new Headers({'Content-Type':'application/json'});
      this.regservice.getCountryData(headers).subscribe(Response =>
        {
          this.countrylist = Response['data']
          this.countrylist.unshift({"CntID":"-1","CntName":'Any'})
          this.onChangeCountry()

        })

  }
  getCountryDetails_job()
  {
      let headers = new Headers({'Content-Type':'application/json'});
      this.regservice.getCountryData(headers).subscribe(Response =>
        {
          this.countrylist_job = Response['data']
          this.countrylist_job.unshift({"CntID":"Any","CntName":'Any'})
  
        })
  }
  mangaldoshfun(val)
  {this.mangaldd=val}


  getage()
  {
    
      for(let i=18;i<=60;i++)
      {
        this.agelist.push(i)
      }
      
      if(localStorage.getItem('UserGender')=='Male')
      {
          let ato=parseInt(localStorage.getItem('UserAge'))
          let afrom = 18;
          if(ato > 25)
          {
            afrom=parseInt(localStorage.getItem('UserAge'))-8;
          }
          
          localStorage.setItem('ageto',JSON.stringify(ato))
          localStorage.setItem('agefrom',JSON.stringify(afrom))
          this.ageto=JSON.stringify(ato);
          this.agefrom=JSON.stringify(afrom);
      }
      else
      {
          let ato=parseInt(localStorage.getItem('UserAge'))+8
          let afrom=parseInt(localStorage.getItem('UserAge'))
          localStorage.setItem('ageto',JSON.stringify(ato))
          localStorage.setItem('agefrom',JSON.stringify(afrom));
          this.ageto=JSON.stringify(ato);
          this.agefrom=JSON.stringify(afrom);
      }

  }

  getincome()
  {
      // for(let i=1;i<=20;i++)
      // {
      //   this.incomelist.push(i)
      // }
localStorage.setItem('incomefrom','Any')
localStorage.setItem('incometo','Any')

  }
  onItemSelect_cast(item: any){
    if(item.CastID=="-1")
  {
  this.castarr=[]
  this.castarr.push({"CastID":"-1","CastName":"Any"});
  }else{
  for(let i=0;i<this.castarr.length;i++)
  {
    if(this.castarr[i]["CastID"]=='-1')
    {
        this.castarr.splice(i,1)
    }
  }
  
  this.castarr = [...this.castarr];
  
  
  
  }

  this.getSubCastDetails()
  }
onItemSelect_subcast(item: any){
  if(item.SubCastID=="-1")
{
this.subcastarr=[]
this.subcastarr.push({"SubCastID":"-1","SubCastName":"Any"});

}else{
for(let i=0;i<this.subcastarr.length;i++)
{
  if(this.subcastarr[i]["SubCastID"]=='-1')
  {
      this.subcastarr.splice(i,1)
  }
}

this.subcastarr = [...this.subcastarr];



}
  
}

onItemSelect_country(item:any){
  if(item.CntID=="-1")
{
this.cntarr=[]
this.cntarr.push({"CntID":"-1","CntName":"Any"});

}else{
for(let i=0;i<this.cntarr.length;i++)
{
  if(this.cntarr[i]["CntID"]=='-1')
  {
      this.cntarr.splice(i,1)
  }
}

this.cntarr = [...this.cntarr];




}
  this.onChangeCountry()

}

onItemSelect_state(item:any){
  if(item.StateID=="-1")
{
this.statarr=[]
this.statarr.push({"StateID":"-1","StateName":"Any"});

}else{
for(let i=0;i<this.statarr.length;i++)
{
  if(this.statarr[i]["StateID"]=='-1')
  {
      this.statarr.splice(i,1)
  }
}

this.statarr = [...this.statarr];




}
  this.onChangeState()

}

onDeSelect(item: any) {
  if(item.StateID=="-1")
{
this.statarr=[]
this.statarr.push({"StateID":"-1","StateName":"Any"});

}else{

for(let i=0;i<this.statarr.length;i++)
{
  if(this.statarr[i]["StateID"]=='-1')
  {
      this.statarr.splice(i,1)
  }
}

this.statarr = [...this.statarr];
}
 
  if(this.statarr.length>0)
  {
    this.onDechangeState()
  }
  else
  {
    this.statarr=[]
    this.statarr.push({"StateID":"-1","StateName":"Any"});
    this.cityarr=[];
    this.cityarr.push({"CityID":"Any","CityName":"Any"});
  }
}

 
  getSubCastDetails()
  {
    let newarr_reli=[]
    if(this.castarr[0].CastID=='-1')
    {
      for(let j=0;j<this.castelist.length;j++){
        newarr_reli.push(this.castelist[j].CastID)
      }
    }else{
  for(let i=0;i<this.castarr.length;i++)
  {
  newarr_reli.push(this.castarr[i].CastID)
  }}
  this.subcastlist=[]
      let headers = new Headers({ 'Content-Type': 'multipart/form-data' });
      let body = new FormData();
      body.append('VarCastID',JSON.stringify(newarr_reli).replace(/"/g, "'").slice(1, -1));
      this.memberservice.getmulsubcatDataUrl(body,headers).subscribe(Response =>
        {
          if(Response['status']==1) 
        {
            this.subcastlist =  Response['data'];
            this.subcastlist.unshift({"SubCastID":"-1","SubCastName":'Any'})

          }
          else
          {
            this.subcastlist = [];
          }
        })
    

    
  }
  getHeightDetails()
  {
      let headers = new Headers({'Content-Type':'application/json'});
      this.regservice.getHeightData(headers).subscribe(Response =>
      {
        this.heightlist = Response['data']
        
      if(localStorage.getItem('UserGender')=='Male')
      {
        let arrindex = 0; let fromindex=0;
        for(let cnt=0; cnt<this.heightlist.length; cnt++)
        {
            if(this.heightlist[cnt]['HeightDisplayUser']==localStorage.getItem('UserHeight'))
            {
              arrindex = cnt;
            }
        }
          fromindex = arrindex - 7;
          this.heightfromval=this.heightlist[fromindex].HeightDropdownDisplay;
          this.heighttoval=this.heightlist[arrindex].HeightDropdownDisplay;

          var tempheightfrom = this.heightlist[fromindex].HeightCode+"|||"+this.heightlist[fromindex].HeightDropdownDisplay;
          var tempheightto = this.heightlist[arrindex].HeightCode+"|||"+this.heightlist[arrindex].HeightDropdownDisplay;

          localStorage.setItem('heightfrom',tempheightfrom)
          localStorage.setItem('heightto',tempheightto)
          this.heightfrom=tempheightfrom;
          this.heightto=tempheightto;
      }
      else
      {
        let arrindex = 0; let fromindex=0;
        for(let cnt=0; cnt<this.heightlist.length; cnt++)
        {
            if(this.heightlist[cnt]['HeightDisplayUser']==localStorage.getItem('UserHeight'))
            {
              arrindex = cnt;
            }
        }
          fromindex = arrindex + 7;
          this.heightfromval=this.heightlist[arrindex].HeightDropdownDisplay;
          this.heighttoval=this.heightlist[fromindex].HeightDropdownDisplay;

          var tempheightfrom = this.heightlist[arrindex].HeightCode+"|||"+this.heightlist[arrindex].HeightDropdownDisplay;
          var tempheightto = this.heightlist[fromindex].HeightCode+"|||"+this.heightlist[fromindex].HeightDropdownDisplay;

          localStorage.setItem('heightfrom',tempheightfrom)
          localStorage.setItem('heightto',tempheightto)
          this.heightfrom=tempheightfrom;
          this.heightto=tempheightto;
      }


      })
      //  localStorage.setItem('heightto','600')
      //  localStorage.setItem('heightfrom','500')

      // heightto
      // heightfrom

      

  }
 
  getEducationDetails()
  {
  
      let headers = new Headers({ 'Content-Type': 'application/json' });
      this.regservice.getEducationData(headers).subscribe(Response =>
      {
          if(Response['status']==1) 
          {
              //this.Educationlist = Response['data'];

              const arr = Response['data'];
              const newArr = arr.filter(object => {
                return object.Header !== "TRUE";
              });
  
             this.Educationlist = newArr;
             this.Educationlist.unshift({"EducationID":"-1","EducationName":"Any","EducationGroupName":"Any"})
             
          }
          else
          {
            this.Educationlist = [];
          }
      })
  
  }
  getOccupationDetails()
 {
   let headers = new Headers({'Content-Type':'application/json'});
   let body = new FormData();
 
     this.regservice.getOccupationData(body,headers).subscribe(Response =>
     {
      
         if(Response['status']==1) 
         {
          const arr = Response['data'];
            const newArr = arr.filter(object => {
              return object.Header !== "TRUE";
            });

           this.Occupationlist = newArr;
           this.Occupationlist.unshift({"OccupationID":"-1","OccupationName":"Any","OccupationGroupName":"Any"})
           
     }
       else
       {
         this.Occupationlist = [];
       }
            
     })
 
 }

 getReligionDetails()
 {
     let headers = new Headers({ 'Content-Type': 'application/json' });
     this.regservice.getReligionData(headers).subscribe(Response =>
       {
         this.religionlist = Response['data'];
       })
 }

 religarr: any=[];

 getCasteDetails()
{
  let newarr_reli=[],rlig_id=[]
  for(let i=0;i<this.religarr.length;i++)
  {
  // newarr_reli.push(this.religarr[i].ReligionName)
  rlig_id.push(this.religarr[i].ReligionID)
  }

    let headers = new Headers({ 'Content-Type': 'multipart/form-data' });
    let body = new FormData();
    body.append('VarReligionID', '1');

    // body.append('VarReligionID', JSON.stringify(rlig_id).replace(/"/g, "'").slice(1, -1));
    this.memberservice.getmulcatDataUrl(body,headers).subscribe(Response =>
    {
        if(Response['status']==1) 
        {
            this.castelist = Response['data'];
            this.castelist.unshift({"CastID":"-1","CastName":"Any"})
            this.getSubCastDetails();
        }
        else
        {
          this.castelist = [];
        }

    })
}


ageselefrom(val)
{
localStorage.setItem('agefrom',val)
this.agefrom=val
}
ageseleto(val)
{
localStorage.setItem('ageto',val)
this.ageto=val
}

// heightto:any=6;
// heightfrom:any=5
heightfromfun(val)
{
  var strex = val.split("|||")
  localStorage.setItem('heightfrom',val)
  this.heightfrom=val;
  this.heightfromval=strex[1];
}
heighttofun(val)
{
  var strex = val.split("|||")
localStorage.setItem('heightto',val)
this.heightto=val;
this.heighttoval = strex[1];

}
incometo:any='Any';
incomefrom:any='Any'
incomefromfun(val)
{
  var strex = val.split("|||")
localStorage.setItem('incomefrom',strex[0])
this.incomefrom=strex[1]
}
incometofun(val)
{
  var strex = val.split("|||")
localStorage.setItem('incometo',strex[0])
this.incometo=strex[1]

}


resetsearch()
{
  let link = ['/advance-search'];
  this.route.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.route.navigate(link);
     
  });
}



searchnow()
{
  //Marital Status
  let newarrm=[]
  if(this.mstarr.length!=0){
  for(let i=0;i<this.mstarr.length;i++)
  {
    newarrm.push(this.mstarr[i].item_text)
    newarrm = [...new Set(newarrm)];
  }
  if(newarrm[0]=='Any'){
    localStorage.setItem('MembMartialStatus',JSON.stringify(newarrm).replace(/"/g, "").slice(1, -1))
  }else{
    localStorage.setItem('MembMartialStatus',JSON.stringify(newarrm).replace(/"/g, "'").slice(1, -1))
  
  }
  }else{
    localStorage.setItem('MembMartialStatus','')
  }

//subcast

let newarr_subcast=[]
for(let i=0;i<this.subcastarr.length;i++)
{


  newarr_subcast.push(this.subcastarr[i].SubCastName)
  newarr_subcast = [...new Set(newarr_subcast)];

}
if(newarr_subcast[0]=='Any'){

  localStorage.setItem('MembSubCast',JSON.stringify(newarr_subcast).replace(/"/g, "").slice(1, -1))
  }else{
    localStorage.setItem('MembSubCast',JSON.stringify(newarr_subcast).replace(/"/g, "'").slice(1, -1))
  }

  //state
  let statesearr=[]
  for(let i=0;i<this.statarr.length;i++)
  {
   
    statesearr.push(this.statarr[i].StateName)
    statesearr = [...new Set(statesearr)];
  }
  localStorage.setItem('MembStateNm',JSON.stringify(statesearr).replace(/"/g, "").slice(1, -1))

  //city id

let citysenid=[]
  for(let i=0;i<this.cityarr.length;i++)
  {
    if(this.cityarr[i].CityID=='Any')
    {
      citysenid.push("-1")
    }else{
      citysenid.push(this.cityarr[i].CityID)
    }
    citysenid = [...new Set(citysenid)];
  }
  
  localStorage.setItem('MembCityID',JSON.stringify(citysenid).replace(/"/g, "").slice(1, -1))

  //education
  let onewarr=[]
  for(let i=0;i<this.educarr.length;i++)
  {
    onewarr.push(this.educarr[i].EducationID)
    

    onewarr = [...new Set(onewarr)];

  }
  if(onewarr[0]=='-1'){
    localStorage.setItem('MembEducation','Any')
  }else{
    localStorage.setItem('MembEducation',JSON.stringify(onewarr).replace(/"/g, "").slice(1, -1))

  }
  //occupation
  let olig_id=[]
  for(let i=0;i<this.occarr.length;i++)
  {
  // newarr_reli.push(this.religarr[i].ReligionName)
  olig_id.push(this.occarr[i].OccupationID)

  olig_id = [...new Set(olig_id)];


  }
  if(olig_id[0]=='-1'){

  localStorage.setItem('MembOccupation','Any')

  }else{
    localStorage.setItem('MembOccupation',JSON.stringify(olig_id).replace(/"/g, "").slice(1, -1))

  }
  //empin

  let emp_nm=[]
  for(let i=0;i<this.mpinarr.length;i++)
  {
  // newarr_reli.push(this.religarr[i].ReligionName)
  emp_nm.push(this.mpinarr[i].item_text)
  emp_nm = [...new Set(emp_nm)];

  }
  if(emp_nm[0]=='Any'){

    localStorage.setItem('MembEmployedIn',JSON.stringify(emp_nm).replace(/"/g, "").slice(1, -1))
    
    }else{
      localStorage.setItem('MembEmployedIn',JSON.stringify(emp_nm).replace(/"/g, "'").slice(1, -1))
  
    }


    //city

    
  let jcntrysearr=[],jcntrysenid=[]

  for(let i=0;i<this.jbcntarr.length;i++)
  {
    if(this.jbcntarr[i].CntID=='Any')
    {
      jcntrysenid.push("-1")
    }else{
      jcntrysenid.push(this.jbcntarr[i].CntID)

    }
   
    jcntrysearr.push(this.jbcntarr[i].CntName)
    jcntrysearr = [...new Set(jcntrysearr)];
    jcntrysenid = [...new Set(jcntrysenid)];

   
  }

  if(jcntrysearr[0]=='Any'){

    localStorage.setItem('jobcity',JSON.stringify(jcntrysearr).replace(/"/g, "").slice(1, -1))
    
    }else{
      localStorage.setItem('jobcity',JSON.stringify(jcntrysenid).replace(/"/g, "'").slice(1, -1))
  
    }

    //mangal dosh
    localStorage.setItem('mangaldosh',this.mangaldd)
    //mother tong
    let newarr=[]

    for(let i=0;i<this.motarr.length;i++)
    {
      newarr.push(this.motarr[i].item_text)
      newarr = [...new Set(newarr)];
  
    }
    if(newarr[0]=='Any'){
      localStorage.setItem('MembMotherTongue',JSON.stringify(newarr).replace(/"/g, "").slice(1, -1))
      }else{
        localStorage.setItem('MembMotherTongue',JSON.stringify(newarr).replace(/"/g, "'").slice(1, -1))
      }
localStorage.setItem('frompage','advancesearch')

let link = ['/search-details'];
  this.route.navigate(link);
}

}
