 import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler, HttpHeaders} from '@angular/common/http';
import { Observable,of } from 'rxjs';
import { catchError} from 'rxjs/Operators';
import{ Forgot } from './forgot'
import { retry } from 'rxjs/internal/operators/retry';
import { WebsiteConstants } from '.././global/website-constants';


@Injectable()
export class LoginService {

  private masterUrl = WebsiteConstants.MasterUrl;

  private loginurl = this.masterUrl+'CheckLoginAuthentication/CheckLoginAuthentication';
  private forgoturl = this.masterUrl+'CheckForgotPwdAuthentication/CheckForgotPwdAuthentication';
  private otpauthentication = this.masterUrl+"CheckOTPAuthentication/CheckOTPAuthentication"
  private appversionurl = this.masterUrl+"Getappver/Getappver";
  private appbannerurl = this.masterUrl+"ViewFlasDiscountBanner/ViewFlasDiscountBanner";

  private historyurl = this.masterUrl+"AddLoginHistory/AddLoginHistory";
  
  constructor(private http:HttpClient) { }

  loginUrl(body, headers)
  {
    return this.http.post(this.loginurl, body, headers);
  }

  loginHistoryUrl(body, headers)
  {
    return this.http.post(this.historyurl, body, headers);
  }

  forgotUrl(body, headers)
  {
    return this.http.post(this.forgoturl, body, headers);
  }

  authenticationUrl(body, headers)
  {
    return this.http.post(this.otpauthentication, body, headers);
  }
  
  appversionUrl(body, headers)
  {
      return this.http.post(this.appversionurl, body, headers);
  }

  appbannerUrl(body, headers)
  {
      return this.http.post(this.appbannerurl, body, headers);
  }

}
