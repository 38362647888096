import { Component, OnInit } from '@angular/core';
import {EditPreferenceService} from '../services/edit-preference.service'
import {Router} from '@angular/router'
import * as $ from 'jquery';
import { FormControl, FormGroup, Validators,ReactiveFormsModule,FormBuilder } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { RegistrationService } from '../services/registration.service';

import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { JsonpClientBackend } from '@angular/common/http';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

@Component({
  selector: 'app-edit-preference',
  templateUrl: './edit-preference.component.html',
  styleUrls: ['./edit-preference.component.css']
})
export class EditPreferenceComponent implements OnInit {

  userProfileData:any; marritalStatus:any; disability:any; userCaste:any;
  userOccuption:any; userEducation:any; logspinner:boolean;basicpreferencesForm:FormGroup;religiouspreferenceform:FormGroup;professionalpreferenceform:FormGroup;locationpreferenceform:FormGroup;issubmitbasicpreference:boolean=false;isreligiouspreference:boolean=false;aboutForForm:FormGroup; mothertongue:any;
  heightlist: any;
  agelist: any=[];
  newarr:any=[];

  availableClasses: string[] = ["dark", "light", "colorful", "modal-open"];
  currentClassIdx: number = 0;

  bodyClass: string;

  dropdownList_female = [
    {  item_id: 'Any',item_text: 'Any' },
    { item_id: 'Never Married', item_text: 'Never Married' },
    { item_id: 'Divorced', item_text: 'Divorced' },
    { item_id: 'Awaiting Divorced', item_text: 'Awaiting Divorced' },
    { item_id: 'Widower', item_text: 'Widower' },

    
  ];
  
  dropdownList_male = [
    {  item_id: 'Any',item_text: 'Any' },
    { item_id: 'Never Married', item_text: 'Never Married' },
    { item_id: 'Divorced', item_text: 'Divorced' },
    { item_id: 'Awaiting Divorced', item_text: 'Awaiting Divorced' },
    { item_id: 'Widow', item_text: 'Widow' },


   
  ];
  mother_toungue = [
    { item_id: 'Any', item_text: 'Any' },
    { item_id: 'Marathi', item_text: 'Marathi' },
    { item_id: 'Hindi', item_text: 'Hindi' },
    { item_id: 'English', item_text: 'English' },
    { item_id: 'Gujrathi', item_text: 'Gujrathi' },
    { item_id: 'Kannada', item_text: 'Kannada' },
    { item_id: 'Telgu', item_text: 'Telgu' },
    { item_id: 'Tamil', item_text: 'Tamil' },
    { item_id: 'Bengali', item_text: 'Bengali' },
    { item_id: 'Kokani', item_text: 'Kokani' },
    { item_id: 'Rajsthani', item_text: 'Rajsthani' }
    
  ];

  empin_list=[
    { item_id: 'Any', item_text: 'Any' },
    { item_id: 'Government', item_text: 'Government' },
    { item_id: 'Private', item_text: 'Private' },
    { item_id: 'Defense', item_text: 'Defense' },
    { item_id: 'Self Employed', item_text: 'Self Employed' },
    { item_id: 'Business', item_text: 'Business' },
    { item_id: 'Not Working', item_text: 'Not Working' }
    // { item_id: 'Doctor', item_text: 'Doctor' },
    // { item_id: 'Pharmacy', item_text: 'Pharmacy' },
    
    
  ]
  nakshatralist=[
    {item_id:"Any",item_text:"Any"},
    {item_id:"Ashwini",item_text:"Ashwini"},
    {item_id:"Bharani",item_text:"Bharani"},
    {item_id:"Krittika",item_text:"Krittika"},
    {item_id:"Rohini",item_text:"Rohini"},
    {item_id:"Mrigasira",item_text:"Mrigasira"},
    {item_id:"Arda",item_text:"Arda"},
    {item_id:"Punarvasu",item_text:"Punarvasu"},
    {item_id:"Pushya",item_text:"Pushya"},
    {item_id:"Ashlesha",item_text:"Ashlesha"},
    {item_id:"Megha",item_text:"Megha"},
    {item_id:"PoorvaPhalguni",item_text:"PoorvaPhalguni"},
    {item_id:"Uttara Phalguni",item_text:"Uttara Phalguni"},
    {item_id:"Hasta",item_text:"Hasta"},
    {item_id:"Chitra",item_text:"Chitra"},
    {item_id:"Swati",item_text:"Swati"},
    {item_id:"Vishakha",item_text:"Vishakha"},
    {item_id:"Anuradha",item_text:"Anuradha"},
    {item_id:"Jyeshta",item_text:"Jyeshta"},
    {item_id:"Moola",item_text:"Moola"},
    {item_id:"Poorvashada",item_text:"Poorvashada"},
    {item_id:"Revati",item_text:"Revati"},
    {item_id:"Uttarashada",item_text:"Uttarashada"},
    {item_id:"Shravana",item_text:"Shravana"},
    {item_id:"Dhanishta",item_text:"Dhanishta"},
    {item_id:"Shattarka",item_text:"Shattarka"},
    {item_id:"Satabhisha",item_text:"Satabhisha"},
    {item_id:"Poorva Bhadrapada",item_text:"Poorva Bhadrapada"},
    {item_id:"Uttara Bhadrapada",item_text:"Uttara Bhadrapada"},
  ]
  dropdownSettings:IDropdownSettings = {
    singleSelection: false,
    textField: 'item_text',
    enableCheckAll: false,
    idField: 'item_id',

    allowSearchFilter: true
  };
  educationdropdownSettings = {
    singleSelection: false,
    textField: 'EducationName',
    enableCheckAll: false,
    idField: 'EducationID',
    groupBy:'EducationGroupName',
    allowSearchFilter: true
  };

  // educationdropdownSettings = {
  //   singleSelection: false,
  //     text: "Select Fields",
  //     selectAllText: 'Select All',
  //     unSelectAllText: 'UnSelect All',
  //     searchPlaceholderText: 'Search Fields',
  //     enableSearchFilter: true,
  //     primaryKey:'EducationID',
  //     labelKey:'EducationName',
  //     groupBy: "EducationGroupName"
  // };
  cntrdropdownSettings:IDropdownSettings = {
    singleSelection: false,
    textField: 'CntName',
    enableCheckAll: false,
    idField: 'CntID',

    allowSearchFilter: true
  };
  statedropdownSettings:IDropdownSettings = {
    singleSelection: false,
    textField: 'StateName',
    enableCheckAll: false,
    idField: 'StateID',

    allowSearchFilter: true
  };

  citydropdownSettings:IDropdownSettings = {
    singleSelection: false,
    textField: 'CityName',
    enableCheckAll: false,
    idField: 'CityID',
    allowSearchFilter: true
  };
  
  
  
  occupationdropdownSettings = {
    singleSelection: false,
    textField: 'OccupationName',
    enableCheckAll: false,
    idField: 'OccupationID',
    groupBy:'OccupationGroupName',


    allowSearchFilter: true
  };
  
  religiondropdownSettings:IDropdownSettings = {
    singleSelection: false,
    textField: 'ReligionName',
    enableCheckAll: false,
    idField: 'ReligionID',

    allowSearchFilter: true
  };
  castdropdownSettings:IDropdownSettings = {
    singleSelection: false,
    textField: 'CastName',
    enableCheckAll: false,
    idField: 'CastID',

    allowSearchFilter: true
  };

  subcastdropdownSettings:IDropdownSettings = {
    singleSelection: false,
    textField: 'SubCastName',
    enableCheckAll: false,
    idField: 'SubCastID',

    allowSearchFilter: true
  };

  motarr: any=[];
  religionlist: any=[];
  ReligionID: any;
  castelist: any=[];
  religarr: any=[];
  educarr:any=[]
  isprofessionalpreference: boolean=false;
  Educationlist: any=[];
  Occupationlist: any=[];
  mpinarr: any=[];
  castarr: any=[];
  islocationpreference: boolean=false;
  isSubmitAbout: boolean;
  countrylist: any=[];
  cntarr: any=[];
  statelist: any=[];
  subcastlist: any;
  subcastarr: any=[];
  naksharr: any=[];
  statarr: any=[];
  citylist: any=[];
  cityarr: any=[];
  jbcntarr: any=[];
  occarr: any=[];
  memfrmage: any;
  memtoage: any;
  countrylist_job: any=[];
  widow: any;
  occarr_msg: any=[];
  Religion: any;
  subCasteVal: any;
  nakshatraVal: any;
  jobcountryVal: any;
  educationVal: any;
  occupationVal: any;
  employeeInVal: any;

  constructor(private memberservice : EditPreferenceService,private formBuilder: FormBuilder,private regservice : RegistrationService) { 

    this.basicpreferencesForm = this.formBuilder.group({
      "agefrom" : new FormControl("",Validators.required),
      "ageto" : new FormControl("",Validators.required),

      "heightfrom" : new FormControl("",Validators.required),
      "heightto" : new FormControl("",Validators.required),

      "mstatus" : new FormControl("",Validators.required),
      "disabilit" : new FormControl("",Validators.required),
    });

    this.religiouspreferenceform=this.formBuilder.group({
      "mtongue" : new FormControl("",Validators.required),
      "relig":new FormControl("",Validators.required),
      "caste":new FormControl("",Validators.required),
      "subcaste":new FormControl("",Validators.required),
      "nakshatra":new FormControl("",Validators.required),
      "mangalDosh":new FormControl("",Validators.required),
    })
    this.professionalpreferenceform=this.formBuilder.group({
    "educat":new FormControl("",Validators.required),
    "occupation":new FormControl("",Validators.required),
    "empin":new FormControl("",Validators.required),

    })

    this.locationpreferenceform=this.formBuilder.group({
      "country":new FormControl("",Validators.required),
      "state":new FormControl("",Validators.required),
      "city":new FormControl("",Validators.required),
      "jobcountry":new FormControl("",Validators.required),

  
      })
      this.aboutForForm = this.formBuilder.group({
        "aboutForm" : new FormControl("",Validators.required)
      });
  }

//height
  onItemSelect_height(item: any){
    if(item.item_id=="Any")
{
  this.mstarr=[]
  this.mstarr.push({"item_id":"Any","item_text":"Any"})
  console.log(this.mstarr);

}else{
  console.log(this.mstarr);
  for(let i=0;i<this.mstarr.length;i++)
  {
    if(this.mstarr[i]["item_id"]=='Any')
    {
        this.mstarr.splice(i,1)
    }
  }
 
  this.mstarr = [...this.mstarr];



}
    console.log(this.mstarr);

  }

  onItemDeSelect_height(item: any) {
    
    console.log(this.mstarr);
  }
//sub cast

onItemSelect_subcast(item: any){
  if(item.SubCastID=="-1")
{
this.subcastarr=[]
this.subcastarr.push({"SubCastID":"-1","SubCastName":"Any"})
console.log(this.subcastarr);

}else{
console.log(this.subcastarr);
for(let i=0;i<this.subcastarr.length;i++)
{
  if(this.subcastarr[i]["SubCastID"]=='-1')
  {
      this.subcastarr.splice(i,1)
  }
}

this.subcastarr = [...this.subcastarr];



}
  console.log(this.subcastarr);

}

onItemDeSelect_subcast(item: any) {
  
  console.log(this.subcastarr);
}


//nakshatra
onItemSelect_naksh(item: any){
  if(item.item_id=="Any")
{
this.naksharr=[]
this.naksharr.push({"item_id":"Any","item_text":"Any"})
console.log(this.naksharr);
}else{
console.log(this.naksharr);
for(let i=0;i<this.naksharr.length;i++)
{
  if(this.naksharr[i]["item_id"]=='Any')
  {
      this.naksharr.splice(i,1)
  }
}
this.naksharr = [...this.naksharr];
}
  console.log(this.naksharr);
}
onItemDeSelect_naksh(item: any) {
  console.log(this.naksharr);
}
//education
onItemSelect_educat(item: any){
  if(item.EducationID=="-1")
{
this.educarr=[]
this.educarr.push({"EducationID":"-1","EducationName":"Any"})
console.log(this.educarr);
}else{
console.log(this.educarr);
for(let i=0;i<this.educarr.length;i++)
{
  if(this.educarr[i]["EducationID"]=='-1')
  {
      this.educarr.splice(i,1)
  }
}
this.educarr = [...this.educarr];
}
  console.log(this.educarr);
}
onItemDeSelect_educat(item: any) {
  console.log(this.educarr);
}
//occupation
onItemSelect_occ(item: any){
  if(item.OccupationID=="-1")
{
this.occarr=[]
this.occarr.push({"OccupationID":"-1","OccupationName":"Any"})
console.log(this.occarr);
}else{
console.log(this.occarr);
for(let i=0;i<this.occarr.length;i++)
{
  if(this.occarr[i]["OccupationID"]=='-1')
  {
      this.occarr.splice(i,1)
  }
}
this.occarr = [...this.occarr];
}
  console.log(this.occarr);
}
onItemDeSelect_occ(item: any) {
  console.log(this.occarr);
}
//emp in

onItemSelect_emp(item: any){
  if(item.item_id=="Any")
{
this.mpinarr=[]
this.mpinarr.push({"item_id":"Any","item_text":"Any"})
console.log(this.mpinarr);
}else{
console.log(this.mpinarr);
for(let i=0;i<this.mpinarr.length;i++)
{
  if(this.mpinarr[i]["item_id"]=='Any')
  {
      this.mpinarr.splice(i,1)
  }
}
this.mpinarr = [...this.mpinarr];
}
  console.log(this.mpinarr);
}
onItemDeSelect_emp(item: any) {
  console.log(this.occarr);
}

//city
onItemSelect_city(item: any){
  if(item.CityID=="-1")
{
this.cityarr=[]
this.cityarr.push({"CityID":"Any","CityName":"Any"})
console.log(this.cityarr);
}else{
console.log(this.cityarr);
for(let i=0;i<this.cityarr.length;i++)
{
  if(this.cityarr[i]["CityID"]=='-1')
  {
      this.cityarr.splice(i,1)
  }
}
this.cityarr = [...this.cityarr];
}
  console.log(this.cityarr);
}
onItemDeSelect_city(item: any) {
  console.log(this.cityarr);
}
//mother toung
  
  onItemSelect_mothert(item: any){
    if(item.item_id=="Any")
{
  this.motarr=[]
  this.motarr.push({"item_id":"Any","item_text":"Any"})
  console.log(this.motarr);

}else{
  console.log(this.motarr);
  for(let i=0;i<this.motarr.length;i++)
  {
    if(this.motarr[i]["item_id"]=='Any')
    {
        this.motarr.splice(i,1)
    }
  }
 
  this.motarr = [...this.motarr];



}
    console.log(this.motarr);

  }

  onItemDeSelect_mothert(item: any) {
    
    console.log(this.motarr);
  }
  //job
  onItemSelect_job(item: any) {
if(item.CntID=="-1")
{
  this.jbcntarr=[]
  this.jbcntarr.push({"CntID":"-1","CntName":"Any"})
  console.log(this.jbcntarr);

}else{
  console.log(this.jbcntarr);
  for(let i=0;i<this.jbcntarr.length;i++)
  {
    if(this.jbcntarr[i]["CntID"]=='-1')
    {
        this.jbcntarr.splice(i,1)
    }
  }
 
  this.jbcntarr = [...this.jbcntarr];



}
    console.log(this.jbcntarr);

  }
  onItemDeSelect_job(item: any) {
    
    console.log(this.jbcntarr);
  }






  onItemSelect(item: any,arr:any=[]) {

    console.log(arr);
    if(item.item_id=="Any")
{
  arr=[]
  arr.push({"item_id":"Any","item_text":"Any"})
  console.log(arr);

}else{
  console.log(arr)
  for(let i=0;i<arr.length;i++)
  {
    if(arr[i]["item_id"]=='Any')
    {
      arr.splice(i,1)
    }
  }
 
  arr = [...arr];



}
this.naksharr = [...this.naksharr];
this.mpinarr = [...this.mpinarr];


console.log(arr)
console.log(this.mpinarr)
console.log(this.naksharr)

  }
  onItemDeSelect(item: any) {
    
    console.log(this.mstarr);

  }

  get agefrom(){return this.basicpreferencesForm.get('agefrom')};
  get ageto(){return this.basicpreferencesForm.get('ageto')};
 get heightfrom(){return this.basicpreferencesForm.get('heightfrom')};
 get heightto(){return this.basicpreferencesForm.get('heightto')};
 get mstatus(){return this.basicpreferencesForm.get('mstatus')};
 get disabilit(){return this.basicpreferencesForm.get('disabilit')};


 get mtongue(){return this.religiouspreferenceform.get('mtongue')};
 get relig(){return this.religiouspreferenceform.get('relig')};
 get caste(){return this.religiouspreferenceform.get('caste')};
 get subcaste(){return this.religiouspreferenceform.get('subcaste')};
 get nakshatra(){return this.religiouspreferenceform.get('nakshatra')};
 get mangalDosh(){return this.religiouspreferenceform.get('mangalDosh')};

get educat(){return this.professionalpreferenceform.get('educat')};
get occupation(){return this.professionalpreferenceform.get('occupation')};
get empin(){return this.professionalpreferenceform.get('empin')};

get country(){return this.locationpreferenceform.get('country')};
get state(){return this.locationpreferenceform.get('state')};
get city(){return this.locationpreferenceform.get('city')};
get jobcountry(){return this.locationpreferenceform.get('jobcountry')};

get aboutForm(){return this.aboutForForm.get('aboutForm')}




 getReligionDetails()
{
  this.logspinner = true;
    let headers = new Headers({ 'Content-Type': 'application/json' });
    this.regservice.getReligionData(headers).subscribe(Response =>
      {
        this.religionlist = Response['data'];
      })
}

getSubCastDetails()
  {
    let newarr_reli=[]
  for(let i=0;i<this.castarr.length;i++)
  {
  newarr_reli.push(this.castarr[i].CastID)
  }
      let headers = new Headers({ 'Content-Type': 'multipart/form-data' });
      let body = new FormData();
      body.append('VarCastID',JSON.stringify(newarr_reli).replace(/"/g, "'").slice(1, -1));
      this.memberservice.getmulsubcatDataUrl(body,headers).subscribe(Response =>
        {
          if(Response['status']==1) 
        {
            this.subcastlist =  Response['data'];
            this.subcastlist.unshift({"SubCastID":"-1","SubCastName":'Any'})

          }
          else
          {
            this.subcastlist = [];
          }
        })
    

    
  }
getCasteDetails()
{
  let newarr_reli=[],rlig_id=[]
  for(let i=0;i<this.religarr.length;i++)
  {
  // newarr_reli.push(this.religarr[i].ReligionName)
  rlig_id.push(this.religarr[i].ReligionID)
  }

    let headers = new Headers({ 'Content-Type': 'multipart/form-data' });
    let body = new FormData();
    body.append('VarReligionID', JSON.stringify(rlig_id).replace(/"/g, "'").slice(1, -1));
    this.memberservice.getmulcatDataUrl(body,headers).subscribe(Response =>
    {
        if(Response['status']==1) 
        {
            this.castelist = Response['data'];
        }
        else
        {
          this.castelist = [];
        }
    })
}
  

 getage()
 {
     for(let i=19;i<=60;i++)
     {
       this.agelist.push(i)
     }
 }


 getHeightDetails()
 {
     let headers = new Headers({'Content-Type':'application/json'});
     this.regservice.getHeightData(headers).subscribe(Response =>
     {
       this.heightlist = Response['data']
       console.log("Abhay Height"+JSON.stringify(this.heightlist));
     })
 }

 getEducationDetails()
 {
 
     let headers = new Headers({ 'Content-Type': 'application/json' });
     this.regservice.getEducationData(headers).subscribe(Response =>
     {
         if(Response['status']==1) 
         {
             this.Educationlist = Response['data'];
            //  console.log(Response['data'])
            this.Educationlist.unshift({"EducationID":"-1","EducationName":"Any"})
            //  for(let i=0;i<Response['data'].length;i++)
            //  {
            //    if(Response['data'][i].EducationName!=='-')
            //    {
            //     this.Educationlist.push({"EducationID":Response['data'][i].EducationID,"EducationName":Response['data'][i].EducationName})
            //    }
            //  }
            //  console.log(this.Educationlist)
         }
         else
         {
          //  this.EducationTitle = [];
           this.Educationlist = [];
         }
     })
 
 }
 getOccupationDetails()
{
  let headers = new Headers({'Content-Type':'application/json'});
  let body = new FormData();
    // body.append('occID', "1");

    this.regservice.getOccupationData(body,headers).subscribe(Response =>
    {
     
        if(Response['status']==1) 
        {
          this.Occupationlist = Response['data'];

          this.Occupationlist.unshift({"OccupationID":"-1","OccupationName":"Any"})
          // for(let i=0;i<Response['data'].length;i++)
          // {
          //   if(Response['data'][i].OccupationName!=='-')
          //   {
          //    this.Occupationlist.push(Response['data'][i])
          //   }
          // }
          console.log(this.Occupationlist)

//           if(this.userProfileData.MemberOccupation=='-1')
//             {
//               this.userOccuption = 'Any';
//               this.occarr.push({"OccupationID":"-1","OccupationName":'Any'})
//             }else{
//               alert(this.userProfileData.MemberOccupation)
//               this.userOccuption = this.userProfileData.MemberOccupation;
// // this.occarr.splice(0,3)
//               let mst=(this.userProfileData.MemberOccupation).split(',')
//               console.log(mst)
//               for(let i=0;i<mst.length;i++)
//               {
//                 for(let j=0;j<this.Occupationlist.length;j++)
//                 {
//                   if(mst[i]==this.Occupationlist[j].OccupationID){
//                     // alert(this.Occupationlist[j].OccupationID)
//                     // this.Occupationlist[j].OccupationID
//                     this.occarr_msg.push(this.Occupationlist[j].OccupationName)
                    
//                     this.occarr.push({"OccupationID":mst[i],"OccupationName":this.Occupationlist[j].OccupationName})

//                   }

//                 }

//               }
//               this.userOccuption = JSON.stringify(this.occarr_msg).replace(/"/g, "'").slice(1, -1)

//             }
            console.log(this.occarr)
          
      
    }
      else
      {
       //  this.EducationTitle = [];
        this.Occupationlist = [];
      }
           
    })

}



openMOdelbasicpreference()
{
  $('#basicpreferences').css("display", "block",).toggleClass("in"); 
  $('body').toggleClass("modal-open");
}

closeModelbasicpreference()
{
  $('#basicpreferences').css("display", "none",).removeClass("in");
  $('body').removeClass('modal-open');
}

openMOdelreligionpreference()
{
  $('#religiousPreferencesEdit').css("display", "block",).toggleClass("in"); 
}

closeModelreligiouspreference()
{
  $('#religiousPreferencesEdit').css("display", "none",).removeClass("in");
}

openMOdelprofessionalpreference()
{
  $('#professionalPreferencesEdit').css("display", "block",).toggleClass("in"); 
}

closeModelprofessionalpreference()
{
  $('#professionalPreferencesEdit').css("display", "none",).removeClass("in");
}
openMOdellocationpreference(){
  $('#locationPreferencesEdit').css("display", "block",).toggleClass("in");  
}
closeModellocationpreference()
{
  $('#locationPreferencesEdit').css("display", "none",).removeClass("in");
}
openMOdel()
{

  $('#proEditAbout').css("display", "block",).toggleClass("in");
}

closeModel(){
  $('#proEditAbout').css("display", "none",).removeClass("in");
}

EditAboutUsHash()
{
   let headers = new Headers({'Content-Type' : 'Application/json'})
   let body = new FormData
  // alert(this.aboutForForm.get('aboutForm').value)
   let editform = (this.aboutForForm.get('aboutForm').value).replaceAll("'","")
    if(editform != '')
    {
   body.append('LoggedUserid',localStorage.getItem('UserMemberID'))
   body.append('MembAboutPartner',(this.aboutForForm.get('aboutForm').value).replaceAll("'",""))

  }
  else
  {
    this.isSubmitAbout = true
  }
 return this.memberservice.aboutusupdateurl(body,headers).subscribe(basicprefRes =>{
    this.MemberPreferenceDetails();
    this.changeBodyClass();
    this.closeModel()

    Swal.fire({
      title : 'Success', 
      text: basicprefRes['message'], 
      icon : 'success',
      showConfirmButton : false,
      timer : 1500});
  })

}

Editlocationlpreference(){
  this.islocationpreference = true;
  if(!this.locationpreferenceform.valid)
  {
    return false;
  }
  else
  {
  let headers = new Headers({'content-type' : 'application/json'})
  let body = new FormData
  let cntsearr=[],cntsenid=[]
  for(let i=0;i<this.cntarr.length;i++)
  {
    cntsenid.push(this.cntarr[i].CntID)
    cntsearr.push(this.cntarr[i].CntName)
    cntsenid = [...new Set(cntsenid)];
    cntsearr = [...new Set(cntsearr)];
  }
  let statesearr=[],statesenid=[]
  for(let i=0;i<this.statarr.length;i++)
  {
    statesenid.push(this.statarr[i].StateID)
    statesearr.push(this.statarr[i].StateName)
    statesearr = [...new Set(statesearr)];
    statesenid = [...new Set(statesenid)];
  }
  let citysearr=[],citysenid=[]
  for(let i=0;i<this.cityarr.length;i++)
  {
    if(this.cityarr[i].CityID=='Any')
    {
      citysenid.push("-1")

    }else{
      citysenid.push(this.cityarr[i].CityID)

    }
    citysearr.push(this.cityarr[i].CityName)
    citysearr = [...new Set(citysearr)];
    citysenid = [...new Set(citysenid)];



  }
  let jcntrysearr=[],jcntrysenid=[]
  for(let i=0;i<this.jbcntarr.length;i++)
  {
    if(this.jbcntarr[i].CntID=='Any')
    {
      jcntrysenid.push("-1")

    }else{
      jcntrysenid.push(this.jbcntarr[i].CntID)

    }
    // jcntrysenid.push(this.jbcntarr[i].CntID)
    jcntrysearr.push(this.jbcntarr[i].CntName)
    jcntrysearr = [...new Set(jcntrysearr)];
    jcntrysenid = [...new Set(jcntrysenid)];


  }

  console.log(JSON.stringify(cntsenid).replace(/"/g, "'").slice(1, -1))
  console.log(JSON.stringify(statesenid).replace(/"/g, "'").slice(1, -1))
  console.log(JSON.stringify(citysenid).replace(/"/g, "'").slice(1, -1))
  console.log(JSON.stringify(cntsearr).replace(/"/g, "'").slice(1, -1))
  console.log(JSON.stringify(statesearr).replace(/"/g, "'").slice(1, -1))
  console.log(JSON.stringify(citysearr).replace(/"/g, "'").slice(1, -1))
  console.log(JSON.stringify(jcntrysenid).replace(/"/g, "'").slice(1, -1))
  console.log(JSON.stringify(jcntrysearr).replace(/"/g, "'").slice(1, -1))
  

  body.append('LoggedUserid',localStorage.getItem('UserMemberID'))
  body.append('MembCountryID',JSON.stringify(cntsenid).replace(/"/g, "").slice(1, -1))
  body.append('MembStateID',JSON.stringify(statesenid).replace(/"/g, "").slice(1, -1))
  body.append('MembCityID',JSON.stringify(citysenid).replace(/"/g, "").slice(1, -1))
  body.append('MembCountryNm',JSON.stringify(cntsearr).replace(/"/g, "").slice(1, -1))
  body.append('MembStateNm',JSON.stringify(statesearr).replace(/"/g, "").slice(1, -1))
  body.append('MembCityNm',JSON.stringify(citysearr).replace(/"/g, "").slice(1, -1))
  body.append('MembJobCountryID',JSON.stringify(jcntrysenid).replace(/"/g, "").slice(1, -1))
  if(jcntrysearr[0]=='Any'){

    body.append('MembJobCountryNM',JSON.stringify(jcntrysearr).replace(/"/g, "").slice(1, -1))
    
    }else{
      body.append('MembJobCountryNM',JSON.stringify(jcntrysearr).replace(/"/g, "'").slice(1, -1))
  
    }


  return this.memberservice.EditlocationPreferenceUrl(body,headers).subscribe(basicprefRes =>{
    this.MemberPreferenceDetails();
    this.changeBodyClass();
    this.closeModellocationpreference()
   
    Swal.fire({
      title : 'Success', 
      text: basicprefRes['message'], 
      icon : 'success',
      showConfirmButton : false,
      timer : 1500});
  })
}
}

Editprofessionalpreference(){
  this.isprofessionalpreference = true;
  if(!this.professionalpreferenceform.valid)
  {
    return false;
  }
  else
  {
  let headers = new Headers({'content-type' : 'application/json'})
  let body = new FormData
  let onewarr=[],edunm=[]
  for(let i=0;i<this.educarr.length;i++)
  {
    onewarr.push(this.educarr[i].EducationID)
    edunm.push(this.educarr[i].EducationName)
    
    edunm = [...new Set(edunm)];

    onewarr = [...new Set(onewarr)];

  }
  console.log(JSON.stringify(onewarr).replace(/"/g, "'").slice(1, -1))

  let olig_id=[],occnm=[]
  for(let i=0;i<this.occarr.length;i++)
  {
  // newarr_reli.push(this.religarr[i].ReligionName)
  olig_id.push(this.occarr[i].OccupationID)
  occnm.push(this.occarr[i].OccupationName)

  olig_id = [...new Set(olig_id)];
  occnm = [...new Set(occnm)];


  }
  console.log(JSON.stringify(olig_id).replace(/"/g, "'").slice(1, -1))
  let emp_nm=[]
  for(let i=0;i<this.mpinarr.length;i++)
  {
  // newarr_reli.push(this.religarr[i].ReligionName)
  emp_nm.push(this.mpinarr[i].item_text)
  emp_nm = [...new Set(emp_nm)];

  }

  // console.log(JSON.stringify(this.basicpreferencesForm.get('mstatus').value).replaceAll('"',"'"))
console.log(JSON.stringify(onewarr).replace(/"/g, "").slice(1, -1))
console.log(JSON.stringify(olig_id).replace(/"/g, "").slice(1, -1))
console.log(JSON.stringify(emp_nm).replace(/"/g, "'").slice(1, -1))
  
  body.append('LoggedUserid',localStorage.getItem('UserMemberID'))
  body.append('MembEducation',JSON.stringify(onewarr).replace(/"/g, "").slice(1, -1))
  body.append('MembOccupation',JSON.stringify(olig_id).replace(/"/g, "").slice(1, -1))

  if(edunm[0]=='Any'){

    body.append('MembEducationName',JSON.stringify(edunm).replace(/"/g, "").slice(1, -1))
    
    }else{
      body.append('MembEducationName',JSON.stringify(edunm).replace(/"/g, "'").slice(1, -1))
  
    }

  if(occnm[0]=='Any'){

    body.append('MembOccupationName',JSON.stringify(occnm).replace(/"/g, "").slice(1, -1))
    
    }else{
      body.append('MembOccupationName',JSON.stringify(occnm).replace(/"/g, "'").slice(1, -1))
  
    }

  if(emp_nm[0]=='Any'){

  body.append('MembEmployedIn',JSON.stringify(emp_nm).replace(/"/g, "").slice(1, -1))
  
  }else{
    body.append('MembEmployedIn',JSON.stringify(emp_nm).replace(/"/g, "'").slice(1, -1))

  }

  return this.memberservice.EditprofesionalPreferenceUrl(body,headers).subscribe(basicprefRes =>{
    this.MemberPreferenceDetails();
    this.changeBodyClass();
    this.closeModelprofessionalpreference()
   
    Swal.fire({
      title : 'Success', 
      text: basicprefRes['message'], 
      icon : 'success',
      showConfirmButton : false,
      timer : 1500});
  })
}
}

Editreligiouspreference()
{
  
  this.isreligiouspreference = true;
  if(!this.religiouspreferenceform.valid)
  {
    return false;
  }
  else
  {
  let headers = new Headers({'content-type' : 'application/json'})
  let body = new FormData
  console.log(this.castarr)
  this.newarr=[]

  for(let i=0;i<this.motarr.length;i++)
  {
    this.newarr.push(this.motarr[i].item_text)
    this.newarr = [...new Set(this.newarr)];

  }
  

  let newarr_reli=[],rlig_id=[];
  for(let i=0;i<this.religarr.length;i++)
  {
  newarr_reli.push(this.religarr[i].ReligionName)
  newarr_reli = [...new Set(newarr_reli)];

  rlig_id.push(this.religarr[i].ReligionID)
  rlig_id = [...new Set(rlig_id)];

  }
  console.log(JSON.stringify(newarr_reli).replace(/"/g, "'").slice(1, -1))
  let newarr_cast=[],cast_id=[]
  for(let i=0;i<this.castarr.length;i++)
  {
    cast_id.push(this.castarr[i].CastID)
    cast_id = [...new Set(cast_id)];

    newarr_cast.push(this.castarr[i].CastName)
    newarr_cast = [...new Set(newarr_cast)];

  }
  let newarr_subcast=[],subcast_id=[]
  for(let i=0;i<this.subcastarr.length;i++)
  {
    subcast_id.push(this.subcastarr[i].SubCastID)
    subcast_id = [...new Set(subcast_id)];

    newarr_subcast.push(this.subcastarr[i].SubCastName)
    newarr_subcast = [...new Set(newarr_subcast)];

  }
  let naksh=[]
  for(let i=0;i<this.naksharr.length;i++)
  {
    naksh.push(this.naksharr[i].item_text)
    naksh = [...new Set(naksh)];

  }
  if(this.newarr[0]=='Any'){
    console.log(JSON.stringify(this.newarr).replace(/"/g, "").slice(1, -1))
  }else{
    console.log(JSON.stringify(this.newarr).replace(/"/g, "'").slice(1, -1))
  
  }
  console.log(JSON.stringify(JSON.stringify(newarr_reli).replace(/"/g, "'").slice(1, -1)))
  console.log(JSON.stringify(JSON.stringify(rlig_id).replace(/"/g, "").slice(1, -1)))
  console.log(JSON.stringify(JSON.stringify(newarr_cast).replace(/"/g, "'").slice(1, -1)))
  console.log(JSON.stringify(JSON.stringify(cast_id).replace(/"/g, "").slice(1, -1)))
  console.log(JSON.stringify(JSON.stringify(newarr_subcast).replace(/"/g, "'").slice(1, -1)))
  console.log(JSON.stringify(JSON.stringify(subcast_id).replace(/"/g, "").slice(1, -1)))
  console.log(JSON.stringify(this.religiouspreferenceform.get('mangalDosh').value))
  console.log(JSON.stringify(JSON.stringify(naksh).replace(/"/g, "'").slice(1, -1)))
// return;

  body.append('LoggedUserid',localStorage.getItem('UserMemberID'))
  if(this.newarr[0]=='Any'){
  body.append('MembMotherTongue',JSON.stringify(this.newarr).replace(/"/g, "").slice(1, -1))
  }else{
    body.append('MembMotherTongue',JSON.stringify(this.newarr).replace(/"/g, "'").slice(1, -1))
  }
  body.append('MembReligion',JSON.stringify(newarr_reli).replace(/"/g, "'").slice(1, -1))
  body.append('MembReliID',JSON.stringify(rlig_id).replace(/"/g, "").slice(1, -1))
  body.append('MembCast',JSON.stringify(newarr_cast).replace(/"/g, "'").slice(1, -1))
  body.append('MembCastID',JSON.stringify(cast_id).replace(/"/g, "").slice(1, -1))
  if(newarr_subcast[0]=='Any'){

  body.append('MembSubCast',JSON.stringify(newarr_subcast).replace(/"/g, "").slice(1, -1))
  }else{
    body.append('MembSubCast',JSON.stringify(newarr_subcast).replace(/"/g, "'").slice(1, -1))
  }
  body.append('MembSubCastID',JSON.stringify(subcast_id).replace(/"/g, "").slice(1, -1))
  body.append('MembDosh',this.religiouspreferenceform.get('mangalDosh').value)
  if(naksh[0]=='Any'){

  body.append('MembNakshtra',JSON.stringify(naksh).replace(/"/g, "").slice(1, -1))

  }else{
    body.append('MembNakshtra',JSON.stringify(naksh).replace(/"/g, "'").slice(1, -1))

  }


  return this.memberservice.EditreligionPreferenceUrl(body,headers).subscribe(basicprefRes =>{
    console.log("zxc"+basicprefRes['message'])
    this.MemberPreferenceDetails();
    this.changeBodyClass();
    this.closeModelreligiouspreference()
   
    Swal.fire({
      title : 'Success', 
      text: basicprefRes['message'], 
      icon : 'success',
      showConfirmButton : false,
      timer : 1500});
  })
}

}


Editbasicpreference()
{
  
  this.issubmitbasicpreference = true;
  if(!this.basicpreferencesForm.valid)
  {
    return false;
  }
  else
  {
  let headers = new Headers({'content-type' : 'application/json'})
  let body = new FormData
  console.log(JSON.stringify(this.basicpreferencesForm.get('mstatus').value).replace(/"/g, "'").slice(1, -1))
  let newarrm=[]
  for(let i=0;i<this.mstarr.length;i++)
  {
    newarrm.push(this.mstarr[i].item_text)
    newarrm = [...new Set(newarrm)];


  }

  console.log(JSON.stringify(newarrm).replace(/"/g, "'").slice(1, -1))
  console.log(this.basicpreferencesForm.get('disabilit').value)

  // console.log(JSON.stringify(this.basicpreferencesForm.get('mstatus').value).replaceAll('"',"'"))

  
  body.append('LoggedUserid',localStorage.getItem('UserMemberID'))
  body.append('MembFromAge',this.basicpreferencesForm.get('agefrom').value)
  body.append('MemberToAge',this.basicpreferencesForm.get('ageto').value)
  body.append('MembFromHeight',this.basicpreferencesForm.get('heightfrom').value)
  body.append('MembToHeight',this.basicpreferencesForm.get('heightto').value)
  

  if(newarrm[0]=='Any'){
    body.append('MembMartialStatus',JSON.stringify(newarrm).replace(/"/g, "").slice(1, -1))
  }else{
    body.append('MembMartialStatus',JSON.stringify(newarrm).replace(/"/g, "'").slice(1, -1))
  
  }
  if(this.basicpreferencesForm.get('disabilit').value=='Any')
{
  body.append('MembDisability',
  this.basicpreferencesForm.get('disabilit').value)
}else{
  body.append('MembDisability',"'"+
  this.basicpreferencesForm.get('disabilit').value+"'")
}

  
  


  return this.memberservice.EditPreferenceUrl(body,headers).subscribe(basicprefRes =>{
    console.log("zxc"+basicprefRes['message'])
    this.MemberPreferenceDetails();
    this.changeBodyClass();
    this.closeModelbasicpreference()
   
    Swal.fire({
      title : 'Success', 
      text: basicprefRes['message'], 
      icon : 'success',
      showConfirmButton : false,
      timer : 1500});
  })
}

}


onChangeCountry()
{
  let newarr_sts=[]
  for(let i=0;i<this.cntarr.length;i++)
  {
  newarr_sts.push(this.cntarr[i].CntID)
  }
    
      let headers = new Headers({'Content-Type':'multipart/form-data'});
      let body = new FormData();
      body.append('VarCountryID', JSON.stringify(newarr_sts).replace(/"/g, "'").slice(1, -1));
      this.memberservice.getmulstateDataUrl(body,headers).subscribe(Response =>
        {
          console.log(Response['data'])

          if(Response['status']==1){
            this.statelist =  Response['data'];
          }
          else
          {
            this.statelist = [];
          }
          console.log(this.statelist)

        })
   

}



onChangeState()
{
  let newarr_sts=[]
  for(let i=0;i<this.statarr.length;i++)
  {
  newarr_sts.push(this.statarr[i].StateID)
  }
  this.citylist=[]
      let headers = new Headers({'Content-Type':'multipart/form-data'});
      let body = new FormData();
      body.append('VarStateID', JSON.stringify(newarr_sts).replace(/"/g, "'").slice(1, -1));
      this.memberservice.getmulcityDataUrl(body,headers).subscribe(Response =>
        {

          if(Response['status']=='1'){
            this.citylist =  Response['data'];
            this.citylist.unshift({'CityID':"-1","CityName":"Any"})

          }else{
            this.citylist=[]
          }
        })
  

}

mstarr:any=[]
  MemberPreferenceDetails()
  {
    this.logspinner = true;
    let headers = new Headers({'Content-Type' : 'Application/json'})
    let body = new FormData

    body.append('LoggedUserid',localStorage.getItem('UserMemberID')); 
    return this.memberservice.ViewPreferenceUrl(body,headers).subscribe(responce =>{
            this.userProfileData = responce['data'];
            
            this.memfrmage=responce['data'].MemberFromAge;
            this.memtoage=responce['data'].MemberToAge;
            this.mothertongue = responce['data'].MemberMotherTongue.replace(/['‘’"“”]/g, '');
            this.Religion = responce['data'].MemberReligion.replace(/['‘’"“”]/g, '');
            this.subCasteVal = responce['data'].MemberSubCaste.replace(/['‘’"“”]/g, '');
            this.nakshatraVal = responce['data'].MemberNakshtra.replace(/['‘’"“”]/g, '');
            this.jobcountryVal = responce['data'].MemberJobCountryNM.replace(/['‘’"“”]/g, '');

            this.educationVal = responce['data'].MemberEducationName.replace(/['‘’"“”]/g, '');
            this.occupationVal = responce['data'].MemberOccupationName.replace(/['‘’"“”]/g, '');
            this.employeeInVal = responce['data'].MemberEmployedIn.replace(/['‘’"“”]/g, '');
            

            if(responce['data'].MemberJobCountryNM!=null){
            if(!responce['data'].MemberJobCountryNM.includes(','))
            {
              this.jbcntarr.push({'CntID':responce['data'].MemberJobCountryID.replace(/['"]+/g, ''),'CntName':responce['data'].MemberJobCountryNM.replace(/['"]+/g, '')})

            }else{
              let mst=(responce['data'].MemberJobCountryNM).split(',')
              for(let i=0;i<mst.length;i++)
              {
                this.jbcntarr.push({'CntID':(responce['data'].MemberJobCountryID).split(',')[i].replace(/['"]+/g, ''),'CntName':mst[i].replace(/['"]+/g, '')})

              }
            }}
            if(responce['data'].MemberCityName!=null){
            if(!responce['data'].MemberCityName.includes(','))
            {
              this.cityarr.push({'CityID':responce['data'].MemberCityID.replace(/['"]+/g, ''),'CityName':responce['data'].MemberCityName.replace(/['"]+/g, '')})

            }else{
              

              let mst=(responce['data'].MemberCityName).split(',')
              for(let i=0;i<mst.length;i++)
              {
                this.cityarr.push({'CityID':(responce['data'].MemberCityID).split(',')[i].replace(/['"]+/g, ''),'CityName':mst[i].replace(/['"]+/g, '')})

              }
            }}
            console.log(this.cityarr)
            
            if(responce['data'].MemberStateName!=null){

            if(!responce['data'].MemberStateName.includes(','))
            {
              this.statarr.push({'StateID':responce['data'].MemberStateID.replace(/['"]+/g, ''),'StateName':responce['data'].MemberStateName.replace(/['"]+/g, '')})
              this.onChangeState()

            }else{
              

              let mst=(responce['data'].MemberStateName).split(',')
              for(let i=0;i<mst.length;i++)
              {
                this.statarr.push({'StateID':(responce['data'].MemberStateID).split(',')[i].replace(/['"]+/g, ''),'StateName':mst[i].replace(/['"]+/g, '')})
                this.onChangeState()

              }
            }}
            console.log(this.statarr)
            if(responce['data'].MemberCountryName!=null){

            if(!responce['data'].MemberCountryName.includes(','))
            {
              this.cntarr.push({'CntID':responce['data'].MemberCountryID.replace(/['"]+/g, ''),'CntName':responce['data'].MemberCountryName.replace(/['"]+/g, '')})
              this.onChangeCountry()

            }else{
              let mst=(responce['data'].MemberCountryName).split(',')
              for(let i=0;i<mst.length;i++)
              {
                this.cntarr.push({'CntID':(responce['data'].MemberCountryID).split(',')[i].replace(/['"]+/g, ''),'CntName':mst[i].replace(/['"]+/g, '')})
                this.onChangeCountry()

              }
            }}
            
//mstatus
if(responce['data'].MemberMStatus!=null){

            if(!responce['data'].MemberMStatus.includes(','))
            {
              this.mstarr.push({'item_id':responce['data'].MemberMStatus.replace(/['"]+/g, ''),'item_text':responce['data'].MemberMStatus.replace(/['"]+/g, '')})

            }else{
              let mst=responce['data'].MemberMStatus.split(',')
              for(let i=0;i<mst.length;i++)
              {
                this.mstarr.push({'item_id':mst[i].replace(/['"]+/g, ''),'item_text':mst[i].replace(/['"]+/g, '')})

              }
            }}
            if(responce['data'].MemberEmployedIn!=null){

            if(!responce['data'].MemberEmployedIn.includes(','))
            {
              this.mpinarr.push({'item_id':responce['data'].MemberEmployedIn.replace(/['"]+/g, ''),'item_text':responce['data'].MemberEmployedIn.replace(/['"]+/g, '')})

            }else{
              let mst=(responce['data'].MemberEmployedIn).split(',')
              for(let i=0;i<mst.length;i++)
              {
                this.mpinarr.push({'item_id':mst[i].replace(/['"]+/g, ''),'item_text':mst[i].replace(/['"]+/g, '')})

              }
            }}
            if(responce['data'].MemberNakshtra!=null){

            if(!responce['data'].MemberNakshtra.includes(','))
            {
              this.naksharr.push({'item_id':responce['data'].MemberNakshtra.replace(/['"]+/g, ''),'item_text':responce['data'].MemberNakshtra.replace(/['"]+/g, '')})

            }else{
              let mst=(responce['data'].MemberNakshtra).split(',')
              for(let i=0;i<mst.length;i++)
              {
                this.naksharr.push({'item_id':mst[i].replace(/['"]+/g, ''),'item_text':mst[i].replace(/['"]+/g, '')})

              }
            }}
console.log(this.naksharr)
            
            //mother tongue
            if(responce['data'].MemberMotherTongue!=null){
              if(!responce['data'].MemberMotherTongue.includes(','))
            {
              this.motarr.push({'item_id':responce['data'].MemberMotherTongue.replace(/['"]+/g, ''),'item_text':responce['data'].MemberMotherTongue.replace(/['"]+/g, '')})

            }else{
              let mst=(responce['data'].MemberMotherTongue).split(',')
              for(let i=0;i<mst.length;i++)
              {
                this.motarr.push({'item_id':mst[i].replace(/['"]+/g, ''),'item_text':mst[i].replace(/['"]+/g, '')})

              }
            }}
            if(responce['data'].MemberReligion!=null){

            if(!responce['data'].MemberReligion.includes(','))
            {
              this.religarr.push({"ReligionID":responce['data'].MemberReligionID,'ReligionName':responce['data'].MemberReligion.replace(/['"]+/g, '')})
              this.getCasteDetails()
            }else{
              let mst=(responce['data'].MemberReligion).split(',')
              for(let i=0;i<mst.length;i++)
              {
                this.religarr.push({"ReligionID":(responce['data'].MemberReligionID).split(',')[i],'ReligionName':mst[i].replace(/['"]+/g, '')})

              }
              this.getCasteDetails()
            }}
console.log(this.mstarr)
console.log(this.motarr)
console.log(this.religarr)


            this.marritalStatus = responce['data'].MemberMStatus.replace(/['"]+/g, '');
            this.disability = responce['data'].MemberDisability.replace(/['"]+/g, '');
            this.userCaste = responce['data'].MemberCaste.replace(/['"]+/g, '');


            if(responce['data'].MemberCaste!=null){

              if(!responce['data'].MemberCaste.includes(','))
            {
              this.userEducation = 'Any';
              this.castarr.push({"CastID":responce['data'].MemberCasteID,"CastName":responce['data'].MemberCaste.replace(/['"]+/g, '')})            
            this.getSubCastDetails()
          }
              else{
              let mst=(responce['data'].MemberCaste).split(',')
              for(let i=0;i<mst.length;i++)
              {
                this.castarr.push({"CastID":(responce['data'].MemberCasteID).split(',')[i],"CastName":mst[i].replace(/['"]+/g, '')})

              }

              this.getSubCastDetails()
            }}

            if(responce['data'].MemberSubCaste!=null){

            if(!responce['data'].MemberSubCaste.includes(','))
            {
              this.userEducation = 'Any';
              this.subcastarr.push({"SubCastID":responce['data'].MemberSubCasteID,"SubCastName":responce['data'].MemberSubCaste.replace(/['"]+/g, '')})            
          }
              else{
              let mst=(responce['data'].MemberSubCaste).split(',')
              for(let i=0;i<mst.length;i++)
              {
                this.subcastarr.push({"SubCastID":(responce['data'].MemberSubCasteID).split(',')[i],"SubCastName":mst[i].replace(/['"]+/g, '')})

              }

            }}

            
            if(responce['data'].MemberEducation!=null){

            if(responce['data'].MemberEducation=='-1')
            {
              this.userEducation = 'Any';
              this.educarr.push({"EducationID":"-1","EducationName":'Any'})
            }else{
              this.userEducation=responce['data'].MemberEducationName
              let mst=(responce['data'].MemberEducation).split(',')
              for(let i=0;i<mst.length;i++)
              {
                this.educarr.push({"EducationID":mst[i],"EducationName":responce['data'].MemberEducationName.split(',')[i].replace(/['‘’"“”]/g, '')})

              }
            }}
            if(responce['data'].MemberOccupation!=null){

            if(responce['data'].MemberOccupation=='-1')
            {
              this.userOccuption = 'Any';
              this.occarr.push({"OccupationID":"-1","OccupationName":'Any'})
            }else{
              this.userOccuption = responce['data'].MemberOccupationName;

              let mst=(responce['data'].MemberOccupation).split(',')
              for(let i=0;i<mst.length;i++)
              {
                // for(let j=0;j<=this.Occupationlist.length;j++)
                // {
                  // alert(this.Occupationlist[j])
                  // if(mst[i]==this.Occupationlist[j].OccupationID)
                  this.occarr.push({"OccupationID":mst[i],"OccupationName":(responce['data'].MemberOccupationName).split(',')[i].replace(/['‘’"“”]/g, '')})

                // }

              }
            }
            console.log(this.occarr)
          }
            this.logspinner = false;
           
    })

  }
  getCountryDetails()
{
    let headers = new Headers({'Content-Type':'application/json'});
    this.regservice.getCountryData(headers).subscribe(Response =>
      {
        this.countrylist = Response['data']
        // this.countrylist.push({"CntID":"-1","CntName":'Any'})

      })
}
getCountryDetails_job()
{
    let headers = new Headers({'Content-Type':'application/json'});
    this.regservice.getCountryData(headers).subscribe(Response =>
      {
        this.countrylist_job = Response['data']
        this.countrylist_job.unshift({"CntID":"-1","CntName":'Any'})

      })
}


changeBodyClass() {
    // get html body element
    const bodyElement = document.body;
    if (bodyElement) {
          $("body").removeClass("modal-open");
    }
  }

  ngOnInit(): void {
    // alert(localStorage.getItem('UserGender'))
    // =='Female')

this.widow=localStorage.getItem('UserGender')
   
    this.getEducationDetails()
    this.getHeightDetails();
    this.getage()
    this.getReligionDetails()
    this.getCountryDetails();
    this.getCountryDetails_job();
    this.MemberPreferenceDetails();
    this.getOccupationDetails()


  }

}
