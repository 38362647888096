import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import{AllnotificationService} from '../services/allnotification.service';
import { WebsiteConstants } from '.././global/website-constants';

@Component({
  selector: 'app-show-notification',
  templateUrl: './show-notification.component.html',
  styleUrls: ['./show-notification.component.css']
})
export class ShowNotificationComponent implements OnInit {

  masterUrl = WebsiteConstants.MasterUrl;
  logspinner=false; photopath:any; notificationresult:any; notificationMessage:any;
  constructor(private service:AllnotificationService, private route : Router) { }

  ngOnInit(): void {
    this.photopath = this.masterUrl;
    this.showallnotification()
  }

  showallnotification()
  {
      this.logspinner = true;
      let headers = new Headers({ 'Content-Type': 'application/json' });
        let body = new FormData();
        body.append('MemberID',localStorage.getItem('userid'));
        this.service.notificationlist(body,headers).subscribe(Response =>{
          
              if(Response['data']!==undefined)
              {
                  this.notificationresult=Response['data'];
                  console.log(this.notificationresult)
                  this.notificationMessage="";
              }
              else
              {
                  this.notificationresult=Response['status'];
                  this.notificationMessage = Response['message']
              }

                this.logspinner = false;
        })
  }

  memberdetailspost(row)
  {
    localStorage.setItem('viewUser',row.MemberID)
    let link = ['/member-details'];
    this.route.navigate(link);
  }

}
