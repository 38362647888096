
<div class="dashboardMain" id="activeButtDIV">
    
  <app-left-menu></app-left-menu>      
  <div class="admin-panel clearfix">
    <div class="main">
             
              <app-top-menu></app-top-menu>
    
              <div id="myMatche" class="myMatchesStyle" >
                
        <div class="samplecover-spin" id="samplecover-spin" *ngIf="lognearmespinner"></div>
        <div class="dashMainCont">

  
        <div class="mainContent clearfix">
                <div class="samplecover-spin" id="samplecover-spin" *ngIf="logspinner"></div>
                 <div class="dashMainCont" *ngIf="!logspinner">
                   <div class="dashMainInner">
                      <div class="matchesInner">
                        <div class="citySelectBt" id="slidePro" (click)="onToggle()">Preferred Profession<span class="caret"></span></div>
                        <div class="seleProPont">
                          <!-- <button class="proSeleBt" > -->
                            <div class="btStt"  *ngFor="let check of Occupationlist;" id="checkmybutton-{{check.OccupationGroupID}}" [ngClass]="{'proSeleBtActive' : check.OccupationGroupID == selectedcheck}">
                              <span class="btSttTool">{{check.OccupationGroupName}}</span>
                              <input type="checkbox" id="locationcheck{{check.OccupationGroupID}}"  name="locationcheck{{check.OccupationGroupID}}" [value]="check.OccupationGroupID" (change)="getChangedVal($event,check.OccupationGroupID)"  [checked]="check.OccupationGroupID == selectedcheck"><em>{{check.OccupationGroupName}}</em>
                            </div>
                          <!-- </button> -->
                        </div>
                        
                        <div class="row">
                        <div *ngIf="discoverProfession?.length > 0">  
                      <div *ngFor="let ProfileLocation of discoverProfession"> 
                          <div class="col-md-4">
                            <div class="matchesDiv">
                                <!-- <div class="ignorBt">Ignore <i class="fa fa-close"></i></div> -->
                                <div class="matchesPic">
                                  <div class="coun">{{ProfileLocation.MemberCNtMultiplePhotos}}</div>
                                  <div class="leftA"> <i class="fa fa-angle-left"></i> </div>
                                  <div class="rightA"> <i class="fa fa-angle-right"></i> </div>
                                    <img [src]='photopath+ProfileLocation.MemberPhotoPath'>
                                    <div *ngIf="ProfileLocation.MemberIsPhotoUpload == '0' && ProfileLocation.MembPhotoAddFlag== '0'">
                                      <button class="requestBtUp" id="requestphoto{{ProfileLocation.MemberID}}"(click)="photoRequestDash($event.target,ProfileLocation)">Request Photo</button>
                                    </div>
    
                                    <div *ngIf="ProfileLocation.MemberIsPhotoUpload == '0' && ProfileLocation.MembPhotoAddFlag== '1'">
                                    <button class="requestBtUp" id="requestphoto{{ProfileLocation.MemberID}}"disabled>Photo Request Sent</button>
                                    </div>
                                    <i class="fa fa-camera phoIco"></i>
                                </div>
                                <div class="callDetais">
                                    <div class="floatNeed">
                                    <span data-tooltip="Call Now"><i class="fa fa-phone phoneIc" (click)="openMOdel(ProfileLocation,masterUrl)"></i></span>

<span data-tooltip="Sent Interest" *ngIf="ProfileLocation.MemberSentInterestFlag !== '0'">
<i class="fa fa-heart heartIc likeIntro" id="sentInterst{{ProfileLocation.MemberID}}" *ngIf="ProfileLocation.MemberSentInterestFlag !== '0'"></i>
<i class="fa fa-heart heartIc" id="sentrunInterst{{ProfileLocation.MemberID}}" *ngIf="ProfileLocation.MemberSentInterestFlag == '0'" (click)="memberInterestlist(ProfileLocation)"></i>
</span>

<span data-tooltip="Send Interest" *ngIf="ProfileLocation.MemberSentInterestFlag == '0'">
  <i class="fa fa-heart heartIc likeIntro" id="sentInterst{{ProfileLocation.MemberID}}" *ngIf="ProfileLocation.MemberSentInterestFlag !== '0'"></i>
  <i class="fa fa-heart heartIc" id="sentrunInterst{{ProfileLocation.MemberID}}" *ngIf="ProfileLocation.MemberSentInterestFlag == '0'" (click)="memberInterestlist(ProfileLocation)"></i>
  </span>


  <span data-tooltip="Sent Interest">
    <i class="fa fa-heart heartIc hideHeart" id="sentshowInterst{{ProfileLocation.MemberID}}"></i>
    </span>

<input type="hidden" name="hiddeninterest{{ProfileLocation.MemberID}}" id="hiddeninterest{{ProfileLocation.MemberID}}" value="{{ProfileLocation.MemberSentInterestFlag}}" [(ngModel)]="ProfileLocation.MemberSentInterestFlag">

    
                                    <span data-tooltip="Shortlist Profile">
                                    <i class="fa fa-star starIc shortLis"  id="shortListID{{ProfileLocation.MemberID}}" *ngIf="ProfileLocation.MemberShortlistFlag !== '0'" (click)="membershortlist(ProfileLocation)"></i>
                                    
                                    <i class="fa fa-star starIc" id="shortListID{{ProfileLocation.MemberID}}" *ngIf="ProfileLocation.MemberShortlistFlag == '0'"  (click)="membershortlist(ProfileLocation)"></i>

                                    <input type="hidden" name="hiddenshort{{ProfileLocation.MemberID}}" id="hiddenshort{{ProfileLocation.MemberID}}" value="{{ProfileLocation.MemberShortlistFlag}}" [(ngModel)]="ProfileLocation.MemberShortlistFlag">
                                  </span>
                                    </div>
        
                                    <div class="detailsOuter">
                                    <h4 class="callDetaillsName">{{ProfileLocation.MemberName}} ({{ProfileLocation.MemberProfileID}})</h4>
                                    <p>{{ProfileLocation.MemberEducation}}, Job-City - {{ProfileLocation.MemberJobCity}}</p>
                                    <p>{{ProfileLocation.MemberAge}} yrs, {{ProfileLocation.MemberHeight}} | {{ProfileLocation.MemberMartialStatus}}</p>
                                    <p>{{ProfileLocation.MemberCaste}}, {{ProfileLocation.MemberNativePlace}} </p>
                                  </div>
                                    
                                    <button type="button" class="proDetailsBt" (click)="memberdetailspost(ProfileLocation)">
                                      View Profile
                                    </button>
                                </div>
                            </div>
                        </div>
                      </div>
                      </div>
                      
          </div>

          <div class="row" >
            <div *ngIf="discoverProfession==0" class="noDataMessage">
              <img src="../../assets/images/no-data-pana.png"/>
              {{myMessagePro}}</div>
          </div>
        
                        
        
        
        
                        </div>
                      </div>
                   </div>
                 </div>
              
  
            </div>
  
  
  
  
  
  </div>
  
      </div>
  </div>
  </div>  
  
  <div class="modal fade success-popup in" id="callModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" style=" padding-left: 17px;">
    <div class="modal-dialog modal-sm" role="document">
      <div class="modal-content">
        
        <div class="modal-body text-center">
          <i class="fa fa-close callPopDele" (click)="closePopup()" data-dismiss="modal"></i>
           <div class="calImgDiv" id="callImgDivs">
            <img src="" >
           </div>
           <h4 id="popupName">Contact Akshda Meshra on</h4>
            <p id="popupContact"></p>
        </div>
        <div class="modal-footer" id="popupupgrads">
          
        </div>
      </div>
    </div>
  </div>
  
  
  

