import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { WebsiteConstants } from '.././global/website-constants';

@Injectable({
  providedIn: 'root'
})
export class PhotoRequestService {

private masterUrl = WebsiteConstants.MasterUrl;  
photoRequestUrl = this.masterUrl+"SendRequesttoPhoto/SendRequesttoPhoto"
  constructor(private http : HttpClient) { }
  photoRequestPost(body,headers)
  {
    return this.http.post(this.photoRequestUrl,body,headers)
  }
}
