import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { WebsiteConstants } from '.././global/website-constants';

@Injectable({
  providedIn: 'root'
})
export class UpdateContactEmailService {
 
private masterUrl = WebsiteConstants.MasterUrl;  
checkExitRegistrationUrl = this.masterUrl+"CheckexitRegistration/CheckexitRegistration"
EditContactNoUrl=this.masterUrl+"CheckContactNoExist/EditContactno"
EditEmailIdUrl=this.masterUrl+"EditEmailid/EditEmailid"
constructor(private http : HttpClient) { }

checkExitRegistrationPost(body,headers)
{
  return this.http.post(this.checkExitRegistrationUrl,body,headers)
}
EditContactNoPost(body,headers)
{
  return this.http.post(this.EditContactNoUrl,body,headers)
}
EditEmailIdPost(body,headers)
{
  return this.http.post(this.EditEmailIdUrl,body,headers)
}
}
