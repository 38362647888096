import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { WebsiteConstants } from '.././global/website-constants';

@Injectable({
  providedIn: 'root'
})
export class DeleteaccountService {

  private masterUrl = WebsiteConstants.MasterUrl;
  deleteAccountUrl=this.masterUrl+"AddDeleteProfile/AddDeleteProfile";
  constructor(private http : HttpClient) { }

  deleteAccountPost(body,headers)
  {
    return this.http.post(this.deleteAccountUrl,body,headers)
  }

}
