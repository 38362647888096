
<div class="dashboardMain" id="activeButtDIV">
    <app-left-menu></app-left-menu>    
         <div class="admin-panel clearfix">
             <div class="main">
                 <app-top-menu></app-top-menu>
                 
             <div class="samplecover-spin" id="samplecover-spin" *ngIf="logspinner"></div> 
            <div class="dashMainInner" *ngIf="!logspinner">
                <div class="matchesInner">
                        
                     <div class="row">
      <div class="col-md-12">
        <h3 class="succHeading">Success Stories</h3>
        </div>
    </div>

    <div class="row rowAlign">
     <div class="col-md-4" *ngFor = "let stories of succStory" (click)="redirectTo(photopath+stories.SPhoto,stories.SName,stories.SMarriageDate,stories.SDescription)">
        <div class="blog-text-wrapp">
            <div class="blog-text-img">
            <img [src]="photopath+stories.SPhoto" alt="">
            </div>
            <div class="blog-text-inner">
            <div class="blog-dateRight">
            <div class="commo-subHead"><b>{{stories.SName}}</b></div>
            <div class="commo-subHead" *ngIf="stories.SMarriageDate != '' "><b>Marriage Date: {{stories.SMarriageDate}}</b></div>
            <div class="common-phara">{{stories.SDescription}}</div>
            </div>
            </div>
        </div>
    </div>
    

    </div>
                      
                </div>
            </div>
 
 
         </div>
     </div>
 </div>
