<div class="slideOverly"></div>
              <ul class="topbar clearfix dashTopIcon">
                <a href="Javascript:void(0)"><img src="assets/images/br-logo3.png" class="dashLogo" alt="img"></a>
                <!-- <li><a href="Javascript:void(0)" (click)="logout()"><i class="fa fa-power-off"></i></a></li> -->
                <li data-toggle="tooltip" data-placement="bottom" title="Notification"><a href="Javascript:void(0)" (click)="shownotification()">
                  <span>
                  <i class="fa fa-bell" style="position: relative;"><span class="messageCount">0</span></i></span></a></li>
                <li data-toggle="tooltip" data-placement="bottom" title="Mail Box"><a href="Javascript:void(0)" (click)="sentInterst()"><span><i class="fa fa-envelope" [ngClass]="{'countStyle': MessageCount != '0'}"><span class="messageCount" *ngIf="MessageCount != '0'">{{MessageCount}}</span></i></span></a></li>
                <li data-toggle="tooltip" data-placement="bottom" title="My Matches"><a href="Javascript:void(0)" (click)="backToMyMatches()"><span><i class="fa fa-home"></i></span></a></li>
                <li class="" data-toggle="tooltip" data-placement="bottom" title="Quick Search"><a href="Javascript:void(0)" (click)="quickSearchUrl()"><span><i class="fa fa-search"></i></span></a></li>
                <li class="proId">({{ProfileId}})</li>
              </ul>
  
  
              <div class="subMenu">
                <div class="custmSearch"><input class="autoSearchClick" type="text" placeholder="Search by Profile ID or Name" name=""><i class="fa fa-search subSearchBT"></i>
                  <div class="autoSearchDiv">
                    <h4>Recent Searches</h4>
                    <div class="cityBt searchAutoBt">Deshpande</div>
                    <div class="cityBt searchAutoBt">Br2322</div>
                    <div class="cityBt searchAutoBt">Br2277</div>
                    <div class="cityBt searchAutoBt">Londhe</div>
                    <div class="autoSearchViewPro">
                      <ul>
                        <li><div class="autoSePro"><img src="assets/images/matches2.jpg"></div>Aishwarya Deshpande</li>
                        <li><div class="autoSePro"><img src="assets/images/matches2.jpg"></div>Vaishnavi Deshpande</li>
                        <li><div class="autoSePro"><img src="assets/images/matches2.jpg"></div>Pratiksha Deshpande</li>
                      </ul>
                    </div>
                    <div class="extraSearch">
                      <span class="searchFo"><a href="#">Search For</a></span><span class="searchFo searchFoLeft"><a href="#dashboard" class="adSearch">Advance Search</a></span>
                      <div class="extraSearhIn">
                        <h4>Discover Preferrd Matches By</h4>
                        <div class="extSeOut">
                          <div class="extSeIn"><i class="fa fa-map-marker"></i></div>
                          <span>Location</span>
                        </div>
        
                        <div class="extSeOut">
                          <div class="extSeIn" style="background-color: #65cafb !important"><i class="fa fa-suitcase"></i></div>
                          <span>Profession</span>
                        </div>
        
                        <div class="extSeOut">
                          <div class="extSeIn" style="background-color: #e250e7 !important">
                            <i class="fa fa-graduation-cap"></i>
                          </div>
                          <span>Education</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <header class="newheader">
                  <input class="menu-btn" type="checkbox" id="menu-btn" />
                  <label class="menu-icon" for="menu-btn">More Matches<i class="fa fa-chevron-down"></i><span class="navicon"></span></label>
                  <ul id="topNavBar" class="menu">
                    <a href="/"><img src="assets/images/br-logo3.png" class="dashLogo2" alt="img"></a>
                    <li><a href="Javascript:void(0)" id="mymatchid" (click)="myMachesfun()"><i class="fa fa-users menuIco"></i> My Matches</a></li>
                    <li><a href="Javascript:void(0)" id="mynewmatchid" (click)="newMatchesfun()"><i class="fa fa-heartbeat menuIco"></i> New Matches</a></li>
                    <li class="dropClick"><a href="Javascript:void(0)" id="nearmeid" (click)="nearMefun()"><i class="fa fa-street-view menuIco"></i> Near Me</a>
                      
                    </li>
                    <li><a href="Javascript:void(0)" id="shortlistedid" (click)="shortlistedfun()"><i class="fa fa-trophy menuIco"></i> Shortlisted</a></li>
                    <li><a href="Javascript:void(0)" id="viewmyprofileid" (click)="viwedMyProfilefun()"><i class="fa fa-user menuIco"></i> Viewed My Profile</a></li>
                    <li><a href="Javascript:void(0)" id="shortlistedmeid" (click)="shortlistedMefun()"><i class="fa fa-hand-o-right menuIco"></i> Shortlisted Me</a></li>
                  </ul>
                </header>
              </div> 

