import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { WebsiteConstants } from '.././global/website-constants';

@Injectable({
  providedIn: 'root'
})
export class ViewmyprofileService {
  private masterUrl = WebsiteConstants.MasterUrl;
  private siteurl=this.masterUrl;
  private viewmyprofileurl = this.siteurl+"ViewedMyProfile/ViewedMyProfile";
  constructor(private http : HttpClient) { }
  viewmyprofilefunction(body,headers)
  {
    return this.http.post(this.viewmyprofileurl,body,headers)
  }
}
