import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler, HttpHeaders} from '@angular/common/http';
import { retry } from 'rxjs/internal/operators/retry';
import { WebsiteConstants } from '.././global/website-constants';

@Injectable({
  providedIn: 'root'
})
export class ShortlistedMeService {
private masterUrl = WebsiteConstants.MasterUrl;  
shortlistedMeUrl = this.masterUrl+"ViewShortlistedMe/ViewShortlistedMe";
  constructor(private http : HttpClient) { }
  shortlistedMe(body,Headers)
  {
    return this.http.post(this.shortlistedMeUrl,body,Headers)
  }
}
