
<div class="dashboardMain" id="activeButtDIV">
    <app-left-menu></app-left-menu>     
<div class="admin-panel clearfix">
    <div class="main">
      <app-top-menu></app-top-menu>
          <div class="mainContent clearfix">
            


<div id="updateProfile">
  <div class="samplecover-spin" id="samplecover-spin" *ngIf="logspinner"></div>
              <div class="dashMainCont" *ngIf="!logspinner">
                <div class="dashMainInner">
                  <div class="matchesInner">
                  <div class="proDetailsDiv">
                   <div class="proDi">
                     <div class="overDiv overDivBrows">
                   <div class="contain animated bounce">
                     <form [formGroup]="form" (ngSubmit)="onSubmit()">
                         <div id="img_contain"><img id="blah" [src]='url' alt="your image" title="" align="middle">
                          <div class="input-group"> 
                            <div class="custom-file">+
                            <input type="file" id="inputGroupFile01" name="avatar" class="imgInp custom-file-input" aria-describedby="inputGroupFileAddon01" (change)="onChangeImage($event)">
                            
                            </div>
                            </div>
                        </div> 
                         
                         
                         <div class="animTest w3-animate-zoom" *ngIf="isUpload && avatar.touched && avatar.invalid">Please upload your profile image</div>
                         <div class="uplImOut" *ngIf="isSinglePhotoUpload">
                          <button class="button large expanded" type="submit">Upload</button>
                         </div>
                     </form>
                     
                   </div>
                   
                   <p class="proname">{{userProfileData.MemberName}} ({{userProfileData.MemberProfileID}})</p>
                     </div>
                     <div class="comlpetedProDiv">
                       <!-- <input type="radio" class="radio" name="progress" value="five1" id="five1">
                     <label for="five1" class="label">5%</label>
     
                     <input type="radio" class="radio" name="progress" value="twentyfive1" id="twentyfive1">
                     <label for="twentyfive1" class="label">25%</label>
     
                     <input type="radio" class="radio" name="progress" value="fifty1" id="fifty1">
                     <label for="fifty1" class="label">50%</label>
     
                     <input type="radio" class="radio" name="progress" value="seventyfive1" id="seventyfive1" checked="">
                     <label for="seventyfive1" class="label">75%</label>
     
                     <input type="radio" class="radio" name="progress" value="onehundred1" id="onehundred1">
                     <label for="onehundred1" class="label">100%</label>
     
                     <div class="progress">
                       <div class="progress-bar"></div>
                     </div> -->
                     </div>
                     <!-- <div class="proInDi">
                       <img src="images/user2.png">
                     </div> -->
                   </div>
                   <div class="multiImgUpload">
                     <div class="row">
                     <div class="col-md-12">
                       <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
     
                           <div class="panel panel-default">
                               <div class="panel-heading" role="tab" id="headingTwo">
                                    <h4 class="panel-title">
                               <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                 <i class="fa fa-photo"></i> Multi Images Upload 
                               </a>
                             </h4>
     
                               </div>
                               <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                                   <div class="panel-body">
                                     <div class="grid-x grid-padding-x">
                                       <div class="small-10 small-offset-1 medium-8 medium-offset-2 cell">
                                        
                                         <h4 class="uploadHeadTxt">Multiple Image File Upload with Preview</h4>
                                         <form [formGroup]="myForm">
                                           <div>
                                            <p class="multiImgBt">
                                              <label for="upload_imgs" class="button hollow">Select Your Images +</label>
                                              <input formControlName="file" id="file" type="file" class="form-control" multiple (change)="onFileChange($event)">
                                              </p>


                                              <div class="upImOuterDOuter">
                                                <span class="upImOuterD" *ngFor='let url of images; let i = index' id="imgUrl" (click)="removeImg(i)">
                                                  <i class="fa fa-close deleteImB"></i>
                                                  <img  [src]="url" height="150" width="200px" style="margin: 3px;"> 
                                                </span>
                                              </div>




                                              <span class="upB">
                                                <input class="button large expanded" type="button" name="submit" value="Upload Images" (click)="uploadMultiImages()">
                                               </span>

                                               <h4 class="uploadHeadTxt">Uploaded Profile Photos</h4>

                                               <div class="upImOuterDOuter">
                                                <span class="upImOuterD" *ngFor="let image of multipleImages; let i = index">
                                                  <i class="fa fa-close phoGalleDele" (click)="deletePhotoFromGallaryHash(image.MemberPhotoPath);"></i>
                                                  <img  [src]="photopath+image.MemberPhotoPath" height="150" width="200px" style="margin: 3px;">
                                                </span>
                                                </div>
                                              

                                               
                                           </div>
                                           <div class="quote-imgs-thumbs quote-imgs-thumbs--hidden" id="img_preview" aria-live="polite"></div>
                                           <!-- <p>
                                            <span class="upImOuterD" *ngFor='let url of images'><img  [src]="url" height="150" width="200px" style="margin: 3px;"> </span>
                                            
                                             <span class="upB">
                                              <input class="button large expanded" type="submit" name="submit" value="Upload Images">
                                             </span>
                                           </p> -->
                                         </form>
                                       </div>
                                     </div>
                                   </div>
                               </div>
                           </div>
                           
                           </div>
                       </div>
                     </div>
                   </div>
               <h4 class="proHead proHead2"><i class="fa fa-user"></i> {{AboutProfile}} <i class="fa fa-pencil proEditIco" (click)="openMOdel()"></i></h4>
               <div class="proInnerCont">
                <p >{{userProfileData.MemberAboutMe}}</p>
               </div>
     
               <!-- data-toggle="modal" data-target="#personalDetailsEdit" -->
               <h4 class="proHead"><i class="fa fa-list"></i> Personal Details <i class="fa fa-pencil proEditIco"  (click)="openMOdelPersonal()"></i></h4>
               <div class="proInnerCont">
                <ul>
                  <li><b>Profile ID</b><span>: {{userProfileData.MemberProfileID}}</span></li>
                  <li><b>Full Name</b><span id="personName">: {{userProfileData.MemberFullName}}</span></li>
                  <li><b>Gender</b><span>: {{userProfileData.MemberGender}}</span></li>
                  <li><b>Date of Birth </b><span>: {{userProfileData.MemberDOB}}</span></li>
                  <li><b>Age</b><span>: {{userProfileData.MemberAge}} Yrs</span></li>
                  <li><b>height</b><span id="personHeight">: {{memberHeightVal}}</span></li>
                  <li><b>Birth Time</b><span id="personBithTime">: {{userProfileData.MemberBirthTime}}</span></li>
                  <li><b>Birth Place</b><span id="personBirthPlace">: {{userProfileData.MemberBirthPlace}}</span></li>
                  <li><b>Marital Status</b><span>: {{userProfileData.MemberMartialStatus}}</span></li>
                  <li><b>Mother Tounge</b><span id="personMotherTounge">: {{userProfileData.MemberMotherTongue}}</span></li>
                  <li><b>Complexion</b><span id="personComplexion">: {{userProfileData.MemberComplexion}}</span></li>
                  <li><b>Blood Group</b><span id="personBloodGroup">: {{userProfileData.MemberBloodGroup}}</span></li>
                  <li><b>Disability</b><span id="personDisability">: {{userProfileData.MemberDisability}}</span></li>
                </ul>
               </div>
     
               <h4 class="proHead"><i class="fa fa-globe"></i> Religion Details <i class="fa fa-pencil proEditIco"  (click)="openMOdelReligion()"></i></h4>
               <div class="proInnerCont">
                <ul>
                  <li><b>Religion</b><span id="personReligion">: {{userProfileData.MemberRelgName}}</span></li>
                  <li><b>Caste</b><span id="personCaste">: {{userProfileData.MemberCasteName}}</span></li>
                  <li><b>Sub Caste</b><span id="personSubCaste">: {{userProfileData.MemberSubCastName}}</span></li>
                  <li><b>Gotra</b><span id="personGotra">: {{userProfileData.MemberGothra}}</span></li>
                  <li><b>Raas</b><span id="personRaas">: {{userProfileData.MemberRaas}}</span></li>
                  <li><b>Charan</b><span id="perosnCharan">: {{userProfileData.MemberCharan}}</span></li>
                  <li><b>Gana</b><span id="personGana">: {{userProfileData.MemberGana}}</span></li>
                  <li><b>Nakshtra</b><span id="personNakshtra">: {{userProfileData.MemberNakshtra}}</span></li>
                  <li><b>Nadi</b><span id="personNadi">: {{userProfileData.MemberNadi}}</span></li>
                  <li><b>Mangal Dosh</b><span id="personDosh">: {{mangalDoshstr}}</span></li>
                </ul>
               </div>
     
               <h4 class="proHead"><i class="fa fa-drivers-license"></i> Contact Details <i class="fa fa-pencil proEditIco" (click)="openMOdelContact()"></i></h4>
               <div class="proInnerCont">
                <ul>
                  <li><b>Contact Number</b><span id="personConcat">: {{userProfileData.MemberContactNo}}</span></li>
                  <li><b>Email ID</b><span id="personEmail">: {{userProfileData.MemberEmailID}}</span></li>
                  <li class="upgradeBtListing" *ngIf="userIsPaid =='0'"><b><i class="fa fa-lock"></i> &nbsp; To unlock the contact detail</b><span>: <button><a href="/upgrade-account">Upgrade Now</a></button></span></li>
                </ul>
               </div>
     
               <h4 class="proHead"><i class="fa fa-map-marker"></i> Address Details <i class="fa fa-pencil proEditIco"  (click)="openMOdelAddress()"></i></h4>
               <div class="proInnerCont">
                <ul>
                  <li><b>Address</b><span id="personAddress">: {{userProfileData.MemberAddress}}</span></li>
                  <li><b>Resident State</b><span id="personState">: {{userProfileData.MemberStateName}}</span></li>
                  <li><b>Resident City / District</b><span id="personCity">: {{userProfileData.MemberCityName}}</span></li>
                  <li><b>Resident Taluka</b><span id="personTaluka">: {{userProfileData.MemberTaluka}}</span></li>
                </ul>
               </div>
     
               <h4 class="proHead"><i class="fa fa-drivers-license"></i> Professional Details <i class="fa fa-pencil proEditIco" (click)="openMOdelProfessional()"></i></h4>
               <div class="proInnerCont">
                <ul>
                  <li><b>Education</b><span id="personEducation">: {{userProfileData.MemberHeightsEducation}}</span></li>
                  <li><b>Other Education</b><span id="personOtherEducation">: {{userProfileData.MemberOtherEducation}}</span></li>
                  <li><b>Employee In</b><span id="personEmployeeIn">: {{userProfileData.MemberEmployedIn}}</span></li>
                  <li><b>Occupation</b><span id="personOccupation">: {{userProfileData.MemberOccupation}}</span></li>
                  <li><b>Job Country</b><span id="personJobCountry">: {{userProfileData.MemberJobCountryName}}</span></li>
                  <li><b>Job City</b><span id="personJobCity">: {{userProfileData.MemberJobCity}}</span></li>
                  <li><b>Annual Income</b><span id="personIncome">: {{userProfileData.MemberAnnualIncome}}</span></li>
                </ul>
               </div>
     
               <h4 class="proHead"><i class="fa fa-users"></i> Family Details <i class="fa fa-pencil proEditIco"  (click)="openMOdelFamily()"></i></h4>
               <div class="proInnerCont">
                <ul>
                  <li><b>Father Name</b><span id="personFatherName">: {{userProfileData.MemberFatherName}}</span></li>
                  <li><b>Father Occupation</b><span id="personFatherOccupation">: {{userProfileData.MemberFatherOccupation}}</span></li>
                  <li><b>Father's/Mother's/Guardian's Contact No</b><span id="personGuardianNo">: {{userProfileData.MemberFatherContactNo}}</span></li>
                  <li><b>Mother's Name</b><span id="personMotherName">: {{userProfileData.MemberMotherName}}</span></li>
                  <li><b>Mother's Occupation</b><span id="personMotherOccupation">: {{userProfileData.MemberMotherOccupation}}</span></li>
                  <li><b>Brother(S)</b><span id="personBrotherNo">: {{userProfileData.MemberBrotherNos}}</span></li>
                  <li><b>Brother(S) Married</b><span id="personBrotherMarried">: {{userProfileData.MemberBMarried}}</span></li>
                  <li><b>Sister(S)</b><span id="personSisterNo">: {{userProfileData.MemberSisterNos}}</span></li>
                  <li><b>Sister(S) Married</b><span id="personSisterMarried">: {{userProfileData.MemberSMarried}}</span></li>
                  <li><b>Family Status</b><span id="personFamilyStatus">: {{userProfileData.MemberFamilyStatus}}</span></li>
                  <li><b>Family Type</b><span id="personFamilyType">: {{userProfileData.MemberFamilyType}}</span></li>
                    <li><b>Family Value</b><span id="personFamilyValue">: {{userProfileData.MemberFamilyValue}}</span></li>
                    <li><b>Relatives</b><span id="personRelatives">: {{userProfileData.MemberRelative}}</span></li>
                </ul>
               </div>
     
              
     
     
           </div>
           <!-- <div class="modal-footer">
             <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
             <button type="button" class="btn btn-primary">Save changes</button>
           </div> -->
           </div>

                </div>
              </div>

</div>
              



            </div>


    </div>
    
</div>
</div>    


<form [formGroup]="aboutForForm">
<div class="modal fade" id="proEditAbout" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">{{AboutProfile}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModel()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">  
      <textarea class="textAria" formControlName="aboutForm" name="aboutFormval">{{getAboutUs}}</textarea>
      <div class="animTest w3-animate-zoom" *ngIf="isSubmitAbout && aboutForm.touched && aboutForm.invalid">Please enter {{AboutProfile}}</div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModel()">Close</button>
        <button type="button" class="btn btn-primary" (click)="EditAboutUsHash()">Save Changes</button>
      </div>
  </div>
</div>
</div>
</form>


<form [formGroup]="personalDetailsForm">
<div class="modal fade" id="personalDetailsEdit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Personal Details</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModelPersonal()">
          <span aria-hidden="true">&times;</span>
        </button>
        
      </div>
      <div class="modal-body">  
        <h6 class="modSubText redText">Please note that these fields are non editable - 'Gender' , 'Date of Birth' , 'Marital Status'</h6>
        <div class="row">
          <div class="col-md-6">
            <label>Profile Id</label>
            <input type="text" name="" placeholder="Profile Id" disabled class="inputFil" formControlName="ProfileIdval" [(ngModel)]="profileID">
            <div class="animTest w3-animate-zoom" *ngIf="isSubmitPersonal && ProfileIdval.invalid">
              <div *ngIf="ProfileIdval.errors.required">Please Enter Profile ID"</div>
          </div>
          </div>
          <div class="col-md-6">
            <label>Full Name</label>
            <input type="text" name="" placeholder="Full Name" class="inputFil" formControlName="FullName" [(ngModel)]="fullnameVal">
            <div class="animTest w3-animate-zoom" *ngIf="isSubmitPersonal && FullName.invalid">
              <div *ngIf="FullName.errors.required">Please Enter Full Name</div>
          </div>
          </div>
          <div class="clear"></div>
          <div class="col-md-6">
            <div class="topLay" (click)="editGender()"></div>
            <label>Gender</label>
            <div class="radioSt">  
              <input type="radio" id="male" value="Male" tabindex="4" formControlName="gender" (change)="differencedate($event)" class="maleInp" [(ngModel)]="genderhash" disabled>

              <label for="male" class="maleStyle">Male</label>
               
              <input type="radio" id="female" value="Female" formControlName="gender" (change)="differencedate($event)" class="maleInp" [(ngModel)]="genderhash" disabled>
                        
              <label for="female" class="maleStyle">Female</label>
            </div>
            <div class="animTest w3-animate-zoom" *ngIf="isSubmitPersonal && gender.invalid">
              <div>Please Select Gender</div>
          </div> 
          </div>
          <div class="col-md-6">
            <div class="topLay" (click)="editDateOfBirth()"></div>
            <label>Date Of Birth</label>
            <!-- <input type="date" name="" placeholder="Date Of Birth" class="inputFil" formControlName="DOB" [(ngModel)]="birthnameval"> -->

            <input type="text" placeholder="Birth Date" class="inputFil"  maxlength="12" formControlName="DOB" [(ngModel)]="birthnameval" [bsValue]="" [maxDate]="maxDate" disabled [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-red', isAnimated: true}" (bsValueChange)="onDateChange($event)"  bsDatepicker>

            <div class="animTest w3-animate-zoom" *ngIf="isSubmitPersonal && DOB.invalid">
              <div *ngIf="DOB.errors.required">Please Enter Date of Birth</div>
          </div> 
          </div>
          <div class="clear"></div>
          <div class="col-md-6">
            <label>Age</label>
            <input type="text" name="" placeholder="Age" class="inputFil" formControlName="Age" readonly [(ngModel)]="AgeVal" (click)="editAge()">
            <div class="animTest w3-animate-zoom" *ngIf="isSubmitPersonal && Age.invalid">
              <div *ngIf="Age.errors.required">Please Select Age</div>
          </div>
          </div>
          <div class="col-md-6">
            <label>Height</label>
           
            <select class="inputFil inputFilSelect" placeholder="Height" formControlName="height"  [(ngModel)]="candidateheightval">
              <option [ngValue]="null" selected>Height</option> 
              <!-- HeightDisplayUser -->
              <option *ngFor="let h of heightlist" value="{{h.HeightDisplayUser+'|||'+h.HeightCode +'|||'+h.HeightDropdownDisplay}}">{{h.HeightDropdownDisplay}}</option>
            </select>
            
            <div class="animTest w3-animate-zoom" *ngIf="isSubmitPersonal && height.invalid">
              <div *ngIf="height.errors.required">Please Select Height</div>
          </div>
          </div>
          <div class="clear"></div>
          <div class="col-md-6">
            <label>Birth Time</label>
            <input type="time" name="" placeholder="Birth Time" class="inputFil" formControlName="birthTime"
            [(ngModel)]="birthtimeval">
            <div class="animTest w3-animate-zoom" *ngIf="isSubmitPersonal && birthTime.invalid">
              <div *ngIf="birthTime.errors.required">Please Enter Birth Time</div>
          </div>
          </div>
          <div class="col-md-6">
            <label>Birth Place</label>
            <input type="text" name="" placeholder="Birth Place" class="inputFil" formControlName="birthPlace" [(ngModel)]="birthplaceval">
            <div class="animTest w3-animate-zoom" *ngIf="isSubmitPersonal && birthPlace.invalid">
              <div *ngIf="birthPlace.errors.required">Please Enter Birth Place</div>
          </div>
          </div>
          <div class="clear"></div>
          <div class="col-md-6">
            <div class="topLay" (click)="editMarritalStatus()"></div>
            <label>Marital Status</label>
            <!-- [(ngModel)]="maritalstatusval" -->
            <select class="inputFil inputFilSelect" formControlName="maritalStatus" [(ngModel)]="maritalStatusVal"  disabled>
              <option [ngValue]="null" selected>Marital Status</option>
              <option *ngFor="let marital of MaritalStatusArray"  value="{{marital.mstatus}}">{{marital.mstatusval}}</option>
            </select>
            <div class="animTest w3-animate-zoom" *ngIf="isSubmitPersonal && maritalStatus.invalid">
              <div *ngIf="maritalStatus.errors.required">Please Select Marital Status</div>
          </div>
          </div>
          <div class="col-md-6">
            <label>Mother Tounge</label>
            <!-- [(ngModel)]="motherTongueval" -->
            <select class="inputFil inputFilSelect" formControlName="motherTounge" [(ngModel)]="motherToungeVal">
              <option [ngValue]="null" selected>Mother Tongue</option>
              <option value="Marathi">Marathi</option>
              <option value="Hindi">Hindi</option>
              <option value="English">English</option>
              <option value="Gujrathi">Gujrathi</option>
              <option value="Kannada">Kannada</option>
              <option value="Telgu">Telgu</option>
              <option value="Tamil">Tamil</option>
              <option value="Bengali">Bengali</option>
              <option value="Kokani">Kokani</option>
              <option value="Rajsthani">Rajsthani</option>
            </select>
            <div class="animTest w3-animate-zoom" *ngIf="isSubmitPersonal && motherTounge.invalid">
              <div *ngIf="motherTounge.errors.required">Please Select Mother Tounge</div>
          </div>
          </div>
          <div class="clear"></div>
          <div class="col-md-6">
            <label>Blood Group</label>
            <!-- [(ngModel)]="bloodgroupval" -->
            <select class="inputFil inputFilSelect" formControlName="bloodGroup" [(ngModel)]="bloodGroupVal">
              <option [ngValue]="null" selected>Blood Group</option>
                        <option *ngFor="let b of bloodgrplist" value="{{b.BloodGroup+'|||'+b.BloodCode}}">{{b.BloodGroup}}
          </select>
          <div class="animTest w3-animate-zoom" *ngIf="isSubmitPersonal && bloodGroup.invalid">
            <div *ngIf="bloodGroup.errors.required">Please Select Blood Group</div>
        </div>
          </div>
          <div class="col-md-6">
            <label>Complexionp</label>
            <!-- [(ngModel)]="complexionval" -->
            <select class="inputFil inputFilSelect" formControlName="Complexion" [(ngModel)]="ComplexionVal">
              <option [ngValue]="null" selected >Complexion</option>
              <option value="Very Fair">Very Fair</option>
              <option value="Fair">Fair</option>
              <option value="Wheaties">Wheaties</option>
              <option value="Wheaties Brown">Wheaties Brown</option>
              <option value="Dark">Dark</option>
            </select>
            <div class="animTest w3-animate-zoom" *ngIf="isSubmitPersonal && Complexion.invalid">
              <div *ngIf="Complexion.errors.required">Please Select Complexion</div>
          </div>
          </div>
          <div class="clear"></div>
          <div class="col-md-6">
            <label>Any Disability</label>
            <!-- [(ngModel)]="disabilityval" -->

            <div class="radioSt">
              <input type="radio" id="disaNo"  value="No" class="maleInp"  formControlName="disability" [(ngModel)]="postbloodval">
              <label for="male" class="maleStyle">No</label>
              <input type="radio" id="disaYes"  value="Yes" class="maleInp" formControlName="disability" [(ngModel)]="postbloodval">
              <label for="female" class="maleStyle">Yes</label>
            </div>
            <div class="animTest w3-animate-zoom" *ngIf="isSubmitPersonal && disability.invalid">
              <div *ngIf="disability.errors.required">Please Select Disability</div>
          </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModelPersonal()">Close</button>
        <button type="button" class="btn btn-primary" (click)="EditPersonalDetailsHash()">Save Changes</button>
      </div>
  </div>
</div>
</div>
</form>

<form [formGroup]="religionDetailForm">
<div class="modal fade" id="rligionDetailsEdit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Religion Details</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModelReligion()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">  
        <div class="row">
          <div class="col-md-6">
            <label>Religion</label>
            <select class="inputFil inputFilSelect" tabindex="5" formControlName="religion" [(ngModel)]="religionVal">
              <option [ngValue]="null" selected>Religion</option>
              <option *ngFor="let k of religionlist" value="{{k.ReligionID+'|||'+k.ReligionName}}">{{k.ReligionName}}</option>                        
          </select>
            <div class="animTest w3-animate-zoom" *ngIf="issubmitReligion && religion.invalid">
              <div *ngIf="religion.errors.required">Please Select Religion</div>
          </div>
          </div>
          <div class="col-md-6">
            <label>Caste</label>
            <!-- [(ngModel)]="casteval" -->
            <select class="inputFil inputFilSelect inputFilSelect2" formControlName="cast" [(ngModel)]="castVal">
                        <option [ngValue]="null" selected>Caste</option>
                        <option *ngFor="let castedata of castelist" value="{{castedata.CasteID+'|||'+castedata.CasteName}}">{{castedata.CasteName}}</option>
                            
                    </select>
            <div class="animTest w3-animate-zoom" *ngIf="issubmitReligion && cast.invalid">
              <div *ngIf="cast.errors.required">Please Select Caste</div>
          </div>
          </div>
          <div class="clear"></div>
          <div class="col-md-6">
            <label>Sub Caste</label>
            <!-- [(ngModel)]="subcasteval" -->
            <select class="inputFil inputFilSelect inputFilSelect2" formControlName="subCast" [(ngModel)]="subCastVal">
                      <option [ngValue]="null" selected>Sub Caste</option>
                        <option *ngFor="let su of subcastlist" value="{{su.SubCasteID+'|||'+su.SubcasteName}}">{{su.SubcasteName}}</option>
                    </select>
            <div class="animTest w3-animate-zoom" *ngIf="issubmitReligion && subCast.invalid">
              <div *ngIf="subCast.errors.required">Please Select Sub Caste</div>
          </div>
          </div>
          <div class="col-md-6">
            <label>Gothra</label>
            <!-- [(ngModel)]="gothraval" -->
            <select class="inputFil inputFilSelect inputFilSelect2" formControlName="gotra" [(ngModel)]="gothraVal">
                        <option [ngValue]="null" selected>Gothra</option>
                        <option *ngFor="let g of gothralist" value="{{g.GothraName}}">{{g.GothraName}}</option>
                    </select>
            <div class="animTest w3-animate-zoom" *ngIf="issubmitReligion && gotra.invalid">
              <div *ngIf="gotra.errors.required">Please Select Gothra</div>
          </div>
          </div>
          <div class="clear"></div>
          <div class="col-md-6">
            <label>Raas</label>
            <select class="inputFil inputFilSelect inputFilSelect2" formControlName="raas" [(ngModel)]="raasVal">
                <option [ngValue]="null" selected>Raas</option>
                <option value="Aries - Mesh">Aries - Mesh</option>
                <option value="Taurus - Vrushabh">Taurus - Vrushabh</option>
                <option value="Gemini - Mithun">Gemini - Mithun</option>
                <option value="Cancer - Kark">Cancer - Kark</option>
                <option value="Leo - Simha">Leo - Simha</option>
                <option value="Virgo - Kanya">Virgo - Kanya</option>
                <option value="Libra - Tula">Libra - Tula</option>
                <option value="Scorplus - Vrushchik">Scorplus - Vrushchik</option>
                <option value="Sagittarius - Dhanu">Sagittarius - Dhanu</option> 
                <option value="Capricornus - Makar">Capricornus - Makar</option>   
                <option value="Aquarius - Kumbha">Aquarius - Kumbha</option> 
                <option value="Pisces - Meen">Pisces - Meen</option> 

            </select>
            
            <div class="animTest w3-animate-zoom" *ngIf="issubmitReligion && raas.invalid">
              <div *ngIf="raas.errors.required">Please Select Raas</div>
          </div>
          </div>
          <div class="col-md-6">
            <label>Charan</label>
            <select class="inputFil inputFilSelect inputFilSelect2" formControlName="charan" [(ngModel)]="charanVal">
                <option [ngValue]="null" selected>Charan</option>
                <option value="Charan 1">Charan 1</option>
                <option value="Charan 2">Charan 2</option>
                <option value="Charan 3">Charan 3</option>
                <option value="Charan 4">Charan 4</option>
                <option value="Charan 5">Charan 5</option>
                <option value="Charan 6">Charan 6</option>
                <option value="Charan 7">Charan 7</option>
            </select>
            <div class="animTest w3-animate-zoom" *ngIf="issubmitReligion && charan.invalid">
              <div *ngIf="charan.errors.required">Please Select Charan</div>
          </div>
          </div>
          <div class="clear"></div>
          <div class="col-md-6">
            <label>Gana</label>
            <select class="inputFil inputFilSelect inputFilSelect2" formControlName="gana" [(ngModel)]="ganaVal">
                <option [ngValue]="null" selected>Gana</option>
                <option value="Rakshash">Rakshash</option>
                <option value="Manushya">Manushya</option>
                <option value="Deva">Deva</option>
            </select>
            <div class="animTest w3-animate-zoom" *ngIf="issubmitReligion && gana.invalid">
              <div *ngIf="gana.errors.required">Please Select Gana</div>
          </div>
          </div>
          <div class="col-md-6">
            <label>Nakshtra</label>
            <select class="inputFil inputFilSelect inputFilSelect2" formControlName="nakshtra" [(ngModel)]="nakshtraVal">
                <option [ngValue]="null" selected>Nakshtra</option>
                <option value="Ashwini">Ashwini</option>
                <option value="Bharani">Bharani</option>
                <option value="Krittika">Krittika</option>
                <option value="Rohini">Rohini</option>
                <option value="Mrigasira">Mrigasira</option>
                <option value="Arda">Arda</option>
                <option value="Punarvasu">Punarvasu</option>
                <option value="Pushya">Pushya</option>
                <option value="Ashlesha">Ashlesha</option>
                <option value="Megha">Megha</option>
                <option value="PoorvaPhalguni">PoorvaPhalguni</option>
                <option value="Uttara Phalguni">Uttara Phalguni</option>
                <option value="Hasta">Hasta</option>
                <option value="Chitra">Chitra</option>
                <option value="Swati">Swati</option>
                <option value="Vishakha">Vishakha</option>
                <option value="Anuradha">Anuradha</option>
                <option value="Jyeshta">Jyeshta</option>
                <option value="Moola">Moola</option>
                <option value="Poorvashada">Poorvashada</option>
                <option value="Revati">Revati</option>
                <option value="Uttarashada">Uttarashada</option>
                <option value="Shravana">Shravana</option>
                <option value="Dhanishta">Dhanishta</option>
                <option value="Shattarka">Shattarka</option>
                <option value="Satabhisha">Satabhisha</option>
                <option value="Poorva Bhadrapada">Poorva Bhadrapada</option>
                <option value="Uttara Bhadrapada">Uttara Bhadrapada</option>
            </select>
            <div class="animTest w3-animate-zoom" *ngIf="issubmitReligion && nakshtra.invalid">
              <div *ngIf="nakshtra.errors.required">Please Select Nakshtra</div>
          </div>
          </div>
          <div class="clear"></div>
          <div class="col-md-6">
            <label>Nadi</label>
            <select class="inputFil inputFilSelect inputFilSelect2" formControlName="nadi" [(ngModel)]="nadiVal">
                  <option [ngValue]="null" selected>Nadi</option>
                  <option value="Adya Nadi">Adya Nadi</option>
                  <option value="Antya Nadi">Antya Nadi</option>
                  <option value="Madhya Nadi">Madhya Nadi</option>
            </select> 
            <div class="animTest w3-animate-zoom" *ngIf="issubmitReligion && nadi.invalid">
              <div *ngIf="nadi.errors.required">Please Select Nadi</div>
          </div>
          </div>
          <div class="col-md-6">
            <label>Mangal Dosh</label>
            <!-- [(ngModel)]="doshval"  -->
            <div class="radioSt radioSt2">
              <!-- <p class="extLab">Mangal Dosh</p> -->
            <input type="radio" id="doshno" class="maleInp" formControlName="mangalDosh" value="0" [(ngModel)]="mangalDoshVal">
            <label for="doshno" class="maleStyle">No</label>
            
            <input type="radio" id="doshyes" class="maleInp" formControlName="mangalDosh" value="1" [(ngModel)]="mangalDoshVal">
            <label for="doshyes" class="maleStyle">Yes</label>
          </div>
            <div class="animTest w3-animate-zoom" *ngIf="issubmitReligion && mangalDosh.invalid">
              <div *ngIf="mangalDosh.errors.required">Please Select MangalDosh</div>
          </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModelReligion()">Close</button>
        <button type="button" class="btn btn-primary" (click)="EditReligionDetailsHash()">Save Changes</button>
      </div>
  </div>
</div>
</div>
</form>

<form [formGroup]="contactDetailForm">
<div class="modal fade" id="contactDetailsEdit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Contact Details</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModelContact()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">  
        <h6 class="modSubText redText" *ngIf="MemberContactFlagVal == 1">You have already changed your contact number once. Please contact customer care for more details !!!</h6>
        <h6 class="modSubText redText hideContactTitle" id="contactTitle">Please note that once you change the contact number, your session will be expired and you have to verify your new contact number !!!</h6>
        <div class="row">
          <div class="col-md-6">
            <label>Contact No</label>
            <!-- [(ngModel)]="gaurdiancontactval" -->
            <input type="text" id="" value="" name="contact" maxlength="10" formControlName="contact" placeholder="Contact Number" class="inputFil" [(ngModel)]="contactVal" (keyup)="onContactKeyUp($event)">
            <div class="animTest w3-animate-zoom" *ngIf="issubmitContact && contact.invalid">
              <div *ngIf="contact.errors.required">Please enter contact no.</div>
          </div>
          </div>
          <div class="col-md-6">
            <label>Email Id</label>
            <!-- [(ngModel)]="emailidval"  -->
            <input type="text" name="emailid" formControlName="emailId" tabindex="7" placeholder="Email Address" class="inputFil" [(ngModel)]="emailidval">
            <div class="animTest w3-animate-zoom" *ngIf="issubmitContact && emailId.invalid">
              <div class="animTest w3-animate-zoom" *ngIf="emailId.errors.required"> Please enter Email Address</div>
              <div class="animTest w3-animate-zoom" *ngIf="emailId.errors.pattern">Please enter valid Email Address</div>
          </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModelContact()">Close</button>
        <button type="button" class="btn btn-primary" (click)="EditContactDetailsHash()" >Save Changes</button>
      </div>
  </div>
</div>
</div>
</form>

<form [formGroup]="addressDetailForm">
<div class="modal fade" id="addressDetailsEdit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Address Details</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModelAddress()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">  
        <div class="row">
          <div class="col-md-6">
            <label>Address</label>
            <!-- [(ngModel)]="countryval" -->
            <input type="text" name="" placeholder="address" class="inputFil" formControlName="address" [(ngModel)]="addressVal">
            <div class="animTest w3-animate-zoom" *ngIf="issubmitaddress && address.invalid">
              <div *ngIf="address.errors.required">Please select address</div>
          </div>
          </div>
          <div class="col-md-6">
            <label>State</label>
            <!-- [(ngModel)]="stateval" -->
            <select class="inputFil inputFilSelect inputFilSelect2" formControlName="state" (change)="onChangeState($event)" [(ngModel)]="stateVal">
              <option [ngValue]="null" selected>State</option>
              <option *ngFor="let s of statelist" value="{{s.StID+'|||'+s.StstateName}}">{{s.StstateName}}</option>
          </select>
            <div class="animTest w3-animate-zoom" *ngIf="issubmitaddress && state.invalid">
              <div *ngIf="state.errors.required">Please select state</div>
          </div>
          </div>
          <div class="clear"></div>
          <div class="col-md-6">
            <label>City</label>
            <!-- [(ngModel)]="cityval"  -->
            <select class="inputFil inputFilSelect inputFilSelect2" formControlName="city" [(ngModel)]="cityVal">
              <option [ngValue]="null" selected>City</option>
              <option *ngFor="let ci of citylist" value="{{ci.CtID+'|||'+ci.CtcityName}}">{{ci.CtcityName}}</option>
              </select>
              <div class="clear"></div>
              <div class="animTest w3-animate-zoom" *ngIf="issubmitaddress && city.invalid">
                  <div *ngIf="city.errors.required">Please select City</div>
              </div>
          </div>
          <div class="col-md-6">
            <label>Taluka / Village</label>
              <!-- [(ngModel)]="talukaval" -->
              <input type="text" name="" placeholder="Taluka / Village" class="inputFil" formControlName="taluka" [(ngModel)]="talukaval">       
          </div>
          <div class="clear"></div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModelAddress()">Close</button>
        <button type="button" class="btn btn-primary" (click)="EditAddressDetailsHash()">Save Changes</button>
      </div>
  </div>
</div>
</div>
</form>

<form [formGroup]="professionalDetailForm">
<div class="modal fade" id="professionalDetailsEdit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Professional Details</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModelProfessional()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">  
        <div class="row">
          <div class="col-md-6">
            <label>Education</label>
            <!-- [(ngModel)]="highesteducationaval"   -->
            <select class="inputFil inputFilSelect inputFilSelect2" formControlName="educationtxt" [(ngModel)]="educationVal"(change)="onChangeEducation($event)">
              <option [ngValue]="null" selected>Highest Education</option>
              <ng-container *ngFor="let education of Educationlist">
              <optgroup *ngIf="education.Header=='TRUE'"  label="{{education.EducationGroupName}}"></optgroup> 
              <option *ngIf="education.Header=='FALSE'"  value="{{education.EducationID+'|||'+education.EducationName+'|||'+education.EducationGroupID+'|||'+education.EducationGroupName}}">{{education.EducationName}}</option>
              </ng-container>
          </select>
            <div class="animTest w3-animate-zoom" *ngIf="issubmitProfession && educationtxt.invalid">
              <div *ngIf="educationtxt.errors.required">Please select education</div>
          </div>
          </div>

          <div class="col-md-6">
            <label>Other Education (Optional)</label>
            <input type="text" name="" placeholder="Other Education" class="inputFil" formControlName="otherEducation" [(ngModel)]="otherEducationVal">  
          </div>
          <div class="clear"></div>
           <div class="col-md-6">
            <label>Employee In</label>
            <select class="inputFil inputFilSelect inputFilSelect2" [(ngModel)]="employeeInVal" formControlName="employeein" (change)="onChangeOccupation($event)">
              <option [ngValue]="null" selected>Employee In</option>
              <option value="Private|||1">Private</option>
              <option value="Government|||1">Government</option>
              <option value="Defense|||1">Defense</option>
              <option value="Self Employed|||1">Self Employed</option>
              <option value="Business|||1">Business</option>
              <option value="Doctor|||1">Doctor</option>
              <option value="Pharmacy|||1">Pharmacy</option>
              <option value="Not Working|||17">Not Working</option>
              
              
          </select>
          <div class="animTest w3-animate-zoom" *ngIf="issubmitProfession && employeein.invalid">
            <div *ngIf="employeein.errors.required">Please select employee In</div>
        </div>
          </div>
          
          
          <div class="col-md-6">
            <label>Occupation</label>
            
            <select class="inputFil inputFilSelect inputFilSelect2" [(ngModel)]="occupationVal" formControlName="occupation">
              <option [ngValue]="null" selected>Occupation</option>
              
              <ng-container *ngFor="let occupationdata of Occupationlist">
              <optgroup *ngIf="occupationdata.Header=='TRUE'"  label="{{occupationdata.OccupationGroupName}}"></optgroup> 
              <option *ngIf="occupationdata.Header=='FALSE'"  value="{{occupationdata.OccupationID+'|||'+occupationdata.OccupationName+'|||'+occupationdata.OccupationGroupID+'|||'+occupationdata.OccupationGroupName}}">{{occupationdata.OccupationName}}</option>
              </ng-container>

          </select>
            <div class="animTest w3-animate-zoom" *ngIf="issubmitProfession && occupation.invalid">
              <div *ngIf="occupation.errors.required">Please select occupation </div>
          </div>
          </div>
          <div class="clear"></div>
          <div class="col-md-6">
            <label>Job Country</label>
           
            <select class="inputFil inputFilSelect inputFilSelect2" formControlName="jobcountry" [(ngModel)]="jobcountryVal">
              <option [ngValue]="null" selected>Job Country</option>
              <option *ngFor="let j of countrylist" value="{{j.CntID+'|||'+j.CntName}}">{{j.CntName}}</option>
          </select>
            <div class="animTest w3-animate-zoom" *ngIf="issubmitProfession && jobcountry.invalid">
              <div *ngIf="jobcountry.errors.required">Please select job country</div>
          </div>
          </div>
          
            
          <div class="col-md-6">
            <label>Job City</label>
           
            <input type="text" name="" placeholder="Job City" class="inputFil" formControlName="jobcity"  [(ngModel)]="jobcityval">
            <div class="animTest w3-animate-zoom" *ngIf="issubmitProfession && jobcity.invalid">
              <div *ngIf="jobcity.errors.required">Please select job city</div>
          </div>
          </div>
          <div class="clear"></div>
          <div class="col-md-6">
            <label>Annual Income</label>
            
            <input type="text" name="" placeholder="Annual Income" class="inputFil" maxLength="6"
            formControlName="income" [(ngModel)]="anualIncomeVal">
            <div class="animTest w3-animate-zoom" *ngIf="issubmitProfession && income.invalid">
              <div *ngIf="income.errors.required">Please Enter Annual Income</div>
          </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModelProfessional()">Close</button>
        <button type="button" class="btn btn-primary" (click)="EditProfessionalDetailsHash()">Save Changes</button>
      </div>
  </div>
</div>
</div>
</form>
<form [formGroup]="familyDetailForm">
<div class="modal fade" id="familyDetailsEdit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Family Details</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModelFamily()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">  
        <div class="row">
          <div class="col-md-6">
            <label>Father's Name</label>
            <input type="text" name="" placeholder="Father Name" class="inputFil" formControlName="fatherName" [(ngModel)]="fatherNameVal">
            <div class="animTest w3-animate-zoom" *ngIf="issubmitFamily && fatherName.invalid">
              <div *ngIf="fatherName.errors.required">Please Enter father's Name</div>
          </div>
          </div>
          <div class="col-md-6">
            <label>Father's Occupation</label>
            <!-- [(ngModel)]="fatheroccupationval"  -->
            <select class="inputFil inputFilSelect inputFilSelect2" formControlName="fatherOccupation" [(ngModel)]="fatherOccupationVal">
              <option [ngValue]="null" selected>Father's Occupation</option>
              <option value="Employed">Employed</option>
              <option value="Businessman">Businessman</option>
              <option value="Professional">Professional</option>
              <option value="Teacher">Teacher</option>
              <option value="Govt. Employed">Govt. Employed</option>
              <option value="Doctor">Doctor</option>
              <option value="Farmer">Farmer</option>
              <option value="Retired">Retired</option>
              <option value="Not Employed">Not Employed</option>
              <option value="Passed Away">Passed Away</option>

          </select>
            <div class="animTest w3-animate-zoom" *ngIf="issubmitFamily && fatherOccupation.invalid">
              <div *ngIf="fatherOccupation.errors.required">Please Select Father's Occupation</div>
          </div>
          </div>
          <div class="clear"></div>
          <div class="col-md-6">
            <label>Father's/Mother's/Guardian's Contact No</label>
            <input type="text" name="" placeholder="Father Contact No" maxlength="10"
            class="inputFil" formControlName="fatherContact" [(ngModel)]="fatherContactVal">
            <div class="animTest w3-animate-zoom" *ngIf="issubmitFamily && fatherContact.invalid">
              <div *ngIf="fatherContact.errors.required">Please Enter Father's/Mother's/Guardian's Contact No</div>
          </div>
          </div>
          <div class="col-md-6">
            <label>Mother's Name</label>
            <input type="text" name="" placeholder="Mother Name" class="inputFil" formControlName="motherName"
            [(ngModel)]="motherNameVal">
            <div class="animTest w3-animate-zoom" *ngIf="issubmitFamily && motherName.invalid">
              <div *ngIf="motherName.errors.required">Please Enter Mother's Name</div>
          </div>
          </div>
          <div class="clear"></div>
          <div class="col-md-6">
            <label>Mother's Occupation</label>
            <!-- [(ngModel)]="motheroccupationval" -->
            <select class="inputFil inputFilSelect inputFilSelect2" formControlName="motherOccupation" [(ngModel)]="motherOccupationVal">
              <option [ngValue]="null" selected>Mother's Occupation</option>
              <option value="Employed">Employed</option>
              <option value="Businesswoman">Businesswoman</option>
              <option value="Professional">Professional</option>
              <option value="Teacher">Teacher</option>
              <option value="Govt. Employed">Govt. Employed</option>
              <option value="Doctor">Doctor</option>
              <option value="Farmer">Farmer</option>
              <option value="Retired">Retired</option>
              <option value="Homemaker">Homemaker</option>
              <option value="Not Employed">Not Employed</option>
              <option value="Passed Away">Passed Away</option>
          </select>
            <div class="animTest w3-animate-zoom" *ngIf="issubmitFamily && motherOccupation.invalid">
              <div *ngIf="motherOccupation.errors.required">Please Select Mother's Occupation</div>
          </div>
          </div>
          <div class="col-md-6">
            <label>Brother(s)</label>
            <select  class="inputFil inputFilSelect inputFilSelect2" formControlName="brother" [(ngModel)]="brotherVal">
              <option [ngValue]="null" selected>No of Brother</option>
              <option value="none">none</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="5+">5+</option>
            </select>
            <div class="animTest w3-animate-zoom" *ngIf="issubmitFamily && brother.invalid">
              <div *ngIf="brother.errors.required">Please Enter No Of Brother</div>
          </div>
          </div>
          <div class="clear"></div>
          <div class="col-md-6">
            <label>Married Brother(s)</label>
            <select class="inputFil inputFilSelect inputFilSelect2" formControlName="brotherMarried" [(ngModel)]="brotherMarriedVal">
              <option [ngValue]="null" selected>No of Brother Married</option>
              <option value="none">none</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="5+">5+</option>
            </select>
            <div class="animTest w3-animate-zoom" *ngIf="issubmitFamily && brotherMarried.invalid">
              <div *ngIf="brotherMarried.errors.required">Please Enter No Of Brother Married</div>
          </div>
          </div>
          <div class="col-md-6">
            <label>Sister(s)</label>
            <select class="inputFil inputFilSelect inputFilSelect2" formControlName="sister" [(ngModel)]="sisterVal">
              <option [ngValue]="null" selected>No of Sister</option>
              <option value="none">none</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="5+">5+</option>
            </select>
            <div class="animTest w3-animate-zoom" *ngIf="issubmitFamily && sister.invalid">
              <div *ngIf="sister.errors.required">Please Enter No Of Sister</div>
          </div>
          </div>
          <div class="clear"></div>
          <div class="col-md-6">
            <label>Married Sister(s)</label>
            <select class="inputFil inputFilSelect inputFilSelect2"formControlName="sisterMarried" [(ngModel)]="sisterMarriedVal">
              <option [ngValue]="null" selected>No of Sister Married</option>
              <option value="none">none</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="5+">5+</option>
            </select>
            <div class="animTest w3-animate-zoom" *ngIf="issubmitFamily && sisterMarried.invalid">
              <div *ngIf="sisterMarried.errors.required">Please Enter No Of Sister Married</div>
          </div>
          </div>
          <div class="col-md-6">
            <label>Family Status</label>
            <!-- [(ngModel)]="familystatusval" -->
            <select class="inputFil inputFilSelect inputFilSelect2" formControlName="familyStatus" [(ngModel)]="familyStatusVal" >
              <option [ngValue]="null" selected>Family Status</option>
              <option value="Middle Class">Middle Class</option>
              <option value="Upper Middle Class">Upper Middle Class</option>
              <option value="Rich / Affluent">Rich / Affluent</option>
          </select>
            <div class="animTest w3-animate-zoom" *ngIf="issubmitFamily && familyStatus.invalid">
              <div *ngIf="familyStatus.errors.required">Please Select Family Status</div>
          </div>
          </div>
          <div class="clear"></div>
          <div class="col-md-6">
            <label>Family Type</label>
            <!-- [(ngModel)]="familytypeval" -->
            <select class="inputFil inputFilSelect inputFilSelect2" formControlName="familyType" [(ngModel)]="familyTypeVal">
              <option [ngValue]="null" selected>Family Type</option>
              <option value="Joint Family">Joint Family</option>
              <option value="Nuclear Family">Nuclear Family</option>
          </select>
            <div class="animTest w3-animate-zoom" *ngIf="issubmitFamily && familyType.invalid">
              <div *ngIf="familyType.errors.required">Please Select Family Type</div>
          </div>
          </div>
          <div class="col-md-6">
            <label>Family Value</label>
            <!-- [(ngModel)]="familyvalueval" -->
            <select class="inputFil inputFilSelect inputFilSelect2" formControlName="familyValue" [(ngModel)]="familyTypeval">
              <option [ngValue]="null" selected>Family Value</option>
              <option value="Liberal">Liberal</option>
              <option value="Moderate">Moderate</option>
              <option value="Traditional">Traditional</option>
              <option value="Orthodox">Orthodox</option>
          </select>
            <div class="animTest w3-animate-zoom" *ngIf="issubmitFamily && familyValue.invalid">
              <div *ngIf="familyValue.errors.required">Please Select Family Value</div>
          </div>
          </div>
          <div class="clear"></div>
          <div class="col-md-6">
            <label>Relatives</label>
            <input type="text" name="" placeholder="Relatives" class="inputFil" formControlName="relatives" [(ngModel)]="relativesVal"> 
            <div class="animTest w3-animate-zoom" *ngIf="issubmitFamily && relatives.invalid">
              <div *ngIf="relatives.errors.required">Please Enter Relatives</div>
          </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModelFamily()">Close</button>
        <button type="button" class="btn btn-primary" (click)="EditFamilylDetailsHash()">Save Changes</button>
      </div>
      
  </div>
</div>
</div>
</form>