import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { WebsiteConstants } from '.././global/website-constants';
@Injectable({
  providedIn: 'root'
})
export class JobCountryService {

private masterUrl = WebsiteConstants.MasterUrl;
jobCountryUrl = this.masterUrl+"DashboardNearJobCountry/DashboardNearJobCountry";
  constructor(private http : HttpClient) { }
  jobCountryPost(headers,body)
  {
      return this.http.post(this.jobCountryUrl,headers,body)
  }
}
