import { ConfirmedValidator } from './confirm-password.validator';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder }  from '@angular/forms';
import{ChangePasswordService} from '../services/change-password.service';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {Router} from '@angular/router';
import * as $ from 'jquery';
import { LogoutService } from '../services/logout.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

    form: FormGroup = new FormGroup({});
    logshortlistmespinner:any; isSubmitted = false; logspinner=false;

  constructor(private fb: FormBuilder, private service:ChangePasswordService, private route : Router,private logoutservice:LogoutService) {
  
    this.form = fb.group({
      oldpassword: ['', [Validators.required]],
      password: ['', Validators.compose([Validators.required,Validators.minLength(6)])],
      confirm_password: ['', [Validators.required]]
    }, { 
      validator: ConfirmedValidator('password', 'confirm_password')
    })
  }


  ngOnInit(): void {
    $('.dropSetting').slideDown("slow");
  }

  
get f(){
    return this.form.controls;
  }
   
  submit(){
    this.isSubmitted = true;
    if(!this.form.valid)
    {
      return false;
    }
    else
    {
      console.log(this.form.value);


      this.logspinner = true;
      let headers = new Headers({ 'Content-Type': 'application/json' });
        let body = new FormData();
        body.append('MemberID',localStorage.getItem('userid'));
        body.append('OldPassword',this.form.value.oldpassword);
        body.append('NewPassword',this.form.value.password);
        body.append('ConfirmPassword',this.form.value.confirm_password);

        this.service.changepassword(body,headers).subscribe(Response =>{
          
              if(Response['status']==1)
              {
                   Swal.fire('Success', Response['message'], 'success');
                  this.logspinner = false;

    //                 let headers = new Headers({ 'Content-Type': 'application/json' });
    //                 let body = new FormData();
    //                 let loggedoutuser = localStorage.getItem('UserMemberID');
    //                 body.append('LoggedUserid', loggedoutuser);

    //                 this.logoutservice.logoutUrl(body,headers).subscribe((Response) =>{
    //                 localStorage.clear();
    //                 let link = ['/login'];
    //                 this.route.navigateByUrl('/', {skipLocationChange: true}).then(() => {
    //                 this.route.navigate(link);

    //                 });

    // })


                  localStorage.clear();
                  let link = ['/login'];
                  this.route.navigate(link);
              }
              else
              {
                  Swal.fire('Invalid Password', Response['message'], 'error');
                  this.logspinner = false;
              }

                
        })
    }
    
  }

    
}
