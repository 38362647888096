import { Component, OnInit } from '@angular/core';
import {ViewmemberdetailsService} from '../services/viewmemberdetails.service'
import {ProfilematchService} from '../services/profilematch.service'
import {PartnerpreferenceService} from '../services/partnerpreference.service'
import {MultiplePhotosService} from '../services/multiple-photos.service'
import {ViewProfileByMemberService} from '../services/view-profile-by-member.service'
import {Router} from '@angular/router'
import * as $ from 'jquery';
import { WebsiteConstants } from '.././global/website-constants';
@Component({
  selector: 'app-member-details',
  templateUrl: './member-details.component.html',
  styleUrls: ['./member-details.component.css']
})
export class MemberDetailsComponent implements OnInit {
  imageObjectval : Array<object> = [] ;
  rightWrongCount : number = 0;
  imageObject : Array<object> = [] ;
  
  
  profilePic : any; ProfileName : any; ProfileId : any; mymatchesresult : any;
  photopath : any; memberdetailsresult:any; logspinner:boolean; prodetailspinner:boolean;
  logshortlistmespinner:boolean; lognearmespinner:boolean;
  profilematchlocation : any; partnerpreference:any; matchprofilepic:any;cityname:any
  shortlistmedash:any; newMatchesdash:any; nearmeval:any; shortlistedval:any; myprofilevar:any;
  logshortlistedspinner:boolean; viewedmyprofilespinner : boolean; newmatchesspinner:boolean;
  mymatchscrollresult:any; multiplePhotoVar:any;userIsPaid:any;ViewProfileByMember:any
  SiteUrl = WebsiteConstants.SiteUrl; 
  sitetitle = WebsiteConstants.SiteTitle; 
  masterUrl = WebsiteConstants.MasterUrl;
  hidemaritalstatus:any=true;

  constructor(private memberservice : ViewmemberdetailsService, private route:Router,private matchprofilservice: ProfilematchService,private partnerservice: PartnerpreferenceService,private multiplePhoto : MultiplePhotosService,private  viewProByMem : ViewProfileByMemberService) { }
  
  ngOnInit(): void {
    
    this.photopath = this.masterUrl;
    this.profilePic = this.masterUrl+localStorage.getItem("UserPhotoPath");
    this.ProfileName = localStorage.getItem("UserFullName");
    this.ProfileId = localStorage.getItem("UserProfileID");
    this.cityname = localStorage.getItem("UserCityName");

    
    this.memberdetailspost()
    
    this.ViewProfileByMemberHash();
    
    this.userIsPaid = localStorage.getItem('UserIsPaid')
  }

  UpgradeMembership()
  {
    let link = ['upgrade-account'];
    this.route.navigate(link);
  }

memberdetailspost = function()
{
  this.prodetailspinner=true;
  let headers = new Headers({'Content-type' : 'application/json'});
  let body = new FormData();
  
  body.append('VwName', localStorage.getItem('UsercasteView'));
  body.append('MemberID',localStorage.getItem('viewUser'));
  body.append('MembIsPaid',localStorage.getItem('UserIsPaid'));
  body.append('LoggedUserid',localStorage.getItem('UserMemberID'));  //localStorage.getItem('UserMemberID')

  localStorage.setItem('viewProfileId',localStorage.getItem('viewUser'));
  this.memberservice.memberdetails(body,headers).subscribe(Result =>{
  console.log("Profile Details====>"+JSON.stringify(Result))
  this.memberdetailsresult = Result.data;
  if(this.memberdetailsresult.MemberMartialStatus=="Never Married")
  {
    this.hidemaritalstatus = false;
  }
  
  
  this.prodetailspinner=false
    // 
    this.multiplePhotodash(this.memberdetailsresult.MemberPhotoPath)
  
  })
}
mymatchprofile = function()
{
  let headers = new Headers({'Content-type' : 'application/json'});
  let body = new FormData();
  body.append('MemberID',localStorage.getItem('UserMemberID'));
  body.append('MembCityNM',localStorage.getItem('UserCityID'));

  this.matchprofilservice.profilematch(body,headers).subscribe(ResponceData =>{
  //console.log("DHAGAT-=-===>"+JSON.stringify(ResponceData))

  ResponceData.data.map(element => {
    let obj = {};
    if(element.MemberName!="")
    {
      console.log(JSON.stringify(element));
      obj['title'] = element.MemberName+' ('+element.MemberProfileID+')';
    }

    if(element.MemberName!="")
    {
      obj['thumbImage'] = this.photopath+element.MemberPhotoPath;
      obj['alt'] = element.MemberID;

    }

    this.imageObjectval.push(obj);
});

//console.log("array with object===>"+newArray)

  this.profilematchlocation = ResponceData.data;
  this.prodetailspinner=false;
   this.partnerprefrencepost();
  })
}


imageClickHandler(row, e) {
  console.log(JSON.stringify(row[e]));
  var arr = row[e];
    localStorage.setItem('viewUser',arr['alt']);
    let link = ['/member-details'];

    this.route.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.route.navigate(link);
       
    });
    
    //this.route.navigate(link);
}

partnerprefrencepost = function()
{
  let headers = new Headers({'Content-type' : 'application/json'});
  let body = new FormData();
  body.append('MemberID',localStorage.getItem('viewProfileId'))
  body.append('LoggedUserid',localStorage.getItem('UserMemberID'))

  this.partnerservice.partnerpreference(body,headers).subscribe(ResultData =>{
    console.log(ResultData)
    this.partnerpreference = ResultData.data;
    //this.prodetailspinner=false;

    ResultData.data.map(element => {
      let obj = {};
      if(element.Result === 'Match')
      {
        console.log("element"+element.Result);
        this.rightWrongCount = this.rightWrongCount+1

      }
       console.log("count"+this.rightWrongCount)
      // this.imageObjectval.push(obj);
  });
  
  })
}
multiplePhotodash(photopathVal)
{
  let headers = new Headers({'Content-type' : 'application/json'});
  let body = new FormData();

  body.append('MemberID',localStorage.getItem('viewUser'))

  this.multiplePhoto.multiplePhotopost(body,headers).subscribe(photoData =>{
    
    console.log("My data data"+ JSON.stringify(photoData))
    if(photoData['status'] != 0)
    {
      let objectNew = {};
      objectNew['image'] = this.photopath+photopathVal;
      objectNew['thumbImage'] = this.photopath+photopathVal;
      this.imageObject.push(objectNew);
      photoData['data'].map(elementval =>{
      let object = {};
      console.log(elementval)
      if(elementval.MemberID != "")
      {
        object['image'] = this.photopath+elementval.MemberPhotoPath;
        object['thumbImage'] = this.photopath+elementval.MemberPhotoPath;
        
      }
  
      this.imageObject.push(object);
      
    });

    
    this.multiplePhotoVar = photoData['data'];
    this.mymatchprofile();
  }
  else
  {
    let object = {};
    this.multiplePhotoVar = "" ;
    object['image'] = this.photopath+photopathVal;
    object['thumbImage'] = this.photopath+photopathVal;
    this.imageObject.push(object);
    this.mymatchprofile();
  }

    
  })
}

ViewProfileByMemberHash()
{
  let headers = new Headers({'Content-type' : 'application/json'});
  let body = new FormData();

  body.append('MemberID',localStorage.getItem('UserMemberID'))
  body.append('MembViewToId',localStorage.getItem('viewUser'))

  return this.viewProByMem.ViewProfileByMemberPost(body,headers).subscribe(Responce => {

    this.ViewProfileByMember = Responce['message']
  })
}

}
