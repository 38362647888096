
<div class="dashboardMain" id="activeButtDIV">
   <app-left-menu></app-left-menu>    
        <div class="admin-panel clearfix">
            <div class="main">
                <app-top-menu></app-top-menu>
                

         <div id="photoGallery">
            <div class="samplecover-spin" id="samplecover-spin" *ngIf="logshortlistmespinner"></div>
         <div class="dashMainCont" *ngIf="!logshortlistmespinner">
           <div class="dashMainInner">
            


<div class="matchesInner">

            <div class="row">

            <div *ngIf="images ?.length > 0">
              <div class="image-row">
  <div class="image-column" *ngFor="let image of images; let i = index">
      <i class="fa fa-close phoGalleDele" (click)="deletePhotoFromGallaryHash(image.MemberPhotoPath);"></i>
     <img [src]="photopath+image.MemberPhotoPath" (click)="openModal();currentSlide(i+1)" class="hover-shadow cursor">
  </div>
               </div>

               <div class="photoGalleryMod">
                  <div id="imgModal" class="modal">
                  <span class="close cursor" (click)="closeModal()">&times;</span>
                  <div class="modal-content">

                     <div class="img-slides mySlides" *ngFor="let image of images; let i = index">
                        <img [src]="photopath+image.MemberPhotoPath">
                     </div>
                     
                     <a class="prev" (click)="plusSlides(-1)">&#10094;</a>
                     <a class="next" (click)="plusSlides(1)">&#10095;</a>

                     <div class="image-row">
                        <div class="image-column" *ngFor="let image of images; let i = index">
                           <img class="images" (click)="currentSlide(i+1)"
                           [src]="photopath+image.MemberPhotoPath">
                        </div>
                     </div>
                  </div>
                  </div>
               </div>
            </div>


            <div *ngIf="images == 0" class="noDataMessage">
               <img src="../../assets/images/no-data-pana.png"/>
               {{myMessage}}
            </div>

            </div>



</div>













           </div>
         </div>
       </div>


        </div>
    </div>
</div>    
  




