import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { WebsiteConstants } from '.././global/website-constants';
@Injectable({
  providedIn: 'root'
})
export class SuccessStoryServiceService {

  private masterUrl = WebsiteConstants.MasterUrl;
  successStoryUrl = this.masterUrl+"ViewSuccessStories/ViewSuccessStories"
  constructor(private http : HttpClient) { }

  successStoryPost(body,headers)
  {
    return this.http.post(this.successStoryUrl,body,headers)
  }
}
