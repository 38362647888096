import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler, HttpHeaders} from '@angular/common/http';
import { WebsiteConstants } from '.././global/website-constants';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  private masterUrl = WebsiteConstants.MasterUrl;
  private logouturl = this.masterUrl+'EditOPTVerify/EditOPTVerify';
  constructor(private http:HttpClient) { }

  logoutUrl(body, headers)
  {
    return this.http.post(this.logouturl, body, headers);
  }
}
