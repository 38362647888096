
<div class="dashboardMain" id="activeButtDIV">
    
  <app-left-menu></app-left-menu>    
  <div class="admin-panel clearfix">
    <div class="main">
              <app-top-menu></app-top-menu>
              <div id="myMatche" class="myMatchesStyle" >
                
        <div class="samplecover-spin" id="samplecover-spin" *ngIf="lognearmespinner"></div>
        <div class="dashMainCont" *ngIf="!lognearmespinner">

  
        <div class="mainContent clearfix">
                <div class="samplecover-spin" id="samplecover-spin" *ngIf="logspinner"></div>
                 <div class="dashMainCont" *ngIf="!logspinner">
                   <div class="dashMainInner">
                      <div class="matchesInner">
                        <div class="row rorBSpace">
                          <div class="col-md-6">
                            <div class="droOut">
                                  <div class="droOutInr">
                                    <form [formGroup]="form">
                                      <ng-multiselect-dropdown formControlName = "city"
                                      [placeholder]="'Matches from Native City'"
                                      [settings]="dropdownSettings"
                                      [data]="dropdownList"
                                      [(ngModel)]="selectedItems"
                                      (onSelect)="onItemSelect($event)"
                                      (onSelectAll)="onSelectAll($event)"
                                      >
                                      </ng-multiselect-dropdown>
                                      </form>
                                      <div class="action">
                                        <button (click)="selectCity()" [disabled]="form.invalid">Apply</button>
                                      </div>
                                  </div>
                                  
                                    <!-- <div class="orSt">OR</div> -->
                            </div>
                          </div>
                          
                          <div class="col-md-6">
                            <div class="droOut">
                              <div class="droOutInr">
                              <form [formGroup]="formval">
                                <ng-multiselect-dropdown formControlName = "Country"
                            [placeholder]="'Matches from Job Country'"
                            [settings]="dropdownSettingshash"
                            [data]="dropdownlistval"
                            
                            (onSelect)="onItemSelecthash($event)"
                            (onSelectAll)="onSelectAllhash($event)"
                          >
                          </ng-multiselect-dropdown>
                              </form>
                              <div class="action">
                                <button (click)="selectCountry()" [disabled]="formval.invalid">Apply</button>
                              </div>
                            </div>                            
                            </div>
                          </div>
                        </div>
                        
                        <div class="row">
                        <div *ngIf="nearmeval?.length > 0">  
                      <div *ngFor="let nearmedata of nearmeval"> 
                          <div class="col-md-4">
                            <div class="matchesDiv">
                                <!-- <div class="ignorBt">Ignore <i class="fa fa-close"></i></div> -->
                                <div class="matchesPic">
                                  <div class="coun" *ngIf="nearmedata.MemberCNtMultiplePhotos > 0">{{nearmedata.MemberCNtMultiplePhotos}}</div>
                                  <div class="leftA"> <i class="fa fa-angle-left"></i> </div>
                                  <div class="rightA"> <i class="fa fa-angle-right"></i> </div>
                                    <img [src]='photopath+nearmedata.MemberPhotoPath'>
                                    <div *ngIf="nearmedata.MemberIsPhotoUpload == '0' && nearmedata.MembPhotoAddFlag== '0'">
                                      <button class="requestBtUp" id="requestphoto{{nearmedata.MemberID}}"(click)="photoRequestDash($event.target,nearmedata)">Request Photo</button>
                                    </div>
    
                                    <div *ngIf="nearmedata.MemberIsPhotoUpload == '0' && nearmedata.MembPhotoAddFlag== '1'">
                                    <button class="requestBtUp" id="requestphoto{{nearmedata.MemberID}}"disabled>Photo Request Sent</button>
                                    </div>
                                    <i class="fa fa-camera phoIco" *ngIf="nearmedata.MemberCNtMultiplePhotos > 0"></i>
                                </div>
                                <div class="callDetais">
                                    <div class="floatNeed">
                                    <span data-tooltip="Call Now"><i class="fa fa-phone phoneIc" (click)="openMOdel(nearmedata,masterUrl)"></i></span>

<span data-tooltip="Sent Interest" *ngIf="nearmedata.MemberSentInterestFlag !== '0'">
<i class="fa fa-heart heartIc likeIntro" id="sentInterst{{nearmedata.MemberID}}" *ngIf="nearmedata.MemberSentInterestFlag !== '0'"></i>
<i class="fa fa-heart heartIc" id="sentrunInterst{{nearmedata.MemberID}}" *ngIf="nearmedata.MemberSentInterestFlag == '0'" (click)="memberInterestlist(nearmedata)"></i>
</span>

<span data-tooltip="Send Interest" *ngIf="nearmedata.MemberSentInterestFlag == '0'">
  <i class="fa fa-heart heartIc likeIntro" id="sentInterst{{nearmedata.MemberID}}" *ngIf="nearmedata.MemberSentInterestFlag !== '0'"></i>
  <i class="fa fa-heart heartIc" id="sentrunInterst{{nearmedata.MemberID}}" *ngIf="nearmedata.MemberSentInterestFlag == '0'" (click)="memberInterestlist(nearmedata)"></i>
  </span>

<span data-tooltip="Sent Interest" >
  <i class="fa fa-heart heartIc hideHeart" id="sentshowInterst{{nearmedata.MemberID}}"></i>
</span>

<input type="hidden" name="hiddeninterest{{nearmedata.MemberID}}" id="hiddeninterest{{nearmedata.MemberID}}" value="{{nearmedata.MemberSentInterestFlag}}" [(ngModel)]="nearmedata.MemberSentInterestFlag">

                                    <span data-tooltip="Shortlist Profile">
                                    <i class="fa fa-star starIc shortLis"  id="shortListID{{nearmedata.MemberID}}" *ngIf="nearmedata.MemberShortlistFlag !== '0'" (click)="membershortlist(nearmedata)"></i>
                                    <i class="fa fa-star starIc" id="shortListID{{nearmedata.MemberID}}" *ngIf="nearmedata.MemberShortlistFlag == '0'"  (click)="membershortlist(nearmedata)"></i>
                                    <input type="hidden" name="hiddenshort{{nearmedata.MemberID}}" id="hiddenshort{{nearmedata.MemberID}}" value="{{nearmedata.MemberShortlistFlag}}" [(ngModel)]="nearmedata.MemberShortlistFlag">
                                  </span>
                                    </div>
        
                                    <div class="detailsOuter">
                                    <h4 class="callDetaillsName">{{nearmedata.MemberName}} ({{nearmedata.MemberProfileID}})</h4>
                                    <p>{{nearmedata.MemberEducation}}, Job-City - {{nearmedata.MemberJobCity}}</p>
                                    <p>{{nearmedata.MemberAge}} yrs, {{nearmedata.MemberHeight}} | {{nearmedata.MemberMartialStatus}}</p>
                                    <p>{{nearmedata.MemberCaste}}, {{nearmedata.MemberNativePlace}} </p>
                                  </div>
                                    
                                    <button type="button" class="proDetailsBt" (click)="memberdetailspost(nearmedata)">
                                      View Profile
                                    </button>
                                </div>
                            </div>
                        </div>
                      </div>
                      </div>
                      <div *ngIf="nearmeval==0" class="noDataMessage">
                        <img src="../../assets/images/no-data-pana.png"/>
                        {{myMessage}}
                      </div>
          </div>
        
                        
        
        
        
                        </div>
                      </div>
                   </div>
                 </div>
              <!-- </div> -->
  
            </div>
  
  
  
  
  
  </div>
  
      </div>
  </div>
  </div>  
  
  <div class="modal fade success-popup in" id="callModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" style=" padding-left: 17px;">
    <div class="modal-dialog modal-sm" role="document">
      <div class="modal-content">
        
        <div class="modal-body text-center">
          <i class="fa fa-close callPopDele" (click)="closePopup()" data-dismiss="modal"></i>
           <div class="calImgDiv" id="callImgDivs">
            <img src="" >
           </div>
           <h4 id="popupName">Contact Akshda Meshra on</h4>
            <p id="popupContact"></p>
        </div>
        <div class="modal-footer" id="popupupgrads">
          
        </div>
      </div>
    </div>
  </div>
  
  
  
