import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cancel-payment',
  templateUrl: './cancel-payment.component.html',
  styleUrls: ['./cancel-payment.component.css']
})
export class CancelPaymentComponent implements OnInit {

  logspinner:any=false;
  constructor() { }

  ngOnInit(): void {
    this.logspinner=false;
  }

}
