import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { WebsiteConstants } from '.././global/website-constants';

@Injectable({
  providedIn: 'root'
})
export class NativeCityService {

private masterUrl = WebsiteConstants.MasterUrl;

nativeCityURl= this.masterUrl+"DashboardNearYou/DashboardNearYou";
  constructor(private http : HttpClient) { }
  nativeCityPost(headers,body)
  {
      return this.http.post(this.nativeCityURl,headers,body)
  }
}
