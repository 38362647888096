import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { MymatchesService } from '../services/mymatches.service';
import { concat } from 'rxjs/internal/operators/concat';
import { WebsiteConstants } from '.././global/website-constants';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  profilePic : any; ProfileName : any; ProfileId : any; mymatchesresult : any;
  photopath : any; memberdetailsresult:any; logspinner:boolean; prodetailspinner:boolean;
  logshortlistmespinner:boolean; lognearmespinner:boolean;
  profilematchlocation : any; partnerpreference:any; matchprofilepic:any;cityname:any
  shortlistmedash:any; newMatchesdash:any; nearmeval:any; shortlistedval:any; myprofilevar:any;
  logshortlistedspinner:boolean; viewedmyprofilespinner : boolean; newmatchesspinner:boolean;
  mymatchscrollresult:any
  fromLimit:any; toLimit:any
  fromLimitnext:any;toLimitnext;
  spinner: any = false;
  notEmptyPost: boolean =true;
  notscrolly: any=true;
  SiteUrl = WebsiteConstants.SiteUrl; 
  sitetitle = WebsiteConstants.SiteTitle; 
  masterUrl = WebsiteConstants.MasterUrl;

  constructor(private service:MymatchesService) { }

  ngOnInit(): void {
    this.photopath = this.masterUrl;
    this.profilePic = this.masterUrl+localStorage.getItem("UserPhotoPath");
    this.ProfileName = localStorage.getItem("UserFullName");
    this.ProfileId = localStorage.getItem("UserProfileID");
    this.cityname = localStorage.getItem("UserCityName");

    this.myMatchesPost()
  
  }

    
  myMatchesPost = function()
  {
    this.logspinner = true;
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let body = new FormData();
    body.append('MemberID',localStorage.getItem('UserMemberID'));
    body.append('FromLimit', '0');
    body.append('ToLimit', '40');
    body.append('OrderProfiles', 'Asc');
    localStorage.setItem('FromLimit','1');
    localStorage.setItem('ToLimit','40');
    this.service.mymachesurl(body,headers).subscribe(Response =>{
      console.log(Response)
    this.mymatchesresult=Response.data;
    this.logspinner = false;
    })
}


onScroll(){
  console.log("scrolled")
  if(this.notscrolly && this.notEmptyPost){
    this.spinner=true;
    this.notscrolly = false;
    this.loadnextpost();
  }
 
}

loadnextpost(){
  let headers = new Headers({ 'Content-Type': 'application/json' });
  let body = new FormData();

  this.fromLimit = localStorage.getItem('FromLimit')
  this.toLimit = localStorage.getItem('ToLimit')

  this.fromLimitnext = parseInt(this.fromLimit)+ 40 ;
  this.toLimitnext = parseInt(this.toLimit) + 40;
  
  localStorage.setItem('FromLimit',this.fromLimitnext );
  localStorage.setItem('ToLimit',this.toLimitnext);

  body.append('MemberID',localStorage.getItem('UserMemberID'));
  body.append('FromLimit', this.fromLimitnext);
  body.append('ToLimit', this.toLimitnext);
  body.append('OrderProfiles', 'Asc');

  
  this.service.mymachesurl(body,headers).subscribe(Res =>{
  // this.mymatchesresult=Res['data'];

  const newPost = Res['data'];
  this.spinner = false;
 if(newPost.length === 0)
 {
   this.notEmptyPost = false
 }
   this.mymatchesresult = this.mymatchesresult.concat(newPost)
   this.notscrolly = true
  })
}



}
