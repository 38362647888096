<div class="dashboardMain" id="activeButtDIV">

    
  <app-left-menu></app-left-menu>
  <div class="admin-panel clearfix">
    <div class="main">

              
  
  
              <app-top-menu></app-top-menu>
             
  
  
              <div id="myMatche" class="myMatchesStyle" >
                
        <div class="samplecover-spin" id="samplecover-spin" *ngIf="newmatchesspinner"></div>
        <div class="dashMainCont" *ngIf="!newmatchesspinner">

  
        <div class="mainContent clearfix">
                <div class="samplecover-spin" id="samplecover-spin" *ngIf="logspinner"></div>
                 <div class="dashMainCont" *ngIf="!logspinner">
                   <div class="dashMainInner">
                      <div class="matchesInner">
                        <div class="row">
        
                          
                       <div *ngIf="newMatchesdash?.length > 0">
                      <div *ngFor="let newMatchesData of newMatchesdash"> 
                          <div class="col-md-4">
                            <div class="matchesDiv">
                                
                                <div class="matchesPic">
                                  <div class="coun" *ngIf="newMatchesData.MemberCNtMultiplePhotos > 0">{{newMatchesData.MemberCNtMultiplePhotos}}</div>
                                  <div class="leftA"> <i class="fa fa-angle-left"></i> </div>
                                  <div class="rightA"> <i class="fa fa-angle-right"></i> </div>
                                    <img [src]='photopath+newMatchesData.MemberPhotoPath'>
                                    <div *ngIf="newMatchesData.MemberIsPhotoUpload == '0' && newMatchesData.MembPhotoAddFlag== '0'">
                                      <button class="requestBtUp" id="requestphoto{{newMatchesData.MemberID}}"(click)="photoRequestDash($event.target,newMatchesData)">Request Photo</button>
                                    </div>
    
                                    <div *ngIf="newMatchesData.MemberIsPhotoUpload == '0' && newMatchesData.MembPhotoAddFlag== '1'">
                                      <button class="requestBtUp" id="requestphoto{{newMatchesData.MemberID}}"disabled>Photo Request Sent</button>
                                    </div>
                                    <i class="fa fa-camera phoIco" *ngIf="newMatchesData.MemberCNtMultiplePhotos > 0"></i>
                                </div>
                                <div class="callDetais">
                                    <div class="floatNeed">
                                    <span data-tooltip="Call Now"><i class="fa fa-phone phoneIc" (click)="openMOdel(newMatchesData,masterUrl)"></i></span>

<span data-tooltip="Sent Interest" *ngIf="newMatchesData.MemberSentInterestFlag !== '0'">
<i class="fa fa-heart heartIc likeIntro" id="sentInterst{{newMatchesData.MemberID}}" *ngIf="newMatchesData.MemberSentInterestFlag !== '0'"></i>
<i class="fa fa-heart heartIc" id="sentrunInterst{{newMatchesData.MemberID}}" *ngIf="newMatchesData.MemberSentInterestFlag == '0'" (click)="memberInterestlist(newMatchesData)"></i>
</span>

<span data-tooltip="Sent Interest">
  <i class="fa fa-heart heartIc hideHeart" id="sentshowInterst{{newMatchesData.MemberID}}"></i>
</span>

<span data-tooltip="Send Interest" *ngIf="newMatchesData.MemberSentInterestFlag == '0'">
  <i class="fa fa-heart heartIc likeIntro" id="sentInterst{{newMatchesData.MemberID}}" *ngIf="newMatchesData.MemberSentInterestFlag !== '0'"></i>
  <i class="fa fa-heart heartIc" id="sentrunInterst{{newMatchesData.MemberID}}" *ngIf="newMatchesData.MemberSentInterestFlag == '0'" (click)="memberInterestlist(newMatchesData)"></i>
  </span>

<input type="hidden" name="hiddeninterest{{newMatchesData.MemberID}}" id="hiddeninterest{{newMatchesData.MemberID}}" value="{{newMatchesData.MemberSentInterestFlag}}" [(ngModel)]="newMatchesData.MemberSentInterestFlag">

                                    <span data-tooltip="Shortlist Profile">
                                    <i class="fa fa-star starIc shortLis"  id="shortListID{{newMatchesData.MemberID}}" *ngIf="newMatchesData.MemberShortlistFlag !== '0'" (click)="membershortlist(newMatchesData)"></i>
                                    
                                    <i class="fa fa-star starIc" id="shortListID{{newMatchesData.MemberID}}" *ngIf="newMatchesData.MemberShortlistFlag == '0'"  (click)="membershortlist(newMatchesData)"></i>

                                    <input type="hidden" name="hiddenshort{{newMatchesData.MemberID}}" id="hiddenshort{{newMatchesData.MemberID}}" value="{{newMatchesData.MemberShortlistFlag}}" [(ngModel)]="newMatchesData.MemberShortlistFlag">
                                  </span>
                                    </div>
        
                                    <div class="detailsOuter">
                                    <h4 class="callDetaillsName">{{newMatchesData.MemberName}} ({{newMatchesData.MemberProfileID}})</h4>
                                    <p>{{newMatchesData.MemberEducation}}, Job-City - {{newMatchesData.MemberJobCity}}</p>
                                    <p>{{newMatchesData.MemberAge}} yrs, {{newMatchesData.MemberHeight}} | {{newMatchesData.MemberMartialStatus}}</p>
                                    <p>{{newMatchesData.MemberCaste}}, {{newMatchesData.MemberNativePlace}} </p>
                                  </div>
                                    
                                    <button type="button" class="proDetailsBt" (click)="memberdetailspost(newMatchesData)">
                                      View Profile
                                    </button>
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="newMatchesdash==0" class="noDataMessage">
                      <img src="../../assets/images/no-data-pana.png"/>
                      {{mymessage}}
                    </div>

          </div>
        
                        
        
        
        
                        </div>
                      </div>
                   </div>
                 </div>
              <!-- </div> -->
  
            </div>
  
  
  
  
  
  </div>
  
      </div>
  </div>
  </div>    

  <div class="modal fade success-popup in" id="callModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" style=" padding-left: 17px;">
    <div class="modal-dialog modal-sm" role="document">
      <div class="modal-content">
        
        <div class="modal-body text-center">
          <i class="fa fa-close callPopDele" (click)="closePopup()" data-dismiss="modal"></i>
           <div class="calImgDiv" id="callImgDivs">
            <img src="" >
           </div>
           <h4 id="popupName">Contact Akshda Meshra on</h4>
            <p id="popupContact"></p>
        </div>
        <div class="modal-footer" id="popupupgrads">
          
        </div>
      </div>
    </div>
  </div>
  
  
  
