import { Route } from '@angular/compiler/src/core';
import { MymatchesService } from '../services/mymatches.service';
import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router'
@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.css']
})
export class TopMenuComponent implements OnInit {
  profilePic : any; ProfileName : any; ProfileId : any; mymatchesresult : any;
  photopath : any; memberdetailsresult:any; logspinner:boolean; prodetailspinner:boolean;
  logshortlistmespinner:boolean; lognearmespinner:boolean;
  profilematchlocation : any; partnerpreference:any; matchprofilepic:any;cityname:any
  shortlistmedash:any; newMatchesdash:any; nearmeval:any; shortlistedval:any; myprofilevar:any;
  logshortlistedspinner:boolean; viewedmyprofilespinner : boolean; newmatchesspinner:boolean;
  mymatchscrollresult:any; multiplePhotoVar:any;userIsPaid:any; MessageCount:any="0";
  constructor(private service:MymatchesService, private route : Router) { }

  ngOnInit(): void {
    

    this.ProfileId = localStorage.getItem("UserProfileID");
    this.getmessagecount();
  }
  
  getmessagecount()
{
  let headers = new Headers({ 'Content-Type': 'application/json' });
  let body = new FormData();
  body.append('MemberID',localStorage.getItem('UserMemberID'));
  this.service.mymessagecount(body,headers).subscribe(Response =>{
    let key = Object.keys(Response)[1];
    this.MessageCount = Response[key];
    })

}

  logout()
  {
    localStorage.clear();
    let link = ['/login'];
    this.route.navigate(link);
  }

  backToMyMatches()
  {
    let link = ['/my-matches'];
    this.route.navigate(link)
  }
  
  myMachesfun()
  {
    let link = ['/my-matches'];
    this.route.navigate(link);
  }
  newMatchesfun()
  {
    let link = ['/new-matches'];
    this.route.navigate(link);
  }
  nearMefun()
  {
    let link = ['/near-me'];
    this.route.navigate(link);
  }
  shortlistedfun()
  {
    let link = ['/shortlisted'];
    this.route.navigate(link);
  }
  viwedMyProfilefun()
  {
    let link = ['/viewed-my-profile'];
    this.route.navigate(link);
  }
  shortlistedMefun()
  {
    let link = ['/shortlisted-me'];
    this.route.navigate(link);
  }
  quickSearchUrl()
  {
     let link = ['/quicksearch'];
     this.route.navigate(link);
  }

  shownotification()
  {
     let link = ['/show-notification'];
     this.route.navigate(link);
  }

  // sentInterst()
  // {
  //     let headers = new Headers({ 'Content-Type': 'application/json' });
  //     let body = new FormData();
  //     body.append('MemberID',localStorage.getItem('UserMemberID'));
  //     this.service.messageclearcount(body,headers).subscribe(Response =>{
  //       let link = ['/send-interest-request']
  //       this.route.navigate(link);

  //     })

    
  // }

  sentInterst()
  {
      let link = ['/send-interest-request']
      this.route.navigate(link);
  }

}
