<div class="dashboardMain" id="activeButtDIV">
    <app-left-menu></app-left-menu>    
       <div class="admin-panel clearfix">
          <div class="main">
              <app-top-menu></app-top-menu>
              
              <div class="dashMainCont">
                <div class="dashMainInner">
                    <div class="matchesInner">
                        <div class="inboHoveOuter">
                            
                          <select class="optMain"[(ngModel)]="slectval" (change)="selectOption($event.target.value)">
                            <option value="Inbox">Inbox</option>
                            <option value="Sent">Sent</option>

                          </select>
                          </div>

                 <div class="panel with-nav-tabs panel-default myTabMain">
                     <div class="panel-heading">
                             <ul class="nav nav-tabs">
                                 <li class="active" id="pendingTab1"><a href="Javascript:void(0)" data-toggle="tab" (click)="pendingRequestHash()">Pending</a></li>
                                 <li id="pendingTab2"><a href="Javascript:void(0)" data-toggle="tab" (click)="acceptRequest()">Accepted</a></li>
                                 <li id="pendingTab3"><a href="Javascript:void(0)" data-toggle="tab" (click)="DeclineRequest()">Declined</a></li>
                             </ul>
                     </div>
                     <div class="panel-body">
                         <div class="tab-content">

                          <div class="samplecover-spin" id="samplecover-spin" *ngIf="logspinner"></div>
                             <div class="tab-pane fade" id="tab1defaultt">
                               <div class="row">
                                 <div *ngIf="pendingData ?.length > 0">
                                   <div *ngFor="let pendingInfo of pendingData" id="pendingReq_{{pendingInfo.MemberID}}">
                                 <div class="col-md-6">
                                  <div class="mailDiv">
                                   <div class="mailProDiv focusCurrsor" (click)="memberdetailspost(pendingInfo.MemberID)">
                                    <div class="mailImgDiv">
                                      <img [src]='photopath+pendingInfo.Memberphoto'>
                                    </div>
                                  </div>
                                    <div class="mailContDiv">
                                      <h4 class="mailHead">{{pendingInfo.MemberShortMessage}}</h4>
                                      <p>{{pendingInfo.MemberFullName}}</p>
                                      <p>{{pendingInfo.MemberProfileID}} {{pendingInfo.MemberMessage}}</p>
                                      <button class="acceptBt" *ngIf="pendingInfo.MemberShortMessage == 'Interest Request'" (click)="acceptRequestInfo(pendingInfo)">Accept</button>
                                      <button class="deleteBt" *ngIf="pendingInfo.MemberShortMessage == 'Interest Request'" (click)="declineRequestInfo(pendingInfo)">Decline</button>
                                      <button class="acceptBt" *ngIf="pendingInfo.MemberShortMessage == 'Add Photo Request'" (click)="redirectuser()">Upload Photo</button>
                                    </div>
                                  </div>
                                </div>
                             </div>
                           </div>
                        <div *ngIf="pendingData == 0">{{message}}</div>
                      </div>
                    </div>





                        <div class="tab-pane fade" id="tab4defaultt">
                          <div class="row">
                            <div *ngIf="sentPendingData ?.length > 0">
                              <div *ngFor="let sentPendingInfo of sentPendingData" id="pendingReq_{{sentPendingInfo.MemberID}}">
                            <div class="col-md-6">
                             <div class="mailDiv">
                              <div class="mailProDiv focusCurrsor" (click)="memberdetailspost(sentPendingInfo.MemberID)"><div class="mailImgDiv"><img [src]='photopath+sentPendingInfo.Memberphoto'></div></div>
                               <div class="mailContDiv">
                                 <h4 class="mailHead">{{sentPendingInfo.MemberShortMessage}}</h4>
                                 <p>{{sentPendingInfo.Membername}} ({{sentPendingInfo.MemberProfileID}}) {{sentPendingInfo.MemberMessage}}</p>
                               </div>
                             </div>
                           </div>
                        </div>
                      </div>
                    <div *ngIf="sentPendingData == 0">{{sentMessage}}</div>
                  </div>
                </div>


                        

                             <div class="tab-pane fade" id="tab2defaultt">
                                 <div class="row">
                                   <div *ngIf="acceptReq ?.length > 0">
                                     <div *ngFor="let accept of acceptReq">
                                   <div class="col-md-6">
                                    <div class="mailDiv">
                                     <div class="mailProDiv focusCurrsor" (click)="memberdetailspost(accept.MemberID)"><div class="mailImgDiv"><img [src]='photopath+accept.Memberphoto'></div></div>
                                      <div class="mailContDiv">
                                        <h4 class="mailHead">ACCEPTED</h4>  
                                        <p>You have accepted interest of {{accept.MemberFullName}}</p>
                                        <p>({{accept.MemberProfileID}})</p>
                                        <!-- <button class="acceptBt" style="cursor: no-drop; background: #dcdcdc;">Accepted</button> -->
                                      </div>
                                    </div>
                                  </div>
                                 </div>
                                </div>
                              <div *ngIf="acceptReq == 0">{{acceptMsg}}</div>
                            </div>
                          </div>



                          <div class="tab-pane fade" id="tab5defaultt">
                          <div class="row">

                            <div *ngIf="sentAcceptedData ?.length > 0">

                              <div *ngFor="let sentPendingInfo of sentAcceptedData" id="pendingReq_{{sentPendingInfo.MemberID}}">

                            <div class="col-md-6">

                             <div class="mailDiv">

                              <div class="mailProDiv focusCurrsor" (click)="memberdetailspost(sentPendingInfo.MemberID)">
                                <div class="mailImgDiv">
                                  <img [src]='photopath+sentPendingInfo.Memberphoto'>
                                </div>
                              </div>
                               <div class="mailContDiv">
                                 <h4 class="mailHead">{{sentPendingInfo.MemberShortMessage}}</h4>
                                 <p>{{sentPendingInfo.Membername}} ({{sentPendingInfo.MemberProfileID}}) {{sentPendingInfo.MemberMessage}}</p>
                               </div>


                             </div>


                           </div>


                        </div>
                      </div>

                      
                    <div *ngIf="sentAcceptedData == 0">Record not available.</div>
                  </div>
                </div>



                           
                          <div class="tab-pane fade" id="tab3defaultt">
                            <div class="row">

                              <div *ngIf="declineReq ?.length > 0">
                                <div *ngFor="let Decline of declineReq">

                            <div class="col-md-6">
                               <div class="mailDiv">


                                <div class="mailProDiv focusCurrsor" (click)="memberdetailspost(Decline.MemberID)">
                                  <div class="mailImgDiv">
                                    <img [src]='photopath+Decline.Memberphoto'>
                                  </div>
                                </div>

                                 <div class="mailContDiv">
                                   <h4 class="mailHead">DECLINED</h4>
                                   <p>You have declined interest of {{Decline.MemberFullName}}</p>
                                   <p>({{Decline.MemberProfileID}})</p>
                                 </div>


                               </div>
                             </div>


                           </div>
                              </div>

                        <div *ngIf="declineReq == 0">{{declineMsg}}</div>
                      </div>
                    </div>





                    <div class="tab-pane fade" id="tab6defaultt">
                          <div class="row">

                            <div *ngIf="sentDeclinedData ?.length > 0">

                              <div *ngFor="let sentPendingInfo of sentDeclinedData" id="pendingReq_{{sentPendingInfo.MemberID}}">

                            <div class="col-md-6">

                             <div class="mailDiv">

                              <div class="mailProDiv focusCurrsor" (click)="memberdetailspost(sentPendingInfo.MemberID)">
                                <div class="mailImgDiv">
                                  <img [src]='photopath+sentPendingInfo.Memberphoto'>
                                </div>
                              </div>
                               <div class="mailContDiv">
                                 <h4 class="mailHead">{{sentPendingInfo.MemberShortMessage}}</h4>
                                 <p>{{sentPendingInfo.Membername}} ({{sentPendingInfo.MemberProfileID}}) {{sentPendingInfo.MemberMessage}}</p>
                               </div>


                             </div>


                           </div>


                        </div>
                      </div>

                      
                    <div *ngIf="sentDeclinedData == 0">Record not available.</div>
                  </div>
                </div>

                    






                    
                  </div>
                </div>
     
     
     
     </div>
                </div>
              </div>
          </div>
    </div>
</div>