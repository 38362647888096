<div class="innerPagesMain">
    <div class="homeMain">
        <app-home-top></app-home-top>
    
  
    <div class="innerPageBannerMain">
        <h3>Privacy Policy</h3>
    </div>
    <div class="innerPageBodyMain">
        
        <div class="container">
            <div class="col-md-12">
                <div class="contentDivMain termsUseMain">
                    <h2 class="subHedTerms">LAST UPDATED 26 NOV, 2021</h2>
                <span class="aboutPara">
                    This Privacy Policy explains how information about you is collected, used and disclosed by Brahmin Reshimgathi when you use the applications developed by Brahmin Reshimgathi.
                </span>
                <span class="aboutPara">
                    By accepting our Privacy Policy, you agree to let Brahmin Reshimgathi collect, use and disclose information about you in accordance with the purposes listed below. The App is not intended for use by children under 18 and we do not knowingly collect personal information from children under 18. We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of the policy and, depending on the specific changes, we may provide you with additional notice or require a renewed consent (e.g., by adding a statement to our website’s homepage or in the App). We encourage you to review the Privacy Policy whenever you access the App to stay informed about our information practices and the ways you can help protect your privacy.
                </span>

                <h2 class="subHedTerms">TERMINATION</h2>
                <span class="aboutPara">
                    <ul style="list-style-type: disclosure-closed;">
                        <li>A. Brahmin Reshimgathi in good faith reserve our right to terminate your BR membership or delete your profile at our sole discretion without any notice to you.</li>
                        <li>B. If member have provided incorrect information on our website/App or have committed any fraudulent/ unlawful/ illegal activities through the use of our App or have violated this Terms and conditions or have misappropriated the name, likeness, email address, contact no or other personally identifiable information of another BR member created a profile on our website /App by impersonation/ fake/ bogus/ false/ misrepresentation/ without consent of the person who s profile is being registered or use only part information including using photo of third parties without the permission of such third parties or act with other members/employees in indecent/improper manner,
                        </li>
                        <li>C. If Brahmin Reshimgathi receives any complaint/report any Abusive from another user against you and consequently we (company) will suspend/delete your profile in good faith, then we shall not be liable to you in any manner whatsoever, including for any loss, costs, expenses, or consequence, if any.
                        </li>
                        <li>D. Company reservs the right to remove / delete any content / message / photo (s) cancel the registration / membership of a User either upon a complaint received from another user or upon discovery of the same on its own or based on its sole judgment and preception and it shall without notice stop providing service entitled to a user and forfeit all other incidental service(s) with immediate effect along with the fee tenure of registration as well as take appropriate legal action against such user.
                        </li>
                        <li>E. Upon termination / suspension of BR membership, your right to use or access the BR App BR services will cease immediately. All provisions of this terms and conditions that by their nature should survive termination shall survive termination, including, without limitation, disclaimers, and limitations of liability. Termination of your access to and use of the BR Services shall not relieve you of any obligations arising or accruing prior to such termination.
                        </li>
                    </ul>
                </span>




                    <h2 class="subHedTerms">USE OF INFORMATION</h2>
                    <span class="aboutPara">
                        Provide, maintain and improve the App Develop new apps, sites and services Provide and deliver the products and services you request and send you related information Send you technical notices, updates, security alerts and support and administrative messagesProvide news and information about the App we think will be of interest to youPersonalize and improve the App and provide tailored content and featuresMonitor and analyze trends, usage and activities in connection with the App; Link or combine with information we get from others to help understand your needsand provide you with better service carry out any other purpose for which the information was collected.
                    </span>

                    <h2 class="subHedTerms">DO ALL VISITORS HAVE TO PAY?</h2>
                    <span class="aboutPara">
                        NO, All visitors to our site may use the app, search the Profiles and view any articles or features, user has to register with required details.
                    </span>

                    <h2 class="subHedTerms">CONTACT US</h2>
                    <span class="aboutPara">
                        If you have any questions about this Privacy Policy, please write us at <a href="mailto:brahminreshimgathi@gmail.com">brahminreshimgathi@gmail.com</a>
                    </span>

                </div>
            </div>
  
  
        </div>

    </div>
  
    <app-bottom></app-bottom>
  </div>
  </div>
  