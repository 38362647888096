<div class="innerPagesMain">
  <div class="homeMain">
      <app-home-top></app-home-top>
  

  <div class="innerPageBannerMain">
      <h3>Contact Us</h3>
  </div>
  <div class="innerPageBodyMain">

    <div class="container" *ngIf="logspinner">
      <div class="samplecover-spin" id="samplecover-spin" ></div> 
    </div>
      <div class="container" *ngIf="!logspinner">

          <div class="col-md-12" *ngFor="let responceData of result">
            <div class="contentDivMain forContactOnly">
              <h2 class="conHeading">{{responceData.BranchName}}</h2>
              <p class="conAdreHeading">
                {{responceData.Branchaddress}}
              </p>
              <!-- <p class="contDetail">
                <i class="fa fa-building"></i>
                <span>Branch ID</span>
                <em>: #{{responceData.BranchID}}</em>
              </p> -->
              <p class="contDetail">
                <i class="fa fa-phone"></i>
                <span>Phone</span>
                <em>: {{responceData.BranchContactNo}}</em>
              </p>
              <p class="contDetail">
                <i class="fa fa-clock-o"></i>
                <span>Branch Time</span>
                <em>: {{responceData.BranchTime}}</em>
              </p>
              <p class="contDetail">
                <i class="fa fa-sticky-note-o"></i>
                <span>Branch Note</span>
                <em>: {{responceData.Branchnote}}</em>
              </p>
            </div>
          </div>


      </div>

      
  </div>

  <app-bottom></app-bottom>
</div>
</div>
