import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { FormControl, FormGroup, Validators,ReactiveFormsModule,FormBuilder } from '@angular/forms';
import * as $ from 'jquery';
import { WebsiteConstants } from '.././global/website-constants';
import { DeleteaccountService } from '../services/deleteaccount.service';
import {ViewmemberdetailsService} from '../services/viewmemberdetails.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LoginService } from '../services/login.service';
import { LogoutService } from '../services/logout.service';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.css']
})
export class LeftMenuComponent implements OnInit {
  photopath:any;profilePic:any;ProfileName:any;ProfileId:any;
  userIsPaid:any = localStorage.getItem('UserIsPaid');
  userProfileForm:FormGroup; isSubmitted = false; sitetitle = WebsiteConstants.SiteTitle; usersubexpirydate:any;
  SiteUrl = WebsiteConstants.SiteUrl;
  masterUrl = WebsiteConstants.MasterUrl;
  formvalidstr=false; isphotoavailable=false;
  AppDiscountBannerFlag:any=0;
  FlashBannerPath:any;
  FlashDisExpiry:any;
  FlashDisPack1:any;
  FlashDisPack2:any;
  FlashHeading:any;
  FlashWebBannerPath:any;
  
  constructor(private formBuilder: FormBuilder, private route : Router, private deleteMyAccount : DeleteaccountService, private memberservice : ViewmemberdetailsService,private appservice:LoginService, private service:LogoutService) { 

    this.userProfileForm = this.formBuilder.group({
      "reasonval" : new FormControl("",Validators.required),
      "reason1": new FormControl(""),
      "reason2": new FormControl(""),
      "marriegedate": new FormControl(""),
      "experienceval": new FormControl("")
    });

  }

  get reasonval() { return this.userProfileForm.get('reasonval'); } 
  get reason1() { return this.userProfileForm.get('reason1'); } 
  get reason2() { return this.userProfileForm.get('reason2'); } 

  ngOnInit(): void {
    this.photopath = this.masterUrl;
    this.profilePic = this.masterUrl+localStorage.getItem("UserPhotoPath");
    this.ProfileName = localStorage.getItem("UserFullName");
    this.ProfileId = localStorage.getItem("UserProfileID");
    
    if(localStorage.getItem('UserIsPhotoUpload')=="0")
    {
      this.isphotoavailable = true;
    }
    
    this.loadSubscrition();

    setInterval(() => {
      
          let headers = new Headers({ 'Content-Type': 'application/json' });
          let body = new FormData();
          body.append('CasteID', '2');

          this.appservice.appversionUrl(body,headers).subscribe(Result =>{
            console.log(Result)
          if(Result['status']==1) 
          { 
               this.AppDiscountBannerFlag = Result['data'][0].AppDiscountBannerFlag;
              localStorage.setItem('AppDiscountBannerFlag',this.AppDiscountBannerFlag);
                
          } 


        })
      

    }, 3000);


   

  }

  loadSubscrition()
  {
    //this.logspinner=true;
    let headers = new Headers({'Content-Type' : 'Application/json'})
    let body = new FormData

    body.append('VwName', localStorage.getItem('UsercasteView'));
    body.append('MemberID',localStorage.getItem('UserMemberID'));
    body.append('MembIsPaid', '1'); 
    body.append('LoggedUserid',localStorage.getItem('UserMemberID')); 

    this.memberservice.memberdetails(body,headers).subscribe(memberresponce =>{
          this.usersubexpirydate = localStorage.getItem('MemberSubExpiryDate');
          localStorage.setItem('MemberSubExpiryDate',memberresponce['data'].MemberSubscriptionExpiredOn); 
          this.userIsPaid = memberresponce['data'].MemberIsPaid;
          localStorage.setItem('UserIsPaid',memberresponce['data'].MemberIsPaid);
          this.bannerview();

    })
  }


  bannerview()
  {

    let headers = new Headers({ 'Content-Type': 'application/json' });
    let body = new FormData();
    body.append('CasteID', '2');

    this.appservice.appbannerUrl(body,headers).subscribe(Result =>{
    if(Result['status']==1) 
    { 
          this.FlashBannerPath = this.masterUrl+Result['data'].FlashBannerPath;
          this.FlashDisExpiry = Result['data'].FlashDisExpiry;
          this.FlashDisPack1 = Result['data'].FlashDisPack1;
          this.FlashDisPack2 = Result['data'].FlashDisPack2;
          this.FlashHeading = Result['data'].FlashHeading;
          this.FlashWebBannerPath = Result['data'].FlashWebBannerPath;
    } 


  })

  }


  logout()
  {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let body = new FormData();
    let loggedoutuser = localStorage.getItem('UserMemberID');
    body.append('LoggedUserid', loggedoutuser);

    this.service.logoutUrl(body,headers).subscribe((Response) =>{
    localStorage.clear();
    let link = ['/login'];
    this.route.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.route.navigate(link);
       
    });

    })
   

  }

  upgradenow()
{
  let link = ['/upgrade-account'];
  this.route.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.route.navigate(link);
     
  });
}

  changepassword()
  {
    let link = ['/change-password'];
    this.route.navigate(link);
    
  }
  
  discoverMatchhash()
  {
    let link = ['/my-discover-matches'];
    this.route.navigate(link);
  }
  updateProfileHash()
  {
    let link = ['/update-profile'];
    this.route.navigate(link);
  }
  editPreference()
  {
    let link = ['/edit-preference'];
    this.route.navigate(link);
  }

  photogallery()
  {
     let link = ['/user-gallery'];
    this.route.navigate(link);
  }

  upgrademembership()
  {
    let link = ['/upgrade-account'];
    this.route.navigate(link);
  }
  helpScreen()
  {
    let link = ['help-screen'];
    this.route.navigate(link); 
  }

  Advancesearch()
  {
    let link = ['advance-search'];
    this.route.navigate(link);
  }

  Stories()
  {
    let link = ['success-stories'];
    this.route.navigate(link); 
  }

  sidebar()
  {

    $(document).ready(function()
  {
    $('#slideRight').toggleClass('leftSlide');
    $(".main").toggleClass('mainFulllWidth');
    $(".slidebar").toggleClass('slidebarWidth');
    $(".mainContent>div").toggleClass('mainContentWidth');
    $("#mytoggle").toggleClass('down');

  })

  }

  slidetoggle()
  {
    $('.dropSetting').slideDown("slow");
  }

openDeleteModel()
{
  $('#deleteAc').css("display", "block");
}
  
selectReason(option)
{
  if(option.target.value=="Other Reason")
  {
      $("#selectsource").slideDown("slow");
      $("#source1").slideUp("slow");
      $("#datesource").slideUp("slow");
      $("#marriegesource").slideUp("slow");
      $("#source2").slideDown("slow");

this.userProfileForm.get('reason1').clearValidators(); 
this.userProfileForm.get('reason1').updateValueAndValidity();
  }
  else
  {
      $("#selectsource").slideDown("slow");
      $("#source2").slideUp("slow");
      $("#source1").slideDown("slow");
      $("#datesource").slideDown("slow");
      $("#marriegesource").slideDown("slow");

this.userProfileForm.get('reason2').clearValidators(); 
this.userProfileForm.get('reason2').updateValueAndValidity();
      
  }
}

deletesubmit(){
    this.isSubmitted = true;
    if(!this.userProfileForm.valid)
    {
      return false;
    }
    else
    {
      
      if(this.userProfileForm.value.reasonval=="Other Reason")
      {
          if(this.userProfileForm.value.reason2=='' || this.userProfileForm.value.reason2==null)
          {
              this.userProfileForm.get('reason2').setValidators([Validators.required]); 
              this.userProfileForm.get('reason2').updateValueAndValidity();
              return false
          }
          else
          {
              this.userProfileForm.get('reason2').clearValidators(); 
              this.userProfileForm.get('reason2').updateValueAndValidity();
              this.formvalidstr=true;
          }
      }
      else
      {
          if(this.userProfileForm.value.reason1=='' || this.userProfileForm.value.reason1==null)
          {
              this.userProfileForm.get('reason1').setValidators([Validators.required]); 
              this.userProfileForm.get('reason1').updateValueAndValidity();
              return false
          }
          else
          {
              this.userProfileForm.get('reason1').clearValidators(); 
              this.userProfileForm.get('reason1').updateValueAndValidity();
              this.formvalidstr=true;
          }

      }



      if(this.formvalidstr)
      {
        Swal.fire({
          title: 'Are you sure?',
          text: 'Are you sure you want to delete your profile?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        }).then((result) => {
          if (result.value) {



            let headers = new Headers({ 'Content-Type': 'application/json' });
            let body = new FormData();
            body.append('LoggedUserid',localStorage.getItem('userid'));
            body.append('ReasonType',this.userProfileForm.value.reasonval);
            let SourceTyp;

            if(this.userProfileForm.value.reasonval=="Other Reason")
            {
                SourceTyp = this.userProfileForm.value.reason2;
            }
            else
            {
                SourceTyp = this.userProfileForm.value.reason1;
            }
            body.append('SourceTyp',SourceTyp);
            body.append('DOM',this.userProfileForm.value.marriegedate);
            body.append('SpecifyExp','');
            body.append('SpecifySite','');
            body.append('SpecifyComments',this.userProfileForm.value.experienceval);
            this.deleteMyAccount.deleteAccountPost(body,headers).subscribe(Response =>{
            if(Response['status']==1)
            {
              this.DeleteAccloseModel();
            Swal.fire('Success', Response['message'], 'success');
            localStorage.clear();
            let link = ['/login'];
            this.route.navigate(link);
            }
            else
            {
            Swal.fire('Invalid Password', Response['message'], 'error');
            }
            })
    
    
 
    
        } 
        else if (result.dismiss === Swal.DismissReason.cancel) {
          
          }

        })









          }

      

    } //Else part

  }   // Submit the form


  DeleteAcopenModel(){
  $('#deleteAc').css("display", "block",).toggleClass("in");
}

  DeleteAccloseModel(){
  $('#deleteAc').css("display", "none",).removeClass("in");
}


  


}
