import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { WebsiteConstants } from '.././global/website-constants';

@Injectable({
  providedIn: 'root'
})
export class HelpScreenService {

private masterUrl = WebsiteConstants.MasterUrl;
private siteurl=this.masterUrl;  
private helpScreenUrl=this.siteurl+"GetContactDetails/GetContactDetails";
private ContactusUrl = this.siteurl+"GetBranchdet/GetBranchdet";

  constructor(private http : HttpClient) { }
  helpScrrenPost(body,headers)
  {
    return this.http.post(this.helpScreenUrl,body,headers)
  }

  contactUs(body, headres)
  {
     return this.http.post(this.ContactusUrl,body, headres)
  }
  
}
