import { Component, OnInit } from '@angular/core';
import {MyDiscoverMatchesService} from '../services/my-discover-matches.service'
import {Router} from '@angular/router';
import { WebsiteConstants } from '.././global/website-constants';
import * as $ from 'jquery';
@Component({
  selector: 'app-my-discover-matches',
  templateUrl: './my-discover-matches.component.html',
  styleUrls: ['./my-discover-matches.component.css']
})
export class MyDiscoverMatchesComponent implements OnInit {
  myMessage:any;discoverMatchRes:any;photopath:any;profilePic:any;ProfileName:any;ProfileId:any;userIsPaid:any;logMyDiscoverSpinner:boolean;discoverProfession:any;discoverEducation:any;myMessagePro:any;myMessageEdu:any
  SiteUrl = WebsiteConstants.SiteUrl; 
  sitetitle = WebsiteConstants.SiteTitle; 
  masterUrl = WebsiteConstants.MasterUrl;
  constructor(private discoverMatch : MyDiscoverMatchesService,private route : Router) { }

  ngOnInit(): void {



    this.photopath = this.masterUrl;
    this.profilePic = this.masterUrl+localStorage.getItem("UserPhotoPath");
    this.ProfileName = localStorage.getItem("UserFullName");
    this.ProfileId = localStorage.getItem("UserProfileID");
    this.userIsPaid = localStorage.getItem('UserIsPaid')

    this.discoverMatchhash()
  }

//   myMachesfun()
//   {
//     let link = ['/my-matches'];
//     this.route.navigate(link);
//   }
//   newMatchesfun()
//   {
//     let link = ['/new-matches'];
//     this.route.navigate(link);
//   }
//   nearMefun()
//   {
//     let link = ['/near-me'];
//     this.route.navigate(link);
//   }
//   shortlistedfun()
//   {
//     let link = ['/shortlisted'];
//     this.route.navigate(link);
//   }
//   viwedMyProfilefun()
//   {
//     let link = ['/viewed-my-profile'];
//     this.route.navigate(link);
//   }
//   shortlistedMefun()
//   {
//     let link = ['/shortlisted-me'];
//     this.route.navigate(link);
//   }
  
//   quickSearchUrl()
//   {
//     let link = ['/quicksearch'];
//     this.route.navigate(link);
//   }
//   updateProfileHash()
//   {
//     let link = ['/update-profile'];
//     this.route.navigate(link);
//   }
  checkPrefrredLocation()
  {
     let link = ['/ViewProfilePreferredLocation'];
     this.route.navigate(link)
  }
  checkPrefrredProfession()
  {
    let link = ['/ViewProfilePreferredProfession'];
    this.route.navigate(link)
  }
  checkPrefrredEducation()
  {
    let link = ['/ViewProfilePreferredEducation'];
    this.route.navigate(link)
  }
//   backToMyMatches()
//   { 
//   let link = ['/my-matches'];
//   this.route.navigate(link)
//   }
//   logout()
// {
//   localStorage.clear();
//   let link = ['/login'];
//   this.route.navigate(link);
// }
// sidebar()
//   {

//     $(document).ready(function()
//   {
//     $('#slideRight').toggleClass('leftSlide');
//     $(".main").toggleClass('mainFulllWidth');
//     $(".slidebar").toggleClass('slidebarWidth');
//     $(".mainContent>div").toggleClass('mainContentWidth');
//     $("#mytoggle").toggleClass('down');

//   })

//   }

  memberdetailspost(row)
  {
    localStorage.setItem('viewUser',row.MemberID)
    let link = ['/member-details'];
    this.route.navigate(link);
  }
  discoverMatchhash()
  {
    this.logMyDiscoverSpinner = true
    let headers = new Headers({'content-Type' : 'application/json'});
    let body = new FormData

    body.append('MemberID', localStorage.getItem('UserMemberID'));
    body.append('MembCityNM', localStorage.getItem('UserCityID'));

    return this.discoverMatch.MyDiscoverMatchesPost(headers,body).subscribe(result =>{
    if(result['data'] !== undefined)
    {
      this.discoverMatchRes = result['data'].slice(0,6)
      this.myMessage = "";
    }
    else
    {
      this.discoverMatchRes = result['status']
      this.myMessage = result['message']
    }
    this.MyDiscoverByProfessionHash()
  
  })
}
  
MyDiscoverByProfessionHash()
{
   let headers = new Headers ({'Content-Type' : 'Application/json'});
   let body = new FormData

   body.append('LoggedUserid',localStorage.getItem('UserMemberID'))
   body.append('MembProfession',localStorage.getItem('UserOccGroupID'))

   return this.discoverMatch.MyDiscoverByProfessionPost(headers,body).subscribe(responce =>{
    //  console.log("aishh"+responce)
     if(responce['data'] !== undefined)
     {
        this.discoverProfession = responce['data'].slice(0,6)
        this.myMessagePro = ""
     }
     else
     {
      this.discoverProfession = responce['status']
      this.myMessagePro = responce['message']
     }
     this.MyDiscoverByQualificationHash()
   })
}

MyDiscoverByQualificationHash()
{  
    let headers = new Headers({'Content-Type' : 'Application/json'})
    let body = new FormData

    body.append('LoggedUserid',localStorage.getItem('UserMemberID'))
    body.append('MembEducationGroup',localStorage.getItem('UserEduGroupID'))

    return this.discoverMatch.MyDiscoverByQualificationPost(headers,body).subscribe(Res =>{
      if(Res['data'] !== undefined)
      {
        this.discoverEducation = Res['data'].slice(0,6)
        this.myMessageEdu = ""
      }
      else
      {
        this.discoverEducation = Res['status']
        this.myMessageEdu = Res['message']
      }
      this.logMyDiscoverSpinner = false
    })

}

}
