import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { WebsiteConstants } from '.././global/website-constants';

@Injectable({
  providedIn: 'root'
})
export class SendInterestProfileService {
 
private masterUrl = WebsiteConstants.MasterUrl;  
sendInterestPhotoUrl = this.masterUrl+"SendInteresttoProfile/SendInteresttoProfile"
  constructor(private http : HttpClient) { }
  sendInterestPhotoPost(body,headers)
  {
      return this.http.post(this.sendInterestPhotoUrl,body,headers)
  }
}
