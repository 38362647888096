import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { WebsiteConstants } from '.././global/website-constants';

@Injectable({
  providedIn: 'root'
})
export class ShortlistedProfileIconService {

private masterUrl = WebsiteConstants.MasterUrl;  
memberShortlistedUrl = this.masterUrl+"Membershorlisting/Membershorlisting"
memberUnShortlistedUrl = this.masterUrl+"Unmembershorlisting/Unmembershorlisting"
  constructor(private http : HttpClient) { }

  memberShortlistedPost(body,headers)
  {
    return this.http.post(this.memberShortlistedUrl,body,headers)
  }

  memberUnShortlistedPost(body,headers)
  {
    return this.http.post(this.memberUnShortlistedUrl,body,headers) 
  }
}
