import { Injectable } from '@angular/core';
import {HttpClient, HttpHandler, HttpHeaders} from '@angular/common/http';
import { WebsiteConstants } from '.././global/website-constants';

@Injectable({
  providedIn: 'root'
})
export class MembershipPlanService {

  private masterUrl = WebsiteConstants.MasterUrl;
  private siteurl=this.masterUrl;
  private PackPlanUrl = this.siteurl+"MembershipPkg/MembershipPkg";
  private DisplayImgUrl = this.siteurl+"ViewDiscountBanner/ViewDiscountBanner";
  private MakePaymentUrl = this.siteurl+"MemberPlans/MemberPlans";
  private SuccessPaymentUrl = this.siteurl+"MemberPlans/SuccessPayment";

  

  constructor(private http : HttpClient) { }


  makePayment(body, headres)
  {
     return this.http.post(this.MakePaymentUrl,body, headres)
  }

  successPayment(body, headres)
  {
     return this.http.post(this.SuccessPaymentUrl,body, headres)
  }

  oneemonthplan(body, headres)
  {
     return this.http.post(this.PackPlanUrl,body, headres)
  }

  threeemonthplan(body, headres)
  {
     return this.http.post(this.PackPlanUrl,body, headres)
  }

  sixemonthplan(body, headres)
  {
     return this.http.post(this.PackPlanUrl,body, headres)
  }
  
  yearhplan(body, headres)
  {
     return this.http.post(this.PackPlanUrl,body, headres)
  }

  upgradeplanimg(body, headres)
  {
     return this.http.post(this.DisplayImgUrl,body, headres)
  }

}
