
<div class="dashboardMain" id="activeButtDIV">
    <app-left-menu></app-left-menu>    
         <div class="admin-panel clearfix">
             <div class="main">
                 <app-top-menu></app-top-menu>
                 
             <div class="samplecover-spin" id="samplecover-spin" *ngIf="logspinner"></div> 
            <div class="dashMainInner" *ngIf="!logspinner">
                <div class="matchesInner">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="helpDiv help1">
                              <h1>Our Time</h1>
                              <p>Timing 10:00 AM To 07:00 PM</p>
                              <p>For any other help and queries</p>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="helpDiv help2">
                              <h1>Contact 1</h1>
                              <p>{{helpcontact}}</p>
                              <p>{{helpemail}}</p>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="helpDiv help3">
                              <h1>Contact 2</h1>
                              <p>{{fraudcontact}}</p>
                              <p>{{fraudemail}}</p>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <h3>Fraud Assistance</h3>
                            <p>To report fraud, contact our fraud assistance team immediately.
                            Your information will be kept confidential</p>
                          </div>
                        </div>
                      
                </div>
            </div>
 
 
         </div>
     </div>
 </div>    
   
 
 
 
 
 