
<div class="dashboardMain" id="activeButtDIV">
    <!-- <div class="slidebarOuter">
        <div class="slidebar">
  <div class="overDiv overDivBrows">
              <div class="contain animated bounce">
              <div class="upPicOuter">
                <label class=newbtn>
                    <img id="blah2" [src]='profilePic' >
                    <i class="fa fa-pencil pencilUpPic"></i>
                </label>
              </div>
          </div>
          <p class="proname">{{ProfileName}}</p>
          <p class="proname pronameID">({{ProfileId}})</p>
      </div>
      <ul>
        <div *ngIf="userIsPaid == '0'">
        <li class="upgrdOut"><a class="upgrd" href="#upgradeView"><i class="fa fa-upgrade"></i>Upgrade to Membership</a></li>
        </div>
        <li><a href="#dashboard" id="targeted"><i class="fa fa-filter"></i>Advance Search</a></li>
        <li><a href="Javascript:void(0)" (click)="discoverMatchhash()"><i class="fa fa-binoculars"></i>My Descover Matches</a></li>
        <li><a href="Javascript:void(0)" (click)="updateProfilehash()"><i class="fa fa-user-plus"></i>Update Profile</a></li>
        <li><a href="#editPreference"><i class="fa fa-edit"></i>Edit Preference</a></li>
        <li><a href="#photoGallery"><i class="fa fa-photo"></i>Photo Gallery</a></li>
        <li><a href="#" data-toggle="modal" data-target="#raeUsM"><i class="fa fa-star-half-full"></i>Rate Us</a></li>
        <li id="Drsettings"><a><i class="fa fa-cogs"></i>Settings<i class="fa fa-chevron-down" style="    float: right; line-height: 36px;"></i></a>
            <ul class="dropSetting">
              <li><a href="#changPass">Change Password</a></li>
              <li><a data-toggle="modal" data-target="#deleteAc">Delete Account</a></li>
              <li><a href="#termsAcond">Terms & Conditions</a></li>
              <li><a href="#privacyPoli">Privacy Policy</a></li>
            </ul>
        </li>
        <li><a href="#help"><i class="fa fa-question-circle"></i>Help</a></li>
        <li><a href="Javascript:void(0)" (click)="logout()"><i class="fa fa-power-off"></i>Logout</a></li>
      </ul>
    </div>
    <div id="slideRight" (click)="sidebar()"><div id="mytoggle" class="fa fa-chevron-left desktopArrow rotate"></div><div class="fa fa-chevron-right responssiveArrow rotate"></div></div>
  </div> -->
  <app-left-menu></app-left-menu>    
  <div class="admin-panel clearfix">
    <div class="main">
              <!-- <div class="slideOverly"></div> -->
              <!-- <ul class="topbar clearfix dashTopIcon">
                <a href="/"><img src="assets/images/br-logo3.png" class="dashLogo" alt="img"></a>
                <li><a href="Javascript:void(0)" (click)="logout()"><i class="fa fa-power-off"></i></a></li>
                <li><a href="#notification"><i class="fa fa-bell"></i></a></li>
                <li><a href="#mail"><i class="fa fa-envelope"></i></a></li>
                <li><a href="Javascript:void(0)" (click)="backToMyMatches()"><i class="fa fa-home"></i></a></li>
                <li class=""><a class="selected" href="Javascript:void(0)"><i class="fa fa-search"></i></a></li>
                <li class="proId">({{ProfileId}})</li>
              </ul> -->
  
  
              <!-- <div class="subMenu">
                <div class="custmSearch"><input class="autoSearchClick" type="text" placeholder="Search by Profile ID or Name" name=""><i class="fa fa-search subSearchBT"></i>
                  <div class="autoSearchDiv">
                    <h4>Recent Searches</h4>
                    <div class="cityBt searchAutoBt">Deshpande</div>
                    <div class="cityBt searchAutoBt">Br2322</div>
                    <div class="cityBt searchAutoBt">Br2277</div>
                    <div class="cityBt searchAutoBt">Londhe</div>
                    <div class="autoSearchViewPro">
                      <ul>
                        <li><div class="autoSePro"><img src="assets/images/matches2.jpg"></div>Aishwarya Deshpande</li>
                        <li><div class="autoSePro"><img src="assets/images/matches2.jpg"></div>Vaishnavi Deshpande</li>
                        <li><div class="autoSePro"><img src="assets/images/matches2.jpg"></div>Pratiksha Deshpande</li>
                      </ul>
                    </div>
                    <div class="extraSearch">
                      <span class="searchFo"><a href="#">Search For</a></span><span class="searchFo searchFoLeft"><a href="#dashboard" class="adSearch">Advance Search</a></span>
                      <div class="extraSearhIn">
                        <h4>Discover Preferrd Matches By</h4>
                        <div class="extSeOut">
                          <div class="extSeIn"><i class="fa fa-map-marker"></i></div>
                          <span>Location</span>
                        </div>
        
                        <div class="extSeOut">
                          <div class="extSeIn" style="background-color: #65cafb !important"><i class="fa fa-suitcase"></i></div>
                          <span>Profession</span>
                        </div>
        
                        <div class="extSeOut">
                          <div class="extSeIn" style="background-color: #e250e7 !important">
                            <i class="fa fa-graduation-cap"></i>
                          </div>
                          <span>Education</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <header class="newheader">
                  <input class="menu-btn" type="checkbox" id="menu-btn" />
                  <label class="menu-icon" for="menu-btn">More Matches<i class="fa fa-chevron-down"></i><span class="navicon"></span></label>
                  <ul id="topNavBar" class="menu">
                    <a href="/"><img src="assets/images/br-logo3.png" class="dashLogo2" alt="img"></a>
                    <li class="dropdown">
                      <a href="Javascript:void(0)" id="mymatchid" (click)="myMachesfun()">My Matches</a>
                    </li>
                    <li><a href="Javascript:void(0)" id="mynewmatchid" (click)="newMatchesfun()">New Matches</a>
                    </li>
                    <li class="dropClick"><a href="Javascript:void(0)" id="nearmeid" (click)="nearMefun()">Near Me</a>  
                    </li>
                    <li><a href="Javascript:void(0)" id="shortlistedid" (click)="shortlistedfun()">Shortlisted</a>
                    </li>
                    <li><a href="Javascript:void(0)" id="viewmyprofileid" (click)="viwedMyProfilefun()">Viewed My Profile</a>
                    </li>
                    <li><a href="Javascript:void(0)" id="shortlistedmeid" (click)="shortlistedMefun()">Shortlisted Me</a>
                    </li>
                  </ul>
                </header>
              </div>  -->

              <app-top-menu></app-top-menu>
              <div class="mainContent clearfix">


          
                <div id="mysearch">
                  <div class="dashMainCont">
                    <div class="dashMainInner">
                      <div class="matchesInner dashInOuter">
              <div class="row">
                      <div class="col-md-12">
                          <div class="panel panel-default">
                              <!-- Default panel contents -->
                              <div class="panel-heading">
                                  <div class="input-group">
                                
                                      <input id="searchText" type="text" class="form-control autoSear ui-autocomplete-input" name="q" [(ngModel)]="searchtxt"  placeholder="Search by Name or Profile ID" value="" autocomplete="off">
                                      <span class="input-group-btn">
                                          <a id="x" class="btn btn-default hide myclo" href="#mysearch" title="Clear"><i class="fa fa-close glyphicon-remove"></i> </a>
                                          <button class="btn btn-info" type="submit" (click)="quicksearchHash()">  GO  </button>
                                      </span>
                                  </div>
                              </div>
          
                              <!-- List group -->
                              <ul *ngIf="quicksearchresult?.length > 0" id="autolist" class="list-group">
                                  <div id="autocompleteTest"></div>


                                  <li id="fav" class="list-group-item" style="background-color: rgb(255, 255, 255);">
                                      <div class="row">
                                          <div id="favorites" class="">
                                                  <span class="fa fa-star glyphicon-star"> </span><b>Search Result</b>
                                          </div>
                                      </div>
                                  </li>
          
                                  <li *ngFor="let responceData of quicksearchresult" class="list-group-item" style="background-color: rgb(255, 255, 255);">
                                      <div class="row" (click)="memberdetailspost(responceData)">
                                          <div class="col-md-12">
                                              <div class="media-left media-middle">
                                                  <a href="Javascript:void(0)">
                                                      <img class="media-object img-circle"  [src]='photopath+responceData.MembPhotoPath'>
                                                  </a>
                                              </div>
                                              <div id="center">
                                                  {{responceData.MembName}} 
                                                  <div id="center" class="material-switch pull-right">
                                                      <input id="someSwitchOptionPrimary" name="someSwitchOption001i" type="checkbox" checked="true">
                                                      <label for="someSwitchOptionPrimary" class="label-primary"></label>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </li>
                                  


                              </ul>


                              <ul *ngIf="quicksearchresult?.length == 0" id="autolist" class="list-group">

                                <li  class="list-group-item" style="background-color: rgb(255, 255, 255);">
                                  <div class="row">
                                      <div class="col-md-12">
                                          
                                          <div id="center">
                                            {{message}}
                                              <div id="center" class="material-switch pull-right">
                                                  <input id="someSwitchOptionPrimary" name="someSwitchOption001i" type="checkbox" checked="true">
                                                  <label for="someSwitchOptionPrimary" class="label-primary"></label>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </li>

                              </ul>

                          </div>
                      </div>
              </div>
              <div class="row">
                  <div class="col-md-2">
                      <div class="">
                        
                        <div class="samplecover-spin" id="samplecover-spin" *ngIf="logspinner"></div>
                      </div>
                  </div>
              </div>
          
              <div class="extraSearch">
                        <span class="searchFo searchFoLeft"><a href="Javascript:void(0)" class="adSearch" (click)="Advancesearch()">Advance Search</a></span>
                        <div class="extraSearhIn">
                          <h4>Discover Preferrd Matches By</h4>
                          <div class="extSeOut" (click)="Advancesdiscover()">
                            <div class="extSeIn"><i class="fa fa-map-marker"></i></div>
                            <span>Location</span>
                          </div>
          
                          <div class="extSeOut" (click)="Advancesdiscover()">
                            <div class="extSeIn" style="background-color: #65cafb !important"><i class="fa fa-suitcase"></i></div>
                            <span>Profession</span>
                          </div>
          
                          <div class="extSeOut" (click)="Advancesdiscover()">
                            <div class="extSeIn" style="background-color: #e250e7 !important">
                              <i class="fa fa-graduation-cap"></i>
                            </div>
                            <span>Education</span>
                          </div>
                        </div>
                      </div>
          </div>
                    </div>
                  </div>
                </div>

         

          </div>
  
  
      </div>
      
  </div>
  </div>    
  
  
  
  
  
  
  
 
  
  
  
  
  
  
