
<div class="dashboardMain" id="activeButtDIV">
    <app-left-menu></app-left-menu>    
         <div class="admin-panel clearfix">
             <div class="main">
                 <app-top-menu></app-top-menu>
                 
             <!-- <div class="samplecover-spin" id="samplecover-spin" *ngIf="logspinner"></div>  -->
            
             <div class="dashMainInner">
              <div class="matchesInner">
              <div class="row advncSearchMain">
                  
                
                
                  <div class="col-md-4">
                    <label>Age :</label>
                      <input type="text" name="" value="{{agefrom}} - {{ageto}}" class="inputFil inputFilSlide">
                      <div class="inpuData">
                          <label>From :</label>
                          <select class="inputFil inputFilSelect" placeholder="Height"  (change)="ageselefrom($event.target.value)" [(ngModel)]="agefrom">
                            <option [ngValue]="null" selected>Age</option> 
                            <option *ngFor="let a of agelist" value="{{a}}">{{a}}</option>
                          </select>
                          <div class="inpuDataDevider"></div>
                        <label>To :</label>
                        <select class="inputFil inputFilSelect" placeholder="Height"  (change)="ageseleto($event.target.value)" [(ngModel)]="ageto">
                            <option [ngValue]="null" selected>Age</option> 
                            <option *ngFor="let a of agelist" value="{{a}}">{{a}}</option>
            
                          </select>
                        <div class="doneBt">Done</div>
                      </div>
                  </div>



                  <div class="col-md-4">
                    <label>Height</label>
                      <input type="text" name="" value="{{heightfromval}} - {{heighttoval}}" class="inputFil inputFilSlide">
                      <div class="inpuData">
                          <label>From :</label>
                          <select class="inputFil inputFilSelect" placeholder="Height"   (change)="heightfromfun($event.target.value)" [(ngModel)]="heightfrom">
                            <option [ngValue]="null" selected>Height</option> 
                            <!-- HeightDisplayUser -->
                            <option *ngFor="let h of heightlist" value="{{h.HeightCode}}|||{{h.HeightDropdownDisplay}}" >{{h.HeightDropdownDisplay}}</option>
                          </select>
<div class="inpuDataDevider"></div>
                        <label>To :</label>
                        <select class="inputFil inputFilSelect" placeholder="Height"   (change)="heighttofun($event.target.value)" [(ngModel)]="heightto">
                            <option [ngValue]="null" selected>Height</option> 
                            <!-- HeightDisplayUser -->
                            <option *ngFor="let h of heightlist" value="{{h.HeightCode}}|||{{h.HeightDropdownDisplay}}" >{{h.HeightDropdownDisplay}}</option>
                          </select>
                        <div class="doneBt">Done</div>
                      </div>
                  </div>    
                  
                  

                  <div class="col-md-4">
                    <label>Marital Status</label>
                     
                        <ng-multiselect-dropdown
                        [placeholder]="'Maritial Status'"
                        [settings]="dropdownSettings"
                        [data]="dropdownList_female"
                        (onSelect)="onItemSelect_mstatus($event)"
                        [(ngModel)]="mstarr"

                      >
                      </ng-multiselect-dropdown>
                      <!-- </div> -->
                  </div>
                  


                  <div class="clear"></div>


                  <div class="col-md-4">
                    <label>Mother Tongue</label>
                    <ng-multiselect-dropdown
                    [placeholder]="'Mother Tongue'"
                    [settings]="dropdownSettings"
                    [data]="mother_toungue"
                    [(ngModel)]="motarr"
                    (onSelect)="onItemSelect_mothert($event)"
                    
                  >
                  </ng-multiselect-dropdown>
                  </div>

                  
                  <!-- <div class="col-md-4">
                    <label>Religion</label>
                    <ng-multiselect-dropdown
                [placeholder]="'Religion'"
                [settings]="religiondropdownSettings"
                [data]="religionlist"
                [(ngModel)]="religarr"
                (onSelect)="getCasteDetails()"
              >
              </ng-multiselect-dropdown>
                    
                  </div> -->

                  <div class="col-md-4">
                    <label>Caste</label>
                      <ng-multiselect-dropdown
                      [placeholder]="'Caste'"
                      [settings]="castdropdownSettings"
                      [data]="castelist"
                      [(ngModel)]="castarr"
                      (onSelect)="onItemSelect_cast($event)"
                      
                    >
                    </ng-multiselect-dropdown> 
                  </div>


                  <div class="col-md-4">
                    <label>Sub Caste</label>
                    <ng-multiselect-dropdown
                    [placeholder]="'Sub Caste'"
                    [settings]="subcastdropdownSettings"
                    [data]="subcastlist"
                    [(ngModel)]="subcastarr"
                    (onSelect)="onItemSelect_subcast($event)"
                   
                  >
                  </ng-multiselect-dropdown>
                  </div>


                  
                  <div class="clear"></div>


                  <!-- <div class="col-md-4">
                    <label>Country</label>
                    <ng-multiselect-dropdown
                    [placeholder]="'Country'"
                    [settings]="cntrdropdownSettings"
                    [data]="countrylist"
                    [(ngModel)]="cntarr"
                     (onSelect)="onItemSelect_country($event)"
                    
                  >
                  </ng-multiselect-dropdown>     
                </div> -->

                  <div class="col-md-4">
                    <label>State</label>
                    <ng-multiselect-dropdown
                    [placeholder]="'State'"
                    [settings]="statedropdownSettings"
                    [data]="statelist"
                    [(ngModel)]="statarr"
                    (onSelect)="onItemSelect_state($event)"
                    (onDeSelect)="onDeSelect($event)"
                    
                  >
                  </ng-multiselect-dropdown>
                  </div>


                  <div class="col-md-4">
                    <label>City</label>
                    <ng-multiselect-dropdown
                    [placeholder]="'City'"
                    [settings]="citydropdownSettings"
                    [data]="citylist"
                    [(ngModel)]="cityarr"
                   (onSelect)="onItemSelect_city($event)"
                    
                  >
                    </ng-multiselect-dropdown>
                  </div>


                  <div class="col-md-4">
                    <label>Education </label>
                    <!-- <angular2-multiselect 
                  [settings]="educationdropdownSettings"
                  [data]="Educationlist"
                  [(ngModel)]="educarr"
                  (onSelect)="onItemSelect_educat($event)"
                  class="inputFil"
></angular2-multiselect> -->

<ng-multiselect-dropdown
                    [placeholder]="'Education'"
                    [settings]="educationdropdownSettings"
                    [data]="Educationlist"
                    [(ngModel)]="educarr"
                   (onSelect)="onItemSelect_educat($event)"
                    
                  >
                    </ng-multiselect-dropdown>

                
                  </div>
                  
                  <div class="clear"></div>



                  <div class="col-md-4">
                    <label>Occupation</label>
                    <!-- <angular2-multiselect 
                    [settings]="occupationdropdownSettings"
                    [data]="Occupationlist"
                    [(ngModel)]="occarr"
                    (onSelect)="onItemSelect_occ($event)"
                    class="inputFil"
                  ></angular2-multiselect> -->

                  <ng-multiselect-dropdown
                    [placeholder]="'Occupation'"
                    [settings]="occupationdropdownSettings"
                    [data]="Occupationlist"
                    [(ngModel)]="occarr"
                   (onSelect)="onItemSelect_occ($event)"
                    
                  >
                    </ng-multiselect-dropdown>

                  </div>
                  

                  <div class="col-md-4">
                    <label>Emlpoyee In</label>
                    <ng-multiselect-dropdown
                    [placeholder]="'Employee In'"
                    [settings]="dropdownSettings"
                    [data]="empin_list"
                    [(ngModel)]="mpinarr"
                   (onSelect)="onItemSelect_emp($event)"
                    
                  >
                    </ng-multiselect-dropdown>
                  </div>


                  

                  <div class="col-md-4">
                    <!-- countrydropdownSettings -->
                    <label>Job Country</label>
                    <ng-multiselect-dropdown
                    [placeholder]="'Job Country'"
                    [settings]="countrydropdownSettings"
                    [data]="countrylist_job"
                    [(ngModel)]="jbcntarr"
                    (onSelect)="onItemSelect_job($event)"
                      >
                  </ng-multiselect-dropdown>
                  </div>
                  <div class="clear"></div>


                  <div class="col-md-4">
                    <label>Manglik</label>
                    <div class="radioSt inputFil" style="margin: 0;">
                      <!-- <p class="extLab">Mangal Dosh</p> -->
                      <input type="radio" id="doshno" class="maleInp" (click)="mangaldoshfun('Any')" value="Any" [(ngModel)]="mangaldd">
                      <label for="doshno" class="maleStyle">Any</label>
      
                    <input type="radio" id="doshno" class="maleInp"  value="No" (click)="mangaldoshfun('No')" [(ngModel)]="mangaldd">
                    <label for="doshno" class="maleStyle">No</label>
                    
                    <input type="radio" id="doshyes" class="maleInp" value="Yes" (click)="mangaldoshfun('Yes')" [(ngModel)]="mangaldd">

                    <label for="doshyes" class="maleStyle">Yes</label>
                  </div>
                  </div>

                  

                  <div class="col-md-4">
                    <label>Annual Income</label>
                    <input type="text" name="" class="inputFil inputFilSlide" value="{{incomefrom}} - {{incometo}}">
                      <div class="inpuData">
                          <label>From :</label>
                        <select class="inputFil inputFilSelect" (change)="incomefromfun($event.target.value)">
                            <option value="Any">Any</option>
                         <!-- <option *ngFor="let a of incomelist" value="{{a}}" >{{a}}</option> -->

                         <option value="100000|||Rs.1 Lakh" >Rs.1 Lakh</option>
                         <option value="200000|||Rs.2 Lakh" >Rs.2 Lakh</option>
                         <option value="300000|||Rs.3 Lakh" >Rs.3 Lakh</option>
                         <option value="400000|||Rs.4 Lakh" >Rs.4 Lakh</option>
                         <option value="500000|||Rs.5 Lakh" >Rs.5 Lakh</option>
                         <option value="600000|||Rs.6 Lakh" >Rs.6 Lakh</option>
                         <option value="700000|||Rs.7 Lakh" >Rs.7 Lakh</option>
                         <option value="800000|||Rs.8 Lakh" >Rs.8 Lakh</option>
                         <option value="900000|||Rs.9 Lakh" >Rs.9 Lakh</option> 
                         <option value="1000000|||Rs.10 Lakh" >Rs.10 Lakh</option>
                         <option value="1100000|||Rs.11 Lakh" >Rs.11 Lakh</option>
                         <option value="1200000|||Rs.12 Lakh" >Rs.12 Lakh</option>
                         <option value="1300000|||Rs.13 Lakh" >Rs.13 Lakh</option>
                         <option value="1400000|||Rs.14 Lakh" >Rs.14 Lakh</option>
                         <option value="1500000|||Rs.15 Lakh" >Rs.15 Lakh</option>
                         <option value="1600000|||Rs.16 Lakh" >Rs.16 Lakh</option>
                         <option value="1700000|||Rs.17 Lakh" >Rs.17 Lakh</option>
                         <option value="1800000|||Rs.18 Lakh" >Rs.18 Lakh</option>
                         <option value="1900000|||Rs.19 Lakh" >Rs.19 Lakh</option>
                         <option value="2000000|||Rs.20 Lakh" >Rs.20 Lakh</option>
                         <option value="2500000|||Rs.25 Lakh" >Rs.25 Lakh</option>
                         <option value="3000000|||Rs.30 Lakh" >Rs.30 Lakh</option>
                         <option value="3500000|||Rs.35 Lakh" >Rs.35 Lakh</option>
                         <option value="4000000|||Rs.40 Lakh" >Rs.40 Lakh</option>
                         <option value="4500000|||Rs.45 Lakh" >Rs.45 Lakh</option>
                         <option value="5000000|||Rs.50 Lakh" >Rs.50 Lakh</option>
                         
                        </select>
                          <div class="inpuDataDevider"></div>
                        <label>To :</label>
                        <select class="inputFil inputFilSelect" (change)="incometofun($event.target.value)">
                           <option value="Any">Any</option>
                           <!-- <option *ngFor="let a of incomelist" value="{{a}}" >{{a}}</option> -->
                           <option value="100000|||Rs.1 Lakh" >Rs.1 Lakh</option>
                         <option value="200000|||Rs.2 Lakh" >Rs.2 Lakh</option>
                         <option value="300000|||Rs.3 Lakh" >Rs.3 Lakh</option>
                         <option value="400000|||Rs.4 Lakh" >Rs.4 Lakh</option>
                         <option value="500000|||Rs.5 Lakh" >Rs.5 Lakh</option>
                         <option value="600000|||Rs.6 Lakh" >Rs.6 Lakh</option>
                         <option value="700000|||Rs.7 Lakh" >Rs.7 Lakh</option>
                         <option value="800000|||Rs.8 Lakh" >Rs.8 Lakh</option>
                         <option value="900000|||Rs.9 Lakh" >Rs.9 Lakh</option> 
                         <option value="1000000|||Rs.10 Lakh" >Rs.10 Lakh</option>
                         <option value="1100000|||Rs.11 Lakh" >Rs.11 Lakh</option>
                         <option value="1200000|||Rs.12 Lakh" >Rs.12 Lakh</option>
                         <option value="1300000|||Rs.13 Lakh" >Rs.13 Lakh</option>
                         <option value="1400000|||Rs.14 Lakh" >Rs.14 Lakh</option>
                         <option value="1500000|||Rs.15 Lakh" >Rs.15 Lakh</option>
                         <option value="1600000|||Rs.16 Lakh" >Rs.16 Lakh</option>
                         <option value="1700000|||Rs.17 Lakh" >Rs.17 Lakh</option>
                         <option value="1800000|||Rs.18 Lakh" >Rs.18 Lakh</option>
                         <option value="1900000|||Rs.19 Lakh" >Rs.19 Lakh</option>
                         <option value="2000000|||Rs.20 Lakh" >Rs.20 Lakh</option>
                         <option value="2500000|||Rs.25 Lakh" >Rs.25 Lakh</option>
                         <option value="3000000|||Rs.30 Lakh" >Rs.30 Lakh</option>
                         <option value="3500000|||Rs.35 Lakh" >Rs.35 Lakh</option>
                         <option value="4000000|||Rs.40 Lakh" >Rs.40 Lakh</option>
                         <option value="4500000|||Rs.45 Lakh" >Rs.45 Lakh</option>
                         <option value="5000000|||Rs.50 Lakh" >Rs.50 Lakh</option>
                        </select>
                        <div class="doneBt">Done</div>
                      </div>
                  </div>




                  
                  <div class="clear"></div>
                  <div class="restSearchDiv">
                    <button class="resetSearchBt" (click)="resetsearch()">Reset Search</button>
                    <button class="searchNowBt" (click)="searchnow()" >Search Now</button>
                  </div>
              </div>
            </div>
          </div>
 
 
         </div>
     </div>
 </div>    
   
 
 
 
 
 
