import { Component, OnInit } from '@angular/core';
import {UpdateProfileService} from '../services/update-profile.service'
import {ViewmemberdetailsService} from '../services/viewmemberdetails.service'
import {Router} from '@angular/router'
import * as $ from 'jquery';
import { FormControl, FormGroup, Validators,ReactiveFormsModule,FormBuilder } from '@angular/forms';
import {UploadUserPhotoService} from '../services/upload-user-photo.service'
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {RegistrationService} from '../services/registration.service'
import {UploadMulPhotoService} from '../services/upload-mul-photo.service'
import {UpdateContactEmailService} from '../services/update-contact-email.service'
import {MultiplePhotosService} from '../services/multiple-photos.service'
import { DeleteMultiplePhotoService } from '../services/delete-multiple-photo.service';
import { WebsiteConstants } from '.././global/website-constants';

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.css']
})
export class UpdateProfileComponent implements OnInit {
  photopath:any;profilePic:any;ProfileName:any;ProfileId:any;userIsPaid:any;userProfileData:any;profileFor:any;isSubmitted=false;AboutProfile:any;url:any;occupationdesc:any;employeeindesc:any;candiatehighedu:any; getAboutUs:any;
  form: FormGroup;isUpload = false;logspinner:boolean;createProfiledes:any; createProfilenoun:any; createfor1:any; createfor2:any;candidatenoun:any;aboutusdescriptionval:any;aboutForForm:FormGroup;isSubmitAbout=false;date1:any;convertdate:any;newdate:any;maxDate:any;pastYear:any;any;date2:any;today:any;birthdate:any;isSubmitPersonal=false;previousDate:any;personalDetailsForm:FormGroup;heightlist:any;genderval:any;MaritalStatusArray:any;bloodgrplist:any;religionDetailForm:FormGroup;issubmitReligion=false;religionlist:any;subCastId:any;subcastlist:any;ReligionID:any;castelist:any;contactDetailForm:FormGroup;
  issubmitContact = false; addressDetailForm:FormGroup;issubmitaddress = false;professionalDetailForm:FormGroup;issubmitProfession=false;familyDetailForm:FormGroup;issubmitFamily=false;gothralist:any;stateId:any;citylist:any;countryId:any;statelist:any;employeeInId:any;Occupationlist:any;countrylist:any;Educationlist:any;EducationTitle:any;fullnameVal:any;candidateheightval:any;birthtimeval:any
  birthplaceval:any;genderhash:any;raasVal:any;contactVal:any;emailidval:any;addressVal:any;talukaval:any;jobcityval:any;anualIncomeVal:any;fatherNameVal:any;fatherOccupationVal:any;fatherContactVal:any;motherNameVal:any;motherOccupationVal:any;familyStatusVal:any;familyTypeVal:any;familyTypeval:any;relativesVal:any;brotherVal:any;brotherMarriedVal:any;sisterVal:any;sisterMarriedVal:any;educationVal:any;otherEducationVal:any;employeeInVal:any;occupationVal:any;jobcountryVal:any;stateVal:any;cityVal:any;religionVal:any;castVal:any;subCastVal:any;gothraVal:any;charanVal:any;ganaVal:any;nakshtraVal:any;nadiVal:any;mangalDoshVal:any;DOBVal:any;AgeVal:any;maritalStatusVal:any;motherToungeVal:any;ComplexionVal:any;bloodGroupVal:any;disabilityVal:any;profileID:any;mangalDoshstr:any;birthnameval:any;birthnamevalformat:any;monthDigit:any;updateCandHeight:any;updateBlood:any;postbloodval:any;disabilityFeild:any;newStateVal:any;memberHeightVal:any;showError:any;isSinglePhotoUpload = false;MemberContactFlagVal = 0
  myFiles:string [] = [];
  images = [];
  multipleImages = [];
  allFiles:any[];
  myForm = new FormGroup({
   file: new FormControl('', [Validators.required]),
   fileSource: new FormControl('', [Validators.required])

 });

  masterUrl = WebsiteConstants.MasterUrl;
  constructor(private updatePro : UpdateProfileService,private route : Router,private memberservice : ViewmemberdetailsService, private userPhotoupload : UploadUserPhotoService,private formBuilder: FormBuilder,private regservice : RegistrationService, private multiplePhoto : UploadMulPhotoService, private multiplephotoservice :MultiplePhotosService,private EditContactEmail : UpdateContactEmailService, private deletePhoto : DeleteMultiplePhotoService) 
  { 
    this.aboutForForm = this.formBuilder.group({
      "aboutForm" : new FormControl("",Validators.required)
    });
    
    this.personalDetailsForm = this.formBuilder.group({
    "ProfileIdval" : new FormControl("",Validators.required),
    "FullName" : new FormControl("",Validators.required),
    "gender" : new FormControl("",Validators.required),
    "DOB" : new FormControl("",Validators.required),
    "Age" : new FormControl("",Validators.required),
    "height" : new FormControl("",Validators.required),
    "birthTime" : new FormControl("",Validators.required),
    "birthPlace"  : new FormControl("",Validators.required),
    "maritalStatus" : new FormControl("",Validators.required),
    "motherTounge" : new FormControl("",Validators.required),
    "Complexion" : new FormControl("",Validators.required),
    "bloodGroup" : new FormControl("",Validators.required),
    "disability" : new FormControl("",Validators.required),
    });

    this.religionDetailForm = this.formBuilder.group({
      "religion" : new FormControl("",Validators.required),
      "cast" : new FormControl("",Validators.required),
      "subCast" : new FormControl("",Validators.required),
      "gotra" : new FormControl("",Validators.required),
      "raas" : new FormControl("",Validators.required),
      "charan" : new FormControl("",Validators.required),
      "gana" : new FormControl("",Validators.required),
      "nakshtra" : new FormControl("",Validators.required),
      "nadi" : new FormControl("",Validators.required),
      "mangalDosh" : new FormControl("",Validators.required),

    });

    this. contactDetailForm = this.formBuilder.group({
       "contact" : new FormControl("",Validators.required),
       "emailId" : new FormControl("",Validators.required),
    });

    this.addressDetailForm = this.formBuilder.group({
      "address" : new FormControl("",Validators.required),
      "state" : new FormControl("",Validators.required),
      "city" : new FormControl("",Validators.required),
      "taluka" : new FormControl(""),
    });

    this.professionalDetailForm = this.formBuilder.group({
      "educationtxt" : new FormControl("",Validators.required),
      "otherEducation" : new FormControl(""),
      "employeein" : new FormControl("",Validators.required),
      "occupation" : new FormControl("",Validators.required),
      "jobcountry" : new FormControl(""),
      "jobcity" : new FormControl(""),
      "income" : new FormControl("",Validators.required)
    });

    this.familyDetailForm = this.formBuilder.group({
      "fatherName" : new FormControl("",Validators.required),
      "fatherOccupation" : new FormControl("",Validators.required),
      "fatherContact" : new FormControl("",Validators.required),
      "motherName" : new FormControl("",Validators.required),
      "motherOccupation" : new FormControl("",Validators.required),
      "brother" : new FormControl("",Validators.required),
      "brotherMarried" : new FormControl("",Validators.required),
      "sister" : new FormControl("",Validators.required),
      "sisterMarried": new FormControl("",Validators.required),
      "familyStatus" : new FormControl("",Validators.required),
      "familyType" : new FormControl("",Validators.required),
      "familyValue" : new FormControl("",Validators.required),
      "relatives" : new FormControl("",Validators.required),
    });

  }

  
  get f(){

    return this.myForm.controls;

  }

  get aboutForm(){return this.aboutForForm.get('aboutForm')}
  

 get ProfileIdval(){return this.personalDetailsForm.get('ProfileIdval')};
 get FullName(){return this.personalDetailsForm.get('FullName')};
 get gender(){return this.personalDetailsForm.get('gender')};
 get DOB(){return this.personalDetailsForm.get('DOB')};
 get Age(){return this.personalDetailsForm.get('Age')};
 get height(){return this.personalDetailsForm.get('height')};
 get birthTime(){return this.personalDetailsForm.get('birthTime')};
 get birthPlace(){return this.personalDetailsForm.get('birthPlace')};
 get maritalStatus(){return this.personalDetailsForm.get('maritalStatus')};
 get motherTounge(){return this.personalDetailsForm.get('motherTounge')};
 get Complexion(){return this.personalDetailsForm.get('Complexion')};
 get bloodGroup(){return this.personalDetailsForm.get('bloodGroup')};
 get disability(){return this.personalDetailsForm.get('disability')};

 get religion(){return this.religionDetailForm.get('religion')};
 get cast(){return this.religionDetailForm.get('cast')};
 get subCast(){return this.religionDetailForm.get('subCast')};
 get gotra(){return this.religionDetailForm.get('gotra')};
 get raas(){return this.religionDetailForm.get('raas')};
 get charan(){return this.religionDetailForm.get('charan')};
 get gana(){return this.religionDetailForm.get('gana')};
 get nakshtra(){return this.religionDetailForm.get('nakshtra')};
 get nadi(){return this.religionDetailForm.get('nadi')};
 get mangalDosh(){return this.religionDetailForm.get('mangalDosh')};

 get contact(){return this.contactDetailForm.get('contact')};
 get emailId(){return this.contactDetailForm.get('emailId')};

 get address(){return this.addressDetailForm.get('address')};
 get state(){return this.addressDetailForm.get('state')};
 get city(){return this.addressDetailForm.get('city')};
 get taluka(){return this.addressDetailForm.get('taluka')};

 get educationtxt(){return this.professionalDetailForm.get('educationtxt')};
 get otherEducation(){return this.professionalDetailForm.get('otherEducation')};
 get employeein(){return this.professionalDetailForm.get('employeein')};
 get occupation(){return this.professionalDetailForm.get('occupation')};
 get jobcountry(){return this.professionalDetailForm.get('jobcountry')};
 get jobcity(){return this.professionalDetailForm.get('jobcity')};
 get income(){return this.professionalDetailForm.get('income')};

 get fatherName(){return this.familyDetailForm.get('fatherName')};
 get fatherOccupation(){return this.familyDetailForm.get('fatherOccupation')};
 get fatherContact(){return this.familyDetailForm.get('fatherContact')};
 get motherName(){return this.familyDetailForm.get('motherName')};
 get motherOccupation(){return this.familyDetailForm.get('motherOccupation')};
 get brother(){return this.familyDetailForm.get('brother')};
 get brotherMarried(){return this.familyDetailForm.get('brotherMarried')};
 get sister(){return this.familyDetailForm.get('sister')};
 get sisterMarried(){return this.familyDetailForm.get('sisterMarried')};
 get familyStatus(){return this.familyDetailForm.get('familyStatus')};
 get familyType(){return this.familyDetailForm.get('familyType')};
 get familyValue(){return this.familyDetailForm.get('familyValue')};
 get relatives(){return this.familyDetailForm.get('relatives')};


 changeBodyClass() {
    // get html body element
    const bodyElement = document.body;
    if (bodyElement) {
          $("body").removeClass("modal-open");
    }
  }


  ngOnInit(): void {

    this.photopath = this.masterUrl;
    this.profilePic = this.masterUrl+localStorage.getItem("UserPhotoPath");
    this.ProfileName = localStorage.getItem("UserFullName");
    this.ProfileId = localStorage.getItem("UserProfileID");
    this.userIsPaid = localStorage.getItem('UserIsPaid');

    // if(localStorage.getItem("openMultiImagesbox")=="show")
    // {
      
    //   localStorage.removeItem("openMultiImagesbox");
    //   $("#collapseTwo").addClass('show');
    // }
    


    this.logginMemberDetails()

    this.form = this.formBuilder.group({
      avatar: ['']
    });

    this.getHeightDetails();
    this.getBloodGrpDetails();
    this.getReligionDetails();
    this.getGothraDetails();
    this.getEducationDetails();
    this.getOccupationDetails();
    this.getCountryDetails();
    this.gallaryphotospost();
    // this.onSelectCountry(localStorage.getItem("country"))
   
    

    if(this.genderval == "male" || this.genderval == "Male")
                    {
                      this.MaritalStatusArray=[
                        {mstatus : "Never Married",mstatusval : "Never Married"},
                        {mstatus :"Divorced" ,mstatusval : "Divorced"},
                        {mstatus :"Awaiting Divorced" ,mstatusval : "Awaiting Divorced"},
                        {mstatus :"Widow" ,mstatusval : "Widow"} 
                      ];
                    }
                    else
                    {
                      this.MaritalStatusArray=[
                        {mstatus : "Never Married",mstatusval : "Never Married"},
                        {mstatus :"Divorced" ,mstatusval : "Divorced"},
                        {mstatus :"Awaiting Divorced" ,mstatusval : "Awaiting Divorced"},
                        {mstatus :"Widower" ,mstatusval : "Widower"}    
                      ];
                    }
  }


  gallaryphotospost = function()
{
   this.logshortlistmespinner = true;
   let headers = new Headers({'Content-type' : 'application/json'});
   let body = new FormData();
   body.append('MemberID', localStorage.getItem('UserMemberID'));
   
   this.multiplephotoservice.galleryPhotopost(body,headers).subscribe(Response =>{
     if(Response.data !== undefined)
     {
       console.log("dadu"+Response.data)
       this.multipleImages = Response.data
       this.myMessage = "";
     }
     else
     {
      this.multipleImages = Response.status
      this.myMessage = "Photos are not available";
    }
   
   this.logshortlistmespinner = false;
    
   })
}


deletePhotoFromGallaryHash(photopath)
{
  let headers = new Headers({'Content-type' : 'application/json'});
   let body = new FormData();

   body.append('MemberID',localStorage.getItem('userid'))
   body.append('MemberMultiplePhotoPath',photopath);

   Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete this photo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {


  return this.deletePhoto.deleteMultiplePhotoPost(body,headers).subscribe(updateReq =>{
    
      this.gallaryphotospost();
    })

    } else if (result.dismiss === Swal.DismissReason.cancel) {
      
      }
    })
}

  uploadMultiImages()
  {
    //alert(this.myFiles.length)
    if(this.myFiles.length <= 0)
    {
      return false;
    }
    this.logspinner = true;
    let headers = new Headers({'content-type' : 'application/json'})
    let body = new FormData;

    for (var i = 0; i < this.myFiles.length; i++) { 
      body.append("MemberMultiplePhotoPath[]", this.myFiles[i]);
    }

    body.append('MemberID',localStorage.getItem('UserMemberID'))
    body.append('MemberProfileID',localStorage.getItem('UserProfileID'))
    body.append('MemberGender',localStorage.getItem('UserGender'))

    return this.multiplePhoto.uploadMulPhotoPost(body,headers).subscribe(mulRes =>{

    let message = mulRes['message']
    Swal.fire({
      title : 'Success', 
      text: message, 
      icon : 'success',
      showConfirmButton : false,
      timer : 1500});
      this.logspinner = false;

      //localStorage.setItem("openMultiImagesbox",'show');
      let link = ['/update-profile'];
      this.route.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.route.navigate(link);
       
    });
      //  let link = ['/user-gallery'];
      //  this.route.navigate(link);
    })
  }
logginMemberDetails()
{
  let headers = new Headers({'Content-Type' : 'Application/json'})
  let body = new FormData

   body.append('VwName', localStorage.getItem('UsercasteView'));
   body.append('MemberID',localStorage.getItem('UserMemberID'));
   body.append('MembIsPaid', '1');
   body.append('LoggedUserid',localStorage.getItem('UserMemberID')); 

   return this.memberservice.memberdetails(body,headers).subscribe(responce =>{
     console.log("aaa"+JSON.stringify(responce['data']))
     this.userProfileData = responce['data'];
     if(localStorage.getItem('UserAboutUs'))
     {
      this.getAboutUs = localStorage.getItem('UserAboutUs');
      this.userProfileData.MemberAboutMe = localStorage.getItem('UserAboutUs');
     }
     else
     {
       localStorage.setItem('UserAboutUs',responce['data'].MemberAboutMe);
       this.getAboutUs = localStorage.getItem('UserAboutUs');
     }
     
     this.profileFor = responce['data']['MemberProfileFor']
     this.fullnameVal = responce['data']['MemberFullName']
     this.profileID = responce['data']['MemberProfileID']
     this.genderhash = responce['data']['MemberGender']
     this.DOBVal = responce['data']['MemberDOB']

     if(this.DOBVal === null)
     {  
       this.birthnameval ="";
     }
     else
     {
       this.birthnamevalformat = this.DOBVal.split("-");
       this.monthDigit = new Date(Date.parse(this.birthnamevalformat[1] +" 1,"+this.birthnamevalformat[2])).getMonth()
      this.birthnameval = new Date(this.birthnamevalformat[2], this.monthDigit, this.birthnamevalformat[0])
     }

     this.AgeVal = responce['data']['MemberAge']
     this.updateCandHeight = responce['data']['MemberHeight']+'|||'+responce['data']['MemberHeightNo']+'|||'+responce['data']['MemberHeightdropdownvalue']
     let heightIdval = responce['data']['MemberHeightdropdownvalue'].split('\"');
     this.memberHeightVal = responce['data']['MemberHeight']+' ft'+heightIdval[1]
     this.candidateheightval = this.updateCandHeight
     this.birthtimeval = responce['data']['MemberBirthTime']
     this.birthplaceval = responce['data']['MemberBirthPlace']
     this.maritalStatusVal = responce['data']['MemberMartialStatus']
     this.motherToungeVal = responce['data']['MemberMotherTongue']
     this.ComplexionVal = responce['data']['MemberComplexion']
     this.updateBlood = responce['data']['MemberBloodGroup']+'|||'+responce['data']['MemberBloodCode']
     this.bloodGroupVal = this.updateBlood 
     this.disabilityVal = responce['data']['MemberDisability']

     if(this.disabilityVal == "Normal")
     {
         this.postbloodval = "No";
     }
     else
     {
         this.postbloodval = "Yes";
     }

     this.religionVal =  responce['data']['MemberRelgID']+'|||'+responce['data']['MemberRelgName']
     this.castVal =  responce['data']['MemberCasteID']+'|||'+responce['data']['MemberCasteName']
     this.subCastVal =  responce['data']['MemberSubCastID']+'|||'+responce['data']['MemberSubCastName']
     this.gothraVal = responce['data']['MemberGothra']
     this.raasVal = responce['data']['MemberRaas']
     this.charanVal =  responce['data']['MemberCharan']
     this.ganaVal =  responce['data']['MemberGana']
     this.nakshtraVal = responce['data']['MemberNakshtra']
     this.nadiVal =  responce['data']['MemberNadi']
     this.mangalDoshVal = responce['data']['MemberDosh']
     if(this.mangalDoshVal == 0)
     {
      this.mangalDoshstr = "No";
     }
     else
     {
      this.mangalDoshstr = "Yes";
     }
     if( responce['data']['MemberContactFlag'] == 0)
     {
       this.MemberContactFlagVal = 0
      this.contactDetailForm.controls['contact'].enable();
     }
     else
     {
       this.MemberContactFlagVal = 1
      this.contactDetailForm.controls['contact'].disable();
     }
     this.contactVal = responce['data']['MemberContactNo']
     this.emailidval = responce['data']['MemberEmailID']
     this.addressVal = responce['data']['MemberAddress']
     this.stateVal = responce['data']['MemberStateID']+'|||'+responce['data']['MemberStateName']
     this.cityVal = responce['data']['MemberCityID']+'|||'+responce['data']['MemberCityName']
     this.talukaval = responce['data']['MemberTaluka']
     this.educationVal = responce['data']['MemberEduID']+'|||'+responce['data']['MemberHeightsEducation']+'|||'+responce['data']['MemberEduGroupID']+'|||'+responce['data']['MemberEductionGroupName']
     if(responce['data']['MemberEmployedIn'] == "Not Working")
     {
      this.employeeInVal = responce['data']['MemberEmployedIn']+'|||17';
    
     }
     else
     {
      this.employeeInVal = responce['data']['MemberEmployedIn']+'|||1';
     }
     this.otherEducationVal = responce['data']['MemberOtherEducation']
     this.occupationVal = responce['data']['MemberOccID']+'|||'+responce['data']['MemberOccupation']+'|||'+responce['data']['MemberOccGroupID']+'|||'+responce['data']['MemberOccupGroupName']
     this.jobcountryVal = responce['data']['MemberJobCountryID']+'|||'+responce['data']['MemberJobCountryName']
     this.jobcityval = responce['data']['MemberJobCity']
     this.anualIncomeVal = responce['data']['MemberAnnualIncome']
     this.fatherNameVal = responce['data']['MemberFatherName']
     this.fatherOccupationVal = responce['data']['MemberFatherOccupation']
     this.fatherContactVal = responce['data']['MemberFatherContactNo']
     this.motherNameVal = responce['data']['MemberMotherName']
     this.motherOccupationVal = responce['data']['MemberMotherOccupation']
     this.brotherVal = responce['data']['MemberBrotherNos']
     this.brotherMarriedVal = responce['data']['MemberBMarried']
     this.sisterVal = responce['data']['MemberSisterNos']
     this.sisterMarriedVal = responce['data']['MemberSMarried']
     this.familyStatusVal = responce['data']['MemberFamilyStatus']
     this.familyTypeVal = responce['data']['MemberFamilyType']
     this.familyTypeval = responce['data']['MemberFamilyValue']
     this.relativesVal = responce['data']['MemberRelative']
     this.url = this.photopath+this.userProfileData.MemberPhotoPath;
     localStorage.setItem("UserPhotoPath",this.userProfileData.MemberPhotoPath);

     if(localStorage.getItem('UserAboutUs'))
     {
      this.aboutForForm.patchValue({aboutForm: localStorage.getItem('UserAboutUs')});
     }
     else
     {
      this.aboutForForm.patchValue({aboutForm: this.userProfileData.MemberAboutMe});
     }
   
     
     if(this.profileFor === "Myself")
      {
          this.AboutProfile="About Myself";
          this.createProfiledes=" ";
          this.createProfilenoun="I am ";
          this.candidatenoun="I have ";
          this.createfor1="my ";
          this.createfor2=" I ";
      }
      else if(this.profileFor==="Son")
      {
          this.AboutProfile="About My Son";
          this.createProfiledes=" Son's ";
          this.createProfilenoun="he is ";
          this.candidatenoun="He has ";
          this.createfor1="his ";
          this.createfor2=" He ";
      }
      else if(this.profileFor==="Daughter")
      {
          this.AboutProfile="About My Daughter";
          this.createProfiledes=" Daughter's ";
          this.createProfilenoun="she is ";
          this.candidatenoun="She has ";
          this.createfor1="her ";
          this.createfor2=" She ";
      }
      else if(this.profileFor==="Brother")
      {
          this.AboutProfile="About My Brother";
          this.createProfiledes=" Brother's ";
          this.createProfilenoun="he is ";
          this.candidatenoun="He has ";
          this.createfor1="his ";
          this.createfor2=" He ";
      }
      else if(this.profileFor==="Sister")
      {
          this.AboutProfile="About My Sister";
          this.createProfiledes=" Sister's ";
          this.createProfilenoun="she is ";
          this.candidatenoun="She has ";
          this.createfor1="her ";
          this.createfor2=" She ";
      }
      else if(this.profileFor==="Relative")
      {
          this.AboutProfile="About My Relative";
          this.createProfiledes=" Relative's ";
          if(this.userProfileData.MemberGender ==="male" || this.userProfileData.MemberGender==="Male")
          {
            this.createProfilenoun="he is ";
            this.candidatenoun="He has ";
            this.createfor1="his ";
            this.createfor2=" He ";
          }
          else
          {
            this.createProfilenoun="she is ";
            this.candidatenoun="She has ";
            this.createfor1="her ";
            this.createfor2=" She ";
          }
      }
      else if(this.profileFor==="Friend")
      {
          this.AboutProfile="About My Friend";
          if(this.userProfileData.MemberGender ==="male" || this.userProfileData.MemberGender==="Male")
          {
            this.createProfilenoun="he is ";
            this.candidatenoun="He has ";
            this.createfor1="his ";
            this.createfor2=" He ";
          }
          else
          {
            this.createProfilenoun="she is ";
            this.candidatenoun="She has ";
            this.createfor1="her ";
            this.createfor2=" She ";
          }
      }

      if(this.userProfileData.MemberOccupation === null)
      {
        this.occupationdesc ='';
      }
      else
      {
        this.occupationdesc = this.userProfileData.MemberOccupation;
      }

      if(this.userProfileData.MemberEmployedIn === null)
      {
         this.employeeindesc ='';
      }
      else
      {
        this.employeeindesc = this.userProfileData.MemberEmployedIn
      }

      if(this.userProfileData.MemberEductionGroupName === null)
      {
        this.candiatehighedu = '';
      }
      else
      {
        this.candiatehighedu = this.userProfileData.MemberEductionGroupName
      }
      this.aboutusdescriptionval="My"+this.createProfiledes+"name is "+this.userProfileData.MemberFullName+" and "+this.createProfilenoun+"working as "+this.occupationdesc+ " in the "+this.employeeindesc+". "+this.candidatenoun+"completed "+this.createfor1+""+this.candiatehighedu+""+this.createfor2+"grew up in "+this.userProfileData.MemberFamilyStatus+",  "+this.userProfileData.MemberFamilyType+" with "+this.userProfileData.MemberFamilyValue+".";


      this.getCasteDetails(this.userProfileData.MemberRelgID)
      this.getSubCastDetails(this.userProfileData.MemberCasteID)
      this.onChangeCountry(this.userProfileData.MemberCountryID)
      this.onSelectState(this.userProfileData.MemberStateID)
      this.onSelectOccupation(this.employeeInVal)
      // this.onSelectOccupation(this.userProfileData.MemberEmployedIn)
        //this.aboutForForm.get('aboutForm').patchValue(this.aboutusdescriptionval)
       // $("#proEditAbout").show();
   })

}

onSubmit()
{
  this.logspinner = true;
  let headers = new Headers({'Content-Type' : 'Application/json'})
  let body = new FormData

  let checkimg = this.form.get('avatar').value['name'];
  if(checkimg!='' && checkimg!='undefined' && checkimg!=undefined)
  {
  
  body.append('MemberID',localStorage.getItem('UserMemberID'))
  body.append('MemberProfileID',localStorage.getItem('UserProfileID'))
  body.append('MemberGender',localStorage.getItem('UserGender'))
  body.append('isPhoto','1')
  body.append('MemberPhotoPath',this.form.get('avatar').value)
  console.log(this.form.get('avatar').value)
  
  return this.userPhotoupload.UploadUserPhotosPost(body,headers).subscribe(result =>{
    console.log("Hello"+JSON.stringify(result['message']))
    let message = result['message']
    Swal.fire({
      title : 'Success', 
      text: message, 
      icon : 'success',
      showConfirmButton : false,
      timer : 1500});
      this.logspinner = false;
  })

}
else
{
      this.isUpload = true;
      this.logspinner = false;
}

}
// onSubmitMul()
// {
//   let headers = new Headers({'Content-Type' : 'Application/json'})
//   let body = new FormData
  
//   body.append('MemberID',localStorage.getItem('UserMemberID'))
//   body.append('MemberProfileID',localStorage.getItem('UserProfileID'))
//   body.append('MemberGender',localStorage.getItem('UserGender'))
//   body.append('MemberMultiplePhotoPath')
// }

onChangeImage(e)
{
  this.isSinglePhotoUpload = true
  if(e.target.files)
  {
    console.log(e.target.files)
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload=(event:any)=>{
      this.url=event.target.result;
      const file = e.target.files[0];
      console.log(file)
      this.form.get('avatar').setValue(file);
      this.isUpload = false;
    }
  }
}

onFileChange(event) {
  
  if (event.target.files && event.target.files[0]) {
  var filesAmount = event.target.files.length;
    for (let i = 0; i < filesAmount; i++) {
      var reader = new FileReader();
      reader.onload = (event:any) => {
      this.images.push(event.target.result);     
        this.myForm.patchValue({
        fileSource: this.images
});
}

        this.myFiles.push(event.target.files[i]);
        reader.readAsDataURL(event.target.files[i]);

        
    }
      console.log(this.images)
  }
}


 removeImg(index)
{
   //const index = this.images.indexOf(image)
   this.images.splice(index,1);
   this.myFiles.splice(index,1);
   console.log(this.myFiles)
}
differencedate(gender)
{
  this.date1 = this.convertdate(new Date()); 
  this.newdate = new Date();
  this.maxDate = new Date();

  if(gender.target.value=="Female")
  {
    this.pastYear = this.newdate.getFullYear() - 18;
  }
  else
  {
    this.pastYear = this.newdate.getFullYear() - 21;
  }

  this.birthdate.reset();
  this.newdate.setFullYear(this.pastYear);
  this.date2 = this.convertdate(this.newdate); 
  
  var Difference_In_Time = new Date(this.date1).getTime() - new Date(this.date2).getTime(); 
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); 
  this.maxDate.setDate(this.maxDate.getDate() - Difference_In_Days);   
  this.today = new Date();
}

onDateChange(newDate: Date) {
  this.previousDate = new Date(newDate);
}

getHeightDetails()
{
    let headers = new Headers({'Content-Type':'application/json'});
    this.regservice.getHeightData(headers).subscribe(Response =>
    {
      this.heightlist = Response['data']
      console.log(this.heightlist);
    })
}
getBloodGrpDetails()
{
  let headers = new Headers({'Content-Type':'application/json'});
  this.regservice.getBloodGrpData(headers).subscribe(Response =>
  {
    this.bloodgrplist = Response['data']
  })
}


getReligionDetails()
{
  this.logspinner = true;
    let headers = new Headers({ 'Content-Type': 'application/json' });
    this.regservice.getReligionData(headers).subscribe(Response =>
      {
        this.logspinner = false;
        this.religionlist = Response['data'];
      })
}
getCasteDetails(redId)
{
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let body = new FormData();
    this.ReligionID = redId;
    body.append('ReligionID', this.ReligionID);
    this.regservice.getCasteData(body,headers).subscribe(Response =>
    {
        if(Response['status']==1) 
        {
            this.castelist = Response['data'];
        }
        else
        {
          this.castelist = [];
        }
    })
}
getGothraDetails()
{
    let headers = new Headers({'Content-Type':'application/json'});
    this.regservice.getGothraData(headers).subscribe(Response =>
    {
     this.gothralist = Response['data']
    })
}
getSubCastDetails(subcastid)
  {
    this.subCastId = subcastid;
    if(this.subCastId)
    {
      let headers = new Headers({'Content-Type':'application/json'});
      let body = new FormData();
      body.append('CasteID', this.subCastId);
      this.regservice.getSubCastData(body,headers).subscribe(Response =>
        {
            this.logspinner = false;
            this.subcastlist =  Response['data'];
        })
    }
    else
    {
      this.subcastlist = [];
      this.logspinner = false;
    }

    
  }

onChangeOccupation(e)
{
  //this.logspinner = true;
  this.employeeInId = e.target.value.split('|||');
  
  if(this.employeeInId[0] != 'Not Working')
  { 
    this.professionalDetailForm.get('jobcountry').setValidators([Validators.required]); // 5.Set Required Validator
      this.professionalDetailForm.get('jobcountry').updateValueAndValidity();

      this.professionalDetailForm.get('jobcity').setValidators([Validators.required]); // 5.Set Required Validator
      this.professionalDetailForm.get('jobcity').updateValueAndValidity();

  }
  else
  {
    this.jobcountry.reset()
    this.jobcity.reset()
    this.professionalDetailForm.get('jobcountry').clearValidators(); // 6. Clear All Validators
    this.professionalDetailForm.get('jobcountry').updateValueAndValidity();

    this.professionalDetailForm.get('jobcity').clearValidators(); // 6. Clear All Validators
    this.professionalDetailForm.get('jobcity').updateValueAndValidity();
  }

  if(this.employeeInId[1])
  {
    let headers = new Headers({'Content-Type':'application/json'});
    let body = new FormData();
    body.append('occID', this.employeeInId[1]);
    this.regservice.getOccupationData(body,headers).subscribe(Response =>
      {
         // this.logspinner = false; 
          this.Occupationlist =  Response['data'];
      })
  }
  else
  {
    this.Occupationlist = [];
    //this.logspinner = false;
  }

}
onChangeEducation(e)
  {
      //alert(e.target.value)
  }
  getEducationDetails()
{

    let headers = new Headers({ 'Content-Type': 'application/json' });
    this.regservice.getEducationData(headers).subscribe(Response =>
    {
        if(Response['status']==1) 
        {
            this.Educationlist = Response['data'];
            console.log(Response['data'])
            
        }
        else
        {
          this.EducationTitle = [];
          this.Educationlist = [];
        }
    })

}
getOccupationDetails()
{
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let body = new FormData();
    this.regservice.getOccupationData(headers,body).subscribe(Response =>
    {
        if(Response['status']==1) 
        {
            this.Occupationlist = Response['data'];
        }
        else
        {
          this.Occupationlist = [];
        }
    })

}

onSelectOccupation(employeeInId)
  {
    // this.logspinner = true;
    this.employeeInId = employeeInId.split('|||');
    // alert(this.employeeInId[1])
    if(this.employeeInId[1])
    {
      let headers = new Headers({'Content-Type':'application/json'});
      let body = new FormData();
      body.append('occID', this.employeeInId[1]);
      this.regservice.getOccupationData(body,headers).subscribe(Response =>
        {
            console.log("Occupation"+Response)
            // this.logspinner = false; 
            this.Occupationlist =  Response['data'];
        })
    }
    else
    {
      this.Occupationlist = [];
      // this.logspinner = false;
    }

  }
onChangeCountry(country)
{
    this.logspinner = true;
    this.countryId = country;
    if(this.countryId)
    {
      let headers = new Headers({'Content-Type':'application/json'});
      let body = new FormData();
      body.append('CountryID', this.countryId);
      this.regservice.getStateData(body,headers).subscribe(Response =>
        {
            this.logspinner = false; 
            this.statelist =  Response['data'];
        })
    }
    else
    {
      this.statelist = [];
      this.logspinner = false;
    }

}
onChangeState(e)
{
    this.logspinner = true;
    this.stateId = e.target.value;
    if(this.stateId)
    {
      let headers = new Headers({'Content-Type':'application/json'});
      let body = new FormData();
      body.append('StateID', this.stateId);
      this.regservice.getCityData(body,headers).subscribe(Response =>
        {
            this.logspinner = false;
            this.citylist =  Response['data'];
        })
    }
    else
    {
      this.cityVal = ''
      this.addressDetailForm.get('city').setValidators(Validators.required);
      this.addressDetailForm.get('city').updateValueAndValidity();
      this.citylist = [];
        
      this.logspinner = false;
    }

}
onSelectState(stateId)
{
    //this.logspinner = true;
    this.stateId = stateId;
    if(this.stateId)
    {
      let headers = new Headers({'Content-Type':'application/json'});
      let body = new FormData();
      body.append('StateID', this.stateId);
      this.regservice.getCityData(body,headers).subscribe(Response =>
        {
            //.logspinner = false;
            this.citylist =  Response['data'];
        })
    }
    else
    {
        this.citylist = [];
        //this.logspinner = false;
    }

}

getCountryDetails()
{
    let headers = new Headers({'Content-Type':'application/json'});
    this.regservice.getCountryData(headers).subscribe(Response =>
      {
        this.countrylist = Response['data']
      })
} 
EditAboutUsHash()
{
   let headers = new Headers({'Content-Type' : 'Application/json'})
   let body = new FormData
  
   let editform = this.aboutForForm.get('aboutForm').value
    if(editform != '')
    {
   body.append('MemberID',localStorage.getItem('UserMemberID'))
   body.append('MembAboutus',this.aboutForForm.get('aboutForm').value)

   return this.updatePro.EditAboutUsPost(body,headers).subscribe(Responce =>{
     this.changeBodyClass();
     this.closeModel()
     let aditpro = Responce['message'];
    //  $("#memberAboutid").val(editform);
    //  $("#aboutidtxt").text(editform);
     this.getAboutUs = localStorage.setItem('UserAboutUs',editform);
     this.userProfileData.MemberAboutMe = editform;
     Swal.fire({
      title : 'Success', 
      text: aditpro, 
      icon : 'success',
      showConfirmButton : false,
      timer : 1500});
      //this.logspinner = false;
    })
  }
  else
  {
    this.isSubmitAbout = true
  }

}
EditPersonalDetailsHash()
{
  //this.logspinner = true;
  this.isSubmitPersonal = true;
  if(!this.personalDetailsForm.valid)
  {
    return false
  }
  else
  {
  let headers = new Headers({'Content-Type' : 'Application/json'})
  let body = new FormData
   
  let userId = this.personalDetailsForm.get('ProfileIdval').value
  let MemberName = this.personalDetailsForm.get('FullName').value
  let MemberHeight = this.personalDetailsForm.get('height').value
  let MemberBTime = this.personalDetailsForm.get('birthTime').value
  let MemberBPlace = this.personalDetailsForm.get('birthPlace').value
  let MemberMTongue = this.personalDetailsForm.get('motherTounge').value
  let MemberComplexion = this.personalDetailsForm.get('Complexion').value
  let MemberBloodgroup = this.personalDetailsForm.get('bloodGroup').value
  let memberBloodGroup = MemberBloodgroup.split('|||')
  let memberBloodGroupDisplay = memberBloodGroup[0]
  let MemberDisability = this.personalDetailsForm.get('disability').value
  
  if(MemberDisability=="Yes") 
  {
      this.disabilityFeild = "Physical Disability";
  }
  else
    {
       this.disabilityFeild = "Normal";
    }
  let heightIdval = MemberHeight.split('|||');
  let MemberBloodCode =  MemberBloodgroup.split('|||');
  body.append('MemberID',localStorage.getItem('UserMemberID'))
  body.append('MemberName',MemberName)
  body.append('MemberHeight',heightIdval[0])
  body.append('MemberBTime',MemberBTime)
  body.append('MemberBPlace',MemberBPlace)
  body.append('MemberMTongue',MemberMTongue)
  body.append('MemberComplexion',MemberComplexion)
  body.append('MemberBloodgroup',MemberBloodCode[0])
  body.append('MemberDisability',this.disabilityFeild)
  body.append('MemberHeightCode',heightIdval[1])
  body.append('MemberBloodCode',MemberBloodCode[1])

  
  return this.updatePro.EditPersonalDetailsPost(body,headers).subscribe(responce =>{
    console.log("HEYYY"+responce['message'])
    let personalResponce = responce['message']
    $('#personalDetailsEdit').css("display", "none").removeClass("in");
    //this.closeModelPersonal()
    $('#personName').text(": "+MemberName)
    let memberHeightSplit = MemberHeight.split('|||')
    let memberHeightDisplay = memberHeightSplit[2]
    $('#personHeight').text(": "+memberHeightDisplay)
    $('#personBithTime').text(": "+MemberBTime)
    $('#personBirthPlace').text(": "+MemberBPlace)
    $('#personMotherTounge').text(": "+MemberMTongue)
    $('#personComplexion').text(": "+MemberComplexion)
    $('#personBloodGroup').text(": "+memberBloodGroupDisplay)
    $('#personDisability').text(": "+MemberDisability);
   // this.logspinner = false;
   this.changeBodyClass();
   this.closeModelPersonal();


    Swal.fire({
      title : 'Success', 
      text: personalResponce, 
      icon : 'success',
      showConfirmButton : false,
      timer : 1500});
      //this.logspinner = false;
    })
  }
}

editGender()
{
  let editGender = "Please contact customer care to edit the gender details."
  Swal.fire({
    text: editGender, 
    showConfirmButton : false,
    timer : 1500
  });
}
editDateOfBirth()
{
  let editDOB ="Please contact customer care to edit date of birth details."
  Swal.fire({
    text: editDOB, 
    showConfirmButton : false,
    timer : 1500
  });
}
editAge()
{
  console.log("hi")
}
editMarritalStatus()
{
  let editMarritalSatatus = "Please contact customer care to edit marrital status."
  Swal.fire({
    text: editMarritalSatatus, 
    showConfirmButton : false,
    timer : 1500
  });
}
EditReligionDetailsHash()
{
   this.issubmitReligion = true;
   if(!this.religionDetailForm.valid)
   { 
      return false;
   }
   else
   {
   let headers = new Headers({'Content-Type' : 'Application/json'})
   let body = new FormData
  
   let religionVal = this.religionDetailForm.get('religion').value
   let memberReligionVal = religionVal.split('|||')
   let memberReligionDisPlay = memberReligionVal[1]
   
   let casteVal = this.religionDetailForm.get('cast').value
   let memberCastVal = casteVal.split('|||')
   let memberCastDisplay = memberCastVal[1]
  
   let subCasteVal = this.religionDetailForm.get('subCast').value
   let memberSubCast = subCasteVal.split('|||')
   let subcastDisplay = memberSubCast[1]
  
   let gotraVal = this.religionDetailForm.get('gotra').value
  
   let raasVal = this.religionDetailForm.get('raas').value
  
   let charanVal = this.religionDetailForm.get('charan').value
   
   let ganaVal = this.religionDetailForm.get('gana').value
   
   let nakshtraVal = this.religionDetailForm.get('nakshtra').value
  
   let nadiVal = this.religionDetailForm.get('nadi').value
  
   let mangalDoshVal = this.religionDetailForm.get('mangalDosh').value
   
  
   body.append('MemberID',localStorage.getItem('UserMemberID'))
   body.append('MemberSubCastID',subCasteVal)
   body.append('MemberGothra',gotraVal)
   body.append('MemberRaas',raasVal)
   body.append('MemberCharan',charanVal)
   body.append('MemberGana',ganaVal)
   body.append('MemberNakshtra',nakshtraVal)
   body.append('MemberDosh',mangalDoshVal)
   body.append('MemberInterCasteFlag','0')
   body.append('MemberNadi',nadiVal)
   return this.updatePro.EditReligionDetailsPost(body,headers).subscribe(religionResult =>{
   console.log("asdfg"+religionResult['message']);
   
   this.closeModelReligion()
   $('#personReligion').text(": "+memberReligionDisPlay)
   $('#personCaste').text(": "+memberCastDisplay)
   $('#personSubCaste').text(": "+subcastDisplay)
   $('#personGotra').text(": "+gotraVal)
   $('#personRaas').text(": "+raasVal)
   $('#perosnCharan').text(": "+charanVal)
   $('#personGana').text(": "+ganaVal)
   $('#personNakshtra').text(": "+nakshtraVal)
   $('#personNadi').text(": "+nadiVal)
   if(this.mangalDoshVal == 0)
   {
    $('#personDosh').text(": "+'No')
   }
   else
   {
    $('#personDosh').text(": "+'Yes')
   }

   this.changeBodyClass();
   let editReligion = religionResult['message']
   Swal.fire({
    title : 'Success', 
    text: editReligion, 
    icon : 'success',
    showConfirmButton : false,
    timer : 1500});
  })
}
}

onContactKeyUp(e)
{
  
   let contactkey = e.target.value
   if(contactkey.length >= 0)
   {
      $('#contactTitle').removeClass('hideContactTitle')
   }
}

EditContactDetailsHash()
{
  
  if(!this.contactDetailForm.valid)
  {
    return false
  }
  else
  {
  let headers = new Headers({'content-type' : 'application/json'})
  let body = new FormData

  let mobileNumber = this.contactDetailForm.get('contact').value
  let emailId = this.contactDetailForm.get('emailId').value
  if(this.userProfileData.MemberContactNo != mobileNumber)
  {
  body.append('ChkEmailID',mobileNumber)
  body.append('ChkContactNo',emailId)
  return this.EditContactEmail.checkExitRegistrationPost(body,headers).subscribe(EditContact =>{
    this.closeModelContact()
    if(EditContact['status'] == 0)
    {
      let SweetMsg = "Mobile is already exit"
      Swal.fire({
        title : 'Error', 
        text: SweetMsg, 
        icon : 'error',
        showConfirmButton : false,
        timer : 1500
      });
    }
    else if(EditContact['status'] == 1 && EditContact['message'] != 'Success')
    {

      let SweetMsg = EditContact['message'] ;
      this.changeBodyClass();
      Swal.fire({
        title : 'Error', 
        text: SweetMsg, 
        icon : 'error',
        showConfirmButton : false,
        timer : 1500
      });

    }
    else
    {
      let headers = new Headers({'content-type' : 'application/json'})
      let body = new FormData
    
      let memId = this.userProfileData.MemberID
      let memOldContactNo = this.userProfileData.MemberContactNo 
      let memConactNo = this.contactDetailForm.get('contact').value
      
      body.append('MemberID',memId)
      body.append('MemberContactNo',this.contactDetailForm.get('contact').value)
      body.append('MemberOldContactno',memOldContactNo)
      this.EditContactEmail.EditContactNoPost(body,headers).subscribe(checkContact =>{
        console.log("hello"+checkContact['message'])
        
       
        let memProfileId = localStorage.getItem("UserProfileID")
        let memUserId = localStorage.getItem("userid")
        
        this.changeBodyClass();
        this.closeModelContact()
        
         localStorage.clear();

         localStorage.setItem("userprofileid",memProfileId);
         localStorage.setItem("userid",memUserId);

        this.redirectToOTP();
      })
       
    }
  })
}
else
{

}
}
}
redirectToOTP()
{
  let link = ['/confirm-otp'];
  this.route.navigate(link);
}
EditAddressDetailsHash()
{
 
  this.issubmitaddress = true;
  if(!this.addressDetailForm.valid)
  {
    return false;
  }
  else
  {
  let headers = new Headers({'content-type' : 'application/json'})
  let body = new FormData

  let address = this.addressDetailForm.get('address').value
  
  let state = this.addressDetailForm.get('state').value
  let stateSplit = state.split('|||')
  let stateDisplay = stateSplit[1]
 
  let taluka = this.addressDetailForm.get('taluka').value
  
  let city = this.addressDetailForm.get('city').value
  let citySplit = city.split('|||')
  let cityDisplay = citySplit[1]
  
  body.append('MemberID',localStorage.getItem('UserMemberID'))
  body.append('MemberAddress',address)
  body.append('MemberStateId',stateSplit[0])
  body.append('MemberCityId',city)
  body.append('MemberTaluka',taluka)

  return this.updatePro.EditAdressDetailsPost(body,headers).subscribe(addressRes =>{
    console.log("zxc"+addressRes['message'])
    this.closeModelAddress()
    $('#personAddress').text(": "+address)
    $('#personState').text(": "+stateDisplay)
    $('#personTaluka').text(": "+taluka)
    $('#personCity').text(": "+cityDisplay)
    let addressEdit = addressRes['message'];
    this.changeBodyClass();
    Swal.fire({
      title : 'Success', 
      text: addressEdit, 
      icon : 'success',
      showConfirmButton : false,
      timer : 1500});
  })
}
}
EditProfessionalDetailsHash()
{

   this.issubmitProfession = true;
   if(!this.professionalDetailForm.valid)
   {
     return false;
   }
   else
   {
   let headers = new Headers({'Content-Type' : 'Application/json'})
   let body = new FormData
   let educationtxt = this.professionalDetailForm.get('educationtxt').value
   let otherEducation = this.professionalDetailForm.get('otherEducation').value
   let educationSplit = educationtxt.split('|||');
   let educationId = educationSplit[0];
   let educationName = educationSplit[1];
  let educationGroupId = educationSplit[2];
  let educationGroup = educationSplit[3];
  
   let employeein = this.professionalDetailForm.get('employeein').value
   let employeeinSplit = employeein.split('|||')
   
   let occupation = this.professionalDetailForm.get('occupation').value
   let occupationSplit = occupation.split('|||')
   let OccupID = occupationSplit[0]
   let occupationName = occupationSplit[1]
   let OccupGroupID = occupationSplit[2]
   let OccupGroup = occupationSplit[3]
  
   let jobcountry = this.professionalDetailForm.get('jobcountry').value
   let jobCountryId = "" ;
   let jobCountryName = "" ;
   if(jobcountry != null)
   {
    let jobCountrySplit = jobcountry.split('|||')
     jobCountryId = jobCountrySplit[0]
     jobCountryName = jobCountrySplit[1]
   }
   else
  {
    jobCountryId = "" ;
    jobCountryName = "" ;
  }
   
   let jobcity = this.professionalDetailForm.get('jobcity').value
   
   let income = this.professionalDetailForm.get('income').value
   

   body.append('MemberID',localStorage.getItem('UserMemberID'))
   body.append('MemberEducationGroup',educationGroup)
   body.append('MemberOtherEducation',otherEducation)
   body.append('MemberHigestEducation',educationName)
   body.append('MemberEmployedIn',employeeinSplit[0])
   body.append('MemberOccupGroup',OccupGroup)
   body.append('MemberOccupation',occupationName)
   body.append('MemberJobCountryId',jobCountryId)
   body.append('MemberJobCity',jobcity)
   body.append('MemberAnnualIncome',income)
   body.append('MemberEduGroupID',educationGroupId)
   body.append('MemberEduID',educationId)
   body.append('MemberOccupGroupID',OccupGroupID)
   body.append('MemberOccupID',OccupID)

   return this.updatePro.EditProfessionalDetailsPost(body,headers).subscribe(editpro =>{
     console.log("pool"+editpro['message']);
     this.changeBodyClass();
     this.closeModelProfessional()
      $('#personEducation').text(": "+educationGroup)
      $('#personOtherEducation').text(": "+otherEducation)
      $('#personEmployeeIn').text(": "+employeeinSplit[0])
      $('#personOccupation').text(": "+occupationName)
      $('#personJobCountry').text(": "+jobCountryName)
      $('#personJobCity').text(": "+jobcity)
      $('#personIncome').text(": "+income)
     let professinalRes = editpro['message']
     Swal.fire({
      title : 'Success', 
      text: professinalRes, 
      icon : 'success',
      showConfirmButton : false,
      timer : 1500});
   })
  }
}
EditFamilylDetailsHash()
{
   this.issubmitFamily = true;
   if(!this.familyDetailForm.valid)
   {
     return false;
   }
   else
   {
   let headers = new Headers({'content-type' : 'application/json'})
   let body = new FormData
   let fatherName = this.familyDetailForm.get('fatherName').value
   
   let fatherOccupation = this.familyDetailForm.get('fatherOccupation').value
   
   let fatherContact = this.familyDetailForm.get('fatherContact').value
   
   let motherName = this.familyDetailForm.get('motherName').value
   
   let motherOccupation = this.familyDetailForm.get('motherOccupation').value
   
   let brother = this.familyDetailForm.get('brother').value
   
   let brotherMarried = this.familyDetailForm.get('brotherMarried').value
   
   let sister = this.familyDetailForm.get('sister').value
   
   let sisterMarried = this.familyDetailForm.get('sisterMarried').value
   
   let familyStatus = this.familyDetailForm.get('familyStatus').value
   
   let familyType = this.familyDetailForm.get('familyType').value
  
   let familyValue = this.familyDetailForm.get('familyValue').value
   
   let relatives = this.familyDetailForm.get('relatives').value
  

   body.append('MemberID',localStorage.getItem('UserMemberID'))
   body.append('MemberFatherName',fatherName)
   body.append('MemberFOccupation',fatherOccupation)
   body.append('MemberFContactNo',fatherContact)
   body.append('MemberMotherName',motherName)
   body.append('MemberMOccupation',motherOccupation)
   body.append('MemberBNos',brother)
   body.append('MemberBMarried',brotherMarried)
   body.append('MemberSNos',sister)
   body.append('MemberSMarried',sisterMarried)
   body.append('MemberFamilyStatus',familyStatus)
   body.append('MemberFamilyType',familyType)
   body.append('MemberFamilyValue',familyValue)
   body.append('MemberRelative',relatives)
   
   return this.updatePro.EditFamilyDetailsPost(body,headers).subscribe(familyRes =>{
     console.log("mnb"+familyRes['message'])
     
          $('#personFatherName').text(": "+fatherName)
          $('#personFatherOccupation').text(": "+fatherOccupation)
          $('#personGuardianNo').text(": "+fatherContact)
          $('#personMotherName').text(": "+motherName)
          $('#personMotherOccupation').text(": "+motherOccupation)
          $('#personBrotherNo').text(": "+brother)
          $('#personBrotherMarried').text(": "+brotherMarried)
          $('#personSisterNo').text(": "+sister)
          $('#personSisterMarried').text(": "+sisterMarried)
          $('#personFamilyStatus').text(": "+familyStatus)
          $('#personFamilyType').text(": "+familyType)
          $('#personFamilyValue').text(": "+familyValue)
          $('#personRelatives').text(": "+relatives)
     let editFamily = familyRes['message'];
     this.changeBodyClass();
     this.closeModelFamily();

     Swal.fire({
      title : 'Success', 
      text: editFamily, 
      icon : 'success',
      showConfirmButton : false,
      timer : 1500});
   })
  }
}

openMOdel()
{

  $('#proEditAbout').css("display", "block",).addClass("in");
  $('body').toggleClass("modal-open");
}

closeModel(){
  $('#proEditAbout').css("display", "none",).removeClass("in");
  $('body').removeClass('modal-open');
}

openMOdelPersonal()
{
  $('#personalDetailsEdit').css("display", "block",).toggleClass("in"); 
  $('body').toggleClass("modal-open");
}
closeModelPersonal()
{
  $('#personalDetailsEdit').css("display", "none",).removeClass("in");
  $('body').removeClass('modal-open');
}


openMOdelReligion()
{
  $('#rligionDetailsEdit').css("display", "block").toggleClass("in");
  $('body').toggleClass("modal-open");
}

closeModelReligion()
{
  $('#rligionDetailsEdit').css("display", "none",).removeClass("in");
  $('body').removeClass('modal-open');
}

openMOdelContact()
{
  $('#contactDetailsEdit').css("display", "block",).toggleClass("in");
  $('body').toggleClass("modal-open");
}

closeModelContact()
{
  $('#contactDetailsEdit').css("display", "none",).removeClass("in");
  $('body').removeClass('modal-open');
}

openMOdelAddress()
{
  $('#addressDetailsEdit').css("display", "block",).toggleClass("in"); 
  $('body').toggleClass("modal-open");
}

closeModelAddress()
{
  $('#addressDetailsEdit').css("display", "none",).removeClass("in");
  $('body').removeClass('modal-open');
}

openMOdelProfessional()
{
  $('#professionalDetailsEdit').css("display", "block",).toggleClass("in"); 
  $('body').toggleClass("modal-open");
}

closeModelProfessional()
{
  $('#professionalDetailsEdit').css("display", "none",).removeClass("in");
  $('body').removeClass('modal-open');
}

openMOdelFamily()
{
  $('#familyDetailsEdit').css("display", "block",).toggleClass("in"); 
  $('body').toggleClass("modal-open");
}

closeModelFamily()
{
  $('#familyDetailsEdit').css("display", "none",).removeClass("in");
  $('body').removeClass("modal-open");
} 

}

