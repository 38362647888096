import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { images } from '../data/image.data';
import {HttpClient} from '@angular/common/http';
import { WebsiteConstants } from '.././global/website-constants';

@Injectable()
export class ImageService {

  setGroupFilter$ = new Subject<any>();
  getGroupFilter = this.setGroupFilter$.asObservable();
  private masterUrl = WebsiteConstants.MasterUrl;
  constructor(private http : HttpClient) {}
  private siteurl=this.masterUrl;
  userphotogallary = this.siteurl+"GetMultiplePhotosforGallery/GetMultiplePhotosforGallery";

  // fetchImages(): Observable<any> {
  //   return of(images);
  // }

  fetchImages(body,headres)
  {
    return this.http.post(this.userphotogallary,body,headres)
  }


}