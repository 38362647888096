import { Injectable } from '@angular/core';
import {HttpClient, HttpHandler, HttpHeaders} from '@angular/common/http';
import { Observable,of } from 'rxjs';
import { catchError} from 'rxjs/Operators';
import { retry } from 'rxjs/internal/operators/retry';
import { WebsiteConstants } from '.././global/website-constants';
@Injectable({
  providedIn: 'root'
})
export class MymatchesService {

  private masterUrl = WebsiteConstants.MasterUrl;
  private siteurl=this.masterUrl;
  private MyMatchesUrl = this.siteurl+"DashboardSrtDetails/DashMyMatchesSrt";
  private MyMessagecntUrl = this.siteurl+"GetMailBoxCnt/GetMailBoxCnt";
  private MessageClearcntUrl = this.siteurl+"GetClearMailBoxCnt/GetClearMailBoxCnt";

  constructor(private http : HttpClient) { }
  mymachesurl(body, headres)
  {
     return this.http.post(this.MyMatchesUrl,body, headres)
  }

  mymessagecount(body,headers)
  {
    return this.http.post(this.MyMessagecntUrl,body, headers)
  }

  messageclearcount(body,headers)
  {
    return this.http.post(this.MessageClearcntUrl,body, headers)
  }

}
