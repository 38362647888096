import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { WebsiteConstants } from '.././global/website-constants';
@Injectable({
  providedIn: 'root'
})
export class InboxRequestService {

private masterUrl = WebsiteConstants.MasterUrl; 
pendingRequestUrl = this.masterUrl+"PendingRequest/PendingRequest";
updateRequestUrl = this.masterUrl+"UpdateReqInterest/UpdateReqInterest";
ShowAcceptDeclinedRequestUrl=this.masterUrl+"ShowAcceptDeclinedRequest/ShowAcceptDeclinedRequest";
ShowSentRequestdetailsUrl=this.masterUrl+"ShowSentRequestdetails/ShowSentRequestdetails";

  constructor(private http : HttpClient) { }
  pendingRequestPost(body,headers)
  {
    return this.http.post(this.pendingRequestUrl,body,headers)
  }
  updateRequestPost(body,headers)
  {
    return this.http.post(this.updateRequestUrl,body,headers)
  }
  ShowAcceptDeclinedRequestPost(body,headers)
  {
    return this.http.post(this.ShowAcceptDeclinedRequestUrl,body,headers)
  }
  ShowSentRequestdetailsPost(body,headers)
  {
    return this.http.post(this.ShowSentRequestdetailsUrl,body,headers)
  }
}
