
<div class="dashboardMain" id="activeButtDIV">
  <div class="slidebarOuter">
      <div class="slidebar">
<div class="overDiv overDivBrows">
            <div class="contain animated bounce">
            <div class="upPicOuter">
              <label class=newbtn>
                  <img id="blah2" [src]='profilePic' >
                  <i class="fa fa-pencil pencilUpPic"></i>
              </label>
            </div>
        </div>
        <p class="proname">{{ProfileName}}</p>
        <p class="proname pronameID">({{ProfileId}})</p>
    </div>
    <ul>
      <li class="upgrdOut"><a class="upgrd" href="#upgradeView"><i class="fa fa-upgrade"></i>Upgrade to Membership</a></li>
      <li><a href="#dashboard" id="targeted"><i class="fa fa-filter"></i>Advance Search</a></li>
      <li><a href="#descoverMatches"><i class="fa fa-binoculars"></i>My Descover Matches</a></li>
      <li><a href="#updateProfile"><i class="fa fa-user-plus"></i>Update Profile</a></li>
      <li><a href="#editPreference"><i class="fa fa-edit"></i>Edit Preference</a></li>
      <li><a href="#photoGallery"><i class="fa fa-photo"></i>Photo Gallery</a></li>
      <li><a href="#" data-toggle="modal" data-target="#raeUsM"><i class="fa fa-star-half-full"></i>Rate Us</a></li>
      <li id="Drsettings"><a><i class="fa fa-cogs"></i>Settings<i class="fa fa-chevron-down" style="    float: right; line-height: 36px;"></i></a>
          <ul class="dropSetting">
            <li><a href="#changPass">Change Password</a></li>
            <li><a data-toggle="modal" data-target="#deleteAc">Delete Account</a></li>
            <li><a href="#termsAcond">Terms & Conditions</a></li>
            <li><a href="#privacyPoli">Privacy Policy</a></li>
          </ul>
      </li>
      <li><a href="#help"><i class="fa fa-question-circle"></i>Help</a></li>
      <li><a href="Javascript:void(0)"><i class="fa fa-power-off"></i>Logout</a></li>
    </ul>
  </div>
  <div id="slideRight"><div class="fa fa-chevron-left desktopArrow rotate"></div><div class="fa fa-chevron-right responssiveArrow rotate"></div></div>
</div>
    <div class="admin-panel clearfix">
  <div class="main">
            <div class="slideOverly"></div>
            <ul class="topbar clearfix dashTopIcon">
              <a><img src="assets/images/br-logo3.png" class="dashLogo" alt="img"></a>
              <li><a href="Javascript:void(0)"><i class="fa fa-power-off"></i></a></li>
              <li><a href="#notification"><i class="fa fa-bell"></i></a></li>
              <li><a href="#mail"><i class="fa fa-envelope"></i></a></li>
              <li><a href="#newMatche"><i class="fa fa-home"></i></a></li>
              <li class=""><a href="#mysearch"><i class="fa fa-search"></i></a></li>
              <li class="proId">({{ProfileId}})</li>
            </ul>


            <div class="subMenu">
              <div class="custmSearch"><input class="autoSearchClick" type="text" placeholder="Search by Profile ID or Name" name=""><i class="fa fa-search subSearchBT"></i>
                <div class="autoSearchDiv">
                  <h4>Recent Searches</h4>
                  <div class="cityBt searchAutoBt">Deshpande</div>
                  <div class="cityBt searchAutoBt">Br2322</div>
                  <div class="cityBt searchAutoBt">Br2277</div>
                  <div class="cityBt searchAutoBt">Londhe</div>
                  <div class="autoSearchViewPro">
                    <ul>
                      <li><div class="autoSePro"><img src="assets/images/matches2.jpg"></div>Aishwarya Deshpande</li>
                      <li><div class="autoSePro"><img src="assets/images/matches2.jpg"></div>Vaishnavi Deshpande</li>
                      <li><div class="autoSePro"><img src="assets/images/matches2.jpg"></div>Pratiksha Deshpande</li>
                    </ul>
                  </div>
                  <div class="extraSearch">
                    <span class="searchFo"><a href="#">Search For</a></span><span class="searchFo searchFoLeft"><a href="#dashboard" class="adSearch">Advance Search</a></span>
                    <div class="extraSearhIn">
                      <h4>Discover Preferrd Matches By</h4>
                      <div class="extSeOut">
                        <div class="extSeIn"><i class="fa fa-map-marker"></i></div>
                        <span>Location</span>
                      </div>
      
                      <div class="extSeOut">
                        <div class="extSeIn" style="background-color: #65cafb !important"><i class="fa fa-suitcase"></i></div>
                        <span>Profession</span>
                      </div>
      
                      <div class="extSeOut">
                        <div class="extSeIn" style="background-color: #e250e7 !important">
                          <i class="fa fa-graduation-cap"></i>
                        </div>
                        <span>Education</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <header class="newheader">
                <input class="menu-btn" type="checkbox" id="menu-btn" />
                <label class="menu-icon" for="menu-btn">More Matches<i class="fa fa-chevron-down"></i><span class="navicon"></span></label>
                <ul id="topNavBar" class="menu">
                  <a href="/"><img src="assets/images/br-logo3.png" class="dashLogo2" alt="img"></a>
                  <li><a href="Javascript:void(0)" id="mymatchid">My Matches</a></li>
                  <li><a href="Javascript:void(0)" id="mynewmatchid">New Matches</a></li>
                  <li class="dropClick"><a href="Javascript:void(0)" id="nearmeid">Near Me</a>
                    
                  </li>
                  <li><a href="Javascript:void(0)" id="shortlistedid">Shortlisted</a></li>
                  <li><a href="Javascript:void(0)" id="viewmyprofileid">Viewed My Profile</a></li>
                  <li><a href="Javascript:void(0)" id="shortlistedmeid">Shortlisted Me</a></li>
                </ul>
              </header>
            </div> 



           


            <div id="myMatche" class="myMatchesStyle" >
              <div class="search-reault" 
      infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
      (scrolled)="onScroll()">

      <div class="mainContent clearfix">
              <div class="samplecover-spin" id="samplecover-spin" *ngIf="logspinner"></div>
               <div class="dashMainCont" *ngIf="!logspinner">
                 <div class="dashMainInner">
                    <div class="matchesInner">
                      <div class="row">
      
                        
                        
                    <div *ngFor="let responceData of mymatchesresult"> 
                        <div class="col-md-4">
                          <div class="matchesDiv">
                              <div class="ignorBt">Ignore <i class="fa fa-close"></i></div>
                              <div class="matchesPic">
                                <div class="coun">{{responceData.MemberCNtMultiplePhotos}}</div>
                                <div class="leftA"> <i class="fa fa-angle-left"></i> </div>
                                <div class="rightA"> <i class="fa fa-angle-right"></i> </div>
                                  <img [src]='photopath+responceData.MemberPhotoPath'>
                                  <div *ngIf="responceData.MemberIsPhotoUpload === '0'">
                                  <button class="requestBtUp">Request Photo</button>
                                </div>
                                  <i class="fa fa-camera phoIco"></i>
                              </div>
                              <div class="callDetais">
                                  <div class="floatNeed">
                                  <span data-tooltip="Call Now"><i class="fa fa-phone phoneIc" data-toggle="modal" data-target="#callModal"></i></span>
                                  <span data-tooltip="Sent Intrest"><i class="fa fa-heart heartIc likeIntro" *ngIf="responceData.MemberSentInterestFlag !== '0'"></i><i class="fa fa-heart heartIc" *ngIf="responceData.MemberSentInterestFlag == '0'"></i></span>
                                  <span data-tooltip="Shortlisted">
                                    <i class="fa fa-star starIc shortLis" *ngIf="responceData.MemberShortlistFlag !== '0'"></i>
                                    <i class="fa fa-star starIc" *ngIf="responceData.MemberShortlistFlag == '0'"></i>
                                  </span>
                                  </div>
      
                                  <div class="detailsOuter">
                                  <h4 class="callDetaillsName">{{responceData.MemberName}} ({{responceData.MemberProfileID}})</h4>
                                  <p>{{responceData.MemberEducation}}, Job-City - {{responceData.MemberJobCity}}</p>
                                  <p>{{responceData.MemberAge}} yrs, {{responceData.MemberHeight}} | {{responceData.MemberMartialStatus}}</p>
                                  <p>{{responceData.MemberCaste}}, {{responceData.MemberNativePlace}} </p>
                                </div>
                                  
                                  <button type="button" class="proDetailsBt" (click)="memberdetailspost(responceData)">
                                    View Profile
                                  </button>
                              </div>
                          </div>
                      </div>
                    </div>
        </div>
      
                      
      
      
      
                      </div>
                    </div>
                 </div>
               </div>
            </div>

          </div>







    </div>
</div>
</div>    
<div class="samplecover-spin" id="samplecover-spin" *ngIf="spinner"></div>

