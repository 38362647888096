<!-- <div class="spinner">
    <p>l</p>
    <p>o</p>
    <p>a</p>
    <p>d</p>
    <p>i</p>
    <p>n</p>
    <p>g</p>
  </div> -->

  <div id="samplecover-spin"></div>
  