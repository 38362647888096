import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler, HttpHeaders} from '@angular/common/http';
import { retry } from 'rxjs/internal/operators/retry';
import { WebsiteConstants } from '.././global/website-constants';

@Injectable({
  providedIn: 'root'
})
export class ProfilematchService {

  private masterUrl = WebsiteConstants.MasterUrl;
  private siteurl=this.masterUrl;
  private profilematchurl = this.siteurl+"DashboardNearYou/DashboardNearYou";
  constructor(private  http : HttpClient) { }
  profilematch(body,Headers)
  {
     return this.http.post(this.profilematchurl,body,Headers)
  }
}
