import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fail-payment',
  templateUrl: './fail-payment.component.html',
  styleUrls: ['./fail-payment.component.css']
})
export class FailPaymentComponent implements OnInit {

  logspinner:any=true;

  constructor() { }

  ngOnInit(): void {
    this.logspinner=false;
  }

}
