
<div class="dashboardMain" id="activeButtDIV">
    
  <app-left-menu></app-left-menu>    
  <div class="admin-panel clearfix">
    <div class="main">
              
  
              <app-top-menu></app-top-menu>
  
             
  
  
              <div id="myMatche" class="myMatchesStyle" >
                
        
        

  
        <div class="mainContent clearfix">
                <div class="samplecover-spin" id="samplecover-spin" *ngIf="logshortlistmespinner"></div>
                 <div class="dashMainCont" *ngIf="!logshortlistmespinner">
                   <div class="dashMainInner">
                      <div class="matchesInner">
                        
                        <div class="row">
        
                          
                         <div *ngIf="shortlistmedash ?.length > 0"> 
                      <div *ngFor="let shortlistedmeData of shortlistmedash"> 
                          <div class="col-md-4">
                            <div class="matchesDiv">
                                <!-- <div class="ignorBt">Ignore <i class="fa fa-close"></i></div> -->
                                <div class="matchesPic">
                                  <div class="coun" *ngIf="shortlistedmeData.MemberCNtMultiplePhotos > 0">{{shortlistedmeData.MemberCNtMultiplePhotos}}</div>
                                  <div class="leftA"> <i class="fa fa-angle-left"></i> </div>
                                  <div class="rightA"> <i class="fa fa-angle-right"></i> </div>
                                    <img [src]='photopath+shortlistedmeData.MemberPhotoPath'>
                                    <div *ngIf="shortlistedmeData.MemberIsPhotoUpload == '0' && shortlistedmeData.MembPhotoAddFlag== '0'">
                                      <button class="requestBtUp" id="requestphoto{{shortlistedmeData.MemberID}}"(click)="photoRequestDash($event.target,shortlistedmeData)">Request Photo</button>
                                    </div>
    
                                    <div *ngIf="shortlistedmeData.MemberIsPhotoUpload == '0' && shortlistedmeData.MembPhotoAddFlag== '1'">
                                      <button class="requestBtUp" id="requestphoto{{shortlistedmeData.MemberID}}"disabled>Photo Request Sent</button>
                                    </div>
                                    <i class="fa fa-camera phoIco" *ngIf="shortlistedmeData.MemberCNtMultiplePhotos > 0"></i>
                                </div>
                                <div class="callDetais">
                                    <div class="floatNeed">
                                    <span data-tooltip="Call Now"><i class="fa fa-phone phoneIc" (click)="openMOdel(shortlistedmeData,masterUrl)"></i></span>

<span data-tooltip="Sent Interest" *ngIf="shortlistedmeData.MemberSentInterestFlag !== '0'">
<i class="fa fa-heart heartIc likeIntro" id="sentInterst{{shortlistedmeData.MemberID}}" *ngIf="shortlistedmeData.MemberSentInterestFlag !== '0'"></i>
<i class="fa fa-heart heartIc" id="sentrunInterst{{shortlistedmeData.MemberID}}" *ngIf="shortlistedmeData.MemberSentInterestFlag == '0'" (click)="memberInterestlist(shortlistedmeData)"></i>
</span>


<span data-tooltip="Send Interest" *ngIf="shortlistedmeData.MemberSentInterestFlag == '0'">
<i class="fa fa-heart heartIc likeIntro" id="sentInterst{{shortlistedmeData.MemberID}}" *ngIf="shortlistedmeData.MemberSentInterestFlag !== '0'"></i>
<i class="fa fa-heart heartIc" id="sentrunInterst{{shortlistedmeData.MemberID}}" *ngIf="shortlistedmeData.MemberSentInterestFlag == '0'" (click)="memberInterestlist(shortlistedmeData)"></i>
</span>

  
<span data-tooltip="Sent Interest">
<i class="fa fa-heart heartIc hideHeart" id="sentshowInterst{{shortlistedmeData.MemberID}}"></i>
</span>

    <input type="hidden" name="hiddeninterest{{shortlistedmeData.MemberID}}" id="hiddeninterest{{shortlistedmeData.MemberID}}" value="{{shortlistedmeData.MemberSentInterestFlag}}" [(ngModel)]="shortlistedmeData.MemberSentInterestFlag">
    

                                    <span data-tooltip="Shortlist Profile">
                                    <i class="fa fa-star starIc shortLis"  id="shortListID{{shortlistedmeData.MemberID}}" *ngIf="shortlistedmeData.MemberShortlistFlag !== '0'" (click)="membershortlist(shortlistedmeData)"></i>
                                    
                                    <i class="fa fa-star starIc" id="shortListID{{shortlistedmeData.MemberID}}" *ngIf="shortlistedmeData.MemberShortlistFlag == '0'"  (click)="membershortlist(shortlistedmeData)"></i>

                                    <input type="hidden" name="hiddenshort{{shortlistedmeData.MemberID}}" id="hiddenshort{{shortlistedmeData.MemberID}}" value="{{shortlistedmeData.MemberShortlistFlag}}" [(ngModel)]="shortlistedmeData.MemberShortlistFlag">
                                  </span>
                                    </div>
        
                                    <div class="detailsOuter">
                                    <h4 class="callDetaillsName">{{shortlistedmeData.MemberName}} ({{shortlistedmeData.MemberProfileID}})</h4>
                                    <p>{{shortlistedmeData.MemberEducation}}, Job-City - {{shortlistedmeData.MemberJobCity}}</p>
                                    <p>{{shortlistedmeData.MemberAge}} yrs, {{shortlistedmeData.MemberHeight}} | {{shortlistedmeData.MemberMartialStatus}}</p>
                                    <p>{{shortlistedmeData.MemberCaste}}, {{shortlistedmeData.MemberNativePlace}} </p>
                                  </div>
                                    
                                    <button type="button" class="proDetailsBt" (click)="memberdetailspost(shortlistedmeData)">
                                      View Profile
                                    </button>
                                </div>
                            </div>
                        </div>
                      </div>
                      </div>
                      <div *ngIf="shortlistmedash == 0" class="noDataMessage">
                        <img src="../../assets/images/no-data-pana.png"/>
                        {{myMessage}}
                      </div>
          </div>
        
                        
        
        
        
                        </div>
                      </div>
                   </div>
                 </div>
              <!-- </div> -->
  
           
  
  
  
  
  
  </div>
  
      </div>
  </div>
  </div>    
  
  
  <div class="modal fade success-popup in" id="callModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" style=" padding-left: 17px;">
    <div class="modal-dialog modal-sm" role="document">
      <div class="modal-content">
        
        <div class="modal-body text-center">
          <i class="fa fa-close callPopDele" (click)="closePopup()" data-dismiss="modal"></i>
           <div class="calImgDiv" id="callImgDivs">
            <img src="" >
           </div>
           <h4 id="popupName">Contact Akshda Meshra on</h4>
            <p id="popupContact"></p>
        </div>
        <div class="modal-footer" id="popupupgrads">
          
        </div>
      </div>
    </div>
  </div> 



