import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { WebsiteConstants } from '.././global/website-constants';

@Injectable({
  providedIn: 'root'
})
export class DeleteMultiplePhotoService {

  private masterUrl = WebsiteConstants.MasterUrl;
  deleteMultiplePhotoUrl=this.masterUrl+"DelUserphotos/DelUserphotos"
  constructor(private http : HttpClient) { }
  deleteMultiplePhotoPost(body,headers)
  {
    return this.http.post(this.deleteMultiplePhotoUrl,body,headers)
  }
}
