
<div class="dashboardMain" id="activeButtDIV">
  <app-left-menu></app-left-menu>        
  <div class="admin-panel clearfix">
    <div class="main">
      <app-top-menu></app-top-menu>
          <div id="myMatche" class="myMatchesStyle" >
        <div class="mainContent clearfix">
                <div class="samplecover-spin" id="samplecover-spin" *ngIf="viewedmyprofilespinner"></div>
                 <div class="dashMainCont" *ngIf="!viewedmyprofilespinner">
                   <div class="dashMainInner">
                      <div class="matchesInner">
                        <div class="row">
        
                          
                         <div *ngIf="myprofilevar ?.length > 0"> 
                      <div *ngFor="let viewmyprofiledata of myprofilevar"> 
                          <div class="col-md-4">
                            <div class="matchesDiv">
                                <div class="matchesPic">
                                  <div class="coun" *ngIf="viewmyprofiledata.MemberCNtMultiplePhotos > 0">{{viewmyprofiledata.MemberCNtMultiplePhotos}}</div>
                                  <div class="leftA"> <i class="fa fa-angle-left"></i> </div>
                                  <div class="rightA"> <i class="fa fa-angle-right"></i> </div>
                                    <img [src]='photopath+viewmyprofiledata.MemberPhotoPath'>
                                    <div *ngIf="viewmyprofiledata.MemberIsPhotoUpload == '0' && viewmyprofiledata.MembPhotoAddFlag== '0'">
                                      <button class="requestBtUp" id="requestphoto{{viewmyprofiledata.MemberID}}"(click)="photoRequestDash($event.target,viewmyprofiledata)">Request Photo</button>
                                    </div>
    
                                    <div *ngIf="viewmyprofiledata.MemberIsPhotoUpload == '0' && viewmyprofiledata.MembPhotoAddFlag== '1'">
                                      <button class="requestBtUp" id="requestphoto{{viewmyprofiledata.MemberID}}"disabled>Photo Request Sent</button>
                                    </div>
                                    <i class="fa fa-camera phoIco" *ngIf="viewmyprofiledata.MemberCNtMultiplePhotos > 0"></i>
                                </div>
                                <div class="callDetais">
                                    <div class="floatNeed">
                                    <span data-tooltip="Call Now"><i class="fa fa-phone phoneIc" (click)="openMOdel(viewmyprofiledata,masterUrl)"></i></span>

<span data-tooltip="Sent Interest" *ngIf="viewmyprofiledata.MemberSentInterestFlag !== '0'">
<i class="fa fa-heart heartIc likeIntro" id="sentInterst{{viewmyprofiledata.MemberID}}" *ngIf="viewmyprofiledata.MemberSentInterestFlag !== '0'"></i>
<i class="fa fa-heart heartIc" id="sentrunInterst{{viewmyprofiledata.MemberID}}" *ngIf="viewmyprofiledata.MemberSentInterestFlag == '0'" (click)="memberInterestlist(viewmyprofiledata)"></i>
</span>


<span data-tooltip="Send Interest" *ngIf="viewmyprofiledata.MemberSentInterestFlag == '0'">
  <i class="fa fa-heart heartIc likeIntro" id="sentInterst{{viewmyprofiledata.MemberID}}" *ngIf="viewmyprofiledata.MemberSentInterestFlag !== '0'"></i>
  <i class="fa fa-heart heartIc" id="sentrunInterst{{viewmyprofiledata.MemberID}}" *ngIf="viewmyprofiledata.MemberSentInterestFlag == '0'" (click)="memberInterestlist(viewmyprofiledata)"></i>
  </span>


  <span data-tooltip="Sent Interest">
    <i class="fa fa-heart heartIc hideHeart" id="sentshowInterst{{viewmyprofiledata.MemberID}}"></i>
    </span>


    <input type="hidden" name="hiddeninterest{{viewmyprofiledata.MemberID}}" id="hiddeninterest{{viewmyprofiledata.MemberID}}" value="{{viewmyprofiledata.MemberSentInterestFlag}}" [(ngModel)]="viewmyprofiledata.MemberSentInterestFlag">

                                    <span data-tooltip="Shortlist Profile">
                                    <i class="fa fa-star starIc shortLis"  id="shortListID{{viewmyprofiledata.MemberID}}" *ngIf="viewmyprofiledata.MemberShortlistFlag !== '0'" (click)="membershortlist(viewmyprofiledata)"></i>
                                    
                                    <i class="fa fa-star starIc" id="shortListID{{viewmyprofiledata.MemberID}}" *ngIf="viewmyprofiledata.MemberShortlistFlag == '0'"  (click)="membershortlist(viewmyprofiledata)"></i>

                                    <input type="hidden" name="hiddenshort{{viewmyprofiledata.MemberID}}" id="hiddenshort{{viewmyprofiledata.MemberID}}" value="{{viewmyprofiledata.MemberShortlistFlag}}" [(ngModel)]="viewmyprofiledata.MemberShortlistFlag">
                                  </span>
                                    </div>
        
                                    <div class="detailsOuter">
                                    <h4 class="callDetaillsName">{{viewmyprofiledata.MemberName}} ({{viewmyprofiledata.MemberProfileID}})</h4>
                                    <p>{{viewmyprofiledata.MemberEducation}}, Job-City - {{viewmyprofiledata.MemberJobCity}}</p>
                                    <p>{{viewmyprofiledata.MemberAge}} yrs, {{viewmyprofiledata.MemberHeight}} | {{viewmyprofiledata.MemberMartialStatus}}</p>
                                    <p>{{viewmyprofiledata.MemberCaste}}, {{viewmyprofiledata.MemberNativePlace}} </p>
                                  </div>
                                    
                                    <button type="button" class="proDetailsBt" (click)="memberdetailspost(viewmyprofiledata)">
                                      View Profile
                                    </button>
                                </div>
                            </div>
                        </div>
                      </div>
                      </div>
                      <div *ngIf="myprofilevar == 0" class="noDataMessage">
                        <img src="../../assets/images/no-data-pana.png"/>
                        {{myMessage}}
                      </div>
          </div>
        
                        
        
        
        
                        </div>
                      </div>
                   </div>
                 </div>
                
  </div>
  
      </div>
  </div>
  </div>    
  
  
  <div class="modal fade success-popup in" id="callModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" style=" padding-left: 17px;">
    <div class="modal-dialog modal-sm" role="document">
      <div class="modal-content">
        
        <div class="modal-body text-center">
          <i class="fa fa-close callPopDele" (click)="closePopup()" data-dismiss="modal"></i>
           <div class="calImgDiv" id="callImgDivs">
            <img src="" >
           </div>
           <h4 id="popupName">Contact Akshda Meshra on</h4>
            <p id="popupContact"></p>
        </div>
        <div class="modal-footer" id="popupupgrads">
          
        </div>
      </div>
    </div>
  </div>


