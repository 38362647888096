import { Component, OnInit } from '@angular/core';
import { NearmeService } from '../services/nearme.service';
import {ShortlistedProfileIconService} from '../services/shortlisted-profile-icon.service'
import {PhotoRequestService} from '../services/photo-request.service'
import {SendInterestProfileService}  from '../services/send-interest-profile.service'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import * as $ from 'jquery';
import {Router} from '@angular/router';
import { RegistrationService } from '../services/registration.service';
import {NativeCityService} from '../services/native-city.service';
import {JobCountryService} from '../services/job-country.service';
import {MyDiscoverMatchesService} from '../services/my-discover-matches.service'
import { FormBuilder, FormGroup } from '@angular/forms';
import { WebsiteConstants } from '.././global/website-constants';
@Component({
  selector: 'app-profile-preferred-education',
  templateUrl: './profile-preferred-education.component.html',
  styleUrls: ['./profile-preferred-education.component.css']
})
export class ProfilePreferredEducationComponent implements OnInit {
  profilePic : any; ProfileName : any; ProfileId : any; mymatchesresult : any;
  photopath : any; memberdetailsresult:any; logspinner:boolean; prodetailspinner:boolean;
  logshortlistmespinner:boolean; lognearmespinner:boolean;
  profilematchlocation : any; partnerpreference:any; matchprofilepic:any;cityname:any
  shortlistmedash:any; newMatchesdash:any; nearmeval:any; shortlistedval:any; myprofilevar:any;
  logshortlistedspinner:boolean; viewedmyprofilespinner : boolean; newmatchesspinner:boolean;
  mymatchscrollresult:any;myMessage:any; fromLimit:any; toLimit:any; fromLimitnext:any;toLimitnext;
  spinner: any = false; notEmptyPost: boolean =true; notscrolly: any=true;stateId:any
  shorlistedIcon:any;UnshortlistedIcon:any; citylist:any;userIsPaid:any
  countrylist:any; Occupationlist=[];discoverProfession:any;myMessagePro:any
  dropdownList = []; dropdownlistval = []; selectedItems = []; selectedItemshash = [];
  form: FormGroup;selectedItem:string[];newSelectedItem:any;Educationlist=[];discoverEducation:any;myMessageEdu:any
  formval : FormGroup;allProfileLocationRes:any; selectedcheck:any;EducationTitle:any;
  SiteUrl = WebsiteConstants.SiteUrl; 
  sitetitle = WebsiteConstants.SiteTitle; 
  masterUrl = WebsiteConstants.MasterUrl;
  
  constructor(private nearmeservice:NearmeService, private route:Router,private memberShortlisted : ShortlistedProfileIconService,private  requestPhoto : PhotoRequestService,private sendInterest : SendInterestProfileService,private regservice:RegistrationService, private formBuider : FormBuilder,private nativCiry : NativeCityService, private jobCountry : JobCountryService,private discoverMatch : MyDiscoverMatchesService) { }

  ngOnInit(): void {

    this.photopath = this.masterUrl;
    this.profilePic = this.masterUrl+localStorage.getItem("UserPhotoPath");
    this.ProfileName = localStorage.getItem("UserFullName");
    this.ProfileId = localStorage.getItem("UserProfileID");
    this.cityname = localStorage.getItem("UserCityName");
    this.selectedcheck =  localStorage.getItem('UserEduGroupID');
    this.selectedItems.push(this.selectedcheck);
    this.userIsPaid = localStorage.getItem('UserIsPaid')
    
    this.getEducationDetails()
    
  }

  onToggle()
  {
    $(".seleProPont").slideToggle();
  }

  getEducationDetails()
{
    this.lognearmespinner = true
    let headers = new Headers({ 'Content-Type': 'application/json' });
    this.regservice.getEducationData(headers).subscribe(Response =>
    {
        
      
      for(let cnt=0; cnt<Response['data'].length; cnt++)
            {
              console.log(Response['data'][cnt]['Header'])
                if(Response['data'][cnt]['Header'] === "TRUE")
                {
                  this.Educationlist.push(Response['data'][cnt]);
                }

          }
          this.MyDiscoverByQualificationHash()
    })

}

MyDiscoverByQualificationHash()
{  
    let headers = new Headers({'Content-Type' : 'Application/json'})
    let body = new FormData

    body.append('LoggedUserid',localStorage.getItem('UserMemberID'))
    body.append('MembEducationGroup',localStorage.getItem('UserEduGroupID'))

    return this.discoverMatch.MyDiscoverByQualificationPost(headers,body).subscribe(Res =>{
      if(Res['data'] !== undefined)
      {
        this.discoverEducation = Res['data']
        this.myMessageEdu = ""
        this.lognearmespinner = false
      }
      else
      {
        this.discoverEducation = Res['status']
        this.myMessageEdu = Res['message']
        this.lognearmespinner = false
      }
    })

}
 


  getChangedVal(e:any,id:string)
  { 
    this.lognearmespinner = true
     if(e.target.checked)
     {
      if (!this.selectedItems.includes(id)) 
      {
        this.selectedItems.push(id);
      }
        this.newSelectedItem = this.selectedItems.toString()
        console.log(this.newSelectedItem)
        $('#checkmybutton-'+id).addClass('proSeleBtActive');
   
    let headers = new Headers({'Content-Type' : 'Application/json'})
    let body = new FormData

    body.append('LoggedUserid',localStorage.getItem('UserMemberID'))
    body.append('MembEducationGroup',this.newSelectedItem)

    return this.discoverMatch.MyDiscoverByQualificationPost(headers,body).subscribe(Res =>{
      if(Res['data'] !== undefined)
      {
        this.discoverEducation = Res['data']
        this.myMessageEdu = ""
        this.lognearmespinner = false
      }
      else
      {
        this.discoverEducation = Res['status']
        this.myMessageEdu = Res['message']
        this.lognearmespinner = false
      }
    })

        
     }
     else
     {
       if(this.selectedItems.length > 1)
       {
        this.selectedItems = this.selectedItems.filter(m=>m!=id);
        this.newSelectedItem = this.selectedItems.toString()
        console.log(this.newSelectedItem)
        $('#checkmybutton-'+id).removeClass('proSeleBtActive');
  
        let headers = new Headers({'Content-Type' : 'Application/json'})
        let body = new FormData

    body.append('LoggedUserid',localStorage.getItem('UserMemberID'))
    body.append('MembEducationGroup',this.newSelectedItem)

    return this.discoverMatch.MyDiscoverByQualificationPost(headers,body).subscribe(Res =>{
      if(Res['data'] !== undefined)
      {
        this.discoverEducation = Res['data']
        this.myMessageEdu = ""
        this.lognearmespinner = false
      }
      else
      {
        this.discoverEducation = Res['status']
        this.myMessageEdu = Res['message']
        this.lognearmespinner = false
      }
    })

  }
     else
     {
      this.lognearmespinner = false
      this.selectedItems = this.selectedItems.filter(m=>m!=id);
      $('#locationcheck'+id).prop('checked', true);
      if (!this.selectedItems.includes(id)) 
      {
         this.selectedItems.push(id);
      }
      Swal.fire({
        title : 'Warning', 
        text: 'You have to checked atleast one faculty for preferred education', 
        icon : 'warning',
        showConfirmButton : false,
        timer : 1500
      });
     }
    }
     
  }

membershortlist(row)
  {
        var hiddenfield = $("#hiddenshort"+row.MemberID).val();
        if(hiddenfield==0)
        {
           
            let profilename = row.MemberName+', '+row.MemberProfileID;
            let messageforsuccess = 'You have shortlisted Profile '+profilename;
            Swal.fire({
              title : 'Shortlisted', 
              text: messageforsuccess, 
              icon : 'success',
              showConfirmButton : false,
              timer : 1500});

            $("#hiddenshort"+row.MemberID).val(1);
            $('#shortListID'+row.MemberID).addClass('shortLis');

            let headers = new Headers({ 'Content-Type': 'application/json' });
            let body = new FormData();

            body.append('MemberID',localStorage.getItem('UserMemberID'))
            body.append('MembShortlistToID',row.MemberID);
            return this.memberShortlisted.memberShortlistedPost(body,headers).subscribe(shortlistedResponce =>{
            this.shorlistedIcon = shortlistedResponce;
            })
        }
        else
        {

          let profilename = row.MemberName+', '+row.MemberProfileID;
          let messageforsuccess = 'You have Un-shortlisted Profile '+profilename;
          Swal.fire({
            title : 'Un-Shortlisted', 
            text: messageforsuccess, 
            icon : 'success',
            showConfirmButton : false,
            timer : 1500});

            $("#hiddenshort"+row.MemberID).val(0);
          $('#shortListID'+row.MemberID).removeClass('shortLis');

            let headers = new Headers({ 'Content-Type': 'application/json' });
            let body = new FormData();

            body.append('MembShortlistByID',localStorage.getItem('UserMemberID'))
            body.append('MembShortlistToID',row.MemberID)

            return this.memberShortlisted.memberUnShortlistedPost(body,headers).subscribe(unShortlistedResponce =>{
            
            })

        }
    
  }

  photoRequestDash(element,row)
  {
    element.textContent = 'Photo Request Sent';
    element.disabled = true;
   
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let body = new FormData();

    body.append('MemberID',localStorage.getItem('UserMemberID'))
    body.append('MemberName',localStorage.getItem('UserFullName'))
    body.append('MembInterestID', row.MemberID)
    body.append('MembName', row.MemberName)
    body.append('MembIntFlag','photo')
    body.append('MembFreeOrPaid', localStorage.getItem('UserIsPaid'))

    return this.requestPhoto.photoRequestPost(body,headers).subscribe(photorequest =>{
      let msgVal = 'You have sent request for photo to '+row.MemberName;
      
      Swal.fire({
        title : 'Success', 
        text: msgVal, 
        icon : 'success',
        showConfirmButton : false,
        timer : 1500
      });


    })
  }

  sidebar()
  {

    $(document).ready(function()
  {
    $('#slideRight').toggleClass('leftSlide');
    $(".main").toggleClass('mainFulllWidth');
    $(".slidebar").toggleClass('slidebarWidth');
    $(".mainContent>div").toggleClass('mainContentWidth');
    $("#mytoggle").toggleClass('down');

  })

  }





memberInterestlist(row)
  {
    var userapplimit = localStorage.getItem('UserAppInterestLimit');
    var userapplimitval = Number(userapplimit);
    
    if(localStorage.getItem('UserIsPaid')=='0' && userapplimitval >0)
    {
      var usersentintcnt = localStorage.getItem('updateAppInterestLimit');
      var usersentintcntval = Number(usersentintcnt);

        if(usersentintcntval<userapplimitval)
        {
          Swal.fire({
            title: 'Are you sure?',
            text: 'As a free member you can send only '+userapplimitval+' interest to liked profile. Are you sure you want to send interest?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
          }).then((result) => {
            if (result.value) {
              let profilename = row.MemberName+', '+row.MemberProfileID;
              let messageforsuccess = 'Your request has been sent successfully to '+profilename;
              Swal.fire({
                title : 'Sent', 
                text: messageforsuccess, 
                icon : 'success',
                showConfirmButton : false,
                timer : 1500});


            $('#sentrunInterst'+row.MemberID).hide();
            $('#sentshowInterst'+row.MemberID).removeClass('hideHeart');
            $('#sentshowInterst'+row.MemberID).addClass('likeIntro');


          var usersentintcnt = localStorage.getItem('updateAppInterestLimit');
          var usersentintcntval = parseInt(usersentintcnt)+1;
          localStorage.setItem('updateAppInterestLimit',usersentintcntval.toString());

          let headers = new Headers({ 'Content-Type': 'application/json' });
          let body = new FormData();
          body.append('MemberID',localStorage.getItem('UserMemberID'))
          body.append('MembInterestID', row.MemberID)
          body.append('MembIntFlag','Interest')
          body.append('MembFreeOrPaid','0')
          return this.sendInterest.sendInterestPhotoPost(body,headers).subscribe(interestRes =>{
            
          })
            
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            
            }
          })


        }
        else
        {

            let message = 'As a free member, you have already sent '+userapplimitval+' interest to liked profile.';
            Swal.fire({
              title : 'Request didn\'t send', 
              text: message, 
              icon : 'error',
              showConfirmButton : true,
              //timer : 1500
            });
             
        }
    }
    else
    {
      //$('#sentInterst'+row.MemberID).addClass('likeIntro');
      let profilename = row.MemberName+', '+row.MemberProfileID;
      let messageforsuccess = 'Interest sent successfully to '+profilename;
      Swal.fire({
        title : 'Interest Sent', 
        text: messageforsuccess, 
        icon : 'success',
        showConfirmButton : false,
        timer : 1500});

$('#sentrunInterst'+row.MemberID).hide();
$('#sentshowInterst'+row.MemberID).removeClass('hideHeart');
$('#sentshowInterst'+row.MemberID).addClass('likeIntro');

      let headers = new Headers({ 'Content-Type': 'application/json' });
      let body = new FormData();
      body.append('MemberID',localStorage.getItem('UserMemberID'))
      body.append('MembInterestID', row.MemberID)
      body.append('MembIntFlag','Interest')
      body.append('MembFreeOrPaid','1')
      return this.sendInterest.sendInterestPhotoPost(body,headers).subscribe(interestRes =>{
        
      })


    }


    
  }

openMOdel(row,masterUrl)
{
  $('#callModal').css("display", "block");
  $("#callImgDivs").html('<img src="'+ masterUrl+row.MemberPhotoPath + '" style="width: 95px" />');

  $('#popupName').text('Contact '+row.MemberName+' on');
  if(localStorage.getItem('UserIsPaid')=='0')
  {
    $('#popupContact').html('<i class="fa fa-phone"></i> &nbsp;XXXXXXXXXX');
    $('#popupupgrads').html('<p>Upgrade now to view her phone number</p><button class="callUpgraBT"><a href="/upgrade-account">Upgrade now</a></button>');
  }
  else
  {
    $('#popupContact').html('<i class="fa fa-phone"></i> &nbsp;'+row.MemberContactNo);
    $('#popupupgrads').html('');
  } 
  
}

closePopup()
{
  $('#callModal').css("display", "none")
}

memberdetailspost(row)
  {
    localStorage.setItem('viewUser',row.MemberID)
    let link = ['/member-details'];
    this.route.navigate(link);
  }


}
