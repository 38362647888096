import { Component, OnInit } from '@angular/core';
import {SuccessStoryServiceService} from '../services/success-story-service.service'
import { Router } from "@angular/router";
import { WebsiteConstants } from '.././global/website-constants';
@Component({
  selector: 'app-success-stories',
  templateUrl: './success-stories.component.html',
  styleUrls: ['./success-stories.component.css']
})
export class SuccessStoriesComponent implements OnInit {

  masterUrl = WebsiteConstants.MasterUrl;
  helpcontact:any; helpemail:any; fraudcontact:any; fraudemail:any; 
  logspinner:boolean; succStory:any; photopath:any

  constructor(private story : SuccessStoryServiceService, private route : Router) { }
 
  ngOnInit(): void {
    this.photopath = this.masterUrl;
    this. successStoryHash()
  }
  successStoryHash()
  {
    this.logspinner = true
    let headers = new Headers({'Content-Type' : 'application/json'})
   let body = new FormData

   return this.story.successStoryPost(body,headers).subscribe(responce =>{
    
    this.succStory = responce['data']
    this.logspinner = false
   })
  }

  redirectTo(photo,coupleName,marriageDate,discription)
  {
    localStorage.setItem('successPhoto',photo)
    localStorage.setItem('successName', coupleName)
    localStorage.setItem('successMarriageDate',marriageDate)
    localStorage.setItem('successDiscription',discription)

    let link = ['/view-success-stories'];
    this.route.navigate(link);
  }
}
