import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-success-redirect',
  templateUrl: './success-redirect.component.html',
  styleUrls: ['./success-redirect.component.css']
})
export class SuccessRedirectComponent implements OnInit {

  logspinner:any=false; profileid : any; profilename:any;
  Mperiod:any; paidAmt:any;

  constructor() {  }

  ngOnInit(): void {
    
    this.profileid = localStorage.getItem("userprofileid");
    this.profilename = localStorage.getItem("UserFullName");
    this.paidAmt = localStorage.getItem("hiddenSamt");
    this.Mperiod = localStorage.getItem("hiddenSperiod");
    
  }

  

}
