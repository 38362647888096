import { Component, OnInit } from '@angular/core';
import { NearmeService } from '../services/nearme.service';
import {ShortlistedProfileIconService} from '../services/shortlisted-profile-icon.service'
import {PhotoRequestService} from '../services/photo-request.service'
import {SendInterestProfileService}  from '../services/send-interest-profile.service'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import * as $ from 'jquery';
import {Router} from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { RegistrationService } from '../services/registration.service';
import {NativeCityService} from '../services/native-city.service';
import {JobCountryService} from '../services/job-country.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { WebsiteConstants } from '.././global/website-constants';

@Component({
  selector: 'app-near-me',
  templateUrl: './near-me.component.html',
  styleUrls: ['./near-me.component.css']
})
export class NearMeComponent implements OnInit {
  profilePic : any; ProfileName : any; ProfileId : any; mymatchesresult : any;
  photopath : any; memberdetailsresult:any; logspinner:boolean; prodetailspinner:boolean;
  logshortlistmespinner:boolean; lognearmespinner:boolean;
  profilematchlocation : any; partnerpreference:any; matchprofilepic:any;cityname:any
  shortlistmedash:any; newMatchesdash:any; nearmeval:any; shortlistedval:any; myprofilevar:any;
  logshortlistedspinner:boolean; viewedmyprofilespinner : boolean; newmatchesspinner:boolean;
  mymatchscrollresult:any;myMessage:any; fromLimit:any; toLimit:any; fromLimitnext:any;toLimitnext;
  spinner: any = false; notEmptyPost: boolean =true; notscrolly: any=true;stateId:any
  shorlistedIcon:any;UnshortlistedIcon:any; citylist:any;userIsPaid:any
  countrylist:any; dropdownSettings:IDropdownSettings; dropdownSettingshash:IDropdownSettings;
  dropdownList = []; dropdownlistval = []; selectedItems = []; selectedItemshash = [];
  form: FormGroup;
  formval : FormGroup;
  SiteUrl = WebsiteConstants.SiteUrl; 
  sitetitle = WebsiteConstants.SiteTitle; 
  masterUrl = WebsiteConstants.MasterUrl;
  constructor(private nearmeservice:NearmeService, private route:Router,private memberShortlisted : ShortlistedProfileIconService,private  requestPhoto : PhotoRequestService,private sendInterest : SendInterestProfileService,private regservice:RegistrationService, private formBuider : FormBuilder,private nativCiry : NativeCityService, private jobCountry : JobCountryService) { }

  ngOnInit(): void {
    this.photopath = this.masterUrl;
    this.profilePic = this.masterUrl+localStorage.getItem("UserPhotoPath");
    this.ProfileName = localStorage.getItem("UserFullName");
    this.ProfileId = localStorage.getItem("UserProfileID");
    this.cityname = localStorage.getItem("UserCityName");
    this.userIsPaid = localStorage.getItem('UserIsPaid')
    this.nearMefun()
    
    this.initForm();
    this.initFormhash(); 
    
    this.selectedItems = [
      { CtID: localStorage.getItem('UserCityID'), CtcityName: localStorage.getItem('UserCityName') }
    ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'CtID',
      textField: 'CtcityName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      "enableCheckAll": false
    };


    
    this.dropdownSettingshash = {      
      singleSelection: false,
      idField: 'CntID',
      textField: 'CntName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      "enableCheckAll": false
    };


  }
  initForm()
  {
    this.form = this.formBuider.group({
      city : ['',[Validators.required]]
    })
  }

  selectCity()
  {
    console.log('value',JSON.stringify(this.form.value['city']));
    let arraykey = this.form.value['city'];
    let arrayobj = Array.prototype.map.call(arraykey, s => s.CtID).toString(); // "A,B,C"
    console.log(arrayobj);
    

    this.lognearmespinner = true;
    let headers = new Headers({'content-Type' : 'application/json'});
    let body = new FormData
    body.append('MemberID', localStorage.getItem('UserMemberID'));
    body.append('MembCityNM', arrayobj);
    this.nativCiry.nativeCityPost(body,headers).subscribe(nearmeInfo =>{
      console.log("hello"+nearmeInfo['data'])
      if(nearmeInfo['data'] !== undefined)
      {
        this.nearmeval = nearmeInfo['data']
        this.myMessage = "";
        this.lognearmespinner = false;
      }
      else
      {
        this.nearmeval = "0";
        this.myMessage = "There is no profile as per your criteria."
        this.lognearmespinner = false;
      }

   
    })
  }
  
  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }



  initFormhash()
  {
    this.formval = this.formBuider.group({
      Country : ['',[Validators.required]]
    })
  }

  selectCountry()
  {
    console.log('value',JSON.stringify(this.formval.value['Country']));
    let arraykey = this.formval.value['Country'];
    let arrayobj = Array.prototype.map.call(arraykey, s => s.CntID).toString(); // "A,B,C"
    console.log(arrayobj);
    

    this.lognearmespinner = true;
    let headers = new Headers({'content-Type' : 'application/json'});
    let body = new FormData
    body.append('MemberID', localStorage.getItem('UserMemberID'));
    body.append('MembJobCountryID', arrayobj);
    return this.jobCountry.jobCountryPost(body,headers).subscribe(nearmeInfo =>{
      console.log("hii"+nearmeInfo['data'])
      if(nearmeInfo['data'] !== undefined)
      {
        this.nearmeval = nearmeInfo['data']
        this.myMessage = "";
        this.lognearmespinner = false;
      }
      else
      {
        this.nearmeval = "0";
        this.myMessage = "There is no profile as per your criteria."
        this.lognearmespinner = false;
      }
  
      
      
    })
    
  }
  
  onItemSelecthash(item: any) {
    console.log(item);
  }
  onSelectAllhash(items: any) {
    console.log(items);
  }


  nearmepost = function() 
  {
  this.lognearmespinner = true;
  let headers = new Headers({'content-Type' : 'application/json'});
  let body = new FormData
  body.append('MemberID', localStorage.getItem('UserMemberID'));
  body.append('MembCityNM', localStorage.getItem('UserCityID'));
  this.nearmeservice.nearmefunction(body,headers).subscribe(nearmeInfo =>{
    if(nearmeInfo.data !== undefined)
    {
      this.nearmeval = nearmeInfo.data;
      this.myMessage = "";
    }
    else
    {
      this.nearmeval = "0";
      this.myMessage = "There is no profile as per your criteria."
    }

  this.onChangeState(localStorage.getItem('UserStateID'))
  this.getCountryDetails()
  })
}

onChangeState(stateId)
{
    if(stateId)
    {
      let headers = new Headers({'Content-Type':'application/json'});
      let body = new FormData();
      body.append('StateID',stateId);
      this.regservice.getCityData(body,headers).subscribe(Response =>
        {
            this.citylist =  Response['data'];
            this.dropdownList = this.citylist;
            this.lognearmespinner = false;
        })
    }
    else
    {
        this.citylist = [];
        this.lognearmespinner = false;
    }

}
getCountryDetails()
{
    let headers = new Headers({'Content-Type':'application/json'});
    this.regservice.getCountryData(headers).subscribe(Response =>
      {
        this.countrylist = Response['data'];
        this.dropdownlistval = this.countrylist
      })
}

nearMefun()
{
    this.nearmepost()
    $(document).ready(function()
    {
      $('#mymatchid').removeClass('selected');
      $('#mynewmatchid').removeClass('selected');
      $('#nearmeid').addClass('selected');
      $('#shortlistedid').removeClass('selected');
      $('#viewmyprofileid').removeClass('selected');
      $('#shortlistedmeid').removeClass('selected');
    })
}
membershortlist(row)
  {
        var hiddenfield = $("#hiddenshort"+row.MemberID).val();
        if(hiddenfield==0)
        {
           
            let profilename = row.MemberName+', '+row.MemberProfileID;
            let messageforsuccess = 'You have shortlisted Profile '+profilename;
            Swal.fire({
              title : 'Shortlisted', 
              text: messageforsuccess, 
              icon : 'success',
              showConfirmButton : false,
              timer : 1500});

            $("#hiddenshort"+row.MemberID).val(1);
            $('#shortListID'+row.MemberID).addClass('shortLis');

            let headers = new Headers({ 'Content-Type': 'application/json' });
            let body = new FormData();

            body.append('MemberID',localStorage.getItem('UserMemberID'))
            body.append('MembShortlistToID',row.MemberID);
            return this.memberShortlisted.memberShortlistedPost(body,headers).subscribe(shortlistedResponce =>{
            this.shorlistedIcon = shortlistedResponce;
            })
        }
        else
        {

          let profilename = row.MemberName+', '+row.MemberProfileID;
          let messageforsuccess = 'You have Un-shortlisted Profile '+profilename;
          Swal.fire({
            title : 'Un-Shortlisted', 
            text: messageforsuccess, 
            icon : 'success',
            showConfirmButton : false,
            timer : 1500});

            $("#hiddenshort"+row.MemberID).val(0);
          $('#shortListID'+row.MemberID).removeClass('shortLis');

            let headers = new Headers({ 'Content-Type': 'application/json' });
            let body = new FormData();

            body.append('MembShortlistByID',localStorage.getItem('UserMemberID'))
            body.append('MembShortlistToID',row.MemberID)

            return this.memberShortlisted.memberUnShortlistedPost(body,headers).subscribe(unShortlistedResponce =>{
            
            })

        }
    
  }

  photoRequestDash(element,row)
  {
    element.textContent = 'Photo Request Sent';
    element.disabled = true;
    // $('#requestphoto'+row.MemberID).text('Photo Request Sent');
    // $('#requestphoto'+row.MemberID).attr( "disabled", "disabled" );
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let body = new FormData();

    body.append('MemberID',localStorage.getItem('UserMemberID'))
    body.append('MemberName',localStorage.getItem('UserFullName'))
    body.append('MembInterestID', row.MemberID)
    body.append('MembName', row.MemberName)
    body.append('MembIntFlag','photo')
    body.append('MembFreeOrPaid', localStorage.getItem('UserIsPaid'))

    return this.requestPhoto.photoRequestPost(body,headers).subscribe(photorequest =>{
      let msgVal = 'You have sent request for photo to '+row.MemberName;
      
      Swal.fire({
        title : 'Success', 
        text: msgVal, 
        icon : 'success',
        showConfirmButton : false,
        timer : 1500
      });


    })
  }


memberInterestlist(row)
  {
   
    
    var userapplimit = localStorage.getItem('UserAppInterestLimit');
    var userapplimitval = Number(userapplimit);
    
    
    if(localStorage.getItem('UserIsPaid')=='0' && userapplimitval >0)
    {
      var usersentintcnt = localStorage.getItem('updateAppInterestLimit');
      var usersentintcntval = Number(usersentintcnt);

        if(usersentintcntval<userapplimitval)
        {


          Swal.fire({
            title: 'Are you sure?',
            text: 'As a free member you can send only '+userapplimitval+' interest to liked profile. Are you sure you want to send interest?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
          }).then((result) => {
            if (result.value) {
              let profilename = row.MemberName+', '+row.MemberProfileID;
              let messageforsuccess = 'Your request has been sent successfully to '+profilename;
              Swal.fire({
                title : 'Sent', 
                text: messageforsuccess, 
                icon : 'success',
                showConfirmButton : false,
                timer : 1500});


            $('#sentrunInterst'+row.MemberID).hide();
            $('#sentshowInterst'+row.MemberID).removeClass('hideHeart');
            $('#sentshowInterst'+row.MemberID).addClass('likeIntro');


          var usersentintcnt = localStorage.getItem('updateAppInterestLimit');
          var usersentintcntval = parseInt(usersentintcnt)+1;
          localStorage.setItem('updateAppInterestLimit',usersentintcntval.toString());

          let headers = new Headers({ 'Content-Type': 'application/json' });
          let body = new FormData();
          body.append('MemberID',localStorage.getItem('UserMemberID'))
          body.append('MembInterestID', row.MemberID)
          body.append('MembIntFlag','Interest')
          body.append('MembFreeOrPaid','0')
          return this.sendInterest.sendInterestPhotoPost(body,headers).subscribe(interestRes =>{
            
          })
            
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            
            }
          })


        }
        else
        {

            let message = 'As a free member, you have already sent '+userapplimitval+' interest to liked profile.';
            Swal.fire({
              title : 'Request didn\'t send', 
              text: message, 
              icon : 'error',
              showConfirmButton : true,
              //timer : 1500
            });
             
        }
    }
    else
    {
      //$('#sentInterst'+row.MemberID).addClass('likeIntro');
      let profilename = row.MemberName+', '+row.MemberProfileID;
      let messageforsuccess = 'Interest sent successfully to '+profilename;
      Swal.fire({
        title : 'Interest Sent', 
        text: messageforsuccess, 
        icon : 'success',
        showConfirmButton : false,
        timer : 1500});

$('#sentrunInterst'+row.MemberID).hide();
$('#sentshowInterst'+row.MemberID).removeClass('hideHeart');
$('#sentshowInterst'+row.MemberID).addClass('likeIntro');

      let headers = new Headers({ 'Content-Type': 'application/json' });
      let body = new FormData();
      body.append('MemberID',localStorage.getItem('UserMemberID'))
      body.append('MembInterestID', row.MemberID)
      body.append('MembIntFlag','Interest')
      body.append('MembFreeOrPaid','1')
      return this.sendInterest.sendInterestPhotoPost(body,headers).subscribe(interestRes =>{
        
      })


    }


    
  }

openMOdel(row,masterUrl)
{
  $('#callModal').css("display", "block");
  $("#callImgDivs").html('<img src="'+ masterUrl+row.MemberPhotoPath + '" style="width: 95px" />');

  $('#popupName').text('Contact '+row.MemberName+' on');
  if(localStorage.getItem('UserIsPaid')=='0')
  {
    $('#popupContact').html('<i class="fa fa-phone"></i> &nbsp;XXXXXXXXXX');
    $('#popupupgrads').html('<p>Upgrade now to view her phone number</p><button class="callUpgraBT"><a href="/upgrade-account">Upgrade now</a></button>');
  }
  else
  {
    $('#popupContact').html('<i class="fa fa-phone"></i> &nbsp;'+row.MemberContactNo);
    $('#popupupgrads').html('');
  } 
  
}

closePopup()
{
  $('#callModal').css("display", "none")
}

memberdetailspost(row)
  {
    localStorage.setItem('viewUser',row.MemberID)
    let link = ['/member-details'];
    this.route.navigate(link);
  }

}
