import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler, HttpHeaders} from '@angular/common/http';
import { Observable,of } from 'rxjs';
import { catchError} from 'rxjs/Operators';
import { retry } from 'rxjs/internal/operators/retry';
import { WebsiteConstants } from '.././global/website-constants';

@Injectable({
  providedIn: 'root'
})
export class EditPreferenceService {

private masterUrl = WebsiteConstants.MasterUrl;
private viewpreferenceurl = this.masterUrl+'ViewMemberPreferenceDetails/ViewMemberPreferenceDetails';
private editpreferenceurl = this.masterUrl+'EditBasicPreferenceDetails/EditBasicPreferenceDetails';
private getmulcatData=this.masterUrl+'GetMultipleCastes/GetMultipleCastes'
private getmulsubcatData=this.masterUrl+'GetMultipleSubCastes/GetMultipleSubCastes'
private getmulstate=this.masterUrl+'GetMultipleStates/GetMultipleStates'
private getmulcity=this.masterUrl+'GetMultipleCities/GetMultipleCities'
private editlocationpreference=this.masterUrl+'EditLocationPreferenceDetails/EditLocationPreferenceDetails'
private editreligionpreference=this.masterUrl+'EditReligionPreferenceDetails/EditReligionPreferenceDetails'
private editprofesionalpreference=this.masterUrl+'EditProfessionalPreferenceDetailsWeb/EditProfessionalPreferenceDetailsWeb'
private aboutusupdate=this.masterUrl+"EditAboutPartnerPreferenceDetails/EditAboutPartnerPreferenceDetails"
  constructor(private http : HttpClient)
  { 

  }


  ViewPreferenceUrl(body, headers)
  {
    return this.http.post(this.viewpreferenceurl, body, headers);
  }

  EditPreferenceUrl(body, headers)
  {
    return this.http.post(this.editpreferenceurl, body, headers);
  }
  getmulcatDataUrl(body, headers)
  {
    return this.http.post(this.getmulcatData, body, headers);
  }
  getmulsubcatDataUrl(body, headers)
  {
    return this.http.post(this.getmulsubcatData, body, headers);
  }
  getmulstateDataUrl(body, headers)
  {
    return this.http.post(this.getmulstate, body, headers);
  }

  getmulcityDataUrl(body, headers)
  {
    return this.http.post(this.getmulcity, body, headers);
  }
  EditlocationPreferenceUrl(body, headers)
  {
    return this.http.post(this.editlocationpreference, body, headers);
  }
  EditreligionPreferenceUrl(body,headers){
    return this.http.post(this.editreligionpreference, body, headers);
  }
  EditprofesionalPreferenceUrl(body,headers){
    return this.http.post(this.editprofesionalpreference, body, headers);
  }
  
  aboutusupdateurl(body,headers){
    return this.http.post(this.aboutusupdate, body, headers);
  }
}
