import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler, HttpHeaders} from '@angular/common/http';
import { Observable,of } from 'rxjs';
import { catchError} from 'rxjs/Operators';
import { retry } from 'rxjs/internal/operators/retry';
import { WebsiteConstants } from '.././global/website-constants';

@Injectable({
  providedIn: 'root'
})
export class ViewmemberdetailsService {

  private masterUrl = WebsiteConstants.MasterUrl;
  private siteurl=this.masterUrl;
  private memberdetailsurl = this.siteurl+"ViewMemberDetails/ViewMemberDetails";
  constructor(private http : HttpClient) { }
  
    memberdetails(body,headers)
    {
      return this.http.post(this.memberdetailsurl, body, headers)
    }
}
