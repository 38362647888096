
<div class="dashboardMain" id="activeButtDIV">
   <app-left-menu></app-left-menu>    
        <div class="admin-panel clearfix">
            <div class="main">
                <app-top-menu></app-top-menu>
                

         <div id="photoGallery">
            <div class="samplecover-spin" id="samplecover-spin" *ngIf="logshortlistmespinner"></div>
            
            
            <div class="dashMainCont" *ngIf="!logshortlistmespinner">
              <div class="dashMainInner">
                 <div class="matchesInner">





                <form [formGroup]="form" (ngSubmit)="submit()">



            <div class="changePasMain">
               <div class="row">

                <div class="col-md-6">
                   <input type="Password" formControlName="oldpassword" placeholder="Old Passsword" class="inputFil" >
                   <div class="clear"></div>

                    <div *ngIf="isSubmitted && f.oldpassword.invalid" class="animTest w3-animate-zoom">
                    <div *ngIf="f.oldpassword.errors.required">Old Password is required.</div>
                    </div>

                 </div>

                 <div class="col-md-6">
                   <input formControlName="password" id="password" type="password" class="inputFil" placeholder="New Passsword" maxlength="8">
                    <div class="clear"></div>
                <div *ngIf="isSubmitted && f.password.invalid" class="animTest w3-animate-zoom">
                <div *ngIf="f.password.errors.required">New Password is required.</div>
                <div *ngIf="f.password.errors.minlength">New Password must have minimum 6 characters</div>
            </div>

                 </div>
                 <div class="col-md-6">
                   <input formControlName="confirm_password" id="confirm_password" type="password"  placeholder="Confirm Passsword" class="inputFil" >
                    <div class="clear"></div>
                <div *ngIf="isSubmitted && f.confirm_password.invalid" class="animTest w3-animate-zoom">
                <div *ngIf="f.confirm_password.errors.required">Confirm Password is required.</div>
                <div *ngIf="f.confirm_password.errors.confirmedValidator">New Password and Confirm Password must be match.</div>
            </div>

                 </div>


                 
                 
                 <div class="col-md-12">
                  <hr class="deviLine">
                  <button class="passBt" type="submit">Change Password</button>
                 </div> 



               </div>
             </div>





           
        <!-- <div class="form-group">
           
            <input 
                formControlName="password"
                id="password" 
                type="password" 
                class="inputFil" 
                placeholder="Old Passsword">


            
        </div>
  
        <div class="form-group">
            
            <input 
                formControlName="confirm_password"
                id="confirm_password" 
                type="password" 
                class="form-control">
            
        </div> -->
        
        <!-- <button class="btn btn-primary" type="submit" >Submit</button> -->
    </form>




                   



                 </div>
              </div>
            </div>






       </div>


        </div>
    </div>
</div>    
  




