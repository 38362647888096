
<div class="dashboardMain" id="activeButtDIV">
   <app-left-menu></app-left-menu>    
        <div class="admin-panel clearfix">
            <div class="main">
                <app-top-menu></app-top-menu>
                <div id="myMatche" class="myMatchesStyle" >
                        <div class="mainContent clearfix">
                            <!-- <div class="samplecover-spin" id="samplecover-spin"></div> -->

                            <div id="notification">
         <!-- <h2 class="header"><a href="#" class="backArrow"><i class="fa fa-arrow-circle-o-left"></i></a>Notification</h2> -->
         <div class="dashMainCont notiMain">
           <div class="dashMainInner">
               <div class="matchesInner">
             <div class="row mailDivMain">

                <div class="samplecover-spin" id="samplecover-spin" *ngIf="logspinner"></div>
               
            <div *ngIf="notificationresult?.length > 0">
                <div *ngFor="let responceData of notificationresult"> 
                <div class="col-md-6">
                 <div class="mailDiv">
                  <div class="mailProDiv"><div class="mailImgDiv"><img [src]='photopath+responceData.MembPhoto'></div></div>
                   <div class="mailContDiv">
                     <h4 class="mailHead">{{responceData.MemberShortMsg}}</h4>
                        <p>{{responceData.MemberFullName}} ({{responceData.MembProfileID}})</p>
                        <p>{{responceData.MemberMessage}}</p>
                     <h4 class="date">{{responceData.MemberNotificationOn}}</h4>
                     <span><a href="Javascript:void(0)" (click)="memberdetailspost(responceData)"><i class="fa fa-eye"></i></a></span>
                   </div>
                 </div>
                </div>
                </div>
            </div>


            <div *ngIf="notificationresult==0" class="noDataMessage">
              <img src="../../assets/images/no-data-pana.png"/>
              {{notificationMessage}}
            </div>
               
             </div>
             </div>
           </div>
         </div>
       </div>


                               
                        </div>  
                </div>
            </div>
        </div>
</div>    
  





