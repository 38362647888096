<div class="homeMain">
<app-loading-spinner *ngIf="logloading"></app-loading-spinner>
<div class="regiMain" *ngIf="!logloading">
        <div id="samplecover-spin" *ngIf="logspinner"></div>
    <div class="logoMain">
        <div class="container">
            <div class="row">
                <div class="col-md-4 col-sm-6 col-xs-4">
                   <a href="/"> <img src="assets/images/br-logo1.png"></a>
                </div>
                <div class="col-md-8">
                    <a [routerLink]="['/login']"> <button class="LoginBt">Login</button></a>
                </div>
            </div>
        </div>
    </div>
    <div class="formMain">
    <form id="msform">
        <ul id="progressbar">
            <li class="active"></li>
            <li></li>
            <li></li>
            <li></li>
        </ul>
                <form [formGroup]="basicForm" id="first" (ngSubmit)="basicinfoPost(basicForm.value)">
            <div class="overDiv">
            <h2 class="fs-title">Create Account</h2>
            
        <div class="fildAria">
            <div class="row">
                <div class="col-md-4 col-sm-4">
                    
                    <select class="inputFil inputFilSelect" tabindex="1" [(ngModel)]="createprofileval" formControlName="createProfile" (change)="onChangeProfile($event)" >
                        <option [ngValue]="null" selected>Create Profile for</option>
                        <option value="Myself">Myself</option>
                        <option value="Son">Son</option>
                        <option value="Daughter">Daughter</option>
                        <option value="Brother">Brother</option>
                        <option value="Sister">Sister</option>
                        <option value="Relative">Relative</option>
                        <option value="Friend">Friend</option>
                    </select>
                    <div class="clear"></div>
                    <div class="animTest w3-animate-zoom" *ngIf="isSubmitted && createProfile.invalid" >
                        <div *ngIf="createProfile.errors.required">Please select create profile for whom</div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-4">
                    <input type="text" name="" placeholder="Full Name" formControlName="fullname" tabindex="2"  [(ngModel)]="fullnameval"  maxlength="150" class="inputFil">
                    <div class="clear"></div>
                    <div class="animTest w3-animate-zoom" *ngIf="isSubmitted && fullname.invalid">
                        <div *ngIf="fullname.errors.required">Please enter Fullname</div>
                         <div class="animTest w3-animate-zoom" *ngIf="fullname.errors.pattern">Please enter valid fullname</div>
                    </div>

                </div>

                <div class="col-md-4 col-sm-4">
                      <div class="radioSt radioSt2">
                        <input type="radio" id="male" value="Male" tabindex="4" [(ngModel)]="genderval" formControlName="gender" (change)="differencedate($event)" class="maleInp" >

                        <label for="male" class="maleStyle">Male</label>
                        <input type="radio" id="female" value="Female" [(ngModel)]="genderval"  formControlName="gender" (change)="differencedate($event)" class="maleInp">
                        
                        <label for="female" class="maleStyle">Female</label>
                        <div class="clear"></div>
                      </div>
                      <div class="animTest w3-animate-zoom" *ngIf="isSubmitted && gender.invalid">
                          <div *ngIf="gender.errors.required">Please select Gender</div>
                      </div> 

                </div>
            </div>
            <div class="row">
<div class="col-md-4 col-sm-4">
   
                    <input type="text" placeholder="Birth Date" class="form-control inputFil" tabindex="3"   maxlength="12" formControlName="birthdate" [(ngModel)]="birthnameval" [bsValue]="" [maxDate]="maxDate" readonly [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-red', isAnimated: true}" (bsValueChange)="onDateChange($event)"  bsDatepicker>
                    <div class="clear"></div>
                    <div class="animTest w3-animate-zoom" *ngIf="isSubmitted && birthdate.invalid">
                        <div *ngIf="birthdate.errors.required">Please select Date of Birth</div>
                    </div>        
                </div>

                <div class="col-md-4 col-sm-4">
                    <select class="inputFil inputFilSelect" tabindex="5" [(ngModel)]="religionval" formControlName="religion">
                        <option [ngValue]="null" selected>Religion</option>
                        <option *ngFor="let k of religionlist" value="{{k.ReligionID+'|||'+k.ReligionName}}">{{k.ReligionName}}</option>                        
                    </select>
                    <div class="clear"></div>
                    <div class="animTest w3-animate-zoom" *ngIf="isSubmitted && religion.invalid">
                        <div *ngIf="religion.errors.required">Please select Religion</div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-4">
                   <select class="inputFil inputFilSelect" tabindex="6" [(ngModel)]="motherTongueval" formControlName="motherTongue">
                        <option [ngValue]="null" selected>Mother Tongue</option>
                        <option value="Marathi">Marathi</option>
                        <option value="Hindi">Hindi</option>
                        <option value="English">English</option>
                        <option value="Gujrathi">Gujrathi</option>
                        <option value="Kannada">Kannada</option>
                        <option value="Telgu">Telgu</option>
                        <option value="Tamil">Tamil</option>
                        <option value="Bengali">Bengali</option>
                        <option value="Kokani">Kokani</option>
                        <option value="Rajsthani">Rajsthani</option>
                    </select>
                    <div class="clear"></div>
                    <div class="animTest w3-animate-zoom" *ngIf="isSubmitted && motherTongue.invalid">
                        <div *ngIf="motherTongue.errors.required">Please select Mother Tongue</div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="deviderLine"></div>
                </div>
            </div>
        </div>
                
    <div class="fildAria">
            <h2 class="fs-title">Login Details</h2>
          
       
            <div class="row">
                <div class="col-md-4 col-sm-4">
                    <input type="text" name="emailid" formControlName="emailid" tabindex="7" [(ngModel)]="emailidval" placeholder="Email Address" class="inputFil">
                    <div class="clear"></div>
                    <div *ngIf="isSubmitted && emailid.invalid">
                      <div class="animTest w3-animate-zoom" *ngIf="emailid.errors.required"> Please enter Email Address</div>
                      <div class="animTest w3-animate-zoom" *ngIf="emailid.errors.pattern">Please enter valid Email Address</div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-4">
                    <input type="text" name="phoneno" formControlName="phoneno" tabindex="8" [(ngModel)]="phonenoval" placeholder="Mobile Number" class="inputFil" maxlength="10">
                    <div class="clear"></div>
                    <div class="animTest w3-animate-zoom" *ngIf="isSubmitted && phoneno.invalid">
                        <div *ngIf="phoneno.errors.required">Please enter Mobile Number</div>
                        <div class="animTest w3-animate-zoom" *ngIf="phoneno.errors.pattern">Please enter Mobile Number</div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-4">
                    <input type="Password" name="password" formControlName="password" tabindex="9"  [(ngModel)]="passwordval"  maxlength="8"  placeholder="Password" class="inputFil">
                    <div class="clear"></div>
                    <div class="animTest w3-animate-zoom" *ngIf="isSubmitted && password.invalid">
                        <div *ngIf="password.errors.required">Please enter Password</div>
                    <div *ngIf="password.errors.minlength">Password must have minimum 6 characters</div>
                        
                    </div>
                </div>


                <div class="fildAria">
                    <div >By Submitting you agree to our <br/> <a href="privacy-policy" target="_blank">Privacy Poilcy</a> and <a href="terms-use" target="_blank">Terms & Conditions</a></div>
                </div>

                
            </div>
       
    </div>
</div>
            <input type="submit" name="next"  class="next action-button" value="Next" />
        </form>
        
                <form id="second"  [formGroup]="secondForm" (ngSubmit)="secondinfoPost(secondForm.value)">
            <div class="overDiv">
            <h2 class="fs-title">Religion Details</h2>
        <div class="fildAria">
            <div class="row">
                <div class="col-md-6 col-sm-6">
                     <select class="inputFil inputFilSelect inputFilSelect2" formControlName="caste" 
                     [(ngModel)]="casteval" (change)="onChangeSubCast($event)">
                        <option [ngValue]="null" selected>Caste</option>
                        <option *ngFor="let castedata of castelist" value="{{castedata.CasteID+'|||'+castedata.CasteCode+'|||'+castedata.CasteName}}">{{castedata.CasteName}}
                                <!-- CasteCode -->
                    </select>
                    <div class="clear"></div>
                    <div class="animTest w3-animate-zoom" *ngIf="isSubmitted1 && caste.invalid">
                        <div *ngIf="caste.errors.required">Please select Caste</div>
                    </div>
                </div>
                <div class="col-md-6 col-sm-6">
                     <select class="inputFil inputFilSelect inputFilSelect2" formControlName="subcaste" 
                     [(ngModel)]="subcasteval">
                        <option [ngValue]="null" selected>Sub Caste</option>
                        <option *ngFor="let su of subcastlist" value="{{su.SubCasteID}}">{{su.SubcasteName}}</option>
                    </select>
                    <div class="clear"></div>
                    <div class="animTest w3-animate-zoom" *ngIf="isSubmitted1 && subcaste.invalid">
                        <div *ngIf="subcaste.errors.required">Please select Sub-Caste</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-6">
                     <select class="inputFil inputFilSelect inputFilSelect2" formControlName="gothra" 
                     [(ngModel)]="gothraval">
                        <option [ngValue]="null" selected>Gothra</option>
                        <option *ngFor="let g of gothralist" value="{{g.GothraName}}">{{g.GothraName}}</option>
                    </select>
                </div>
                <div class="col-md-6 col-sm-6">
                    <div id="noChild">
                    <div class="radioStNew">
                        <p class="extLab">Mangal Dosh</p>
                      <input type="radio" id="doshno" class="maleInp" formControlName="dosh" [(ngModel)]="doshval" value="0">
                      <label for="doshno" class="maleStyle">No</label>
                      
                      <input type="radio" id="doshyes" class="maleInp" formControlName="dosh" [(ngModel)]="doshval" value="1">
                      <label for="doshyes" class="maleStyle">Yes</label>
                    </div>
                </div>
                      
                </div>

                <div class="col-md-12">
                    <div class="deviderLine"></div>
                </div>
            </div>
        </div>
                    
        <div class="fildAria">
             <h2 class="fs-title">Personal Details </h2>
           
            <div class="row">
                <div class="col-md-4 col-sm-4">
                    
                    <select class="inputFil inputFilSelect inputFilSelect2" formControlName="maritalstatus" [(ngModel)]="maritalstatusval" (change)="getMaritalStatus($event)">
                        <option [ngValue]="null" selected>Marital Status</option>
                        <option *ngFor="let marital of MaritalStatusArray"  value="{{marital.mstatus}}">{{marital.mstatusval}}</option>
                    </select>
                    <div class="clear"></div>
                    <div class="animTest w3-animate-zoom" *ngIf="isSubmitted1 && maritalstatus.invalid">
                        <div *ngIf="maritalstatus.errors.required">Please select Martial Status</div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-4">
                    <select class="inputFil inputFilSelect inputFilSelect2" formControlName="candidateheight" [(ngModel)]="candidateheightval">
                        <option [ngValue]="null" selected>Height</option> 
                        <!-- HeightDisplayUser -->
                        <option *ngFor="let h of heightlist" value="{{h.HeightDisplayUser+'|||'+h.HeightCode}}">{{h.HeightDropdownDisplay}}</option>
                    </select>
                    <div class="clear"></div>
                    <div class="animTest w3-animate-zoom" *ngIf="isSubmitted1 && candidateheight.invalid">
                        <div *ngIf="candidateheight.errors.required">Please select Height</div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-4">
                    <input value="13:20" type="time" formControlName="birthtime" [(ngModel)]="birthtimeval"  class="inputFil placeClass">
                    <div class="clear"></div>
                    <!-- <div class="animTest w3-animate-zoom" *ngIf="birthtime.touched && birthtime.invalid">
                        <div *ngIf="birthtime.errors.required">Please select birth time</div>
                    </div> -->
                </div>

                <!-- *ngIf="isShown" -->
                <div class="col-md-12">
                    <div class="" *ngIf="isShown" id="noChild">


                            <div class="radioSt">
                                    <p class="extLab">No of Children</p>
                                    
                                    <div>
                                        <input type="radio" id="none" (change)="getNoOfChildren($event)" class="maleInp" formControlName="noChildren" [(ngModel)]="noChildrenval" value="0">
                                        <label class="maleStyle">None</label>
                                    </div>

                                    <div>
                                        <input type="radio" id="1" (change)="getNoOfChildren($event)" class="maleInp" formControlName="noChildren" [(ngModel)]="noChildrenval" value="1">
                                        <label class="maleStyle">1</label>
                                    </div>
                                    
                                    <div>
                                        <input type="radio" id="2" (change)="getNoOfChildren($event)" class="maleInp" formControlName="noChildren" [(ngModel)]="noChildrenval" value="2">
                                        <label class="maleStyle">2</label>
                                    </div>
                                    
                                    <div>
                                        <input type="radio" id="3 or more" (change)="getNoOfChildren($event)" class="maleInp" formControlName="noChildren" [(ngModel)]="noChildrenval" value="3">
                                        <label for="3" class="maleStyle">3 OR more</label>
                                    </div>
                            </div>

                                <div class="clear"></div>

                                <div class="animTest w3-animate-zoom" *ngIf="isSubmitted1 && isShown && noChildren.invalid">
                                    <div *ngIf="noChildren.errors.required">Please select number of child</div>
                                </div>
                    </div>
                </div>



                <div class="chidOfMain" *ngIf="isChild">
                        
                        
                                                <div class="col-md-12 col-sm-12 radioSt">
                                                        <p class="extLab">Staying</p>
                                                          
                                                          <input type="radio" id="withMe" class="maleInp" formControlName="childrenStaying" [(ngModel)]="childrenStayingval" value="Children living with me">
                                                          <label  class="childrenStyle">Children living with me</label>
                                                          
                                                          <input type="radio" id="notWithMe" class="maleInp" formControlName="childrenStaying" [(ngModel)]="childrenStayingval" value="Children not living with me">
                                                          <label class="childrenStyle">Children not living with me</label>
                                                         
                                                    </div>

                                                    <div class="clear"></div>

                                                    <div class="animTest w3-animate-zoom" *ngIf="isSubmitted1 && isChild && childrenStaying.invalid">
                                                        <div *ngIf="childrenStaying.errors.required">Please select number of child</div>
                                                    </div>
                                        </div>

            </div>
            <div class="row">
                <div class="col-md-4 col-sm-4">
                    <input type="text" [(ngModel)]="birthplaceval" placeholder="Birth Place" class="inputFil" formControlName="birthplace">
                </div>
                <div class="col-md-4 col-sm-4">
                    <select class="inputFil inputFilSelect inputFilSelect2" formControlName="complexion" [(ngModel)]="complexionval">
                        <option [ngValue]="null" selected >Complexion</option>
                        <option value="Very Fair">Very Fair</option>
                        <option value="Fair">Fair</option>
                        <option value="Wheaties">Wheaties</option>
                        <option value="Wheaties Brown">Wheaties Brown</option>
                        <option value="Dark">Dark</option>
                    </select>
                    
                </div>
                <div class="col-md-4 col-sm-4">
                    <select class="inputFil inputFilSelect inputFilSelect2" formControlName="bloodgroup" [(ngModel)]="bloodgroupval">
                        <option [ngValue]="null" selected>Blood Group</option>
                        <option *ngFor="let b of bloodgrplist" value="{{b.BloodGroup+'|||'+b.BloodCode}}">{{b.BloodGroup}}
                    </select>
                    <div class="clear"></div>
                    <div class="animTest w3-animate-zoom" *ngIf="isSubmitted1 && bloodgroup.invalid">
                        <div *ngIf="bloodgroup.errors.required">Blood group cannot be blank</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div id="noChild">
                    <div class="radioStNew">
                        <p class="extLab">Any Disability</p>
                      <input type="radio" id="disaNo"  value="No" class="maleInp" [(ngModel)]="disabilityval" formControlName="disability">
                      <label for="male" class="maleStyle">No</label>
                      <input type="radio" id="disaYes"  value="Yes" class="maleInp" [(ngModel)]="disabilityval" formControlName="disability">
                      <label for="female" class="maleStyle">Yes</label>
                    </div>
                </div>
                </div>
            </div>
        
        </div>
    </div>
            <input type="button" name="previous" class="previous action-button" (click)="showfirstscreen();" value="Previous" />
            <input type="submit" name="next"  class="next action-button" value="Next" />
            </form>
        <!-- </fieldset>
        <fieldset> -->
            <form id="third" [formGroup]="thirdForm" (ngSubmit)="thirdPost(thirdForm.value)">
            <div class="overDiv">
            <h2 class="fs-title">Address Details</h2>
            <div class="fildAria">
            <div class="row">
                <div class="col-md-6 col-sm-6">
                    <select class="inputFil inputFilSelect inputFilSelect2" [(ngModel)]="countryval"  formControlName="country" (change)="onChangeCountry($event)">
                        <option [ngValue]="null" selected>Country</option>
                        <option *ngFor="let c of countrylist" value="{{c.CntID+'|||'+c.CntName}}">{{c.CntName}}</option>
                    </select>
                    <div class="clear"></div>
                    <div class="animTest w3-animate-zoom" *ngIf="isSubmitted2 && country.invalid">
                        <div *ngIf="country.errors.required">Please select Country</div>
                    </div>
                </div>
                <div class="col-md-6 col-sm-6">
                    <select class="inputFil inputFilSelect inputFilSelect2" [(ngModel)]="stateval" formControlName="state" (change)="onChangeState($event)">
                        <option [ngValue]="null" selected>State</option>
                        <option *ngFor="let s of statelist" value="{{s.StID+'|||'+s.StstateName}}">{{s.StstateName}}</option>
                    </select>
                    <div class="clear"></div>
                    <div class="animTest w3-animate-zoom" *ngIf="isSubmitted2 && state.invalid">
                        <div *ngIf="state.errors.required">Please select State</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-6">
                    <select class="inputFil inputFilSelect inputFilSelect2" [(ngModel)]="cityval" formControlName="city">
                    <option [ngValue]="null" selected>City</option>
                    <option *ngFor="let ci of citylist" value="{{ci.CtID}}">{{ci.CtcityName}}</option>
                    </select>
                    <div class="clear"></div>
                    <div class="animTest w3-animate-zoom" *ngIf="isSubmitted2 && city.invalid">
                        <div *ngIf="city.errors.required">Please select City</div>
                    </div>
                </div>
                <div class="col-md-6 col-sm-6">
                    <input type="text" name="" [(ngModel)]="talukaval" formControlName="taluka" placeholder="Taluka / Village" class="inputFil">
                </div>
            </div>
            
        </div>

        <div class="col-md-12">
            <div class="deviderLine"></div>
        </div>
                    
            <div class="fildAria">
                <h2 class="fs-title">Professional Details</h2>
            <div class="row">
                <div class="col-md-4 col-sm-4">
                    <select class="inputFil inputFilSelect inputFilSelect2" formControlName="highesteducation" [(ngModel)]="highesteducationaval"  (change)="onChangeEducation($event)">
                        <option value="null" selected>Highest Education</option>
                        <ng-container *ngFor="let education of Educationlist">
                        <optgroup *ngIf="education.Header=='TRUE'"  label="{{education.EducationGroupName}}"></optgroup> 
                        <option *ngIf="education.Header=='FALSE'"  value="{{education.EducationID+'|||'+education.EducationName+'|||'+education.EducationGroupID+'|||'+education.EducationGroupName}}">{{education.EducationName}}</option>
                        </ng-container>
                    </select>

                    <div class="clear"></div>
                    <div class="animTest w3-animate-zoom" *ngIf="isSubmitted2 && highesteducation.invalid">
                        <div *ngIf="highesteducation.errors.required">Please select Highest Education</div>
                    </div>
                </div>
                <div class="col-md-4">
                    <input type="text" name="" [(ngModel)]="othereducationval" formControlName="othereducation" placeholder="Other Education" class="inputFil">
                </div>
                <div class="col-md-4 col-sm-4">
                    <select class="inputFil inputFilSelect inputFilSelect2" [(ngModel)]="employeeinval" formControlName="employeein" (change)="onChangeOccupation($event)">
                        <option [ngValue]="null" selected>Employee In</option>
                        <option value="Government|||1">Government</option>
                        <option value="Private|||1">Private</option>
                        <option value="Defense|||1">Defense</option>
                        <option value="Self Employed|||1">Self Employed</option>
                        <option value="Business|||1">Business</option>
                        <option value="Doctor|||1">Doctor</option>
                        <option value="Pharmacy|||1">Pharmacy</option>
                        <option value="Farmer|||1">Farmer</option>
                        <option value="Not Working|||17">Not Working</option>
                    </select>
                    <div class="clear"></div>
                    <div class="animTest w3-animate-zoom" *ngIf="isSubmitted2 && employeein.invalid">
                        <div *ngIf="employeein.errors.required">Please select Employed In   </div>
                    </div>
                </div>
                
            </div>

            <div class="row">
                <div class="col-md-4 col-sm-4">
                    <select class="inputFil inputFilSelect inputFilSelect2" [(ngModel)]="occupationval" formControlName="occupation">
                        <option value="null" selected>Occupation</option>
                        
                        <ng-container *ngFor="let occupationdata of Occupationlist">
                        <optgroup *ngIf="occupationdata.Header=='TRUE'"  label="{{occupationdata.OccupationGroupName}}"></optgroup> 
                        <option *ngIf="occupationdata.Header=='FALSE'"  value="{{occupationdata.OccupationID+'|||'+occupationdata.OccupationName+'|||'+occupationdata.OccupationGroupID+'|||'+occupationdata.OccupationGroupName}}">{{occupationdata.OccupationName}}</option>
                        </ng-container>

                    </select>
                    <div class="clear"></div>
                    <div class="animTest w3-animate-zoom" *ngIf="isSubmitted2 && occupation.invalid">
                        <div *ngIf="occupation.errors.required">Please select Occupation</div>
                    </div>
                </div>

                <div class="col-md-4 col-sm-4">
                    <input type="text" name="" [(ngModel)]="annualincomeval" placeholder="Annual Income" class="inputFil" formControlName="annualincome">

                    <div class="animTest w3-animate-zoom" *ngIf="isSubmitted2 && isShown1 && annualincome.invalid">
                        <div *ngIf="annualincome.errors.required">Please enter your annual income</div>
                    </div>
                    
                </div>
                
                
                
                <div class="col-md-4 col-sm-4">
                    <select class="inputFil inputFilSelect inputFilSelect2" [(ngModel)]="jobcountryval" formControlName="jobcountry">
                        <option [ngValue]="null" selected>Job Country</option>
                        <option *ngFor="let j of countrylist" value="{{j.CntID}}">{{j.CntName}}</option>
                    </select>


                    <div class="animTest w3-animate-zoom" *ngIf="isSubmitted2 && isShown2 && jobcountry.invalid">
                        <div *ngIf="jobcountry.errors.required">Please enter your job country</div>
                    </div>


                </div>
                
            </div>

            <div class="row">
                <div class="col-md-4 col-sm-4">
                    <input type="text" name="" placeholder="Job City" class="inputFil" formControlName="jobcity" [(ngModel)]="jobcityval">
                    <div class="animTest w3-animate-zoom" *ngIf="isSubmitted2 && isShown3 && jobcity.invalid">
                        <div *ngIf="jobcity.errors.required">Please enter your job city</div>
                    </div>
                </div>
                
               
            </div>
            
                <div class="deviderLine"></div>
            
        </div>
                    
        <div class="fildAria">
            <h2 class="fs-title">Family Details</h2>
            
            <div class="row">
                <div class="col-md-4">
                    <input type="text" id="" value="" [(ngModel)]="fathernameval" formControlName="fathername" placeholder="Father Name" class="inputFil">
                    <div class="clear"></div>
                    <div class="animTest w3-animate-zoom" *ngIf="isSubmitted2 && fathername.invalid">
                        <div *ngIf="fathername.errors.required">Please enter father's name</div>
                    </div>
                </div>
                <div class="col-md-4">
                    <select class="inputFil inputFilSelect inputFilSelect2" [(ngModel)]="fatheroccupationval" formControlName="fatheroccupation">
                        <option value="null" selected>Father Occupation</option>
                        <option value="Employed">Employed</option>
                        <option value="Businessman">Businessman</option>
                        <option value="Professional">Professional</option>
                        <option value="Teacher">Teacher</option>
                        <option value="Govt. Employed">Govt. Employed</option>
                        <option value="Doctor">Doctor</option>
                        <option value="Farmer">Farmer</option>
                        <option value="Retired">Retired</option>
                        <option value="Not Employed">Not Employed</option>
                        <option value="Passed Away">Passed Away</option>

                    </select>
                    <div class="clear"></div>
                    <div class="animTest w3-animate-zoom" *ngIf="isSubmitted2 && fatheroccupation.invalid">
                        <div *ngIf="fatheroccupation.errors.required">Please select father's occupation</div>
                    </div>
                </div>
                <div class="col-md-4">
                    <input type="text" id="" value="" [(ngModel)]="mothernameval" formControlName="mothername" placeholder="Mother Name" class="inputFil">
                    <div class="clear"></div>
                    <div class="animTest w3-animate-zoom" *ngIf="isSubmitted2 && mothername.invalid">
                        <div *ngIf="mothername.errors.required">Please enter mother's name</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <select class="inputFil inputFilSelect inputFilSelect2" [(ngModel)]="motheroccupationval" formControlName="motheroccupation">
                        <option value="null" selected>Mother Occupation</option>
                        <option value="Employed">Employed</option>
                        <option value="Businesswoman">Businesswoman</option>
                        <option value="Professional">Professional</option>
                        <option value="Teacher">Teacher</option>
                        <option value="Govt. Employed">Govt. Employed</option>
                        <option value="Doctor">Doctor</option>
                        <option value="Farmer">Farmer</option>
                        <option value="Retired">Retired</option>
                        <option value="Homemaker">Homemaker</option>
                        <option value="Not Employed">Not Employed</option>
                        <option value="Passed Away">Passed Away</option>
                    </select>
                    <div class="clear"></div>
                    <div class="animTest w3-animate-zoom" *ngIf="isSubmitted2 && motheroccupation.invalid">
                        <div *ngIf="motheroccupation.errors.required">Please select mother's occupation</div>
                    </div>
                </div>
                <div class="col-md-4">
                    <input type="text" id="" value="" maxlength="10" [(ngModel)]="gaurdiancontactval" formControlName="gaurdiancontact" placeholder="Father/Mother/Guardian Contact No." class="inputFil">
                    <div class="clear"></div>
                    <div class="animTest w3-animate-zoom" *ngIf="isSubmitted2 && gaurdiancontact.invalid">
                        <div *ngIf="gaurdiancontact.errors.required">Please enter Father’s/Mother’s/Guardian’s contact no.</div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-4">
                    <select class="inputFil inputFilSelect inputFilSelect2" formControlName="familystatus" [(ngModel)]="familystatusval">
                        <option value="null" selected>Family Status</option>
                        <option value="Middle Class">Middle Class</option>
                        <option value="Upper Middle Class">Upper Middle Class</option>
                        <option value="Rich / Affluent">Rich / Affluent</option>
                    </select>
                    <div class="clear"></div>
                    <div class="animTest w3-animate-zoom" *ngIf="isSubmitted2 && familystatus.invalid">
                        <div *ngIf="familystatus.errors.required">Please select Family Status</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-4">
                    <select class="inputFil inputFilSelect inputFilSelect2" [(ngModel)]="familytypeval" formControlName="familytype">
                        <option value="null" selected>Family Type</option>
                        <option value="Joint Family">Joint Family</option>
                        <option value="Nuclear Family">Nuclear Family</option>
                    </select>
                    <div class="clear"></div>
                    <div class="animTest w3-animate-zoom" *ngIf="isSubmitted2 && familytype.invalid">
                        <div *ngIf="familytype.errors.required">Please select Family Type</div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-4">
                    <select class="inputFil inputFilSelect inputFilSelect2" [(ngModel)]="familyvalueval" formControlName="familyvalue">
                        <option value="null" selected>Family Value</option>
                        <option value="Liberal">Liberal</option>
                        <option value="Moderate">Moderate</option>
                        <option value="Traditional">Traditional</option>
                        <option value="Orthodox">Orthodox</option>
                    </select>
                    <div class="clear"></div>
                    <div class="animTest w3-animate-zoom" *ngIf="isSubmitted2 && familyvalue.invalid">
                        <div *ngIf="familyvalue.errors.required">Please select Family Value</div>
                    </div>
                </div>
            </div>
       
        </div>
    </div>
            <input type="button" name="previous" (click)="showsecondscreen();" class="previous action-button" value="Previous" />
            <input type="submit" name="next" class="next action-button" value="Next" />
        </form>
        
                    <form id="four" [formGroup]="fourForm" (ngSubmit)="fourPost(fourForm.value)">
            <div class="overDiv overDivBrows">
                <div class="contain animated bounce">
                    <input type="hidden" formControlName="hiddenFile" [(ngModel)]="hiddenFileValue">
                    <div class="alert"></div>

                    <div id='img_contain' *ngIf="imageUrl && imageUrl !== ''">
                        <img id="blah" align='middle' [src]="imageUrl" alt="your image" title=''/>
                    </div> 


                    <div class="input-group"> 
                    <div class="custom-file2">
                        
                    <input type="file" name="profilePhoto" accept="image/*" (change)="onChangeImage($event)" id="inputGroupFile01" class="imgInp custom-file-input" aria-describedby="inputGroupFileAddon01" >
                    <label class="custom-file-label" for="inputGroupFile01">Choose Profile Photo</label>
                  </div>

                  <!-- <div class="clear"></div>
                    <div class="animTest w3-animate-zoom" *ngIf="profilePhoto.touched && profilePhoto.invalid" >
                        <div *ngIf="profilePhoto.errors.required">Please upload your Photo</div>
                    </div> -->

                <div class="proInnerCont">
                <textarea class="textAria" rows="3" placeholder="{{AboutProfile}}" formControlName="fewlineyou"></textarea>

                <div class="clear"></div>
                    <div class="animTest w3-animate-zoom" *ngIf="fewlineyou.touched && fewlineyou.invalid" >
                        <div *ngIf="fewlineyou.errors.required">Please enter few lines for your profile.</div>
                    </div>

                </div>

                <div class="proInnerCont">
                <textarea class="textAria" rows="3" placeholder="Few lines About Partner" formControlName="fewlinepartner"></textarea>
                <!-- <div class="clear"></div>
                    <div class="animTest w3-animate-zoom" *ngIf="fewlinepartner.touched && fewlinepartner.invalid" >
                        <div *ngIf="fewlinepartner.errors.required">Please upload your Photo</div>
                    </div> -->
                </div>
                </div>
                
    </div>
            </div>
            <input type="button" name="previous" (click)="showthirdscreen();" class="previous action-button" value="Previous" />
            <input type="submit" name="submit" class="submit action-button" value="Submit" />
        </form>
        <!-- </fieldset> -->
    </form>
    </div>
    
    </div>
</div>
    
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/1.9.1/jquery.min.js" type="text/javascript"></script>