import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {MembershipPlanService} from '../services/membership-plan.service';
import {ViewmemberdetailsService} from '../services/viewmemberdetails.service';
import { HttpClient } from '@angular/common/http';
import * as $ from 'jquery';
import { WebsiteConstants } from '.././global/website-constants';


@Component({
  selector: 'app-upgrade-account',
  templateUrl: './upgrade-account.component.html',
  styleUrls: ['./upgrade-account.component.css']
})
export class UpgradeAccountComponent implements OnInit {

  public payuform: any = {};
  disablePaymentButton: boolean = true;

  onemonth:any; threemonth:any; sixmonth:any; yearmonth:any; 
  onepktitle:any; onepkdisamt:any; onediscount:any; onepkpayamt:any;
  oneMpkgID:any; oneMpkgPeriodinDays:any;

  threepktitle:any; threepkdisamt:any; threediscount:any; threepkpayamt:any;
  threeMpkgID:any; threeMpkgPeriodinDays:any;

  sixktitle:any; sixpkdisamt:any; sixdiscount:any; sixpkpayamt:any;
  sixMpkgID:any; sixMpkgPeriodinDays:any;

  yearpktitle:any; yearpkdisamt:any; yeardiscount:any; yearpkpayamt:any;
  yearMpkgID:any; yearMpkgPeriodinDays:any;

  displaybanner:any; displaybannerflag:any="0"; logspinner=false; photopath:any;
  onepkoriamt:any; threepkoriamt:any; sixpkoriamt:any; yearpkoriamt:any; userProfileData:any;

  masterUrl = WebsiteConstants.MasterUrl;

  constructor(private service:MembershipPlanService, private route : Router, private http: HttpClient, private memberservice : ViewmemberdetailsService) { }

  ngOnInit(): void 
  {
      this.photopath = this.masterUrl;
      this.logspinner = true;
      this.onemonth = localStorage.getItem('AppOneMonthFlag');
      this.threemonth = localStorage.getItem('AppThreeMonthFlag');
      this.sixmonth = localStorage.getItem('AppSixMonthFlag');
      this.yearmonth = localStorage.getItem('AppYearFlag');
      

      if(localStorage.getItem('AppOneMonthFlag')=='1')
      {
          setTimeout(()=>{                           
            this.onemonthplan();
          }, 1000);
        
      }
      if(localStorage.getItem('AppThreeMonthFlag')=='1')
      {
        
        setTimeout(()=>{                           
            this.threemonthplan();
          }, 1500);
      }
      if(localStorage.getItem('AppSixMonthFlag')=='1')
      {
        
        setTimeout(()=>{                           
            this.sixmonthplan();
          }, 2000);
      }
      if(localStorage.getItem('AppYearFlag')=='1')
      {
        
        setTimeout(()=>{                           
            this.yearplan()
          }, 2500);
      }

      setTimeout(()=>{                           
            this.displayofferimg()
          }, 3000);


      

          

  }

  ngDoCheck() {
      if (this.logspinner ==true) {

                setTimeout(()=>{                           
            this.logspinner = false;
          }, 3500);
      }
    }
  

  displayofferimg()
  {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let body = new FormData();
        body.append('CasteID',localStorage.getItem('UserCasteID'));
        this.service.upgradeplanimg(body,headers).subscribe(Response =>{
                this.displaybanner = this.photopath+Response['data']['DiscountBanner'];
                this.displaybannerflag = Response['data']['Disdisplay'];
                
        })
  }  

  onemonthplan()
  {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let body = new FormData();
        body.append('CasteID',localStorage.getItem('UserCasteID'));
        body.append('PkgPeriod','1 Month');
        this.service.oneemonthplan(body,headers).subscribe(Response =>{
          this.onepktitle=Response['data'][0]['MpkgTitle']; 
          this.onepkdisamt=Response['data'][0]['MpkgDiscountedAmt']; 
          this.onediscount=Response['data'][0]['MpkgDiscount']; 
          this.onepkpayamt=Response['data'][0]['MpkgPayAmt'];
          this.onepkoriamt=Response['data'][0]['MpkgOriginalAmt'];
          this.oneMpkgID = Response['data'][0]['MpkgID'];
          this.oneMpkgPeriodinDays = Response['data'][0]['MpkgPeriodinDays'];
        
        })
  }

  threemonthplan()
  {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let body = new FormData();
        body.append('CasteID',localStorage.getItem('UserCasteID'));
        body.append('PkgPeriod','3 Months');
        this.service.threeemonthplan(body,headers).subscribe(Response =>{
            this.threepktitle=Response['data'][0]['MpkgTitle']; 
            this.threepkdisamt=Response['data'][0]['MpkgDiscountedAmt']; 
            this.threediscount=Response['data'][0]['MpkgDiscount']; 
            this.threepkpayamt=Response['data'][0]['MpkgPayAmt'];
            this.threepkoriamt = Response['data'][0]['MpkgOriginalAmt'];
            this.threeMpkgID = Response['data'][0]['MpkgID'];
            this.threeMpkgPeriodinDays = Response['data'][0]['MpkgPeriodinDays'];
        })

  
  }

  sixmonthplan()
  {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let body = new FormData();
        body.append('CasteID',localStorage.getItem('UserCasteID'));
        body.append('PkgPeriod','6 Months');
        this.service.sixemonthplan(body,headers).subscribe(Response =>{
              this.sixktitle=Response['data'][0]['MpkgTitle']; 
              this.sixpkdisamt=Response['data'][0]['MpkgDiscountedAmt']; 
              this.sixdiscount=Response['data'][0]['MpkgDiscount']; 
              this.sixpkpayamt=Response['data'][0]['MpkgPayAmt'];
              this.sixpkoriamt = Response['data'][0]['MpkgOriginalAmt'];
              this.sixMpkgID = Response['data'][0]['MpkgID'];
              this.sixMpkgPeriodinDays = Response['data'][0]['MpkgPeriodinDays'];
        })

  
  }

  yearplan()
  {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let body = new FormData();
        body.append('CasteID',localStorage.getItem('UserCasteID'));
        body.append('PkgPeriod','1 Year');
        this.service.yearhplan(body,headers).subscribe(Response =>{
              this.yearpktitle=Response['data'][0]['MpkgTitle']; 
              this.yearpkdisamt=Response['data'][0]['MpkgDiscountedAmt']; 
              this.yeardiscount=Response['data'][0]['MpkgDiscount']; 
              this.yearpkpayamt=Response['data'][0]['MpkgPayAmt'];
              this.yearpkoriamt = Response['data'][0]['MpkgOriginalAmt'];
              this.yearMpkgID = Response['data'][0]['MpkgID'];
              this.yearMpkgPeriodinDays = Response['data'][0]['MpkgPeriodinDays'];
        })

  }


  confirmPayment(plan,amount,MPKGID,planperday) 
  {
      
   this.logspinner = true;
   let headers = new Headers({'Content-Type' : 'Application/json'})
   let body = new FormData

   body.append('VwName', localStorage.getItem('UsercasteView'));
   body.append('MemberID',localStorage.getItem('UserMemberID'));
   body.append('MembIsPaid', '1'); 
   body.append('LoggedUserid',localStorage.getItem('UserMemberID')); 

   return this.memberservice.memberdetails(body,headers).subscribe(responce =>{
      
      this.userProfileData = responce['data'];

     var hiddenplan = $("#"+plan).val();
     var hiddenplanamt = $("#"+amount).val();
     var hiddenMPKGID = $("#"+MPKGID).val();
     var hiddenPeriodinDays = $("#"+planperday).val();

     localStorage.setItem("hiddenSamt",hiddenplanamt);
     if(plan=='plan1')
     {
      localStorage.setItem("hiddenSperiod",'1 Month');
     }
     
     if(plan=='plan2')
     {
      localStorage.setItem("hiddenSperiod",'3 Months');
     }
     
     if(plan=='plan3')
     {
      localStorage.setItem("hiddenSperiod",'6 Months');
     }
     
     if(plan=='plan4')
     {
      localStorage.setItem("hiddenSperiod",'1 Year');
     }
     
     

     
      let headers = new Headers({ 'Content-Type': 'application/json' });
      let body = new FormData();
      body.append('product_info',hiddenplan);
      body.append('Planprice',hiddenplanamt);
      body.append('customer_name',localStorage.getItem('UserFullName'));
      body.append('customer_email',this.userProfileData.MemberEmailID);
      body.append('mobile_number',this.userProfileData.MemberContactNo);
      body.append('customer_address','');
      body.append('udf1',localStorage.getItem('UserMemberID'));
      body.append('udf2',hiddenMPKGID);
      body.append('udf3','WebsiteOnline');
      body.append('udf4',hiddenPeriodinDays);
      
      this.service.makePayment(body,headers).subscribe(Response =>{
      console.log("Response payment"+Response['data'].action)
      let fullname = Response['data'].name.split(" ");
      let firstpayname = fullname[0];
      let lastpayname = fullname[1];
      //alert(Response['data'].hashstring);
      //hashstring
      let htmlBody = `<html><body>
<form action="${Response['data'].action}" method='post' id="payu_form" enctype="multipart/form-data">
<input type="hidden" name="key" value="${Response['data'].mkey}" />
<input type="hidden" name="txnid" value="${Response['data'].tid}" />
<input type="hidden" name="productinfo" value="${Response['data'].productinfo}" />
<input type="hidden" name="amount" value="${Response['data'].amount}" />
<input type="hidden" name="email" value="${Response['data'].mailid}" />
<input type="hidden" name="firstname" value="${firstpayname}" />
<input type="hidden" name="lastname" value="${lastpayname}" />
<input type="hidden" name="surl" value="${Response['data'].sucess}" />
<input type="hidden" name="furl" value="${Response['data'].failure}" />
<input type="hidden" name="phone" value="${Response['data'].phoneno}" />
<input type="hidden" name="hash" value="${Response['data'].hash}" />
<input type="hidden" name="abc" value="${Response['data'].hashstring}" />
<input type="hidden" name="udf1" value="${Response['data'].udf1}" />
<input type="hidden" name="udf2" value="${Response['data'].udf2}" />
<input type="hidden" name="udf3" value="${Response['data'].udf3}" />
<input type="hidden" name="udf4" value="${Response['data'].udf4}" />


 </form>
<script type="text/javascript">document.getElementById("payu_form").submit();</script>
</body>
</html>`;
const winURL = URL.createObjectURL(new Blob([htmlBody],{type:"text/html"}));
window.location.href = winURL;

      })


      })

  }


  //1 Month / 3 Months / 6 Months / 1 Year
}
