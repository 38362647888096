import { Injectable } from '@angular/core';
import {HttpClient,HttpHandler, HttpHeaders} from '@angular/common/http'
import { Observable,of } from 'rxjs';
import { catchError} from 'rxjs/Operators';
import { retry } from 'rxjs/internal/operators/retry';
import { WebsiteConstants } from '.././global/website-constants';
@Injectable({
  providedIn: 'root'
})
export class NewMatchesService {

  private masterUrl = WebsiteConstants.MasterUrl;
  private siteurl=this.masterUrl;
  private newMatchesUrl = this.siteurl+"DashboardNewMatches/DashboardNewMatches";
  private advancesearchUrl = this.siteurl+"GetProfilesbyAdvanceSearch/GetProfilesbyAdvanceSearch";

  constructor(private http : HttpClient) { }
  newMatches(body,Headers)
  {
     return this.http.post(this.newMatchesUrl,body,Headers)
  }


  advancesearch(body,Headers)
  {
     return this.http.post(this.advancesearchUrl,body,Headers)
  }

}
