import { Component, OnInit } from '@angular/core';
import {QuickSearchByIdService} from '../services/quick-search-by-id.service'
import {Router} from '@angular/router'
import * as $ from 'jquery';
import { retry } from 'rxjs/Operators';
import { WebsiteConstants } from '.././global/website-constants';
@Component({
  selector: 'app-quick-search',
  templateUrl: './quick-search.component.html',
  styleUrls: ['./quick-search.component.css']
})
export class QuickSearchComponent implements OnInit {
  photopath:any;profilePic:any;ProfileName:any;ProfileId:any; userIsPaid:any;quickSearchRes;
  searchtxt:any; logspinner:boolean; quicksearchresult:any; message:any="No profiles are available.";
  SiteUrl = WebsiteConstants.SiteUrl; 
  sitetitle = WebsiteConstants.SiteTitle; 
  masterUrl = WebsiteConstants.MasterUrl;

  constructor(private quickSearch : QuickSearchByIdService, private route : Router) { }

  ngOnInit(): void {
    this.photopath = this.masterUrl;
    this.profilePic = this.masterUrl+localStorage.getItem("UserPhotoPath");
    this.ProfileName = localStorage.getItem("UserFullName");
    this.ProfileId = localStorage.getItem("UserProfileID");
    this.userIsPaid = localStorage.getItem('UserIsPaid')

    
  }

  Advancesdiscover()
  {
    let link = ['my-discover-matches'];
    this.route.navigate(link);
  }


  Advancesearch()
  {
    let link = ['advance-search'];
    this.route.navigate(link);
  }
  quicksearchHash()
  {
    this.logspinner = true;
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let body = new FormData();
    let searchidbyval = this.searchtxt;
    body.append('LoggedUserid',localStorage.getItem('UserMemberID'));
    body.append('SearchbyNmId', "'"+searchidbyval+"'");
    this.quickSearch.quichSearchByIdPost(body,headers).subscribe(Response =>{

      if(Response['data']!==undefined)
      {
        this.quicksearchresult = Response['data'];
        this.message="";
        this.logspinner = false;
      }
      else
      {
        this.quicksearchresult=Response['status'];
        this.message = Response['message'];
        this.logspinner = false;
      }
      
    
    })

    
  }

  memberdetailspost(row)
  {
    // console.log(row.MemberID)
    localStorage.setItem('viewUser',row.MemberID)
    let link = ['/member-details'];
    this.route.navigate(link);
  }



}
