import { Component, OnInit } from '@angular/core';
import { MymatchesService } from '../services/mymatches.service';
import { WebsiteConstants } from '.././global/website-constants';

@Component({
  selector: 'app-scroll',
  templateUrl: './scroll.component.html',
  styleUrls: ['./scroll.component.css']
})
export class ScrollComponent implements OnInit {
  profilePic : any; ProfileName : any; ProfileId : any; mymatchesresult : any;
  photopath : any; memberdetailsresult:any; logspinner:boolean; prodetailspinner:boolean;
  logshortlistmespinner:boolean; lognearmespinner:boolean;
  profilematchlocation : any; partnerpreference:any; matchprofilepic:any;cityname:any
  shortlistmedash:any; newMatchesdash:any; nearmeval:any; shortlistedval:any; myprofilevar:any;
  logshortlistedspinner:boolean; viewedmyprofilespinner : boolean; newmatchesspinner:boolean;
  mymatchscrollresult:any
  spinner: any = false;
  notEmptyPost: boolean =true;
  notscrolly: any=true;
  mydataresult:any;
  SiteUrl = WebsiteConstants.SiteUrl; 
  sitetitle = WebsiteConstants.SiteTitle; 
  masterUrl = WebsiteConstants.MasterUrl;

  constructor(private service:MymatchesService) { }

  ngOnInit(): void {

    this.photopath = this.masterUrl;
    this.profilePic = this.masterUrl+localStorage.getItem("UserPhotoPath");
    this.ProfileName = localStorage.getItem("UserFullName");
    this.ProfileId = localStorage.getItem("UserProfileID");
    this.cityname = localStorage.getItem("UserCityName");

    this.mymatchesscrolldash()
  }
  mymatchesscrolldash = function()
  { 
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let body = new FormData();
    body.append('MemberID',localStorage.getItem('UserMemberID'));
    body.append('FromLimit', '0');
    body.append('ToLimit', '40');

    body.append('OrderProfiles', 'Asc');
    this.service.mymachesurl(body,headers).subscribe(Res =>{
      console.log("helloooo"+Res.data)
    this.mymatchscrollresult=Res.data;
   
    })
}

onScroll(){
  console.log("scrolled")
  if(this.notscrolly && this.notEmptyPost){
    this.spinner=true;
    this.notscrolly = false;
    this.
    loadnextpost();
  }
 
}

loadnextpost(){
  let headers = new Headers({ 'Content-Type': 'application/json' });
  let body = new FormData();
  body.append('MemberID',localStorage.getItem('UserMemberID'));
  body.append('FromLimit', '41');
  body.append('ToLimit', '80');
  body.append('OrderProfiles', 'Asc');
  this.service.mymachesurl(body,headers).subscribe(Res =>{
  this.mymatchscrollresult=Res['data'];

  const newPost = ['data']
  this.spinner = false;
 if(newPost.length === 0)
 {
   this.notEmptyPost = false
 }
   this.mydataresult = this.mydataresult.concat(newPost)
   this.notscrolly = true
  })
}

}
