import { Injectable } from '@angular/core';
import {HttpClient,HttpHandler, HttpHeaders} from '@angular/common/http';
import { retry } from 'rxjs/internal/operators/retry';
import { WebsiteConstants } from '.././global/website-constants';

@Injectable({
  providedIn: 'root'
})
export class PartnerpreferenceService {

private masterUrl = WebsiteConstants.MasterUrl;  
private partnerpreferenceurl = this.masterUrl+"ViewPartnerPreferenceDetails/ViewPartnerPreferenceDetails"
  constructor(private http : HttpClient) { }
  partnerpreference(body,Headers)
  {
    return this.http.post(this.partnerpreferenceurl,body,Headers)
  }
}
