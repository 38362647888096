import { Component, OnInit } from '@angular/core';
import {HelpScreenService} from '../services/help-screen.service'
@Component({
  selector: 'app-help-screen',
  templateUrl: './help-screen.component.html',
  styleUrls: ['./help-screen.component.css']
})
export class HelpScreenComponent implements OnInit {
  helpcontact:any; helpemail:any; fraudcontact:any; fraudemail:any; 
  logspinner:boolean;
  constructor(private help : HelpScreenService) { }

  ngOnInit(): void {
    this.helpScrrenHash()
  }
  helpScrrenHash()
  {
    this.logspinner = true;
    let headers = new Headers({'Content-Type' : 'Application/json'})
    let body = new FormData
 
    body.append('CasteID', localStorage.getItem('UserCasteID'))  
    return this.help.helpScrrenPost(body,headers).subscribe(helpResponce =>{

      
      this.helpcontact = helpResponce['data'][0]['Con_HelpllineContactNo'];
      this.helpemail = helpResponce['data'][0]['Con_HelpEmailID'];
      this.fraudcontact = helpResponce['data'][0]['Con_FraudAssistContactNo'];
      this.fraudemail = helpResponce['data'][0]['Con_FraudEmailID'];
      this.logspinner = false;
      
    })
    
  }

}
