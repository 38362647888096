
<div class="dashboardMain" id="activeButtDIV">
    <app-left-menu></app-left-menu>    
         <div class="admin-panel clearfix">
             <div class="main">
                 <app-top-menu></app-top-menu>
                 
             <div class="samplecover-spin" id="samplecover-spin" *ngIf="logspinner"></div> 
            <div class="dashMainInner" *ngIf="!logspinner">
                <div class="matchesInner">
                        <div class="row">
                          
                          <div class="col-md-12">
                            <h3>Thanks for making payment to opting Subscription.</h3>
                            <p>Profile ID - {{profileid}}</p>
                            <p>Name - {{profilename}}</p>
                            <p>Membership period - {{Mperiod}}</p>
                            <p>Subscription paid Amount - {{paidAmt}}</p>
                          </div>
                        </div>
                      
                </div>
            </div>
 
 
         </div>
     </div>
 </div>


