<div class="slidebarOuter">
    <div class="slidebar">
<div class="overDiv overDivBrows">
          <div class="contain animated bounce">
          <div class="upPicOuter">
            <label class=newbtn>
                <img id="blah2" [src]='profilePic' >
            </label>
          </div>
      </div>
      <p class="proname">{{ProfileName}}</p>
      <p class="proname pronameID">({{ProfileId}})</p>
      <!-- *ngIf="isphotoavailable" -->
      <p *ngIf="isphotoavailable" class="proname pronameID photoTex">Profile with photo give 10 times better response. Add quality profile photo.</p>
  </div>
  <ul>
   
    <div *ngIf="userIsPaid == 0">
    <li class="upgrdOut"><a href="Javascript:void(0)" (click)="upgrademembership()" class="upgrdplan"><i class="fa fa-upgrade"></i>Upgrade to Membership</a><b class="offerTx" data-toggle="modal" data-target="#bannerModal" *ngIf="userIsPaid == 0  && AppDiscountBannerFlag==1">Offer</b></li>
    </div>

    <div *ngIf="userIsPaid == 1">
      <li class="upgrdOut"><a href="Javascript:void(0)" class="upgrd"><i class="fa fa-upgrade"></i>Subscription Expiry: {{usersubexpirydate}}</a></li>
      </div>

    <li><a href="Javascript:void(0)" (click)="Advancesearch()"><i class="fa fa-filter"></i>Advance Search</a></li>
    <li><a href="Javascript:void(0)" (click)="discoverMatchhash()"><i class="fa fa-binoculars"></i>My Descover Matches</a></li>
    <li><a href="Javascript:void(0)" (click)="updateProfileHash()"><i class="fa fa-user-plus"></i>Update Profile</a></li>
    <li><a href="Javascript:void(0)" (click)="editPreference()"><i class="fa fa-edit"></i>Edit Preference</a></li>
    <li><a href="Javascript:void(0)" (click)="photogallery()"><i class="fa fa-photo"></i>Photo Gallery</a></li>
    <!-- <li><a href="#" data-toggle="modal" data-target="#raeUsM"><i class="fa fa-star-half-full"></i>Rate Us</a></li> -->
    <li id="Drsettings" (click)="slidetoggle()"><a><i class="fa fa-cogs"></i>Settings<i class="fa fa-chevron-down" style="    float: right; line-height: 36px;"></i></a>
        <ul class="dropSetting" >
          <li><a href="Javascript:void(0)" (click)="changepassword()">Change Password</a></li>
          <li><a href="Javascript:void(0)" (click)="DeleteAcopenModel()">Delete Account</a></li>
          <li><a href="terms-use" target="_blank">Terms & Conditions</a></li>
          <li><a href="privacy-policy" target="_blank">Privacy Policy</a></li>
        </ul>
    </li>
    <li><a href="Javascript:void(0)" (click)="helpScreen()"><i class="fa fa-question-circle"></i>Help</a></li>
    <li><a href="Javascript:void(0)" (click)="Stories()"><i class="fa fa-heart"></i>Success Stories</a></li>
    <li><a href="Javascript:void(0)" (click)="logout()"><i class="fa fa-power-off"></i>Logout</a></li>
  </ul>
</div>
<div id="slideRight" (click)="sidebar()"><div id="mytoggle" class="fa fa-chevron-left desktopArrow rotate"></div><div class="fa fa-chevron-right responssiveArrow rotate"></div></div>
</div>

<form [formGroup]="userProfileForm" (ngSubmit)="deletesubmit()">

<div class="modal fade" id="deleteAc" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" >

  
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Delete Your Profile</h5>
        <button type="button" class="close" (click)="DeleteAccloseModel()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">  
          
          <div class="col-md-12">
            <p>
              <input type="radio" id="married1" formControlName="reasonval" value="Married/Marriage Fixed" class="maleInp" (change)="selectReason($event)">
              <label for="married1" class="maleStyle">Married/Marriage Fixed</label>
            </p>
            <p>
              <input type="radio" id="married2" formControlName="reasonval" value="Other Reason" class="maleInp" (change)="selectReason($event)">
              <label for="married2" class="maleStyle">Other Reason</label>
            </p>

        <div class="animTest w3-animate-zoom" *ngIf="isSubmitted && reasonval.invalid">
        <div *ngIf="reasonval.errors.required">Please select reason</div>
        </div> 

          </div>
          <div class="col-md-12" id="selectsource" style="display: none;">
            <h5 class="deleHeading">Select The Source</h5>
          </div>
          <div class="col-md-12" id="source1" style="display: none;">
              <p>
              <input type="radio" id="through1" formControlName="reason1" name="reason1" value="Through {{sitetitle}}" class="maleInp">
              <label for="through1" class="maleStyle">Through {{sitetitle}}</label>
            </p>
            <p>
              <input type="radio" id="through2" formControlName="reason1" name="reason1" value="Through Other Site" class="maleInp">
              <label for="through2" class="maleStyle">Through Other Site</label>
            </p>
            <p>
              <input type="radio" id="through3" formControlName="reason1" name="reason1" value="Through Other Source" class="maleInp">
              <label for="through3" class="maleStyle">Through Other Source</label>
            </p>

            <div class="animTest w3-animate-zoom" *ngIf="isSubmitted && reason1.invalid">
              <div *ngIf="reason1.errors.required">Please select the source</div>
          </div>

          </div>

          <div class="col-md-12" id="source2" style="display: none;">
              <p>
              <input type="radio" id="through4" formControlName="reason2" name="reason2" value="Prefer to Search later" class="maleInp">
              <label for="through1" class="maleStyle">Prefer to Search later</label>
            </p>
            <p>
              <input type="radio" id="through5" formControlName="reason2" name="reason2" value="Not getting responses as expected" class="maleInp">
              <label for="through2" class="maleStyle">Not getting responses as expected</label>
            </p>
            <p>
              <input type="radio" id="through6" formControlName="reason2" name="reason2" value="Do not with to Specify" class="maleInp">
              <label for="through3" class="maleStyle">Do not with to Specify</label>
            </p>

             <div class="animTest w3-animate-zoom" *ngIf="isSubmitted && reason2.invalid">
              <div *ngIf="reason2.errors.required">Please select the source</div>
          </div>
          </div>

          <div class="col-md-12" id="datesource" style="display: none;">
            <h5 class="deleHeading">Date Of Marriage (Optional)</h5>
          </div>
          <div class="col-md-12" id="marriegesource" style="display: none;">
            <p>Please Select Your Date Of Marriege</p>
            <input type="date" class="deleDate" formControlName="marriegedate"  placeholder="dd-mm-yyyy" name="">
          </div>
          <div class="col-md-12">
            <h5 class="deleHeading">Share Your Experience (Optional)</h5>
            <textarea class="deleText"  formControlName="experienceval" placeholder="Please Share Your Experience Here"></textarea>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="DeleteAccloseModel()">No</button>
        <button type="submit" class="btn btn-primary">Delete Account</button>
      </div>
  </div>
</div>
</div>
</form>



<!-- Kailas Modal-->
<!-- <button data-toggle="modal" data-target="#bannerModal" data-dismiss="modal" class="btn btn-primary">Ok</button> -->
<div class="bannerModalMain">
  <div class="modal" id="bannerModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">{{FlashHeading}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="banrModImOuter">
            <img [src]="FlashBannerPath"/>
          </div>
        </div>
        <div class="modal-body" style="text-align: center;">
          <h3 class="banHeading">{{FlashHeading}}</h3>
          <p>{{FlashDisPack1}}</p>
          <p>{{FlashDisPack2}}</p>

          <button type="button" (click)="upgradenow()" data-dismiss="modal" class="upgrBNew">Upgrade Now</button>
          
          <input id="pic" class='pis' onchange="readURL(this);" type="file" >
        </div>
    </div>
  </div>
  </div>
</div>