import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { MymatchesService } from '../services/mymatches.service';
import {ViewmemberdetailsService} from '../services/viewmemberdetails.service'
import {ProfilematchService} from '../services/profilematch.service'
import {PartnerpreferenceService} from '../services/partnerpreference.service'
import {ShortlistedMeService} from '../services/shortlisted-me.service'
import {NewMatchesService} from '../services/new-matches.service'
import {Router} from '@angular/router';
import {NearmeService} from '../services/nearme.service'
import {ShortlistedService} from '../services/shortlisted.service'
import {ViewmyprofileService} from '../services/viewmyprofile.service'
import { concat } from 'rxjs/internal/operators/concat';
import { LogoutService } from '../services/logout.service';
import { WebsiteConstants } from '.././global/website-constants';
@Component({
  selector: 'app-my-dashboard',
  templateUrl: './my-dashboard.component.html',
  styleUrls: ['./my-dashboard.component.css']
})
export class MyDashboardComponent implements OnInit {
  profilePic : any; ProfileName : any; ProfileId : any; mymatchesresult : any;
  photopath : any; memberdetailsresult:any; logspinner:boolean; prodetailspinner:boolean;
  logshortlistmespinner:boolean; lognearmespinner:boolean;
  profilematchlocation : any; partnerpreference:any; matchprofilepic:any;cityname:any
  shortlistmedash:any; newMatchesdash:any; nearmeval:any; shortlistedval:any; myprofilevar:any;
  logshortlistedspinner:boolean; viewedmyprofilespinner : boolean; newmatchesspinner:boolean;
  mymatchscrollresult:any
  spinner: any = false;
  notEmptyPost: boolean =true;
  notscrolly: any=true;

  SiteUrl = WebsiteConstants.SiteUrl; 
  sitetitle = WebsiteConstants.SiteTitle; 
  masterUrl = WebsiteConstants.MasterUrl;

  constructor(private service:MymatchesService,private route:Router, private memberservice : ViewmemberdetailsService, private matchprofilservice: ProfilematchService, private partnerservice: PartnerpreferenceService, private shortlistedmeservicedash :ShortlistedMeService, private newMatchesService:NewMatchesService , private nearmeservice:NearmeService, private shortlisted:ShortlistedService, private viewmyprofilelog:ViewmyprofileService,private logoutservice:LogoutService ) { }

  ngOnInit(): void {
    this.photopath = this.masterUrl;
    this.profilePic = this.masterUrl+localStorage.getItem("UserPhotoPath");
    this.ProfileName = localStorage.getItem("UserFullName");
    this.ProfileId = localStorage.getItem("UserProfileID");
    this.cityname = localStorage.getItem("UserCityName");

    this.myMachesfun()
  }

  myMatchesPost = function()
  {
    this.logspinner = true;
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let body = new FormData();
    body.append('MemberID',localStorage.getItem('UserMemberID'));
    body.append('FromLimit', '0');
    body.append('ToLimit', '40');
    body.append('OrderProfiles', 'Asc');
    this.service.mymachesurl(body,headers).subscribe(Response =>{
      console.log(Response)
    this.mymatchesresult=Response.data;
    this.logspinner = false;
    })
}

// onScroll()
//   {
//     alert("hii")
//         console.log("scrolled")
//         if(this.notscrolly && this.notEmptyPost)
//         {
//             //this.spinner=true;
//             this.logspinner=true;
//             this.notscrolly = false;
//             this.loadnextpost();
//         }
//   }


// loadnextpost(){
//   let headers = new Headers({ 'Content-Type': 'application/json' });
//   let body = new FormData();
//   body.append('MemberID',localStorage.getItem('UserMemberID'));
//   body.append('FromLimit', '41');
//   body.append('ToLimit', '80');
//   body.append('OrderProfiles', 'Asc');
//   this.service.mymachesurl(body,headers).subscribe(Response =>{
//   this.mymatchesresult=Response['data'];

//   const newPost = Response['data'];
//   this.spinner = false;
//  if(newPost.length === 0)
//  {
//    this.notEmptyPost = false
//  }
//    this.mymatchesresult = this.mymatchesresult.concat(newPost)
//    this.notscrolly = true
//   })
// }

memberdetailspost = function(row)
{

  $('#mymatchid').removeClass('selected');
  $('#mynewmatchid').removeClass('selected');
  $('#nearmeid').removeClass('selected');
  $('#shortlistedid').removeClass('selected');
  $('#viewmyprofileid').removeClass('selected');
  $('#shortlistedmeid').removeClass('selected');

  $("#welcomedash").hide();
  $('#myMatche').removeClass("showWeb");
  $("#newMatche").removeClass("showWeb");
  $("#nearMe").removeClass("showWeb");
  $("#shortlisted").removeClass("showWeb");
  $("#viewedmyProfile").removeClass("showWeb");
  $("#shortlistedMe").removeClass("showWeb");

  $('#proDetails').addClass("showWeb");
  this.prodetailspinner=true;
  let headers = new Headers({'Content-type' : 'application/json'});
  let body = new FormData();
  
  body.append('VwName', localStorage.getItem('UsercasteView'));
  body.append('MemberID',row.MemberID);
  body.append('MembIsPaid',localStorage.getItem('UserIsPaid'));
  body.append('LoggedUserid',localStorage.getItem('UserMemberID'));  //localStorage.getItem('UserMemberID')

  localStorage.setItem('viewProfileId',row.MemberID);
  this.memberservice.memberdetails(body,headers).subscribe(Result =>{
  console.log("Profile Details====>"+Result)
  this.memberdetailsresult = Result.data;
    
    this.mymatchprofile();
    //this.nearmepost()
  
  })
}

mymatchprofile = function()
{
  let headers = new Headers({'Content-type' : 'application/json'});
  let body = new FormData();
  body.append('MemberID',localStorage.getItem('UserMemberID'));
  body.append('MembCityNM',localStorage.getItem('UserCityID'));

  this.matchprofilservice.profilematch(body,headers).subscribe(ResponceData =>{
  console.log(ResponceData)
  this.profilematchlocation = ResponceData.data;
  this.prodetailspinner=false;
   this.partnerprefrencepost();
  })
}

partnerprefrencepost = function()
{
  let headers = new Headers({'Content-type' : 'application/json'});
  let body = new FormData();
  body.append('MemberID',localStorage.getItem('viewProfileId'))
  body.append('LoggedUserid',localStorage.getItem('UserMemberID'))

  this.partnerservice.partnerpreference(body,headers).subscribe(ResultData =>{
    console.log(ResultData)
    this.partnerpreference = ResultData.data;
    //this.prodetailspinner=false;
  })
}

shortlistmepost = function()
{
   this.logshortlistmespinner = true;
   let headers = new Headers({'Content-type' : 'application/json'});
   let body = new FormData();
   body.append('MemberID', localStorage.getItem('UserMemberID'))
   body.append('ViewNm', localStorage.getItem('UsercasteView'))
   
   this.shortlistedmeservicedash.shortlistedMe(body,headers).subscribe(Response =>{
   this.shortlistmedash = Response.data;
   this.logshortlistmespinner = false;
    
   })
}

newMatchesPost = function()
{
  this.newmatchesspinner = true;
  let headers = new Headers({'Content-Type' : 'application/json'});
  let body = new FormData

  body.append('MemberID',localStorage.getItem('UserMemberID'))

  this.newMatchesService.newMatches(body,headers).subscribe(ResultInfo =>{
    //console.log("kkkkkkkkkkkkkkkk"+ResultInfo.data)
    this.newMatchesdash = ResultInfo.data
    this.newmatchesspinner = false
  })
}

nearmepost = function()
{
  this.lognearmespinner = true;
  let headers = new Headers({'content-Type' : 'application/json'});
  let body = new FormData

  body.append('MemberID', localStorage.getItem('UserMemberID'))
  body.append('MembCityNM', localStorage.getItem('UserCityID'))

  this.nearmeservice.nearmefunction(body,headers).subscribe(nearmeInfo =>{
  
  this.nearmeval = nearmeInfo.data
  this.lognearmespinner = false;
  })
}

shortlistedpost = function()
{
  this.logshortlistedspinner = true;
   let headers = new Headers({'Content-Type' : 'application/json'})
   let body = new FormData

   body.append('MemberID', localStorage.getItem('UserMemberID'))
   body.append('ViewNm', localStorage.getItem('UsercasteView'))

   this.shortlisted.shortlistedfunction(body,headers).subscribe(shortlisted =>{
     //console.log("dadu"+JSON.stringify(shortlisted.data))
     this.shortlistedval = shortlisted.data
     this.logshortlistedspinner = false;
   })
}

viewmyprofiepost = function()
{  
  
   this.viewedmyprofilespinner = true;
    let headers = new Headers ({'Content-type' : 'application/json'})
    let body = new FormData
  
    body.append('MemberID', localStorage.getItem('UserMemberID'))
    body.append('ViewNm', localStorage.getItem('UsercasteView'))

    this.viewmyprofilelog.viewmyprofilefunction(body,headers).subscribe(myprofileval =>{
      console.log(myprofileval.data)
      this.myprofilevar = myprofileval.data
      this.viewedmyprofilespinner = false;
    })
}



Logout()
{
  let headers = new Headers({ 'Content-Type': 'application/json' });
  let body = new FormData();
  let loggedoutuser = localStorage.getItem('UserMemberID');
  body.append('LoggedUserid', loggedoutuser);

  this.logoutservice.logoutUrl(body,headers).subscribe((Response) =>{
  localStorage.clear();
  let link = ['/login'];
  this.route.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.route.navigate(link);
     
  });

  })
 

}


mykindfun()
{

  this.mymatchesscrolldash()
  $(document).ready(function()
  {
    $('#mymatchid').addClass('selected');
    $('#mynewmatchid').removeClass('selected');
    $('#nearmeid').removeClass('selected');
    $('#shortlistedid').removeClass('selected');
    $('#viewmyprofileid').removeClass('selected');
    $('#shortlistedmeid').removeClass('selected');

    $("#welcomedash").hide();
    $('#proDetails').removeClass("showWeb");
    $('#myMatche').addClass("showWeb");
    $("#newMatche").removeClass("showWeb");
    $("#nearMe").removeClass("showWeb");
    $("#shortlisted").removeClass("showWeb");
    $("#viewedmyProfile").removeClass("showWeb");
    $("#shortlistedMe").removeClass("showWeb");
      

  })


}

myMachesfun()
{
  this.myMatchesPost()
  $(document).ready(function()
  {
    $('#mymatchid').addClass('selected');
    $('#mynewmatchid').removeClass('selected');
    $('#nearmeid').removeClass('selected');
    $('#shortlistedid').removeClass('selected');
    $('#viewmyprofileid').removeClass('selected');
    $('#shortlistedmeid').removeClass('selected');

    $("#welcomedash").hide();
    $('#proDetails').removeClass("showWeb");
    $('#myMatche').addClass("showWeb");
    $("#newMatche").removeClass("showWeb");
    $("#nearMe").removeClass("showWeb");
    $("#shortlisted").removeClass("showWeb");
    $("#viewedmyProfile").removeClass("showWeb");
    $("#shortlistedMe").removeClass("showWeb");
      

  })
}

newMatchesfun()
{
  this.newMatchesPost()
  $(document).ready(function()
  { 
     $('#mymatchid').removeClass('selected');
     $('#mynewmatchid').addClass('selected');
     $('#nearmeid').removeClass('selected');
     $('#shortlistedid').removeClass('selected');
     $('#viewmyprofileid').removeClass('selected');
     $('#shortlistedmeid').removeClass('selected');
     
      $("#welcomedash").hide();
      $('#myMatche').removeClass("showWeb");
      $('#proDetails').removeClass("showWeb");
      $("#newMatche").addClass("showWeb");
      $("#nearMe").removeClass("showWeb");
      $("#shortlisted").removeClass("showWeb");
      $("#viewedmyProfile").removeClass("showWeb");
      $("#shortlistedMe").removeClass("showWeb");
  })
}

nearMefun()
{
  this.nearmepost()
  $(document).ready(function()
  { 
    $('#mymatchid').removeClass('selected');
    $('#mynewmatchid').removeClass('selected');
    $('#nearmeid').addClass('selected');
    $('#shortlistedid').removeClass('selected');
    $('#viewmyprofileid').removeClass('selected');
    $('#shortlistedmeid').removeClass('selected');

    $("#welcomedash").hide();
    $('#myMatche').removeClass("showWeb");
    $("#newMatche").removeClass("showWeb");
    $('#proDetails').removeClass("showWeb");
    $("#nearMe").addClass("showWeb");
    $("#shortlisted").removeClass("showWeb");
    $("#viewedmyProfile").removeClass("showWeb");
    $("#shortlistedMe").removeClass("showWeb");
  })
}

shortlistedfun()
{
  this.shortlistedpost()
  $(document).ready(function()
  { 
    $('#mymatchid').removeClass('selected');
    $('#mynewmatchid').removeClass('selected');
    $('#nearmeid').removeClass('selected');
    $('#shortlistedid').addClass('selected');
    $('#viewmyprofileid').removeClass('selected');
    $('#shortlistedmeid').removeClass('selected');

    $("#welcomedash").hide();
    $('#myMatche').removeClass("showWeb");
    $("#newMatche").removeClass("showWeb");
    $("#nearMe").removeClass("showWeb");
    $('#proDetails').removeClass("showWeb");
    $("#shortlisted").addClass("showWeb");
    $("#viewedmyProfile").removeClass("showWeb");
    $("#shortlistedMe").removeClass("showWeb");
  })
}

viewedMyProfilefun()
{
  this.viewmyprofiepost()
  $(document).ready(function()
  { 
    $('#mymatchid').removeClass('selected');
    $('#mynewmatchid').removeClass('selected');
    $('#nearmeid').removeClass('selected');
    $('#shortlistedid').removeClass('selected');
    $('#viewmyprofileid').addClass('selected');
    $('#shortlistedmeid').removeClass('selected');

    $("#welcomedash").hide();
    $('#myMatche').removeClass("showWeb");
    $("#newMatche").removeClass("showWeb");
    $("#nearMe").removeClass("showWeb");
    $("#shortlisted").removeClass("showWeb");
    $('#proDetails').removeClass("showWeb");
    $("#viewedmyProfile").addClass("showWeb");
    $("#shortlistedMe").removeClass("showWeb");
  })
}

shortlistedMefun()
{
  this.shortlistmepost()
  $(document).ready(function()
  { 
    $('#mymatchid').removeClass('selected');
    $('#mynewmatchid').removeClass('selected');
    $('#nearmeid').removeClass('selected');
    $('#shortlistedid').removeClass('selected');
    $('#viewmyprofileid').removeClass('selected');
    $('#shortlistedmeid').addClass('selected');
    
    $("#welcomedash").hide();
    $('#myMatche').removeClass("showWeb");
    $("#newMatche").removeClass("showWeb");
    $("#nearMe").removeClass("showWeb");
    $("#shortlisted").removeClass("showWeb");
    $("#viewedmyProfile").removeClass("showWeb");
    $('#proDetails').removeClass("showWeb");
    $("#shortlistedMe").addClass("showWeb");
  })
}

mymatchesscrolldash = function()
{ 
    this.logspinner = true;
  
  let headers = new Headers({ 'Content-Type': 'application/json' });
  let body = new FormData();
  body.append('MemberID',localStorage.getItem('UserMemberID'));
  body.append('FromLimit', '41');
  body.append('ToLimit', '80');
  body.append('OrderProfiles', 'Asc');
  this.service.mymachesurl(body,headers).subscribe(Res =>{
    console.log("helloooo"+Res.data)
  this.mymatchscrollresult=Res.data;
  this.logspinner = false;
  
  })
}

onScroll(){
  alert("hii")
console.log("scrolled")
if(this.notscrolly && this.notEmptyPost){
  this.spinner=true;
  this.notscrolly = false;
  this.loadnextpost();
}

}
loadnextpost(){
let headers = new Headers({ 'Content-Type': 'application/json' });
let body = new FormData();
body.append('MemberID',localStorage.getItem('UserMemberID'));
body.append('FromLimit', '41');
body.append('ToLimit', '80');
body.append('OrderProfiles', 'Asc');
this.service.mymachesurl(body,headers).subscribe(Res =>{
this.mymatchscrollresult=Res['data'];

const newPost = ['data']
this.spinner = false;
if(newPost.length === 0)
{
 this.notEmptyPost = false
}
 this.mymatchscrollresult = this.mymatchscrollresult.concat(newPost)
 this.notscrolly = true
})
}

}
