
<div class="dashboardMain" id="activeButtDIV">
   <app-left-menu></app-left-menu>    
        <div class="admin-panel clearfix">
            <div class="main">
                <app-top-menu></app-top-menu>
                <div id="myMatche" class="myMatchesStyle" >
                        <div class="mainContent clearfix">
                            <!-- <div class="samplecover-spin" id="samplecover-spin"></div> -->
                                <div class="dashMainCont" >
                                    <div class="dashMainInner">
                                        <div class="row">
              <div class="col-md-12" >
                <div class="bemberShipBanner" *ngIf="displaybannerflag == '1'">
                  <!-- <h1>Launching offer <span class="flatOf">Flat <span style="color: #fff700;">50%</span> off</span></h1> -->
                  <img id="blah2" [src]='displaybanner' >
                </div>
                <div class="membershipDiv">
                    <div class="row">

                      <div class="samplecover-spin" id="samplecover-spin" *ngIf="logspinner"></div>

                      <div class="col-md-4" *ngIf="onemonth != '0'">


                        <div class="memberShiInner">
                          <div class="promo">
                            <div class="deal">
                              <span>{{onepktitle}} Pack</span>
                              <span>Flat Rs.{{onepkdisamt}} OFF ON 1 Month PACKAGE</span>
                            </div>
                            <span class="price"><span style="font-size: 16px;
    color: #00c573;"> {{onediscount}}% OFF </span> <span class="hoverAmou"> <i class="fa fa-inr"></i> {{onepkpayamt}} </span> <span style="color: #bd0035; text-decoration: line-through; font-size: 18px;"> <i class="fa fa-inr"></i> {{onepkoriamt}} </span></span>
                            <ul class="features">
                              <li><i class="fa fa-phone"></i> Unlimited Call View</li>
                              <li><i class="fa fa-heart"></i> Unlimited Send Interest</li>
                              <li><i class="fa fa-search"></i> Unlimited Search Profile and View Details</li>   
                            </ul>
                            <input type="hidden" name="plan1" id="plan1" value="{{onepktitle}} Pack">
                            <input type="hidden" name="planamount1" id="planamount1" value="{{onepkpayamt}}">
                            <input type="hidden" name="MpkgID1" id="MpkgID1" value="{{oneMpkgID}}">
                            <input type="hidden" name="MpkgPeriodinDays1" id="MpkgPeriodinDays1" value="{{oneMpkgPeriodinDays}}">
                             <button class="price1" (click)="confirmPayment('plan1','planamount1','MpkgID1','MpkgPeriodinDays1')">Pay Now</button>
                          </div>
                        </div>

                        
                      </div>


                      <div class="col-md-4" *ngIf="threemonth != '0'">
                        <div class="memberShiInner">
                          <div class="promo">
                            <div class="deal">
                              <span>{{threepktitle}} Pack</span>
                              <span>Flat Rs.{{threepkdisamt}} OFF ON 3 Month PACKAGE</span>
                            </div>
                            <span class="price"><span style="font-size: 16px;
    color: #00c573;">{{threediscount}}% OFF</span> <span class="hoverAmou"><i class="fa fa-inr"></i>{{threepkpayamt}}</span> <span style="color: #bd0035; text-decoration: line-through; font-size: 18px;"><i class="fa fa-inr"></i>{{threepkoriamt}}</span></span>
                            <ul class="features">
                              <li><i class="fa fa-phone"></i> Unlimited Call View</li>
                              <li><i class="fa fa-heart"></i> Unlimited Send Interest</li>
                              <li><i class="fa fa-search"></i> Unlimited Search Profile and View Details</li>   
                            </ul>
                            
                            <input type="hidden" name="plan2" id="plan2" value="{{threepktitle}} Pack">
                            <input type="hidden" name="planamount2" id="planamount2" value="{{threepkpayamt}}">
                            <input type="hidden" name="MpkgID2" id="MpkgID2" value="{{threeMpkgID}}">
                            <input type="hidden" name="MpkgPeriodinDays2" id="MpkgPeriodinDays2" value="{{threeMpkgPeriodinDays}}">

                            <button class="price2" (click)="confirmPayment('plan2','planamount2','MpkgID2','MpkgPeriodinDays2')">Pay Now</button>
                          </div>
                        </div>
                      </div>


                      <div class="col-md-4" *ngIf="sixmonth != '0'">
                        <div class="memberShiInner">
                          <div class="promo">
                            <div class="deal">
                              <span>{{sixktitle}} Pack</span>
                              <span>Flat Rs.{{sixpkdisamt}} OFF ON 6 Month PACKAGE</span>
                            </div>
                            <span class="price"><span style="font-size: 16px;
    color: #00c573;">{{sixdiscount}}% OFF</span> <span class="hoverAmou"><i class="fa fa-inr"></i>{{sixpkpayamt}}</span> <span style="color: #bd0035; text-decoration: line-through; font-size: 18px;"><i class="fa fa-inr"></i>{{sixpkoriamt}}</span></span>
                            <ul class="features">
                              <li><i class="fa fa-phone"></i> Unlimited Call View</li>
                              <li><i class="fa fa-heart"></i> Unlimited Send Interest</li>
                              <li><i class="fa fa-search"></i> Unlimited Search Profile and View Details</li>   
                            </ul>
                            <input type="hidden" name="plan3" id="plan3" value="{{sixktitle}} Pack">
                            <input type="hidden" name="planamount3" id="planamount3" value="{{sixpkpayamt}}">
                            <input type="hidden" name="MpkgID3" id="MpkgID3" value="{{threeMpkgID}}">
                            <input type="hidden" name="MpkgPeriodinDays3" id="MpkgPeriodinDays3" value="{{sixMpkgPeriodinDays}}">
                            <button class="price3" (click)="confirmPayment('plan3','planamount3','MpkgID3','MpkgPeriodinDays3')">Pay Now</button>
                          </div>
                        </div>
                      </div>


                      <div class="col-md-4" *ngIf="yearmonth != '0'">
                        <div class="memberShiInner planYearly">
                          <div class="promo">
                            <div class="deal">
                              <span>{{yearpktitle}} Pack</span>
                              <span>Flat Rs.{{yearpkdisamt}} OFF ON 1 Year PACKAGE</span>
                            </div>
                            <span class="price"><span style="font-size: 16px;
    color: #00c573;">{{yeardiscount}}% OFF</span> <span class="hoverAmou"><i class="fa fa-inr"></i>{{yearpkpayamt}}</span> <span style="color: #bd0035; text-decoration: line-through; font-size: 18px;"><i class="fa fa-inr"></i>{{yearpkoriamt}}</span></span>
                            <ul class="features">
                              <li><i class="fa fa-phone"></i> Unlimited Call View</li>
                              <li><i class="fa fa-heart"></i> Unlimited Send Interest</li>
                              <li><i class="fa fa-search"></i> Unlimited Search Profile and View Details</li>   
                            </ul>
                            <input type="hidden" name="plan4" id="plan4" value="{{yearpktitle}} Pack">
                            <input type="hidden" name="planamount4" id="planamount4" value="{{yearpkpayamt}}">
                            <input type="hidden" name="MpkgID4" id="MpkgID4" value="{{yearMpkgID}}">
                            <input type="hidden" name="MpkgPeriodinDays4" id="MpkgPeriodinDays4" value="{{yearMpkgPeriodinDays}}">
                            <button class="price4" (click)="confirmPayment('plan4','planamount4','MpkgID4','MpkgPeriodinDays4')">Pay Now</button>
                          </div>
                        </div>
                      </div>








                  </div>
                </div>
              </div>
            </div>
                                    </div>
                                </div>
                        </div>  
                </div>
            </div>
        </div>
</div>    
  




