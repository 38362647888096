<p>scroll works!</p>

<div class="search-reault" 
infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
(scrolled)="onScroll()">
<div class="mainContent clearfix">

<div *ngFor="let scrolldash of mymatchscrollresult"> 
    <div class="col-md-4">
      <div class="matchesDiv">
          <div class="ignorBt">Ignore <i class="fa fa-close"></i></div>
          <div class="matchesPic">
            <div class="coun">{{scrolldash.MemberCNtMultiplePhotos}}</div>
            <div class="leftA"> <i class="fa fa-angle-left"></i> </div>
            <div class="rightA"> <i class="fa fa-angle-right"></i> </div>
              <img [src]='photopath+scrolldash.MemberPhotoPath'>
              <div *ngIf="scrolldash.MemberIsPhotoUpload === '0'">
              <button class="requestBtUp">Request Photo</button>
            </div>
              <i class="fa fa-camera phoIco"></i>
          </div>
          <div class="callDetais">
              <div class="floatNeed">
              <span data-tooltip="Call Now"><i class="fa fa-phone phoneIc" data-toggle="modal" data-target="#callModal"></i></span>
              <span data-tooltip="Sent Intrest"><i class="fa fa-heart heartIc likeIntro" *ngIf="scrolldash.MemberSentInterestFlag !== '0'"></i><i class="fa fa-heart heartIc" *ngIf="scrolldash.MemberSentInterestFlag == '0'"></i></span>
              <span data-tooltip="Shortlisted">
                <i class="fa fa-star starIc shortLis" *ngIf="scrolldash.MemberShortlistFlag !== '0'"></i>
                <i class="fa fa-star starIc" *ngIf="scrolldash.MemberShortlistFlag == '0'"></i>
              </span>
              </div>

              <div class="detailsOuter">
              <h4 class="callDetaillsName">{{scrolldash.MemberName}} ({{scrolldash.MemberProfileID}})</h4>
              <p>{{scrolldash.MemberEducation}}, Job-City - {{scrolldash.MemberJobCity}}</p>
              <p>{{scrolldash.MemberAge}} yrs, {{scrolldash.MemberHeight}} | {{scrolldash.MemberMartialStatus}}</p>
              <p>{{scrolldash.MemberCaste}}, {{scrolldash.MemberNativePlace}} </p>
            </div>
              
              <button type="button" class="proDetailsBt" (click)="memberdetailspost(scrolldash)">
                View Profile
              </button>
          </div>
      </div>
  
</div>
</div>


</div>

</div>

<div class="samplecover-spin" id="samplecover-spin" *ngIf="spinner"></div>
