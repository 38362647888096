import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringreplacepipe'
})
export class StringreplacepipePipe implements PipeTransform {

  transform(value: string, ...args: string[]): string {
    let replacemystring = value.replace('\"', "");
    return replacemystring.replace('"',"");
  }

}
