import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { WebsiteConstants } from '.././global/website-constants';

@Injectable({
  providedIn: 'root'
})
export class UploadMulPhotoService {

private masterUrl = WebsiteConstants.MasterUrl;  
uploadMulPhotoUrl = this.masterUrl+"UploadMultiplePhotos/UploadMultiplePhotos"
constructor(private http : HttpClient) { }
uploadMulPhotoPost(body,headers)
{
  return this.http.post(this.uploadMulPhotoUrl,body,headers)
}
}
