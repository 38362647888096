import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { WebsiteConstants } from '.././global/website-constants';
@Injectable({
  providedIn: 'root'
})
export class NearmeService {

  private masterUrl = WebsiteConstants.MasterUrl;
  private siteurl= this.masterUrl;
  private nearmeurl = this.siteurl+"DashboardNearYou/DashboardNearYou";
  constructor(private http : HttpClient) { }
  
  nearmefunction(body,headers)
  {
    return this.http.post(this.nearmeurl,body,headers)
  }
}
